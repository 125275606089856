import React, { useState } from 'react';
import {
    Divider,
    List,
    ListItemButton,
    Badge,
    ListItemIcon,
    ListItemText,
    Collapse,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import groupArrow from '../../assets/icons/icon-arrow-white.svg';

interface Props {
    label: string,
    link: string,
    icon?: any,
    children: { label: string, link: string }[],
    type?: string,
    location?: any,
    isSidebarOpened?: boolean,
    nested?: boolean
}


const SidebarLink: React.FC<Props> = ({ label, link, icon, children, type, location, isSidebarOpened, nested }) => {
    const isLinkActive = (link != "") && (location.pathname === link || location.pathname.indexOf(link) !== -1);
    var [isOpen, setIsOpen] = useState(true);
    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    }
    if (type == "divider") return <Divider_Custom />;
    if (children.length > 0) {
        return (
            <>
                <ListItemButton_Custom
                    onClick={toggleCollapse}
                    highlight={`true`}
                    nested={`${nested}`}
                    disableRipple
                >
                    <ListItemText primary={label} />
                    <GroupIcon>
                        {isOpen ? <img src={groupArrow}></img>
                            : <Img src={groupArrow}></Img>
                        }
                    </GroupIcon>
                </ListItemButton_Custom>
                {children && (
                    <Collapse
                        in={isOpen && isSidebarOpened}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            {children.map(e => (
                                <SidebarLink
                                    key={e.label}
                                    label={e.label ? e.label : ""}
                                    link={e.link ? e.link : ""}
                                    icon={<FiberManualRecordIcon sx={{fontSize:"small"}}/>}
                                    location = {location}
                                    children={[]}
                                    isSidebarOpened={isSidebarOpened}
                                    nested={true}
                            />
                            ))}
                        </List>
                    </Collapse>
                )}
            </>
        );
    }
    return (
        <ListItemButton_Custom
            linkactive={`${isLinkActive && !nested}`}
            highlight={`false`}
            nested={`${nested}`}
            component={Link}
            to={link}
            disableRipple
        >
            {nested ? <Badge variant="dot"></Badge> : null}
            {icon ?
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                : null}
            <ListItemText primary={label} />
        </ListItemButton_Custom>
    );
}

interface LinkActive {
    linkactive?: string,
    highlight?: string,
    nested? : string,
    component?: any,
    to?: any,
}

export default SidebarLink;

const ListItemButton_Custom = styled(ListItemButton).attrs<LinkActive>(({ linkactive, highlight, nested, ...props }) => ({
    linkactive: linkactive,
    highlight: highlight,
    nested: nested,
})) <LinkActive>`
    text-decoration: "none";
    padding-left: 20px;
    &:hover, &:focus {
      background-color: ${(props) => props.theme.palette.background.light};
    };
    
    ${(props) => props.linkactive === "true" ? `
        background-color:  ${props.theme.color.sidebarBackgroundActive};
        & .MuiListItemText-primary, & .MuiSvgIcon-root{
            color: ${props.theme.color.sidebarTextActive};
        }
    `: `
        & .MuiListItemText-primary, & .MuiSvgIcon-root{
            color: ${props.theme.color.sidebarText};
        }
    `};
    
    ${(props) => props.highlight === "true" ? `
        & .MuiListItemText-primary, & .MuiSvgIcon-root{
            color: ${props.theme.color.sidebarTextActive};
        }
        &:hover, &:focus {
            background-color: #FFFFFF,
        };
    `: ``};

    ${(props) => props.nested === "true" ? `
        & .MuiListItemIcon-root{
            min-width: 28px;
        };
    `: `
        & .MuiListItemIcon-root{
            min-width: 40px;
        };
    `};
`

const Divider_Custom = styled(Divider)`
    background-color: #243D51;;
`

const GroupIcon = styled(ListItemIcon)`
    display: flex; justify-content: flex-end;
`

const Img = styled.img`
    transform: rotate(90deg);
    margin-right: 4px;
`
