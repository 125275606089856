import requestInstance from "configure/requestConfig";
import SnackbarUtils from "helpers/utils";
import { number } from "yup/lib/locale";
import { formatDate } from "../helpers/formatDate";
import { DEVICES } from "./useRequest";

export type IConfigItem = (
    IArsenicConfigItem |
    IConveyorConfigItem |
    IGpsTrackingConfigItem |
    ILandSlideConfigItem |
    IPiezoConfigItem |
    ITruckConfigItem |
    IWaterLevelConfigItem |
    IWeatherConfigItem
);

export interface IArsenicConfigItem {
    device_id: string;
    latitude: string;
    longitude: string;
    sampling_rate: number;
    threshold: number;
    consecutive_time: number;
}

export interface ILandSlideConfigItem {
    device_id: string;
    sampling_rate: number;
    latitude: number;
    longitude: number;
    altitude: number;
}
export interface IGpsTrackingConfigItem {
    device_id: string;
    height: number;
}

export interface IPiezoConfigItem {
    device_id: string;
    sampling_rate: number;
    ntc_res: number;
    ntc_beta: number;
    piezo_g: number;
    piezo_k: number;
    piezo_zero_read: number;
    piezo_temp: number;
    tip_elevation: number;
    latitude: string;
    longitude: string;
    area: string;
    observation: string;
    remark: string;
    elevation: number;
}

export interface IWaterLevelConfigItem {
    device_id: string;
    sampling_rate: number;
    latitude: string;
    longitude: string;
}

export interface IConveyorConfigItem {
    device_id: string;
    latitude: string;
    longitude: string;
    sampling_rate: number;
    line: string;
    name: string;
    reverse: boolean;
    threshold: number;
}

export interface IWeatherConfigItem {
    device_id: string;
    sampling_rate: number;
    latitude: string;
    longitude: string;
}
export interface ITruckConfigItem {
    device_id: string;
    latitude: string;
    longitude: string;
}

/* 
    Default config value
*/

export const defaultArsenicConfigItem: IArsenicConfigItem = {
    device_id: "",
    latitude: "",
    longitude: "",
    sampling_rate: 0,
    threshold: 0,
    consecutive_time: 0,
}
export const defaultLandSlideConfigItem: ILandSlideConfigItem = {
    device_id: "",
    sampling_rate: 0,
    latitude: 0,
    longitude: 0,
    altitude: 0,
}
export const defaultGpsTrackingConfigItem: IGpsTrackingConfigItem = {
    device_id: "",
    height: 0,
}
export const defaultPiezoConfigItem: IPiezoConfigItem = {
    device_id: "",
    sampling_rate: 0,
    ntc_res: 0,
    ntc_beta: 0,
    piezo_g: 0,
    piezo_k: 0,
    piezo_zero_read: 0,
    piezo_temp: 0,
    tip_elevation: 0,
    latitude: "",
    longitude: "",
    area: "",
    observation: "",
    remark: "",
    elevation: 0
}
export const defaultWaterLevelConfigItem: IWaterLevelConfigItem = {
    device_id: "",
    sampling_rate: 0,
    latitude: "",
    longitude: "",
}
export const defaultConveyorConfigItem: IConveyorConfigItem = {
    device_id: "",
    latitude: "",
    longitude: "",
    sampling_rate: 0,
    line: "",
    name: "",
    reverse: false,
    threshold: 0,
}
export const defaultWeatherConfigItem: IWeatherConfigItem = {
    device_id: "",
    sampling_rate: 0,
    latitude: "",
    longitude: "",
}
export const defaultTruckConfigItem: ITruckConfigItem = {
    device_id: "",
    latitude: "",
    longitude: "",
}

/* 
    API Get Method
*/


export async function reqGetConfig(type: DEVICES) {
    const path = `/api/config/${type.toString()}`;
    return requestInstance.get(path);
}

/* 
    interface upload
*/

export interface ISetArsenicConfig {
    device_id: string;
    device_name: string;
    sampling_rate: number;
    threshold: number;
    consecutive_time: number;
    latitude: number;
    longitude: number;
}

export interface ISetLandSlideConfig {
    device_id: string;
    device_name: string;
    sampling_rate: number;
}

export interface ISetPiezoConfig {
    device_id: string;
    device_name: string;
    sampling_rate: number;
    ntc_res: number;
    ntc_beta: number;
    piezo_g: number;
    piezo_k: number;
    piezo_zero_read: number;
    piezo_temp: number;
    tip_elevation: number;
    latitude: number;
    longitude: number;
    area: string;
    observation: string;
    remark: string;
    elevation: number;
}

export interface ISetWaterLevelConfig {
    device_id: string;
    device_name: string;
    sampling_rate: number;
    latitude: number;
    longitude: number;
}

export interface ISetConveyorConfig {
    device_id: string;
    device_name: string;
    sampling_rate: number;
    threshold: number;
    // latitude: number;
    // longitude: number;
    line: string;
    name: string;
    reverse: boolean;
}
export interface ISetWeatherConfig {
    device_id: string;
    device_name: string;
    sampling_rate: number;
    latitude: number;
    longitude: number;
}
export interface ISetTruckConfig {
    device_id: string;
    device_name: string;
    latitude: number;
    longitude: number;
}
export interface ISetGpsConfig {
    device_id: string;
    device_name: string;
    height: number;
}

export type ISetConfig = (
    ISetArsenicConfig |
    ISetConveyorConfig |
    ISetGpsConfig |
    ISetLandSlideConfig |
    ISetPiezoConfig |
    ISetTruckConfig |
    ISetWaterLevelConfig |
    ISetWeatherConfig
)
/* 
    API upload method
*/
export async function reqUploadConfig(type: DEVICES, values: ISetConfig) {
    const path = `/api/config/${type.toString()}`;
    const params = values;
    console.log(params);

    const res = await requestInstance.patch(path, params).then((result) => {
        SnackbarUtils.success("Setting config success !");
        return result;
    }).catch((err) => {
        SnackbarUtils.error("Setting config fail !");
        return err;
    });
    console.log(res);

    return res;
}