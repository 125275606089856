import { FunctionComponent, useEffect, useMemo, useState } from "react";
import ReactLeafletKml from 'react-leaflet-kml';
import { useMap } from "react-leaflet";
import useMapContext, { getMapFile } from "context/MapContext";
import * as Leaflet from 'leaflet';
import * as Core from '@react-leaflet/core';
import { EditStyleKmlLine } from "./EditStyleKmlLine";
export interface KmlOverlayProps {
    fileType: any;
    lineWidth?: number;
}

const KmlOverlay: FunctionComponent<KmlOverlayProps> = (props: KmlOverlayProps) => {
    const [lastLayer, setLastLayer] = useState(null);
    const [kml, setKml] = useState<any>(null);
    const mapContext = useMapContext();
    const map = useMap();
    const leafletContext = Core.useLeafletContext();
    const initLayer = async () => {
        const kmlFileName = props.fileType?.file_name;
        if (kmlFileName === undefined) {
            return;
        }
        let newKml: any = mapContext.getOverlayData(kmlFileName);
        if (newKml === undefined) {

            getMapFile(kmlFileName).then(
                (kmlFileRes: any) => {
                    newKml = EditStyleKmlLine(props, kmlFileRes);
                    mapContext.addOverlayData(kmlFileName, newKml);
                }
            ).catch((err) => {
                console.error({err});
                
            });
        }
        const kmlLayer = new (Leaflet as any).circle([0, 0], { radius: 0 }).on("add", function (event: any) {
            leafletContext.layersControl?.removeLayer(event.target);
            let newKmlLayer = new (Leaflet as any).KML(mapContext.getOverlayData(kmlFileName));
            newKmlLayer.addTo(map);
            leafletContext.layersControl?.addOverlay(newKmlLayer, props.fileType.name);
            setLastLayer(newKmlLayer);
        });;
        leafletContext.layersControl?.addOverlay(kmlLayer, props.fileType.name);
        setLastLayer(kmlLayer);

    }

    useEffect(() => {
        console.time("initLayer")
        initLayer();
        console.timeEnd("initLayer")
        return () => {
            if (lastLayer !== null) {
                leafletContext.layersControl?.removeLayer(lastLayer);
            }
        }
    }, []);

    return useMemo(() => (
        kml !== null ?
            <ReactLeafletKml key={'test-key'} kml={kml} />
            : null
    ), [kml]);
}

export default KmlOverlay;

