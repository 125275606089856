import React, { useEffect, useState } from "react";
import { Box, IconButton, Paper } from "@mui/material";
import { usePageContext } from "./GpsTrackingPageDevice";
import * as GpsTrackingAPI from "api/useGpsTrackingAPI";
import styled from 'styled-components'
import { ISensorItem, reqDeviceData } from "api/useGpsTrackingAPI";
import BaseMap from "components/Map/BaseMap";
import SensorMarker from "components/Map/Marker/SensorMarker";
import { DEVICES } from "api/useRequest";
import { LatLngExpression } from "leaflet";
import { useParams } from "react-router-dom";
import HistoryLine from "../gpstracking/HistoryLine";
import HistoryPoint from "../gpstracking/HistoryPoint";
import { SimpleLoading } from "components/Loading/SimpleLoading";

const MAP_HEADER_TEXT = "GPS ตำแหน่งอุปกรณ์ ID อุปกรณ์";
export function MapCard() {
    const { stateContext, dispatchContext } = usePageContext();
    const [latLngHistory, setLatLngHistory] = useState<Record<string, LatLngExpression[] | LatLngExpression[][]>>();
    const [historyDataByID, setHistoryDataByID] = useState<Record<string, ISensorItem[]>>();
    /* Get params */
    let { deviceID } = useParams<{ deviceID: string; }>();
    const createDevicePath = async () => {

        let newLatLngs = { ...latLngHistory };
        let newHistoryDataByID = { ...historyDataByID };

        const id = deviceID;
        let items: ISensorItem[] = await getDeviceDataByID(id);
        items = items.sort((a: ISensorItem, b: ISensorItem) => (a.timestamp > b.timestamp ? 1 : -1));
        newHistoryDataByID[id] = items;

        let latLngs: LatLngExpression[] | LatLngExpression[][] = items.map((element: ISensorItem) => [Number(element.latitude), Number(element.longitude)]);
        console.log("latLngs", latLngs);
        newLatLngs[id] = latLngs;

        console.log("newLatLngs", newLatLngs);
        setLatLngHistory(newLatLngs);
        console.log("newHistoryDataByID", newHistoryDataByID);
        setHistoryDataByID(newHistoryDataByID);
    }

    const getDeviceDataByID = async (id: string) => {
        const res = await reqDeviceData(stateContext.dateRange, id);
        console.log(id, res);
        if (res.status != 200) {
            return [];
        }
        const { items } = res.data;
        return items;
    }

    useEffect(() => {
        createDevicePath();
    }, [stateContext.allSensorList, stateContext.curSensorList, stateContext.dateRange])


    return (
        <MapBox>
            {stateContext.curSensorItem ?
                <BaseMap>
                    <SensorMarker
                        key={`GpsMarker_${stateContext.curSensorItem.device_id}`}
                        type={DEVICES.GPS}
                        state={stateContext.curSensorItem.state}
                        position={[Number(stateContext.curSensorItem.latitude), Number(stateContext.curSensorItem.longitude)]}
                        label={stateContext.curSensorItem.device_name}
                        data={stateContext.curSensorItem}
                    />
                    <HistoryLine latLngHistory={latLngHistory} />
                    <HistoryPoint historyDataByID={historyDataByID} />
                </BaseMap>
                : <SimpleLoading />}
        </MapBox>
    );
}

const MapBox = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        height: "500px"
    },
    [theme.breakpoints.down('md')]: {
        height: "300px"
    },
}));