import axios, { AxiosResponse, AxiosError } from 'axios'

import { authStorage } from '../configure/localStorageConfig'

const authDataFromStorage = authStorage.get()

const requestInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
})

export default requestInstance

export const setAuthorization = (access_token: string, eno:number) => {
  requestInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
  requestInstance.defaults.headers.common['X-EMP-NO'] = eno.toString();
}

export const unsetAuthorization = () => {
  delete requestInstance.defaults.headers.common['Authorization'];
  delete requestInstance.defaults.headers.common['X-EMP-NO'];
}

export const listenOnUnauthorized = (callback: () => void) => {
  requestInstance.interceptors.response.use(undefined, (error) => {
    const statusCode = error.response ? error.response.status : null

    if (statusCode === 401) {
      callback()
    }

    return Promise.reject(error)
  })
}

if (authDataFromStorage && authDataFromStorage.access_token && authDataFromStorage.eno) {
  setAuthorization(authDataFromStorage.access_token,authDataFromStorage.eno)
}

export type RequestResponse<T> = AxiosResponse<T>
export type RequestError = AxiosError<Error>

/**
 * Please at section `Options`
 * https://react-query.tanstack.com/reference/useQuery
 * NOTES: need to update more if we want to use more option and need to find the why how to set this type by `UseQueryOptions`
 */
export interface RequestQueryOptions<T> {
  enabled?: boolean
  onSuccess?: (data: RequestResponse<T>) => void
  onError?: (error: RequestError) => void
  refetchOnMount?: boolean | 'always'
  refetchOnWindowFocus?: boolean | 'always'
}

// create server for mock api.
export const requestInstancemock = axios.create({
  // baseURL: "http://localhost:8080",
  baseURL: "http://35.221.201.117:5001",
  headers: {
    'Content-Type': 'text/plain',
  },
})