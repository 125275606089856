import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack'
import React from 'react'

let useSnackbarRef: WithSnackbarProps
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar()
  return null
}

const SnackbarUtils = {
  success(msg: string, autoHideDuration?: number) {
    this.toast(msg, 'success', autoHideDuration)
  },
  warning(msg: string, autoHideDuration?: number) {
    this.toast(msg, 'warning', autoHideDuration)
  },
  info(msg: string, autoHideDuration?: number) {
    this.toast(msg, 'info', autoHideDuration)
  },
  error(msg: string, autoHideDuration?: number) {
    this.toast(msg, 'error', autoHideDuration)
  },
  toast(msg: string, variant: VariantType = 'default', autoHideDuration: number = 2000) {
    useSnackbarRef.enqueueSnackbar(msg, {
        variant,
        preventDuplicate: true,
        autoHideDuration: autoHideDuration,
    })
  }
}

export default SnackbarUtils;

export const groupBy = (xs:any[], key:string) => {
  return xs.reduce((prev, cur) => ({
    ...prev,
    [cur[key]]: (prev[cur[key]] || []).concat(cur)
  }), {});
};

export const getKeys = (obj:object) => {
  var keys = [];
  for(var key in obj){
     keys.push(key);
  }
  return keys;
}

export const decimalRound = (value: number, precision: number) => {
    const factor = Math.pow(10, precision);
    return Math.round((value + Number.EPSILON) * factor) / factor;
}
