import { FunctionComponent } from "react";
import { UserData } from '../../../api/useAdminAPI';
import { Box, Checkbox, Paper, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@mui/material";
import EnhancedTableHead from "./EnhancedTableHead/EnhancedTableHead";
import useUserTable from "./useUserTable";
import MenuButton from "./MenuButton/MenuButtonView";

export interface UserTableProps {
    activeUser: UserData[];
    allPermission?: { name: string, description: string }[];
    reloadfn: () => void;
}



const UserTable: FunctionComponent<UserTableProps> = (props) => {
    const { activeUser, reloadfn } = props;
    const {
        isLoadTable,
        dispRows,
        handleItemChecked,
        enhancedHead: {
            headCells,
            order,
            orderBy,
            isCheckedAll,
            numSelectedRow,
            handleRequestSort,
            handleSelectAllClick,
        },
        pagination: {
            rowsPerPageOptions,
            rowsPerPage,
            page,
            count,
            emptyRows,
            onPageChange,
            onRowsPerPageChange,
        }
    } = useUserTable(props);
    return (
        <Box >
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    {!isLoadTable ? <Table
                        sx={{ minWidth: 20 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            headCells={headCells}
                            rowCount={activeUser.length}
                            order={order}
                            orderBy={orderBy}
                            numSelected={numSelectedRow}
                            isCheckedAll={isCheckedAll}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                            {dispRows.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={row.checked}
                                        tabIndex={-1}
                                        key={row.employeeNumber}
                                        selected={row.checked}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={row.checked}
                                                onClick={() => handleItemChecked(row.employeeNumber)}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            width="15%"
                                        >
                                            {row.employeeNumber}
                                        </TableCell>
                                        <TableCell align="left">{row.role}</TableCell>
                                        <TableCell align="left">{row.permissionLable}</TableCell>
                                        <TableCell align="right">
                                            <MenuButton data={row.editPermissionProps} reloadfn={reloadfn} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table> : <>LOADING</>}
                </TableContainer>
                <TablePagination
                    component="div"
                    count={count}
                    rowsPerPageOptions={rowsPerPageOptions}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                />
            </Paper>
        </Box>
    );
}

export default UserTable;