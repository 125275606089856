import React from 'react'
import {
  useLocation, useHistory
} from "react-router-dom";
import styled from 'styled-components'

import {
  Grid,
  Typography
} from '@mui/material';

/**
 * API
 */
import {
  useGetNoticationToken,
  ReqLineToken,
} from 'api/useRequest'

/**
 * Localstorage
 */
import {
  linitifyStorage,
  linenotifyStorageData,
  SETTINGPAGE
} from 'configure/localStorageConfig'

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let DefaultCountDown: number = 2, intervalTime:any;

interface LineProps { }

function LineNoti(props: LineProps) {

  const LineToken = useGetNoticationToken()
  let history = useHistory();
  
  const [CountDown, setCountDown ] = React.useState<number>(DefaultCountDown)

  let query = useQuery();

  React.useEffect(() => {
    (() => {
      // response: ?code=aONfXtULWs3XPfnhOmfiJ1&state=6
      var code:string | null = "";
      var state: string | null = "";
      code = query.get("code");
      state = query.get("state");
      console.log("code: ", code)
      console.log("state: ", state)
      var param: ReqLineToken = {
        code: code
      }
      LineToken.mutate(param, {
        onSuccess: (res) => {
          /**
           * response hook line token.
           * status : 400 -> invalid code
           * status : 200 -> access_token is issued. it have Token data.
           */
          var token: string | null = res.data?.access_token
          var local:linenotifyStorageData = linitifyStorage.get()
          var Ldata: linenotifyStorageData = {
            from: SETTINGPAGE.NOTI,
            setting: local.setting,
            notiToken: token,
            note: local.note,
            enable: local.enable
          }
          linitifyStorage.set(Ldata)
          history.push(`/setting`)
        },
        onError: () => {
          var token: string | null = '';
          var local:linenotifyStorageData = linitifyStorage.get()
          var Ldata: linenotifyStorageData = {
            from: SETTINGPAGE.EMAIL,
            setting: local.setting,
            notiToken: token,
            note: local.note,
            enable: local.enable
          }
          linitifyStorage.set(Ldata)
          alert("ไม่สามารถขอ token line ได้กรุณาติดต่อ admin")
          history.push(`/setting`)
        }
      })
    })()

    return () => {
      // clearInterval(intervalTime)
    }
  }, [])


  const retureSettingLine = () => {
    // var count:number = DefaultCountDown
    // intervalTime = setInterval(() => {
    //   count = count - 1
    //   setCountDown(count)
    //   if (count === 0){
    //     clearInterval(intervalTime)
    //     // goto setting line noti
    //     // window.location.replace(`${window.location.protocol}//${window.location.host}/setting`);
    //     setTimeout(() => {
    //       history.push(`/setting`)
    //     }, 500);
    //   }
    // }, 1000)
  }

  return (
    <>
      <MainGrid container direction="row" justifyContent="center" alignItems="center" columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
        <Typography variant="h4" gutterBottom component="div">
          {/* ยืนยันสมัคร Line Notify สำเร็จแล้วกรุณารอซักครู่จะกลับสู่หน้าหลัก {CountDown} วินาที */}
          กรุณารอซักครู่
        </Typography>
      </MainGrid>
    </>
  )
}

export default LineNoti

const MainGrid = styled(Grid)({
  height: "100vh", 
  width: "100vw"
});