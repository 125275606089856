import React, {createContext, Dispatch, useReducer, useContext} from "react";
export type HeaderTextLink = {
  texts: string[],
  link: string,
};

type State = {
  isSidebarOpened: boolean,
  headerText: string | HeaderTextLink
}
type Action = {
  type: "TOGGLE_SIDEBAR" | "CHANGE_HEADERTEXT",
  payload: string | HeaderTextLink
};

const initialState = {
    isSidebarOpened: true,
    headerText: ""
}
interface IContext {
  state: State,
  dispatch: (action: Action) => void
}

const layoutReducer = (state:State, action:Action) => {
    switch (action.type) {
      case "TOGGLE_SIDEBAR":
        return { ...state, isSidebarOpened: !state.isSidebarOpened };
      case "CHANGE_HEADERTEXT":
        return { ...state, headerText: action.payload}
    }
  }

var LayoutContext = createContext<IContext>({
        state: initialState,
        dispatch: () => {}
    });

const LayoutProvider: React.FC = ({ children }) => {
  var [state, dispatch] = useReducer(layoutReducer, initialState);
  const value = { state, dispatch };
  return (
    <LayoutContext.Provider value={value}>
        {children}
    </LayoutContext.Provider>
  );
}

function useLayoutState() {
  var context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context.state;
}

function useLayoutDispatch() {
  var context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context.dispatch;
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar, changeHeaderText};

// ###########################################################
const toggleSidebar = (dispatch:Dispatch<any>) => {
  dispatch({
    type: "TOGGLE_SIDEBAR",
    payload: ""
  });
}

const changeHeaderText = (dispatch: Dispatch<any>, text: string | HeaderTextLink) => {
  dispatch({
    type: "CHANGE_HEADERTEXT",
    payload: text
  });
}
