import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import type { RouteComponentProps } from 'react-router-dom'
import { useLayoutDispatch, changeHeaderText } from "../../context/LayoutContext";
import UserTable from './Usertable/UserTableView';
import PendingTable from '../../components/Table/PendingTable';
import {
    Box,
    Grid,
    Typography,
    TextField,
    InputAdornment,
    Tabs,
    Tab,
    Skeleton,
} from "@mui/material"

import SearchIcon from '../../assets/icons/icon-search.svg';
import type { UserData } from 'api/useAdminAPI';
import { PermissionData } from 'api/usePermissionAPI';
import { PermissionDetail } from 'components/Dialog/Permissions';
import useUserContext from 'context/UserContext';
import color from 'constants/Colors'
import { useTabMenu } from './useTabMenu';
import { GetAllPermissions, GetAllUser } from 'api/useUserManagementAPI';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const createPermissionDetail = (
    name: string,
    thName: string,
    enable: boolean
): PermissionDetail => {
    return {
        name,
        thName,
        enable,
    };
}
export const arrayRemove = (arr: PermissionData[] | null, value: string[]): PermissionData[] => {
    if (arr === null) {
        return []
    }
    return arr.filter(function (ele) {
        return !value.includes(ele.name);
    });
}

const UserManagementPage: React.FC<RouteComponentProps> = () => {
    const hookTab = useTabMenu({ initialTab: 0 });
    const dispatch = useLayoutDispatch()
    useEffect(() => {
        changeHeaderText(dispatch, "จัดการผู้ใช้")
    }, []);

    const userListPermission = useUserContext()
    const allPermission = useRef([] as { name: string, description: string }[]);
    const fullPendingUsers = useRef([] as UserData[]);
    const fullActivatedUsers = useRef([] as UserData[]);
    const [pendingUsers, setPendingUsers] = useState([] as UserData[]);
    const [activatedUsers, setActivatedUsers] = useState([] as UserData[]);

    const reloadAllUser = () => {
        Promise.all([
            GetAllPermissions(),
            GetAllUser("activated", "0", "9999999"),
            GetAllUser("created", "0", "9999999")
        ]).then((values) => {
            console.log("values", values);
            let allPermissionResult = values[0].data.permissions;
            let activatedUsersResult = values[1].data.users;
            let pendingUsersResult = values[2].data.users;
            allPermission.current = allPermissionResult;
            fullPendingUsers.current = pendingUsersResult;
            fullActivatedUsers.current = activatedUsersResult;
            const temp: PermissionDetail[] = []
            arrayRemove(allPermissionResult, ["admin", "user", "gateway"]).forEach(e => {
                temp.push(createPermissionDetail(e.name, e.description, false))
            });
            userListPermission.setListPermissions(temp);
            setActivatedUsers(activatedUsersResult || ([] as UserData[]));
            setPendingUsers(pendingUsersResult || ([] as UserData[]))
        }).catch((err) => {
            console.log("err", err);
        })
    }

    useEffect(() => {
        reloadAllUser();
    }, []);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        let value = event.target.value || "";
        console.log("value", value);

        if (value === "") {
            setActivatedUsers(fullActivatedUsers.current);
            setPendingUsers(fullPendingUsers.current);
        } else {
            setActivatedUsers(fullActivatedUsers.current.filter((row) => {
                return row.employee_id.toString().includes(value.toLowerCase());
            }));
            setPendingUsers(fullPendingUsers.current.filter((row) => {
                return row.employee_id.toString().includes(value.toLowerCase());
            }));
        }
    }

    return (
        <Container>
            <Grid container direction="column" columnSpacing="5px" rowSpacing="5px">
                <Grid item>
                    <HeaderText>จัดการผู้ใช้</HeaderText>
                </Grid>

                <Grid item>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={hookTab.value} onChange={hookTab.handleChange} aria-label="basic tabs example">
                                <Tab label="ผู้ใช้" {...a11yProps(0)} />
                                <Tab label={`รอยืนยัน (${pendingUsers ? pendingUsers.length : 0})`} {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <br />

                        <SearchTextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={SearchIcon} alt="SearchIcon" width="20px" height="20px" />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="ค้นหา"
                            sx={SearchTextSX}
                            onChange={handleSearchChange}
                        />

                        <TabPanel value={hookTab.value} index={0}>
                            {allPermission.current.length > 0 ?
                                <UserTable activeUser={activatedUsers} allPermission={allPermission.current} reloadfn={reloadAllUser} /> :
                                <Skeleton animation="wave" width="100%" height={118} />
                            }
                        </TabPanel>
                        <TabPanel value={hookTab.value} index={1}>
                            <PendingTable data={pendingUsers} />
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>

        </Container>
    )
}

export default UserManagementPage


const Container = styled.div`
  align-items: left;
  justify-content: left;
  
  > p {
    line-height: 1;
    font-size: 20px;
  }
`
const HeaderText = styled(Typography)`
  color: "#000000";
  font-size: 'h6.fontSize';
`

const SearchTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#919EAB',
        },
        '&:hover fieldset': {
            borderColor: '#0739A2',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0739A2',
        },
    },

});

const SearchTextSX = {
    width: "30%",
    height: "5%",
    backgroundColor: color.white,
}