import React, { useEffect, useState } from "react";
import DetailCardList, { IDataStation } from "components/List/DetailCardList";
import { usePageContext } from "./ConveyorPage";
import { useHistory } from "react-router-dom";
import { SimpleLoading } from "components/Loading/SimpleLoading";

export function CurrentDataList() {
    let history = useHistory();
    const { stateContext, dispatchContext } = usePageContext();
    const [dataStationList, setDataStationList] = useState<IDataStation[]>([])
    useEffect(() => {
        let conveyorLines: Record<string, any> = {};
        stateContext.curSensorList.forEach(e => {
            let key = e.line.replaceAll(" ", "")

            if (key === '') {
                /* Line is empty */
            } else if (key in conveyorLines) {
                conveyorLines[key].sensorList.push(e);
                conveyorLines[key].isWorking = (e.value > 0) ? true : conveyorLines[key].isWorking;
                conveyorLines[key].lineStatus = e.state === 'Offline' ? conveyorLines[key].lineStatus : e.value > 0 ? "ทำงาน" : conveyorLines[key].lineStatus !== " - " ? conveyorLines[key].lineStatus : "ไม่ทำงาน";
                conveyorLines[key].timestamp = (e.timestamp > conveyorLines[key].timestamp) ? e.timestamp : conveyorLines[key].timestamp;
                conveyorLines[key].onlineDevice = (e.state === 'Online') ? conveyorLines[key].onlineDevice + 1 : conveyorLines[key].onlineDevice;
            } else {
                conveyorLines[key] = {
                    name: e.line,
                    sensorList: [e],
                    timestamp: e.timestamp,
                    onlineDevice: (e.state === 'Online') ? 1 : 0,
                    isWorking: (e.value > 0),
                    lineStatus: e.state === 'Offline' ? " - " : e.value > 0 ? "ทำงาน" : "ไม่ทำงาน",
                };
            }
        });
        console.log("conveyorLines: ", conveyorLines);
        const datas: IDataStation[] = [];
        for (const key in conveyorLines) {
            if (Object.prototype.hasOwnProperty.call(conveyorLines, key)) {
                const element = conveyorLines[key];
                datas.push({
                    id: `Online: ${element.onlineDevice} / ${element.sensorList.length}`,
                    name: element.name,
                    date: element.timestamp,
                    // state: element.isOnline ? 'Online' : 'Offline',//(value === 0 ? "Offline" : "Online"),
                    data: [
                        { name: "สถานะสายพาน", value: element.lineStatus, unit: "", status: (element.onlineDevice !== 0) ? 'Online' : 'Offline' },
                    ],
                    // location: `${latitude}, ${longitude}`,
                    // path: "/conveyor"
                })
            }
        }
        setDataStationList(datas);
    }, [stateContext.curSensorList]);

    return dataStationList.length === 0 ? <SimpleLoading /> : <DetailCardList dataList={dataStationList} path="/conveyor" />;
}