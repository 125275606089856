import React, { FunctionComponent, useRef } from "react";
import { IPageContext } from "./ConveyorPage";
import { ISensorItem } from "api/useConveyorAPI";
import { DEVICES } from 'api/useRequest';
import SensorMarker from "components/Map/Marker/SensorMarker";
import { useMap, FeatureGroup } from "react-leaflet";

export const ConveyorMarkers: FunctionComponent<ConveyorMarkersProps> = ({ stateContext, mapTFPointByName, lineStatus }) => {
    const map = useMap();
    const refFeatureGroup = useRef<any>({});
    return (stateContext.curSensorList && mapTFPointByName && lineStatus &&
        <FeatureGroup
            ref={refFeatureGroup}
            eventHandlers={{
                add: () => {
                    map.fitBounds(refFeatureGroup.current.getBounds());
                }
            }}
        >
            {stateContext.curSensorList.map((e: ISensorItem, i: number) => (
                <SensorMarker
                    key={`ConveyorMarker_${i}`}
                    type={DEVICES.CONVEYOR}
                    state={e.state}
                    position={mapTFPointByName[e.name] === undefined ? [Number(e.latitude), Number(e.longitude)] : mapTFPointByName[e.name]}
                    label={e.device_name}
                    data={e}
                    conveyorStatus={(e.state === "Online" && e.value > 0)} />
            ))}
        </FeatureGroup>
    );
};
interface ConveyorMarkersProps {
    stateContext: IPageContext;
    mapTFPointByName: Record<string, any>;
    lineStatus: Record<string, boolean>;
}
export default ConveyorMarkers;
