import { FunctionComponent } from "react";
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import {
    Formik,
    FormikHelpers,
    FormikProps,
    Form,
    Field,
    FieldProps,
    useFormik,
} from 'formik';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField'
import { CancleButton, SaveButton } from "../Button/DialogButtonStyles";

export interface FieldItem {
    id: string;
    lable: string;
    value: string | number;
}

interface ThresholdSettingViewProps {
    open: boolean;
    fields: FieldItem[];
    formik: FormikProps<Record<string, string | number>>;
    buttonLabel?: string;
    dialogTitle?: string;
    onClickButton: () => void;
    onDialogClose: () => void;
    disabled?: boolean;

}

const ThresholdSettingView: FunctionComponent<ThresholdSettingViewProps> = (props) => {
    const {
        open,
        formik,
        fields,
        buttonLabel,
        dialogTitle,
        onClickButton,
        onDialogClose,
    } = props;
    console.log('ThresholdSettingView Props : ', props);




    return (
        <>
            <SettingButton variant="contained" type="submit" size="large" onClick={onClickButton} disabled={props.disabled} >
                {buttonLabel}
            </SettingButton>
            <Dialog
                fullWidth
                maxWidth='sm'
                open={open}
                onClose={onDialogClose}
                aria-labelledby={"threshold-dialog-setting"}
            >
                <DialogTitle id={"threshold-dialog-setting"}>
                    {dialogTitle}
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {fields.map((e: FieldItem) => {
                            return (
                                <div key={`field-${e.id}`}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <label htmlFor={e.id}>{e.lable}:</label>
                                    <TextField
                                        id={e.id}
                                        name={e.id}
                                        type={typeof e.value}
                                        value={formik.values[e.id]}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            )
                        })}
                    </DialogContent>
                    <DialogActions>
                        <CancleButton onClick={onDialogClose} color="primary">
                            ยกเลิก
                        </CancleButton>
                        <SaveButton variant="contained" type="submit" >
                            บันทึก
                        </SaveButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

export default ThresholdSettingView;


export const SettingButton = styled(Button)`
width: fit-content;
height: max-content;
color: white;
font-size: 16px;
border-color: #0739A2;
background-color: #0739A2;
&:hover {
    color: white;
    background-color: #0739A2;
    opacity:0.8;
}
text-transform: none;
`

