import { format } from "date-fns"

const addDigiNubmerStr = (snum: number) => {
  return ((Number(snum) < 10) ? "0" + snum.toString() : snum.toString())
}

export default function parseDateTime(date?: Date | string): string {

  let time: Date
  if (date === undefined || date === null) {
    time = new Date()
  } else {
    time = new Date(date)
  }

  return `${time.getFullYear()}-${addDigiNubmerStr(time.getMonth() + 1)}-${addDigiNubmerStr(time.getDate())} ${addDigiNubmerStr(time.getHours())}:${addDigiNubmerStr(time.getMinutes())}:${addDigiNubmerStr(time.getSeconds())}`
}