import React from "react";
import Logout from "./logout";
import Login from "./login";

interface Props {
    isLoggedIn: boolean,
    name?: string;
    eno: string;
    className?: string;
    children?: React.ReactNode;
    prefixCls?: string;
    email?: string;
}

const ProfileHeader: React.FC<Props> = (props) => {
    const isLoggedIn = props.isLoggedIn;
    return (
        <div>{isLoggedIn ?
            <Logout name={props.name} eno={props.eno} email={props.email} />
            :<Login/>}
        </div>

    );
}

export default ProfileHeader;
