import { Box, Checkbox, IconButton, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { FunctionComponent } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import color from "constants/Colors";
import { visuallyHidden } from '@mui/utils';
import { RowItem } from "../useUserTable";

export interface HeadCell {
    disablePadding: boolean;
    id: keyof RowItem;
    label: string;
}

export type Order = 'asc' | 'desc';

export interface EnhancedTableHeadProps {
    isCheckedAll: boolean;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof RowItem) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: HeadCell[];
}

const EnhancedTableHead: FunctionComponent<EnhancedTableHeadProps> = (props) => {
    const { isCheckedAll, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    return (
        <TableHead sx={{ backgroundColor: color.headTableBaackground }}>
            <TableRow>
                <TableCell padding="checkbox" align="left">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={isCheckedAll as boolean}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={(event) => onRequestSort(event, headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {headCells.length === 2 ?
                    <TableCell align="right"></TableCell>
                    :
                    null
                }

                {numSelected > 0 ?
                    <TableCell align="right">
                        <IconButton aria-label="delete" size="small" color="inherit">
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                    :
                    <TableCell align="right"></TableCell>
                }
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;