import requestInstance from "configure/requestConfig";
import parseDate from "helpers/parseDate";
import parseTime from "helpers/parseTime";
import { formatDate } from "../helpers/formatDate";

/* API response
{
    "device_id": "e6a7010707000002",
    "device_name": "GPS tracking01",
    "power_pri": 0,
    "power_sec": 0,
    "state": "Online",
    "latitude": "18.335745833",
    "longitude": "99.726785833",
    "altitude": "7",
    "speed": "0.002",
    "timestamp": "2021-12-18T12:16:40Z"
}
*/
export interface ISensorItem {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    state: string;
    latitude: string;
    longitude: string;
    altitude: string;
    speed: string;
    timestamp: string;
    real_altitude: number;
    [key: string]: any;
}

export const defaultSensorItem: ISensorItem = {
    device_id: "",
    device_name: "",
    power_pri: 0,
    power_sec: 0,
    state: "",
    latitude: "",
    longitude: "",
    altitude: "",
    speed: "",
    timestamp: "",
    real_altitude: 0,
}

export const MapStateToDeviceCardState: Record<string, string> = {
    "Online": "ON",
    "Offline": "OFF",
    "Error": "ERROR",
}

export async function reqCurDeviceData() {
    const path = "/api/iot/all";
    const params = {
        limit: "",
        offset: "",
        device: "gps_tracking"
    };

    const res = await requestInstance.get(path, { params: params, });
    return res;
}

export async function reqDeviceData(dateRange: Date[], device_id?: string) {

    const path = "/api/iot/gps_tracking";
    const params = {
        limit: 10000,
        offset: 0,
        start: parseDate(dateRange[0]) + ' ' + parseTime(dateRange[0]),
        end: parseDate(dateRange[1]) + ' ' + parseTime(dateRange[1]),
        device_id
    };

    console.log(params);


    const res = await requestInstance.get(path, { params: params, });
    console.log(res);

    return res;
}

export async function reqFileLayer() {
    const path = "/api/files/types";
    const params = {
        limit: "",
        offset: ""
    };

    const res = await requestInstance.get(path, { params: params, });
    console.log(res);

    return res;
}

