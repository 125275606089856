const addDigiNubmerStr = (snum: number) => {
  return ((Number(snum) < 10) ? "0" + snum.toString() : snum.toString())
}

export default function parseTime(date: any | undefined): string {
  if (date === undefined || date === null) {
    return ""
  }
  var time = new Date(date)
  return `${addDigiNubmerStr(time.getUTCHours())}:${addDigiNubmerStr(time.getUTCMinutes())}`
}