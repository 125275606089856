// missing interface: gps_tracking, dump_truck

import { useQuery, useMutation } from 'react-query'
import requestInstance, {
    RequestResponse,
    RequestError,
} from 'configure/requestConfig'

// =========================================== Interface Response ===========================================
export interface ArsenicResponseData {
    count: number;
    limit: number;
    offset: number;
    items: ArsenicItem[];
}
export interface ArsenicItem {
    device_id: string;
    device_name: string;
    latitude: number;
    longitude: number;
    percent: number;
    power_pri: number;
    power_sec: number;
    raw: number;
    state: string;
    status: number;
    timestamp: string;
}
export interface PiezometerResponseData {
    count: number;
    limit: number;
    offset: number;
    items: PiezometerItem[];
}
export interface WeatherItem {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    rain: number;
    rain_24hr: number;
    temperature: number;
    humidity: number;
    pm_25: number;
    pm_10: number;
    wind_speed: number;
    wind_direction: number;
    status: number;
    timestamp: string;
    latitude: string;
    longitude: string;
    state: string;
}
export interface WeatherResponseData {
    count: number;
    limit: number;
    offset: number;
    items: WeatherItem[];
}
export interface PiezometerItem {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    digit: number;
    temperature: number;
    quality: number;
    msl: number;
    latitude: number;
    longitude: number;
    state: string;
    timestamp: string;
    frequency: number;
}
export interface ConveyorResponseData {
    count: number;
    limit: number;
    offset: number;
    items: ConveyorItem[];
}
export interface ConveyorItem {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    status: number;
    raw: number;
    value: number;
    latitude: number;
    longitude: number;
    state: string;
    timestamp: string;
}
export interface LandslideResponseData {
    count: number;
    limit: number;
    offset: number;
    items: LandslideItem[];
}
export interface LandslideItem {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    status: number;
    top_x: number;
    top_y: number;
    top_z: number;
    bottom_x: number;
    bottom_y: number;
    bottom_z: number;
    gnss_status: number;
    latitude: string;
    longitude: string;
    atitude: number;
    h_accuracy: number;
    v_accuracy: number;
    satelite_num: number;
    p_dop: number;
    state: string;
    mine_n: string;
    mine_e: string;
    diff2d: number;
    diff3d: number;
    gnss_fix_type: number;
    gnss_fix_type_name: string;
    gnss_rtk_solution: number;
    gnss_rtk_solution_name: string;
    timestamp: string;
}
export interface WaterLevelResponseData {
    count: number;
    limit: number;
    offset: number;
    items: WaterLevelItem[];
}
export interface WaterLevelItem {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    level: number;
    raw: number;
    state: string;
    latitude: number;
    longitude: number;
    timestamp: string;
}
export interface GPStrackingResponseData {
    count: number;
    limit: number;
    offset: number;
    items: GPSTrackingItem[];
}
export interface GPSTrackingItem {
    // misssing
}
export interface DumpTruckResponseData {
    count: number;
    limit: number;
    offset: number;
    items: DumpTruckItem[];
}
export interface DumpTruckItem {
    // misssing
}
// =========================================== Interface Request File ===========================================
export interface FileResponseData {
    file: FIleItem;
}
export interface FIleItem {
    id: number;
    name: string;
    file_type: string;
    file_name: string;
    created_at: string;
    color: string | undefined | null;
    updated_at: string;
}
// =========================================== Interface Request ===========================================
export interface RequestData {
    limit: number;
    offset: number;
    start?: string;
    end?: string;
    device_id?: string;
}

// =========================================== Interface form data =========================================
export interface RequestUploadFile {
    FILES: string;
    UploadFile: UploadFile;
}
export interface UploadFile {
    file: Blob;
    file_type: FILETPYE | string;
    name: string;
    color: string;
}
export interface RequestGetFileByID {
    FILES: string;
    GetFileByID: GetFileByID;
}
export interface RequestDeleteFileByID {
    FILES: string;
    DeleteFileByID: DeleteFileByID;
}
export interface RequestEditFileBy {
    FILES: string;
    EditFileBy: EditFileBy;
}
export interface GetFileByID {
    id: number;
}
export interface DeleteFileByID {
    id: number;
}
export interface EditFileBy {
    file_id: number;
    File: UploadFile;
}

// =========================================== React query ===========================================
export enum DEVICES {
    ARSENIC = "arsenic",
    CONVEYOR = "conveyor",
    DUMPTRUCK = "dump_truck",
    TRUCK = "truck",
    GPSTRACKING = "gps_tracking",
    GPS = "gps",
    PIEZOMETER = "ground_water",
    PIEZO = "piezo",
    LANDSLIDE = "landslide",
    WATERLEVEL = "water_level",
    WEATHER = "weather",
    GATEWAY = "gateway",
    ALL = "",
}
export function getTypeId(type:DEVICES) {
    if (type == DEVICES.ARSENIC) {
        return 4;
    } else if (type == DEVICES.CONVEYOR) {
        return 6;
    } else if (type == DEVICES.DUMPTRUCK || type == DEVICES.TRUCK) {
        return 8;
    } else if (type == DEVICES.GPSTRACKING || type == DEVICES.GPS) {
        return 7;
    } else if (type == DEVICES.PIEZOMETER || type == DEVICES.PIEZO) {
        return 2;
    } else if (type == DEVICES.LANDSLIDE) {
        return 1;
    } else if (type == DEVICES.WATERLEVEL) {
        return 3;
    } else if (type == DEVICES.WEATHER) {
        return 5;
    } else {
        return 0;
    }
}

export function strToDEVICES(type: string) {
    if (type == DEVICES.ARSENIC) {
        return DEVICES.ARSENIC;
    } else if (type == DEVICES.CONVEYOR) {
        return DEVICES.CONVEYOR;
    } else if (type == DEVICES.DUMPTRUCK || type == DEVICES.TRUCK) {
        return DEVICES.TRUCK;
    } else if (type == DEVICES.GPSTRACKING || type == DEVICES.GPS) {
        return DEVICES.GPS;
    } else if (type == DEVICES.PIEZOMETER || type == DEVICES.PIEZO) {
        return DEVICES.PIEZO;
    } else if (type == DEVICES.LANDSLIDE) {
        return DEVICES.LANDSLIDE;
    } else if (type == DEVICES.WATERLEVEL) {
        return DEVICES.WATERLEVEL;
    } else if (type == DEVICES.WEATHER) {
        return DEVICES.WEATHER;
    } else if (type == DEVICES.GATEWAY) {
        return DEVICES.GATEWAY;
    } else {
        return DEVICES.ALL
    }
}
export enum FILES {
    FILE = "types",
    UPLOAD = "upload",
    EXTENSIONS = "extensions",
}
export enum STATUS {
    OFFLINE = 0,
    ONLINE = 1,
    ON = 2,
    OFF = 3,
    WARNING = 4,
    ERROR = 5,
}

export enum FILETPYE {
    GEOTIFF = "GeoTiff",
    KML = "KML",
    SHAPEFILE = "ShapeFile",
    SHAPEFILECONVEYER = "ShapeFile_Conveyer",
}

export const useGetDeviceAPI = () => {
    const queryKey = 'get/device'
    const fetcher = async (device: DEVICES) => requestInstance.get(`/api/iot/all?device=${device}`)
    return useMutation<RequestResponse<any>, RequestError, DEVICES>(
        queryKey,
        fetcher,
    )
}

export const useGatRawAPI = (deivce: string) => {
    const queryKey = 'get/raw'
    const fetcher = async (req: RequestData) => {
        return requestInstance.get(`/api/iot/${deivce}?limit=${req.limit}&offset=${req.offset}&start=${req.start === undefined ? "" : req.start}&end=${req.end === undefined ? "" : req.end}&device_id=${req.device_id === undefined ? "" : req.device_id}`)
    }
    return useMutation<RequestResponse<
        ArsenicResponseData[] | PiezometerResponseData[] | WeatherResponseData[] | ConveyorResponseData[] | LandslideResponseData[] | WaterLevelResponseData[]
    >, RequestError, RequestData>(
        queryKey,
        fetcher
    )
}

export const useGetFile = () => {
    const queryKey = 'get/file'
    const fetcher = async (file: FILES = FILES.FILE) => requestInstance.get(`/api/files/${file}`)
    return useMutation<RequestResponse<FileResponseData>, RequestError, FILES>(
        queryKey,
        fetcher,
    )
}

export const useUploadFile = () => {
    const querykey = "upload/file"
    const fetcher = async (req: RequestUploadFile) => {
        var BodyForm = new FormData();
        BodyForm.append("file", req.UploadFile.file)
        BodyForm.append("file_type", req.UploadFile.file_type)
        BodyForm.append("name", req.UploadFile.name)
        BodyForm.append("color", req.UploadFile.color)
        return requestInstance.post(`/api/files/${req.FILES}`, BodyForm)
    }
    return useMutation<RequestResponse<any>, RequestError, RequestUploadFile>(
        querykey,
        fetcher,
    )
}

export const useEditFile = () => {
    const querykey = "edit/file"
    const fetcher = async (req: RequestEditFileBy) => {
        var BodyForm = new FormData();
        BodyForm.append("file", req.EditFileBy.File.file)
        BodyForm.append("file_type", req.EditFileBy.File.file_type)
        BodyForm.append("name", req.EditFileBy.File.name)
        BodyForm.append("color", req.EditFileBy.File.color)
        return requestInstance.post(`/api/files/${req.FILES}/${req.EditFileBy.file_id}`, BodyForm)
    }
    return useMutation<RequestResponse<any>, RequestError, RequestEditFileBy>(
        querykey,
        fetcher,
    )
}

export const useDeleteFile = () => {
    const querykey = "edit/file"
    const fetcher = async (req: RequestDeleteFileByID) => {
        return requestInstance.delete(`/api/files/${req.FILES}/${req.DeleteFileByID.id}`)
    }
    return useMutation<RequestResponse<any>, RequestError, RequestDeleteFileByID>(
        querykey,
        fetcher,
    )
}

export const useExtensions = () => {
    const querykey = "extensions/file"
    const fetcher = async (file: FILES) => {
        return requestInstance.get(`/api/files/${file}`)
    }
    return useMutation<RequestResponse<any>, RequestError, FILES>(
        querykey,
        fetcher,
    )
}

// =========================================== mapping response layer map ===========================================
export interface Layer {
    id: number;
    fileType: string;
    name: string;
    fileName: string;
}

export const mapresponseMapLayer = (res: FIleItem[]): Layer[] => {
    var data: Layer[] = []
    data = res.map(e => {
        return {
            id: e.id,
            fileType: e.file_type,
            name: e.name,
            fileName: e.file_name,
            Color: e.color, // add color from api.
        }
    })
    return data
}

export const useListNotication = () => {
    const querykey = "line/token"
    const fetcher = async () => {
        return requestInstance.get(`/api/notifications/`)
    }
    return useMutation<RequestResponse<ListNotication[]>, RequestError, null>(
        querykey,
        fetcher,
    )
}
export const useGetNotication = () => {
    const querykey = "get/noti"
    const fetcher = async () => {
        return requestInstance.get(`/api/notifications/line`)
    }
    return useMutation<RequestResponse<ListNoti[]>, RequestError, null>(
        querykey,
        fetcher,
    )
}

export const useDeleteLineNotify = () => {
    // {{domain}}/api/notifications/line/:line_notification_id
    const querykey = "delete/noti"
    const fetcher = async (line_notification_id: number) => {
        return requestInstance.delete(`/api/notifications/line/` + line_notification_id)
    }
    return useMutation<RequestResponse<ListNoti[]>, RequestError, number>(
        querykey,
        fetcher,
    )
}

export interface ListNotication {
    ID: number;
    name: string;
    key: string;
    description: string;
    check?: boolean;
}
export interface ListNoti {
    line_notification_id: number;
    note: string;
    access_token: string;
    enabled: boolean;
    notifications: Notifications[];
    created_at: string;
    updated_at: string;
}
export interface Notifications {
    noti_type_id: number;
    name: string;
}

/**
 * line noti config.
 */
export interface NotiConfig {
    client_id: string;
    client_secret: string;
}
export const useGetNoticationConfig = () => {
    const querykey = "get/noti/config"
    const fetcher = async () => {
        return requestInstance.get(`/api/line/config`)
    }
    return useMutation<RequestResponse<NotiConfig>, RequestError, null>(
        querykey,
        fetcher,
    )
}

/**
 * hook line token
 */
export interface LineToken {
    access_token: string;
}
export interface ReqLineToken {
    code: string | null;
}
export const useGetNoticationToken = () => {
    const querykey = "get/noti/token"
    const fetcher = async (param: ReqLineToken) => {
        return requestInstance.post(`/api/line/generate`, {
            code: param.code
        })
    }
    return useMutation<RequestResponse<LineToken>, RequestError, ReqLineToken>(
        querykey,
        fetcher,
    )
}

/**
 * create line notify.
 */
export interface ReqLineCreate {
    access_token: string;
    note: string;
    notification_ids: number[];
}
export const useCreateNotication = () => {
    const querykey = "get/noti/token"
    const fetcher = async (param: ReqLineCreate) => {
        return requestInstance.post(`/api/notifications/line`, {
            access_token: param.access_token,
            note: param.note,
            notification_ids: param.notification_ids,
        })
    }
    return useMutation<RequestResponse<any>, RequestError, ReqLineCreate>(
        querykey,
        fetcher,
    )
}

/**
 * get email notification
 */
export interface ListEmailNoti {
    email: string;
    notification_ids: number[];
    created_at?: string;
    updated_at?: string;
}
export const useGetEmailNotication = () => {
    const querykey = "get/email/noti"
    const fetcher = async () => {
        return requestInstance.get(`/api/notifications/email`)
    }
    return useMutation<RequestResponse<ListEmailNoti[]>, RequestError, null>(
        querykey,
        fetcher,
    )
}
export interface reqEmailNoti {
    email: string;
    notification_ids: number[];
}
export const useCreateEmailNotication = () => {
    const querykey = "create/email/noti"
    const fetcher = async (data: reqEmailNoti) => {
        return requestInstance.post(`/api/notifications/email`, {
            email: data.email,
            notification_ids: data.notification_ids
        })
    }
    return useMutation<RequestResponse<any>, RequestError, reqEmailNoti>(
        querykey,
        fetcher,
    )
}

export const useEmailenable = () => {
    const querykey = "enabled/email/noti"
    const fetcher = async () => {
        return requestInstance.post(`/api/notifications/email/enabled`)
    }
    return useMutation<RequestResponse<any>, RequestError, null>(
        querykey,
        fetcher,
    )
}
export const useEmaildisable = () => {
    const querykey = "disabled/email/noti"
    const fetcher = async () => {
        return requestInstance.post(`/api/notifications/email/disabled`)
    }
    return useMutation<RequestResponse<any>, RequestError, null>(
        querykey,
        fetcher,
    )
}