import { jsx } from "@emotion/react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { ISensorItem, QrMapItem } from "api/useTruckAPI";
import addHours from "date-fns/addHours";
import format from "date-fns/format";
import { FunctionComponent, useEffect, useLayoutEffect, useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import { JsxEmit } from "typescript";
import CountingTableByTimeRange from "./CountingTable/CountingTableByTimeRange";
import { ToolButton } from "./CountingTable/Styles";
import Typography from '@mui/material/Typography'
import isSameDay from "date-fns/isSameDay";




interface TruckCountingPanelProps {
    date: Date;
    dataSource: ISensorItem[];
    deviceNameList: string[];
    qrMaps: QrMapItem[];
}

const TruckCountingPanel: FunctionComponent<TruckCountingPanelProps> = (props) => {
    const {
        date,
        dataSource,
        qrMaps

    } = props;
    const [columns, setColumns] = useState<any[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const [shiftRows, setShiftRows] = useState<any[]>([]);
    const [totalRow, setTotalRow] = useState<any[]>([]);
    const [deviceNameList, setDeviceNameList] = useState<string[]>([]);
    const [countByCarTableProps, setCountByCarTableProps] = useState<{ row: any[], column: any[] }>({ row: [], column: [] });

    useEffect(() => {
        if (deviceNameList.length === 0) {
            setDeviceNameList(props.deviceNameList);
        }
    }, [props.deviceNameList]);


    useLayoutEffect(() => {
        if (deviceNameList.length === 0 || dataSource.length === 0) {
            return;
        }

        setColumns([`Time ( ${format(date, "dd/MM/yyyy")} )`, ...deviceNameList]);

        let countingData: { tableData: any, totalCount: any } = makeCountingData(date, dataSource, deviceNameList);

        console.log("countingData", { ...countingData });
        let newCountByCarTableProps = createCountByCarProps(qrMaps, countingData.totalCount, deviceNameList);
        setCountByCarTableProps(newCountByCarTableProps);

        let newRows = createRows(countingData.tableData, deviceNameList);
        // console.log("newRows", newRows);
        setRows(newRows);

        let newShiftRow = createShiftRow(newRows);
        newShiftRow.forEach((shift: any[], index: number) => {
            let totalRow = sumEachColumn(shift);
            totalRow[0] = `Total shift ${index + 1}`;
            shift.push(totalRow);
        });
        console.log("newShiftRow", newShiftRow);
        setShiftRows(newShiftRow);

        let newTotalRow = createTotalRow(countingData.totalCount, deviceNameList);
        setTotalRow(newTotalRow);


    }, [dataSource, deviceNameList]);

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >

            <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h6" color="initial" >
                    {`ตารางสรุปจำนวนรถเทท้าย วันที่ ${format(date, "dd/MM/yyyy")}`}
                </Typography>

                <Grid item>
                    <CSVLink
                        asyncOnClick
                        filename={`สรุปจำนวนรถเข้าครัชเชอร์_${format(new Date(), "dd/MM/yyyy_HH:mm:ss")}.csv`}
                        data={[columns, ...rows, totalRow]}
                        style={{
                            textDecorationLine: "none",
                        }}
                    >
                        <ToolButton >
                            ดาวน์โหลดสรุปจำนวนรถเข้าครัชเชอร์ตามเวลา
                        </ToolButton>
                    </CSVLink>
                    <CSVLink
                        asyncOnClick
                        filename={`สรุปจำนวนรถเข้าครัชเชอร์รายคัน_${format(new Date(), "dd/MM/yyyy_HH:mm:ss")}.csv`}
                        data={[countByCarTableProps.column, ...countByCarTableProps.row]}
                        style={{
                            textDecorationLine: "none",
                        }}
                    >
                        <ToolButton >
                            ดาวน์โหลดสรุปจำนวนรถเข้าครัชเชอร์รายคัน
                        </ToolButton>
                    </CSVLink>
                </Grid>


            </Grid>
            <br />

            {shiftRows.length === 3 &&
                <>
                    <Grid item container gap={2}>
                        <Grid item xs >
                            <CountingTableByTimeRange
                                row={shiftRows[0]}
                                column={columns}
                            />
                        </Grid>
                        <Grid item xs >
                            <CountingTableByTimeRange
                                row={shiftRows[1]}
                                column={columns}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item container gap={2}>
                        <Grid item xs >
                            <CountingTableByTimeRange
                                row={shiftRows[2]}
                                column={columns}
                            />
                        </Grid>
                        <Grid item xs >
                            {/* countByCarTableProps */}
                            <CountingTableByTimeRange {...countByCarTableProps} />
                        </Grid>
                    </Grid>
                </>
            }


        </Grid>

    );
}

export default TruckCountingPanel;

/**
 * Methods
 */

function makeDateTimeKey(fromDate: Date, toDate: Date) {
    let obj: Record<string, any> = {};
    let fromYear = parseInt(format(fromDate, 'yyyy'));
    let toYear = parseInt(format(toDate, 'yyyy'));
    let fromMonth = parseInt(format(fromDate, 'MM'));
    let toMonth = parseInt(format(toDate, 'MM'));
    let fromDay = parseInt(format(fromDate, 'dd'));
    let toDay = parseInt(format(toDate, 'dd'));
    for (let year = fromYear; year <= toYear; year++) {
        for (let month = fromMonth; month <= toMonth; month++) {
            let m = month < 10 ? `0${month}` : `${month}`;
            for (let day = fromDay; day <= toDay; day++) {
                let d = day < 10 ? `0${day}` : `${day}`;
                for (let i = 0; i < 24; i++) {
                    let h = i < 10 ? `0${i}` : `${i}`;
                    let nextH = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
                    let date = `${year}/${m}/${d}`;
                    let time1 = `${h}:00 - ${h}:30`;
                    let time2 = `${h}:30 - ${nextH}:00`;
                    let key1 = `${date} ${time1}`;
                    let key2 = `${date} ${time2}`;
                    obj[key1] = { date: date, time: time1 };
                    obj[key2] = { date: date, time: time2 };
                }
            }
        }
    }

    return obj;
}

function makeCountingData(date: Date, dataSource: ISensorItem[], deviceNameList: string[]) {
    let tableData: Record<string, any> = makeDateTimeKey(date, date);
    // console.log("DateTimeKey", { ...tableData });


    let totalCount: Record<string, any> = {};

    deviceNameList.forEach((name: string) => {
        totalCount[name] = 0;
    })

    for (const key in dataSource) {
        if (Object.prototype.hasOwnProperty.call(dataSource, key)) {
            const e = dataSource[key];
            /* Make Time column */
            let min = parseInt(format(new Date(e.timestamp), 'mm')) < 30 ? ':00' : ':30';
            let hr = format(new Date(e.timestamp), 'HH');
            let nextHr = parseInt(hr) + 1 < 10 ? `0${parseInt(hr) + 1}` : `${parseInt(hr) + 1}`;
            let date = format(new Date(e.timestamp), 'yyyy/MM/dd');
            let keyTime = min === ':00' ? `${date} ${hr + min} - ${hr}:30` : `${date} ${hr + min} - ${nextHr}:00`;

            if (Object.keys(tableData).includes(keyTime) === false) {
                tableData[keyTime] = {};
            }
            if (Object.keys(tableData[keyTime]).includes(e.device_name) === false) {
                tableData[keyTime][e.device_name] = { total: 0 };
            }
            if (Object.keys(tableData[keyTime][e.device_name]).includes(e.car_name) === false) {
                tableData[keyTime][e.device_name][e.car_name] = 0;
            }
            if (e.dump_lift_image !== "") {
                tableData[keyTime][e.device_name][e.car_name] = tableData[keyTime][e.device_name][e.car_name] + 1;
                tableData[keyTime][e.device_name].total = tableData[keyTime][e.device_name].total + 1;
            }

            /* Count for Total */
            if (Object.keys(totalCount).includes(e.device_name) === false) {
                totalCount[e.device_name] = 0;
            }
            console.log("e.car_name", e.car_name);
            let carName = e.car_name === "" ? "ระบุหมายเลขไม่ได้" : e.car_name;
            if (Object.keys(totalCount).includes(carName) === false) {
                totalCount[carName] = {};
                deviceNameList.forEach((name: string) => {
                    totalCount[carName][name] = 0;
                })
            }

            if (e.dump_lift_image !== "") {
                totalCount[e.device_name] = totalCount[e.device_name] + 1;
                totalCount[carName][e.device_name] = totalCount[carName][e.device_name] + 1;

            }
        }
    }

    return { tableData, totalCount };
}

function createRows(tableData: any, deviceNameList: string[]) {
    let rows = [];
    for (const key in tableData) {
        if (Object.prototype.hasOwnProperty.call(tableData, key)) {
            const element = tableData[key];
            let dataInRow = deviceNameList.map((e: string) => element[e] !== undefined ? element[e].total : 0);
            rows.push([element.time, ...dataInRow]);
        }
    }
    return rows;
}

function createShiftRow(rowPerDay: any[]) {
    let result: any[] = [];
    let rowPerShift: number = 16;
    for (let i = 0; i < 3; i++) {
        const shift = rowPerDay.slice(i * rowPerShift, i * rowPerShift + rowPerShift);
        result.push(shift);
    }
    return result;
}

function sumEachColumn(arr: any[][]) {

    let result: any[] = arr[0]?.map(e => 0) ?? [];
    for (let i = 0; i < arr.length; i++) {
        const e = arr[i];
        for (let y = 0; y < e.length; y++) {
            const element = e[y];
            if (typeof element === 'number') {
                result[y] = Number(result[y]) + Number(element);
            } else {
                result[y] = Number(result[y]) + Number(0);
            }
        }

    }
    return result
}

function createTotalRow(totalCount: any, deviceNameList: string[]) {
    let dataInRow = deviceNameList.map((e: string) => totalCount[e] !== undefined && totalCount[e] !== "" ? totalCount[e] : 0);
    return ['Total', ...dataInRow];
}

function createCountByCarProps(qrMaps: QrMapItem[], totalCount: any, crusherNameList: string[]) {
    let column: any[] = ["หมายเลขรถ", ...crusherNameList, "Total"];
    console.log("createCountByCarProps column", column);

    let row: any[] = [...qrMaps, { name: "ระบุหมายเลขไม่ได้" }].map(e => {
        let name = e.name;
        if (totalCount[name] === undefined) {
            return [name, ...crusherNameList.map(crusher => 0), 0];
        } else {
            let countArr = Object.keys(totalCount[name]).map((key: string) => totalCount[name][key]);
            let total = countArr.reduce((preVal, curVal) => preVal + curVal, 0);
            return [name, ...countArr, total];
        }
    });
    console.log("createCountByCarProps row", row);

    let totalRow = row.reduce((pre, cur) => {
        let result = [...pre];
        for (let i = 0; i < cur.length; i++) {
            const e = cur[i];
            if (typeof e === 'number') { result[i] += e; }
        }
        return result;
    }, ["Total", ...crusherNameList.map(crusher => 0), 0])
    row.push(totalRow);

    return { column, row };


}

