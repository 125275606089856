import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import { usePageContext } from "./WirelessPage";
import BaseCurrentTable from "components/BaseTable/BaseCurrentTable";
import { CardTemplate } from "components/Card/CardTemplate";
import { SimpleLoading } from "components/Loading/SimpleLoading";
import { coordinateDecimalToMineGrid } from 'helpers/geo';
import { decimalRound } from 'helpers/utils';

export function DeviceTable() {
    const { stateContext } = usePageContext();
    const [dataList, setDataList] = useState<IDataOnTable[]>([]);

    useEffect(() => {
        const datas: IDataOnTable[] = stateContext.allSensorList.map((e: any) => {
            const {
                name,
                description,
                state,
                latitude,
                longitude,
                altitude,
            } = e;

            const mineGrid = coordinateDecimalToMineGrid(latitude, longitude);

            return {
                name,
                description,
                state,
                latitude: decimalRound(latitude, 9),
                longitude: decimalRound(longitude, 9),
                altitude,
                mine_n: mineGrid.mineN,
                mine_e: mineGrid.mineE,
            }
        });
        setDataList(datas);
    }, [stateContext.allSensorList]);

    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0">
        <CardTemplate>
            {stateContext.allSensorList.length === 0 ? <SimpleLoading /> : <BaseCurrentTable tableName={"รายละเอียดอุปกรณ์ที่เชื่อมต่อ"} device={"gateway"} order={"asc"} orderBy={"device_id"} tableCell={headCells} DataCurrentDevice={dataList} />}
        </CardTemplate>
    </Grid>;
}

interface IDataOnTable {
    name: string;
    description: string;
    state: string;
    latitude: number;
    longitude: number;
    altitude: number;
    mine_n: number;
    mine_e: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof IDataOnTable;
    label: string;
    numeric: boolean;
    isStatus?: boolean;
    isUpper?: boolean;
    isUseFormatTime?: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
    },
    {
        id: 'state',
        numeric: false,
        isStatus: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'latitude',
        numeric: true,
        disablePadding: false,
        label: 'Latitude',
    },
    {
        id: 'longitude',
        numeric: true,
        disablePadding: false,
        label: 'Longitude',
    },
    {
        id: 'altitude',
        numeric: true,
        disablePadding: false,
        label: 'Altitude',
    },
    {
        id: 'mine_n',
        numeric: true,
        disablePadding: false,
        label: 'Mine N',
    },
    {
        id: 'mine_e',
        numeric: true,
        disablePadding: false,
        label: 'Mine E',
    },
    // {
    //     id: 'organization_id',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'organization_id',
    // },
    // {
    //     id: 'ping',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'ping',
    // },
    // {
    //     id: 'last_ping',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'last_ping',
    // },
    // {
    //     id: 'last_ping_sent_at',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'last_ping_sent_at',
    // },
    // {
    //     id: 'network_server_id',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'network_server_id',
    // },
    // {
    //     id: 'gateway_profile_id',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'gateway_profile_id',
    // },
    // {
    //     id: 'first_seen_at',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'first_seen_at',
    // },
    // {
    //     id: 'last_seen_at',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'last_seen_at',
    // },
    // {
    //     id: 'service_profile_id',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'service_profile_id',
    // },
    // {
    //     id: 'mac',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'mac',
    // },
    // {
    //     id: 'created_at',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'created_at',
    // },
    // {
    //     id: 'updated_at',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'updated_at',
    // },
    // {
    //     id: 'tag',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'tag',
    // },
    // {
    //     id: 'meta_data',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'meta_data',
    // },
];
