import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import routeConfig from './routeConfig'
import useUserContext from 'context/UserContext'
import RoutePath from 'constants/RoutePath'


const AppRoutes: React.FC = () => {
    const isLoggedIn = useUserContext().auth?.access_token != undefined ? true : false ; 
    const permission = useUserContext().permission?.permissions;

    return (
        <Switch>
          <Route
            path="/"
            exact
            render={() => <Redirect to={RoutePath.dashboard} />}
          />
          {routeConfig.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact
              render={(props) => {
                const { meta } = route
    
                if ((meta.requiredAuth && !isLoggedIn)
                  ||(meta.requiredAuth && !meta.requiredAuth.some(r => permission?.includes(r)))){
                  return (
                    <Redirect
                      to={`${RoutePath.dashboard}?from=${props.location.pathname}`}
                    />
                  )
                }
    
                return meta.layout ? (
                  <meta.layout>
                    <route.component {...props} />
                  </meta.layout>
                ) : (
                  <route.component {...props} />
                )
              }}
            />
          ))}
        </Switch>
      )
    }
    
export default AppRoutes