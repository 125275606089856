import requestInstance from "configure/requestConfig";


// set new device address
export const setDeviceAddress = async (deviceId: string, address: string) => {
    const path = "/api/address/create";
    const body = {
        device_id: deviceId,
        addr_des: address
    };
    return requestInstance.post(path, body);
}

// get device address
export const getDeviceAddress = async (deviceId: string) => {
    const path = "/api/address/get";
    const params = { device_id: deviceId };

    return requestInstance.get(path, { params: params });
}

// stop measuring
export const stopMeasuring = async (deviceId: string, addrDes: string) => {
    const path = "/api/address/stop";
    const params = {
        device_id: deviceId,
        addr_des: addrDes
    };
    return requestInstance.patch(path, params);
}