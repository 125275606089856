import { UserTableProps } from "./UserTableView";
import { useEffect, useRef, useState } from "react";
import { DialogData } from "./EditPermissionDialog/EditPermissionDialog";
import usePagination from "./Pagination/Pagination";
import useEnhancedTableHead from "./EnhancedTableHead/useEnhancedTableHead";

export interface RowItem {
    checked: boolean;
    employeeNumber: string;
    role: string;
    permissionLable: string;
    editPermissionProps: DialogData;
}

const useUserTable = (props: UserTableProps) => {
    const { activeUser, allPermission } = props;
    const firstRender = useRef(true);
    const [isLoadTable, setIsLoadTable] = useState(true);
    const allDashboardPermission = useRef({} as {
        name: string;
        description: string;
    }[]);
    const initAllPermission = () => {
        if (allPermission) {
            const excludePermission = ['admin', 'user', 'dashboard', 'gateway'];
            allDashboardPermission.current = allPermission.filter((permission) => !excludePermission.includes(permission.name));
        }
    }

    /* Rows */
    const [rows, setRows] = useState([] as RowItem[]);
    const [displayRows, setDisplayRows] = useState([] as RowItem[]);

    const handleItemChecked = (employeeNumber: string) => {
        const newRows = rows.map((row) => {
            if (row.employeeNumber === employeeNumber) {
                row.checked = !row.checked;
            }
            return row;
        });
        setRows(newRows);
    }

    const makeDashboardPermission = (permissions: string[], allDashboardPermission: { name: string, description: string }[]) => {
        console.log("permissions", permissions);
        console.log("allDashboardPermission", allDashboardPermission);

        let temp: string[] = [];
        allDashboardPermission?.forEach((dashPermission) => {
            if (permissions?.includes(dashPermission.name)) {
                temp.push(dashPermission.description);
            }
        });
        console.log("temp", temp);

        return temp.join(", ");
    }

    const initRows = () => {
        let newRows = activeUser.map((user) => {
            const editPermissionProps: DialogData = {
                employeeID: user.id.toString(),
                employeeNumber: user.employee_id.toString(),
                role: user.permissions?.includes('admin') ? 'Admin' : 'User',
                permissions: user.permissions!,
                allPermission: allDashboardPermission.current,
            };
            let permissionLable = makeDashboardPermission(user.permissions!, allDashboardPermission.current);
            let rowItem: RowItem = {
                checked: false,
                employeeNumber: user.employee_id.toString(),
                role: user.permissions?.includes('admin') ? 'Admin' : 'User',
                permissionLable: permissionLable,
                editPermissionProps: editPermissionProps,
            };
            return rowItem;
        });
        setRows(newRows);
    };

    /* Pagination */
    const {
        rowsPerPageOptions,
        rowsPerPage,
        page,
        count,
        emptyRows,
        onPageChange,
        onRowsPerPageChange,
    } = usePagination(activeUser.length);

    /* EnhancedTableHead */
    const [numSelectedRow, setNumSelectedRow] = useState(0);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("handleSelectAllClick");
        console.log("event.target.checked", event.target.checked);
        let checked = event.target.checked;
        setIsCheckedAll(checked);
        let newRows = rows.map((row) => {
            row.checked = checked;
            return row;
        });
        setRows(newRows);
        setNumSelectedRow(checked ? newRows.length:0);
    };
    const {
        headCells,
        order,
        orderBy,
        handleRequestSort,
        stableSort,
        getComparator,
    } = useEnhancedTableHead();

    useEffect(() => {
        setIsLoadTable(true);
        if (firstRender.current) {
            firstRender.current = false;
            initAllPermission();
        }
        initRows();
        setIsLoadTable(false);
    }, [activeUser]);

    useEffect(() => {
        const dispRows = stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        setDisplayRows(dispRows);
    }, [rows, page, rowsPerPage, order, orderBy]);



    return {
        isLoadTable,
        dispRows: displayRows,
        handleItemChecked,
        enhancedHead: {
            headCells,
            order,
            orderBy,
            numSelectedRow,
            isCheckedAll,
            handleRequestSort,
            handleSelectAllClick,
            stableSort,
            getComparator,
        },
        pagination: {
            rowsPerPageOptions,
            rowsPerPage,
            page,
            count,
            emptyRows,
            onPageChange,
            onRowsPerPageChange,
        }
    };
}

export default useUserTable;