import { Polyline, AntPath, antPath } from 'leaflet-ant-path';
import { useMap } from "react-leaflet";
import * as Leaflet from 'leaflet';
import { useEffect } from 'react';

const AntPathOverlay = ({ positions, paused, reverse=false}) => {
    const map = useMap();

    
    useEffect(() => {
        if (positions === undefined) {
            return;
        }
        // Usethe constructor...
        let antPolyline = new AntPath(positions, {
            "use": Leaflet.polyline,
            "delay": 2000,
            "dashArray": [10, 30],
            "weight": 7,
            "color": paused ? "black":"#00FF65",
            "pulseColor": "#FFFFFF",
            "paused": paused,
            "reverse": Boolean(reverse),
            "zIndex":100,
          });
        console.log("antPolyline",antPolyline);
        // let out = [];
        // console.log("properties",properties);
        // if (properties) {
        //   for (var key in properties) {
        //       out.push(key + ": " + properties[key]);
        //   }
        //   // l.bindPopup(out.join("<br />"));
        //   antPolyline.bindTooltip(out.join("<br />"));
        // }
        antPolyline.addTo(map);
        // map.fitBounds(antPolyline.getBounds());

        return () => {
          antPolyline.removeFrom(map);
        }

    }, [positions/* ,properties */]);

    return null;//<AntPath positions={latLngs} options={antPathOptions} />   ;
}
export default AntPathOverlay;