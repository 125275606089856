import { QueryClient } from 'react-query'

const reactQueryClientConfig = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchInterval: 60000,
    },
  },
})

export default reactQueryClientConfig
