import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Container from '@mui/material/Container'
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export interface IBarChartProps {
    title: string;
    labels: string[];
    datasets: IBarChartDataSets[];
}

export interface IBarChartDataSets {
    label: string;
    data: number[];
    backgroundColor?: string;
}

export function BarChart({ ...props }: IBarChartProps) {
    const { title, labels, datasets } = props
    /* device_id */
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: title,
            },
        },
    };
    const data = {
        labels,
        datasets: datasets,
    };

    return <div style={{ height: '350px', width: '700px' }}>
        <Bar options={options} data={data} />
    </div>
}
