import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import React, { useState, useEffect, Children, useContext } from "react";
import type { RouteComponentProps } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import styled from 'styled-components'
import Paper from '@mui/material/Paper';
import NormalText from 'constants/TextStyles';
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField'
import DownloadButton from 'components/Button/DownloadBtn'
import { TabContext, TabPanel } from "@mui/lab";
import CustomizedTabs from 'components/TabBar/TabBar';
import DeviceDetailTable from 'components/DeviceDetailTable';
import DateTimeRangePicker from "components/Datetime/DateTimeRangePicker";
import useUserContext from 'context/UserContext';
// component map
import { MapCard } from "./MapCard";
import * as dateFns from 'date-fns';

import { ISensorItem } from "api/useGatewayAPI";
import * as GatewayAPI from "api/useGatewayAPI";
import { UpdateCurrentDataButton } from "./UpdateCurrentDataButton";
import { TableZone } from "./TableZone";

interface IPageContext {
    curSensorList: ISensorItem[];
    curSensorItem: ISensorItem;
    allSensorList: ISensorItem[];
    dateRange: Date[];
}

const defaultContext: IPageContext = {
    curSensorList: [],
    curSensorItem: GatewayAPI.defaultSensorItem,
    allSensorList: [],
    dateRange: [dateFns.addHours(new Date(), -1), new Date()],
}
const pageContext = React.createContext<{ stateContext: IPageContext, dispatchContext?: React.Dispatch<any>; }>({ stateContext: defaultContext });

export const usePageContext = () => useContext(pageContext);

const HEADER_TEXT = "ระบบสื่อสารไร้สาย"
const WirelessPage: React.FC<RouteComponentProps> = () => {
    /* Create global state in page */
    const [stateContext, dispatchContext] = React.useReducer((state: IPageContext, action: any) => {
        return { ...state, ...action }
    }, defaultContext);

    return (
        <pageContext.Provider value={{ stateContext, dispatchContext }}>
            <WirelessPageContent />
        </pageContext.Provider>
    );

}

function WirelessPageContent() {
    const isAdmin = useUserContext().permission?.permissions.includes('admin');
    const { stateContext, dispatchContext } = usePageContext();
    const dispatch = useLayoutDispatch()

    useEffect(() => {
        /* Set page header */
        changeHeaderText(dispatch, HEADER_TEXT);
        /* Request current data */
        (async () => {
            const res = await GatewayAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            const items = res.data.gateway.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
            dispatchContext!({ curSensorList: items });
        })();
        /* Request all data */
        (async () => {
            const res = await GatewayAPI.reqDeviceData(stateContext.dateRange);
            if (res.status != 200) {
                return [];
            }
            const { items } = res.data;
            dispatchContext!({ allSensorList: items });
        })();

    }, [])
    return (
        <DashboardContainer>
            <Grid
                width="100%"
                height="100%"
                container
                direction="column"
                rowSpacing={2}
                columnSpacing={2}
                columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
            >
                {/* <HeaderRow /> */}
                <Grid
                    item
                    container
                    direction="row"
                    columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                >
                    <Grid item xs={100} sm={100} md={30} lg={30} xl={30} container alignItems="center" >
                        <NormalText>
                            {HEADER_TEXT}
                        </NormalText>
                    </Grid>
                    <Grid item xs={100} sm={100} md={70} lg={70} xl={70}>
                        <Grid container rowSpacing={5} columnSpacing={5} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} justifyContent="flex-end">
                            <Grid item xs={100} sm={100} md={100} lg={100} xl={100} container justifyContent="flex-end" >
                                <UpdateCurrentDataButton />
                                {/* {isAdmin ? <>
                                    <div style={{ marginLeft: "10px" }} />
                                    <ThresholdSettingButton sensorName='gateway' />
                                </> : null
                                } */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <MapRow /> */}
                <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} >
                    <MapCard />
                </Grid>


                {/* <TableZone /> */}
                <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
                    <TableZone />
                </Grid >


            </Grid>
        </DashboardContainer>

    );
}

export default WirelessPage
const DashboardContainer = styled.div`
  margin-left: 10px;
`

function HeaderRow() {
    return (
        <Grid container item spacing={2} /* marginTop="8px" */ justifyContent="space-between" alignItems="stretch" >
            <Grid item xs minWidth="320px" >
                <NormalText>
                    {HEADER_TEXT}
                </NormalText>
            </Grid>
            <Grid container item spacing={2} margin="0px" gap="8px" xs justifyContent="flex-end" alignItems="stretch" flexWrap="nowrap">
                <LastUpdateTextField datetime={"19/09/2021 12:51:09"} />
                <DownloadButton onTap={() => {
                    //TODO: implement function ontap of DownloadButton
                    console.log("Test onTap DownloadButton");

                }} />
            </Grid>
        </Grid>);
}


function MapRow() {
    return <Grid container item spacing={2} justifyContent="center" alignItems="stretch" >
        <Grid item xs md minWidth="320px">
            <MapCard />
        </Grid>
    </Grid>;
}



