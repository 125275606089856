import React, { useEffect } from "react";
import { Paper } from "@mui/material";
import { CardTemplate } from "components/Card/CardTemplate";
import MapWireless from 'components/Map/MapWireless.js';
import styled from 'styled-components'
import { usePageContext } from "./WirelessPage";
import * as GatewayAPI from "api/useGatewayAPI";
import { ISensorItem } from "api/useGatewayAPI";
import { getAngle } from "helpers/calLatLongDir";

//API
import {
    DEVICES,
} from 'api/useRequest'
import { SimpleLoading } from "components/Loading/SimpleLoading";
import BaseMap from "components/Map/BaseMap";
import SensorMarker from "components/Map/Marker/SensorMarker";

export function MapCard() {
    return (
        <CardTemplate>
            <MapContent />
        </CardTemplate>
    );
}


function MapContent() {
    const { stateContext, dispatchContext } = usePageContext();

    return (
        <MapBox>
            {stateContext.curSensorList.length > 0 ?
                <BaseMap>
                    {
                        stateContext.curSensorList.map((e: ISensorItem, i: number) => (
                            <SensorMarker
                                key={`GatewayMarker_${i}`}
                                type={DEVICES.GATEWAY}
                                state={e.state}
                                position={[Number(e.latitude), Number(e.longitude)]}
                                label={e.name}
                                data={e}
                            />
                        ))
                    }
                </BaseMap>
                : <SimpleLoading />}
        </MapBox>
    );
}


const MapBox = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        height: "500px"
    },
    [theme.breakpoints.down('md')]: {
        height: "300px"
    },
}));