import { FunctionComponent, useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FmdGoodIcon from '@mui/icons-material/FmdGood';

interface SelectGroupButtonProps {
    groupNames: string[];
    onSelected?: (groupName: string, index: number) => void;
}

const SelectGroupButton: FunctionComponent<SelectGroupButtonProps> = (props) => {
    const {
        groupNames,
        onSelected,
    } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (selectedName: string, index: number) => {
        if (onSelected !== undefined) {
            onSelected(selectedName, index);
        }
        handleClose();
    }

    return (
        <>
            <Tooltip title="เลือกอุปกรณ์แบบกลุ่ม">
                <IconButton
                    aria-label="select-group-marker"
                    aria-controls={open ? 'select-group-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    disabled={groupNames && groupNames.length <= 0}
                    onClick={handleClick}
                    sx={{
                        color:"#0739A2"
                    }}
                >
                    <FmdGoodIcon />
                </IconButton>
            </Tooltip>
            {groupNames.length > 0 &&
                <Menu
                    id="select-group-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {groupNames.map((e: string, idx: number) => (
                        <MenuItem onClick={() => handleMenuItemClick(e, idx)}>{e}</MenuItem>
                    ))}
                </Menu>
            }
        </>
    );
}

export default SelectGroupButton;