import React, { useState, useEffect, useRef } from "react";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { CardTemplate } from "./CardTemplate";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, TextField, Switch, Divider } from "@mui/material";

export interface IGraphCard {
    title?: string;
    onTapDownload?: () => void;

    linkPrefix: string;
    deviceIdList?: string[];
    panelId: number;
    dateTimeRange: Date[];
    yAxisConfigurable?: boolean;
}

export function GraphCard({ linkPrefix, deviceIdList, panelId, dateTimeRange, yAxisConfigurable }: IGraphCard) {
    const iframeEl = useRef<HTMLIFrameElement>(null);
    const [openYAxisDialog, setOpenYAxisDialog] = useState(false);
    const [config, setConfig] = useState<IConfigYAxis>({ minY: 'auto', maxY: 'auto' });

    const [timeRangeParam, setTimeRangeParam] = useState('');

    const nameIdx = 6;
    const linkChunks = linkPrefix.split(/\/|&|\?/);
    const deviceType = window.location.pathname.split('/')[1] || "";
    const pageName = (linkChunks[nameIdx] === undefined) ? 'unknown' : linkChunks[nameIdx];
    const minYKey = `${deviceType}-${pageName}-${panelId}-minY`;
    const maxYKey = `${deviceType}-${pageName}-${panelId}-maxY`;

    let angularEl: any;

    try {
        let contentWindow: any = iframeEl.current?.contentWindow;
        angularEl = contentWindow?.angular
    }
    catch(e) {}

    useEffect( () => {
        // Load config from localstorage
        const minY = getValueFromStorage(minYKey);
        const maxY = getValueFromStorage(maxYKey);

        setConfig( (prevConfig: IConfigYAxis) => ({
            ...prevConfig,
            minY: minY,
            maxY: maxY
        }));
    }, []);

    useEffect( () => {
        if (angularEl) {
            const timeService = angularEl.element('grafana-app')?.injector()?.get('timeSrv');
            timeService.setTime({from: dateTimeRange![0], to: dateTimeRange![1]});
        }
        else {
            setTimeRangeParam(`&from=${dateTimeRange![0].getTime()}&to=${dateTimeRange![1].getTime()}`);
        }
    }, [dateTimeRange]);

    const getValueFromStorage = (key: string) => {
        const value = localStorage.getItem(key);
        return (value === null) ? 'auto' : value;
    }

    const setValueToStorage = (key: string, value: string) => {
        if (value === 'auto')
            localStorage.removeItem(key)
        else
            localStorage.setItem(key, value);
    }

    const saveConfigYAxis = (newConfig: IConfigYAxis) => {
        // Save config to storage
        setValueToStorage(minYKey, newConfig.minY);
        setValueToStorage(maxYKey, newConfig.maxY);

        setConfig( (prevConfig: IConfigYAxis) => ({ ...prevConfig, ...newConfig }));
    }

    const deviceIdUrlParam = (deviceIdList === undefined || deviceIdList.length === 0) ? '' : '&var-device_id=' + deviceIdList.join('&var-device_id=');
    const panelIdParam = `&panelId=${panelId}`;
    const minYParam = (config.minY === undefined) ? '' : '&var-min_y=' + config.minY;
    const maxYParam = (config.maxY === undefined) ? '' : '&var-max_y=' + config.maxY;

    const url = linkPrefix + deviceIdUrlParam + panelIdParam + timeRangeParam + minYParam + maxYParam;

    const minYStr = (config.minY !== undefined) && (config.minY !== 'auto') ? `MIN: ${parseFloat(config.minY).toFixed(2)}` : '';
    const maxYStr = (config.maxY !== undefined) && (config.maxY !== 'auto') ? `MAX: ${parseFloat(config.maxY).toFixed(2)}` : '';
    const configIsNotDefault = (minYStr !== '' || maxYStr !== '');
    const configBtnText = configIsNotDefault ? `Y Axis ${minYStr} ${maxYStr}` : 'Set Y Axis';

    return (
        <Grid item width="100%">
            <CardTemplate>
                <iframe
                    ref={iframeEl}
                    src={url}
                    width="100%"
                    height="500"
                    frameBorder="0"
                />

                <>
                    { yAxisConfigurable &&
                        <Button
                            size="small"
                            variant={configIsNotDefault ? 'contained': 'outlined'}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                marginRight: '20px',
                                marginTop: '4px',
                                height: 24,
                                fontSize: '12px'
                            }}
                            children={configBtnText}
                            onClick={() => { setOpenYAxisDialog(true) }}
                        />
                    }
                </>
            </CardTemplate>

            { yAxisConfigurable && openYAxisDialog &&
                <GraphConfigYAxisDialog
                    open={openYAxisDialog}
                    onClose={() => { setOpenYAxisDialog(false) }}
                    currentConfig={config}
                    onSaveConfig={saveConfigYAxis}
                />
            }
        </Grid>
    );
}

interface IConfigYAxis {
    minY: string;
    maxY: string;
}

interface IPropsGraphConfigYAxisDialog {
    open: any;
    onClose: any;
    currentConfig: IConfigYAxis;
    onSaveConfig: (newConfig: IConfigYAxis) => void;
}

const GraphConfigYAxisDialog = ({ open, onClose, currentConfig, onSaveConfig }: IPropsGraphConfigYAxisDialog) => {
    const defaultConfig: IConfigYAxis = { minY: 'auto', maxY: 'auto' };
    const [config, setConfig] = useState<IConfigYAxis>(defaultConfig);

    useEffect( () => {
        setConfig({ ...currentConfig });
    }, []);

    const handleReset = () => {
        setConfig(defaultConfig);
        onSaveConfig(defaultConfig);
        onClose();
    }

    const handleSaveConfig = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSaveConfig({ minY: recorrectValue(config.minY), maxY: recorrectValue(config.maxY) });
        onClose();
    }

    const handleFieldChange = (fieldId: keyof IConfigYAxis, fieldValue: string) => {
        setConfig( (prevConfig: IConfigYAxis) => ({ ...prevConfig, [fieldId]: fieldValue }));
    }

    const handleFieldFocusOut = (fieldId: keyof IConfigYAxis) => {
        if (config[fieldId] === '')
            setConfig((prevConfig: IConfigYAxis) => ({ ...prevConfig, [fieldId]: 'auto' }));
        else {
            // const floatValue = parseFloat(config[fieldId]);
            // let newValue = (!isNaN(floatValue) && isFinite(floatValue)) ? floatValue : 'auto';
            setConfig((prevConfig: IConfigYAxis) => ({ ...prevConfig, [fieldId]: recorrectValue(config[fieldId]) }));
        }
    }

    const recorrectValue = (value: string) => {
        const floatValue = parseFloat(value);
        return (!isNaN(floatValue) && isFinite(floatValue)) ? floatValue.toString() : 'auto';
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <form onSubmit={handleSaveConfig}>
                <DialogTitle>
                    {"Set Y AXIS"}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        margin="normal"
                        id="maxY"
                        label="Maximum Y Axis"
                        variant="standard"
                        value={config.maxY}
                        onChange={(e) => { handleFieldChange('maxY', e.target.value) }}
                        onBlur={() => { handleFieldFocusOut('maxY') }}
                        fullWidth
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        id="minY"
                        label="Minimum Y Axis"
                        type="text"
                        variant="standard"
                        value={config.minY}
                        onChange={(e) => { handleFieldChange('minY', e.target.value) }}
                        onBlur={() => { handleFieldFocusOut('minY') }}
                        fullWidth
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={handleReset}>Reset</Button>
                    <Button type="submit" variant="contained">Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
