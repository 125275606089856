import React, { FunctionComponent, useEffect, useState } from "react";
import { MapBox, usePageContext } from "./GpsTrackingPage";
import { ISensorItem, reqDeviceData } from "api/useGpsTrackingAPI";
import BaseMap from "components/Map/BaseMap";
import SensorMarker from "components/Map/Marker/SensorMarker";
import { DEVICES } from "api/useRequest";
import { LatLngExpression } from "leaflet";
import HistoryPoint from "./HistoryPoint";
import HistoryLine from "./HistoryLine";
import { SimpleLoading } from "components/Loading/SimpleLoading";

interface GpsTrackingMapContentProps {
}

interface IHistoryData {
    name: string;
    latLngs: LatLngExpression[] | LatLngExpression[][];
    timestamp: string;
    speed: string;
}

const GpsTrackingMapContent: FunctionComponent<GpsTrackingMapContentProps> = (props) => {
    const { stateContext, dispatchContext } = usePageContext();
    const [latLngHistory, setLatLngHistory] = useState<Record<string, LatLngExpression[] | LatLngExpression[][]>>();
    const [historyDataByID, setHistoryDataByID] = useState<Record<string, ISensorItem[]>>();

    const createDevicePath = async () => {

        let deviceIdList = stateContext.curSensorList.map((item: ISensorItem) => item.device_id);
        console.log("deviceIdList", deviceIdList);
        let newLatLngs = { ...latLngHistory };
        let newHistoryDataByID = { ...historyDataByID };
        for (let i = 0; i < deviceIdList.length; i++) {
            const id = deviceIdList[i];
            let items: ISensorItem[] = await getDeviceDataByID(id);
            items = items.sort((a: ISensorItem, b: ISensorItem) => (a.timestamp > b.timestamp ? 1 : -1));
            newHistoryDataByID[id] = items;

            let latLngs: LatLngExpression[] | LatLngExpression[][] = items.map((element: ISensorItem) => [Number(element.latitude), Number(element.longitude)]);
            console.log("latLngs", latLngs);
            newLatLngs[id] = latLngs;
        }

        console.log("newLatLngs", newLatLngs);
        setLatLngHistory(newLatLngs);
        console.log("newHistoryDataByID", newHistoryDataByID);
        setHistoryDataByID(newHistoryDataByID);
    }

    const getDeviceDataByID = async (id: string) => {
        const res = await reqDeviceData(stateContext.dateRange, id);
        console.log(id, res);
        if (res.status != 200) {
            return [];
        }
        const { items } = res.data;
        return items;
    }

    useEffect(() => {
        createDevicePath();
    }, [stateContext.allSensorList, stateContext.curSensorList, stateContext.dateRange])


    return (
        <MapBox>
            {stateContext.curSensorList.length > 0 ?
                <BaseMap>
                    {
                        stateContext.curSensorList.map((e: ISensorItem, i: number) => (
                            <SensorMarker
                                key={`GpsMarker_${i}`}
                                type={DEVICES.GPS}
                                state={e.state}
                                position={[Number(e.latitude), Number(e.longitude)]}
                                label={e.device_name}
                                data={e}
                            />
                        ))
                    }
                    <HistoryLine latLngHistory={latLngHistory} />
                    <HistoryPoint historyDataByID={historyDataByID} />
                </BaseMap>
                : <SimpleLoading />}
        </MapBox>
    );
}

export default GpsTrackingMapContent;


