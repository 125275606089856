import { FunctionComponent, useEffect, useState } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { CardTemplate } from "components/Card/CardTemplate";
import { SelectDeviceName } from "./SelectDeviceName";
import useUserContext from "context/UserContext";

const TruckTimeline: FunctionComponent<any> = ({ dateRange }) => {
    const [filterID, setFilterID] = useState<string>("");
    const accessToken = useUserContext().auth?.access_token

    const url = `${process.env.REACT_APP_GRAFANABASE_BASE_URL}/d/dumptruck/dumptruck?orgId=1&var-Crusher=${filterID}&kiosk&from=${dateRange[0].getTime()}&to=${dateRange[1].getTime()}&auth_token=${accessToken}`

    return (
        <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
            <SelectDeviceName
                noAll={true}
                onChange={(value: string) => { setFilterID(value) }}
            />
            <CardTemplate>
                <iframe
                    src={url}
                    width="100%"
                    height="640"
                    style={{ border: "none" }}
                />
            </CardTemplate>
        </Grid>
    )
}

export default TruckTimeline;
