const RoutePermission = {
    none: undefined,
    setting: ['admin', 'setting'],
    usermanagement: ['admin'],
    weather: ['admin', 'weather'],
    conveyor: ['admin', 'conveyor'],
    arsenic: ['admin', 'arsenic'],
    wireless: ['admin', 'gateway', "wireless"],
    landslide: ['admin', 'landslide'],
    waterlevel: ['admin', 'water_level'],
    maplayersetting: ['admin'],
    piezometer: ['admin', 'groundwater_pressure'],
    gpstracking: ['admin', 'gps'],
    truck: ['admin', 'truck'],
    noti_line: ['admin', "setting"],
}

// 'dashboard'
// 'piezo'
// 'arsenic'
// 'landslide'
// 'gps'
// 'groundwater_pressure'
// 'water_level'
// 'weather'
// 'belt'
// 'truck'

export default RoutePermission
