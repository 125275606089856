import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import styledComponent from "styled-components";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Tooltip,
  Button,
  TableCellProps,
} from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';

import { visuallyHidden } from '@mui/utils';

import color from "../../constants/Colors";
import { grey } from '@mui/material/colors';

import parseDateTime from 'helpers/parseDateTime'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el: any, index: number) => [el, index] as [any, number]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  headCells?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


interface ITableProps {
  tableName: string;
  onDownloadReport?: () => void;
}

const EnhancedTableToolbar = (props: ITableProps) => {

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
        align="left"
        fontSize="16px"
      >
        {
          props.tableName
        }
      </Typography>

      <Tooltip title="ดาวน์โหลดรายงาน">
        <Search>
          <ReportButton variant="outlined" type="submit" startIcon={<DownloadIcon />} size="small" style={{ width: "100%", minWidth: 150 }} onClick={props.onDownloadReport}>
            ดาวน์โหลดรายงาน
          </ReportButton>
        </Search>
      </Tooltip>
    </Toolbar>
  );
};

interface ICurrentProps {
  tableName: string;
  tableCell?: any;
  device: string;
  orderBy: string;
  order: Order;
  DataCurrentDevice?: any;
}

export default function CurrentTable(props: ICurrentProps) {
  const [order, setOrder] = React.useState<Order>(props.order);
  const [orderBy, setOrderBy] = React.useState<string>(props.orderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [HeaderCell] = React.useState<any>(props.tableCell)
  const [DataCurrentDevice, setDataCurrentDevice] = React.useState<any[]>(props.DataCurrentDevice)

  React.useLayoutEffect(() => {
    setDataCurrentDevice(props.DataCurrentDevice)
    return
  })

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - DataCurrentDevice.length) : 0;

  const CheckStatus = (status: string): any => {

    if (status === "Online") {
      return <StatusShow>
        <BoxGreen />{"Online"}
      </StatusShow>
    } else {
      return <StatusShow>
        <BoxRed />{"Offline"}
      </StatusShow>
    }
  }

  const OnlineOfflineColumn = (status: string) => {
    const isOnline: boolean = (status === "Online")
    const title: string     = isOnline ? "อุปกรณ์ออนไลน์" : "อุปกรณ์ออฟไลน์"
    const color: string     = isOnline ? "green" : "lightgray"

    const style = {
      height: '0.8em',
      width: '0.8em',
      borderRadius: '50%',
      cursor: 'pointer',
    }

    return (
      <Tooltip title={title} placement="top" arrow>
        <Box sx={{ ...style, backgroundColor: color}} />
      </Tooltip>
    )
  }

  const TableCellWithSecondary = (primary: string, secondary: string) => {
    const CellContainer = styled('div')`
      display: flex;
      flex-direction: column;
    `

    return (
        <CellContainer>
          <span>{ primary.toLocaleString() }</span>
          <Typography
            variant="caption"
            color={grey[500]}
            fontSize="10px"
            marginTop="-2px"
          >
            { secondary.toLocaleString() }
          </Typography>
        </CellContainer>
    )
  }

  const ConfigCell = (row: any, Headder: any): any => {
    var Cell: any = []

    Headder.forEach((e: any, i: number) => {
      const tableCellProps = {
        key: `${i}_${e.id}`,
        align: (e.numeric ? 'right' : 'left') as TableCellProps['align'],
        style: {
          color: e.color,
          backgroundColor: e.bgcolor,
        }
      };

      Cell.push(
        <TableCell {...tableCellProps}>
          { e.isStatus ? CheckStatus(row[e.id]) : /* status */

            // Device online status
            e.isOnline ? OnlineOfflineColumn(row[e.id]) :

            // Secondary text
            (e.subid && e.sublabel) ? TableCellWithSecondary(row[e.id], row[e.subid]) :

            // Upppercase
            e.isUpper ? (Boolean(row[e.id]) === false ? (e.Zero ? "0" : "-") : row[e.id].toUpperCase()) :

            // Datetime
            e.isUseFormatTime ? parseDateTime(row[e.id]) :

            (Boolean(row[e.id]) === false) ? (e.Zero ? "0" : "-") :

            // Numeric
            e.numeric ? row[e.id].toLocaleString() : row[e.id]
          }
        </TableCell>
      )
    })
    return Cell
  }

  return (
    <Box sx={{ width: '100%' }}>
      <EnhancedTableToolbar tableName={props.tableName} onDownloadReport={() => {
        // DataCurrentDevice
        // HeaderCell
        var title: any[] = HeaderCell.map((e: any) => (!Boolean(e['notDownload'])) ? e.label : null).filter((it: any) => it !== null)
        var csvHeader: string;
        var body: any[]
        csvHeader = title.join(",")
        var csv: string = `data:text/csv;charset=utf-8,${csvHeader},\r\n`;
        DataCurrentDevice.forEach((be: any, bi: any) => {
          body = HeaderCell.map((he: any, hi: number) => {
            if (Boolean(he['notDownload'])) {
              return null;
            }
            return (he.isUpper
              ? be[he.id].toUpperCase()
              : (he.isUseFormatTime
                ? parseDateTime(be[he.id])
                : be[he.id]))
          }).filter((it: any) => it !== null)
          csv += body.join(",") + "\r\n"
        })

        // create link download.
        var encodedUri = encodeURI(csv);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `EGAT_IOT_CURRENT_EXPORT${parseDateTime(new Date())}.csv`);
        document.body.appendChild(link);
        link.click();

      }} />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={HeaderCell}
          />
          <TableBody>
            {stableSort(DataCurrentDevice, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => {
                return (
                  <TableRow
                    key={`${index}_${row.name}`}
                    hover
                    tabIndex={-1}
                  >
                    {
                      ConfigCell(row, HeaderCell).map((e: any) => { return e })
                    }
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (53) * emptyRows,
                }}
              >
                <TableCell colSpan={HeaderCell.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={DataCurrentDevice.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"จำนวนแถวต่อหน้า:"}
      />
    </Box>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const BoxRed = styledComponent.div`
  margin-right: 14px;
  height: 5px;
  width: 5px;
  border: 1px solid solid;
  background-color: red;
`

const BoxGreen = styledComponent.div`
  margin-right: 10px;
  height: 5px;
  width: 5px;
  border: 1px solid solid;
  background-color: green;
`

const StatusShow = styledComponent.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`
const ReportButton = styled(Button)`
    border: 1px solid ${color.iconBlue};
    color: ${color.iconBlue};
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
`
