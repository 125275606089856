import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { SelectChangeEvent } from "@mui/material";
import { useParams } from 'react-router-dom';
import SelectDevice from "components/SelectBox/SelectDevice";
import { usePageContext } from "./TruckPage";
import { ISensorItem } from "api/useTruckAPI";
import { Box } from "@mui/system";

export function SelectDeviceName({ onChange, noAll }: { onChange: (value: string) => void, noAll: boolean }) {
    const { stateContext } = usePageContext();

    const [value, setValue] = useState("");
    const [options, setOptions] = useState<Array<any>>([]);

    useEffect(() =>{
        if (stateContext?.curSensorList) {
            let newOptions: Array<any> = stateContext.curSensorList.map((e: any) => ({
                name: e.device_name,
                value: e.device_id,
            }));

            if (!noAll) {
                newOptions.unshift({
                    name: "เลือกทั้งหมด",
                    value: "all",
                })
            }

            setOptions(newOptions)
            setValue(newOptions[0].value)
            onChange(newOptions[0].value)
        }
    }, [stateContext.curSensorList])

    return (
        <Box margin={"4px 2px"} >
            <SelectDevice
                label="เลือกครัชเชอร์"
                width="fit-content"
                selected={value}
                Option={options}
                onChange={(event: SelectChangeEvent) => {
                    setValue(event.target?.value)
                    onChange(event.target?.value)
                }} />
        </Box>
    )
}
