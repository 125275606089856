import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from '@mui/material';
import GlobalMUITheme from './styles/GlobalMUITheme';
import GlobalStyle from './styles/GlobalStyle';
import { LayoutProvider } from './context/LayoutContext';
import { UserContextProvider } from './context/UserContext';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider } from 'react-query';
import reactQueryClientConfig from 'configure/reactQueryClientConfig';

import "./index.css"
import { MapContextProvider } from 'context/MapContext';

ReactDOM.render(
  <React.StrictMode>
    <LayoutProvider>
      <QueryClientProvider client={reactQueryClientConfig}>
        <UserContextProvider>
          <MapContextProvider >
            <ThemeProvider theme={GlobalMUITheme}>
              <GlobalStyle />
              <App />
            </ThemeProvider>
          </MapContextProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </LayoutProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
