import React, { useState, useEffect } from "react";
import sideBarIcon from '../../assets/icons/icon-double-arrow-down-wh.svg';
import logoEgat from '../../assets/icons/logo-egat.svg';
import { Drawer, IconButton, Icon, List } from "@mui/material";
import styled, { css } from "styled-components";
import GlobalMUITheme from "../../styles/GlobalMUITheme";
import color from "../../constants/Colors";
import SidebarLink from "./sidebarLink";
import useUserContext from 'context/UserContext';
import { structure } from './sideMenuList';
import usePermissionAPI from "api/usePermissionAPI";
import SnackbarUtils from 'helpers/utils';
import { useLogoutAPI } from "api/useAuthenticationAPI";

interface Props {
    layoutState: {
        isSidebarOpened: boolean,
        location: any
    },
    toggleSidebar: () => void
}

const Sidebar: React.FC<Props> = (props) => {
    const userContext = useUserContext();
    const [isLoggedIn, setLogin] = useState(userContext.auth?.access_token !== undefined);
    const apiPermissionAPI = usePermissionAPI(isLoggedIn);
    const logout = useLogoutAPI()
    const { layoutState, toggleSidebar } = props;
    var [isPermanent, setPermanent] = useState(true);
    var dataList: any[] = []

    const permission = userContext.permission?.permissions ? userContext.permission?.permissions : [""];
    // console.log("permission: ", permission);

    structure.forEach((element: any, index: number) => {
        // console.log("element: ", element)

        if (element.requireAuthen !== undefined) {
            if (element.requireAuthen.includes("setting") && permission.includes('setting')){
                dataList.push(element)
            }
        }

        if (element.requireAuthen !== undefined) {
            if (element.requireAuthen.includes("usermanagement") && permission.includes('usermanagement')){
                dataList.push(element)
            }
        }

        if (permission.includes('admin') || element.requireAuthen === undefined) {
            dataList.push(element)
        } else if (element.children !== undefined) {
            let newElement = { ...element }

            // if (element.requireAuthen !== undefined){
            //     if (element.requireAuthen.includes("setting")){
            //         dataList.push(element)
            //     }
            // }

            newElement.children = element.children.filter((c: any) => {
                return (c.requireAuthen && c.requireAuthen.some((r: any) => permission.includes(r)))
            })
            if (newElement.children.length > 0) {
                dataList.push(newElement)
            }
        }
    });

    useEffect(() => {
        setLogin(userContext.auth?.access_token !== undefined)
    }, [userContext.auth]);

    useEffect(() => {
        if (isLoggedIn && apiPermissionAPI.isSuccess) {
            userContext.setPermissions(apiPermissionAPI.data.data)
        }
        else if (!isLoggedIn) {
            userContext.setPermissions({ permissions: [] })
        }
        else if (isLoggedIn && apiPermissionAPI.isError) {
            if (Number(apiPermissionAPI.error?.response?.status) >= 401 && Number(apiPermissionAPI.error?.response?.status) <= 499) {
                logout.mutate(undefined,
                    {
                        onSuccess: () => {
                            userContext.clearAuth()
                            SnackbarUtils.error(
                                'คุณออกจากระบบแล้ว กรุณาเข้าสู่ระบบอีกครั้ง'
                            )
                            apiPermissionAPI.remove()
                        },
                        onError: () => {
                            userContext.clearAuth()
                            SnackbarUtils.error(
                                'สิทธิ์ในการเข้าถึงข้อมูลหมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง'
                            )
                            apiPermissionAPI.remove()
                        }
                    });
            } else {
                userContext.clearAuth()
                userContext.setPermissions({ permissions: [] })
                SnackbarUtils.error(
                    'คุณออกจากระบบแล้วเนื่องจากเกิดข้อผิดพลาด ไม่สามารถเรียกข้อมูล permission ได้'
                )
            }
        }


    }, [isLoggedIn, apiPermissionAPI.data?.data, apiPermissionAPI.error, apiPermissionAPI.isError])



    var theme = GlobalMUITheme;

    const handleWindowWidthChange = () => {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.sm;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return () => {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    return (
        <React.Fragment >

            <SideDrawer variant={isPermanent ? "permanent" : "temporary"}
                open={layoutState.isSidebarOpened}
            >
                {layoutState.isSidebarOpened ? <div>
                    <DrawerHeader>
                        <LogoIcon><img src={logoEgat}></img></LogoIcon>
                        <ToggleSideBar
                            color="inherit"
                            aria-label="close sidebar"
                            onClick={() => toggleSidebar()}
                        >
                            <ArrowCloseIcon_Header>
                                <img src={sideBarIcon}></img>
                            </ArrowCloseIcon_Header>
                        </ToggleSideBar>
                    </DrawerHeader>
                    <List>
                        {dataList.map(e => {
                            return (
                                <SidebarLink
                                    key={e.id.toString() + e.label}
                                    label={e.label ? e.label : ""}
                                    link={e.link ? e.link : ""}
                                    icon={e.icon ? e.icon : null}
                                    type={e.type ? e.type : ""}
                                    location={layoutState.location}
                                    children={e.children ? e.children : []}
                                    isSidebarOpened={layoutState.isSidebarOpened}
                                />
                            )
                        })}
                    </List></div> : null
                }
            </SideDrawer>
        </React.Fragment>
    );
}

export default Sidebar;

interface SideDrawerProps {
    open: boolean,
}

export type { SideDrawerProps };

const SideDrawer = styled(Drawer).attrs((props: SideDrawerProps) => ({ open: props.open })) <SideDrawerProps>`
    & .MuiPaper-root{
        display: block;
        z-index: ${(props: any) => props.theme.zIndex.drawer + 0};
        margin-right: 500px;
        flex-shrink: 0;
        white-space: "nowrap";
        background-color: ${color.sidebarBackground};
        transition: ${(props: any) => props.theme.transitions.create(['margin', 'width'], {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.enteringScreen
})};
        width: ${props => props.open ? `${props.theme.sidebarWidth}px` : `0px`};
        ${(props: any) => props.theme.breakpoints.down("sm")} {
            //mobile
        };
    }
`

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1.5),
    paddingLeft: theme.spacing(2.5),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const ToggleSideBar = styled(IconButton)`
    ${(props: any) => props.theme.breakpoints.down("sm")} {
      margin-left: 0;
    };
    padding: ${(props: any) => props.theme.spacing(0.5)};
    margin-right: ${(props: any) => props.theme.spacing(0)};
`
const headerIcon = css`
    font-size: 28;
    color: "rgba(255, 255, 255, 0.35)";
    color: "white";
`

const ArrowCloseIcon_Header = styled(Icon)`
    ${headerIcon};
`
const LogoIcon = styled(ArrowCloseIcon_Header)`
    width: auto;
    height: auto;
    margin-right: auto;
`


