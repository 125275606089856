import { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { CardTemplate } from "components/Card/CardTemplate";
import { DEVICES } from "api/useRequest";
import { usePageContext } from "./WaterLevelPage";
import BaseCurrentTable from "components/BaseTable/BaseCurrentTable";
import * as WaterLvAPI from "api/useWaterLevelAPI";
import { decimalRound } from '../../../helpers/utils'

export function WaterQuantityTable() {
    const { stateContext } = usePageContext();
    const [dataList, setDataList] = useState<any[]>([]);

    useEffect(() => {
        (async () => {
            const res = await WaterLvAPI.reqSummary();
            if (res.status != 200) {
                return [];
            }

            const datas: IDataOnTable[] = res.data?.map((data: any) => {
                const {
                    device_id,
                    device_name,
                    sump_name,
                    level,
                    warn_level,
                    crit_level,
                    current_volume,
                    warn_volume,
                    crit_volume,
                    max_volume,
                } = data;

                const toPercent = (value: number) => {
                    if (max_volume === 0)
                        return '0%';
                    return `${((value / max_volume) * 100).toFixed(2)}%`
                };

                return {
                    device_id,
                    device_name,
                    sump_name,
                    level,
                    warn_level,
                    crit_level,
                    current_volume,
                    current_volume_percent: toPercent(current_volume),
                    warn_volume,
                    warn_volume_percent: toPercent(warn_volume),
                    crit_volume,
                    crit_volume_percent: toPercent(crit_volume),
                    max_volume
                };
            });

            setDataList(datas);
        })();
    }, [stateContext.curSensorList])

    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
        <CardTemplate>
            <BaseCurrentTable tableName={"ตารางสรุปปริมาณน้ำในบ่อ"} device={DEVICES.WATERLEVEL} order={"asc"} orderBy={"device_id"} tableCell={tableHeaders} DataCurrentDevice={dataList} />
        </CardTemplate>
    </Grid>;
}

interface IDataOnTable {
    device_id: string;
    device_name: string;
    sump_name: string;
    level: number,
    warn_level: number,
    crit_level: number,
    current_volume: number;
    current_volume_percent: string;
    warn_volume: number;
    warn_volume_percent: number;
    crit_volume: number;
    crit_volume_percent: number;
    max_volume: number;
}

interface ITableHeader {
    disablePadding: boolean;
    id: keyof IDataOnTable;
    label: string;
    subid?: keyof IDataOnTable;
    sublabel?: string;
    numeric: boolean;
    isStatus?: boolean;
    isUpper?: boolean;
    isUseFormatTime?: boolean;
    color?: string,
    bgcolor?: string,
}

const defaultHeader: ITableHeader = {
    id: 'device_name',
    label: '',
    numeric: true,
    disablePadding: false,
}

const tableHeaders: readonly ITableHeader[] = [
    {
        ...defaultHeader,
        id: 'device_name',
        label: 'อุปกรณ์',
        subid: 'device_id',
        sublabel: 'ID',
        numeric: false,
    },
    {
        ...defaultHeader,
        id: 'sump_name',
        label: 'บ่อ',
        numeric: false,
    },
    {
        ...defaultHeader,
        id: 'level',
        label: 'ระดับน้ำ',
        bgcolor: '#f0f9ff',
    },
    {
        ...defaultHeader,
        id: 'warn_level',
        label: 'ระดับควบคุม',
        bgcolor: '#f0f9ff',
    },
    {
        ...defaultHeader,
        id: 'crit_level',
        label: 'ระดับวิกฤต',
        bgcolor: '#f0f9ff',
    },
    {
        ...defaultHeader,
        id: 'current_volume',
        label: 'ปริมาณน้ำปัจจุบัน (m\u00B3)',
        subid: 'current_volume_percent',
        sublabel: 'ปริมาณน้ำ %',
        bgcolor: '#fffeed',
    },
    {
        ...defaultHeader,
        id: 'warn_volume',
        label: 'ความจุควบคุม (m\u00B3)',
        subid: 'warn_volume_percent',
        sublabel: 'ความจุควบคุม %',
        bgcolor: '#fffeed',
    },
    {
        ...defaultHeader,
        id: 'crit_volume',
        label: 'ความจุวิกฤต (m\u00B3)',
        subid: 'crit_volume_percent',
        sublabel: 'ความจุวิกฤต %',
        bgcolor: '#fffeed',
    },
    {
        ...defaultHeader,
        id: 'max_volume',
        label: 'ความจุสูงสุด (m\u00B3)',
    },
];
