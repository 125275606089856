import { useEffect, useState } from "react";
import * as TruckAPI from "api/useTruckAPI";
import SnackbarUtils from "helpers/utils";
import { QrMapItemSaved } from "./QrMapping/AddEditQrMapDialog";

export const useQrMapping = () => {
    const [qrMaps, setQrMaps] = useState<TruckAPI.QrMapItem[]>([]);
    useEffect(() => {
        getMapQrTruck();
    }, []);
    const getMapQrTruck = async () => {
        TruckAPI.getCarNumberMapping()
            .then((res: any) => {
                console.log("getCarNumberMapping", res);
                let newQrMaps: TruckAPI.QrMapItem[] = res.data?.items;
                if (newQrMaps === undefined)
                    throw new Error("Qr maps is undefined.");
                setQrMaps(newQrMaps);

            }).catch((err: any) => {
                console.log("Get Qr mapping fail.", err);

            });
    };
    const handleAddQrMapItem = (values: QrMapItemSaved) => {
        console.log("handleAddQrMapItem", values);
        TruckAPI.addCarNumberMapping(values.name, values.qr_values)
            .then((res: any) => {
                console.log("addCarNumberMapping res", res);
                getMapQrTruck();
                SnackbarUtils.success("Add Successed");

            }).catch((err: any) => {
                console.log("addCarNumberMapping err", err);
                SnackbarUtils.error("Add Error");

            });
    };
    const handleEditQrMapItem = (values: QrMapItemSaved) => {
        console.log("handleEditQrMapItem", values);
        if (values.id === undefined) {
            console.log("values have no id");
            SnackbarUtils.error("Edit Error");
        }

        TruckAPI.editCarNumberMapping(values.id!, values.name, values.qr_values)
            .then((res: any) => {
                console.log("addCarNumberMapping res", res);
                getMapQrTruck();
                SnackbarUtils.success("Edit Successed");

            }).catch((err: any) => {
                console.log("addCarNumberMapping err", err);
                SnackbarUtils.error("Edit Error");

            });

    };
    const handleDeleteQrMapItem = (id: number) => {
        console.log("handleDeleteQrMapItem", id);
        TruckAPI.deleteCarNumberMapping(id)
            .then((res: any) => {
                console.log("deleteCarNumberMapping", res);
                getMapQrTruck();
                SnackbarUtils.success("Delete Successed");

            }).catch((err: any) => {
                console.log("Get Qr mapping fail.", err);
                SnackbarUtils.error("Delete Error");

            });

    };
    return {
        qrMaps,
        getMapQrTruck,
        handleAddQrMapItem,
        handleEditQrMapItem,
        handleDeleteQrMapItem,
    };
};
