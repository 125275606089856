
import * as dateFns from 'date-fns';
import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Button, { ButtonProps } from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from "@mui/material/styles";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { myContext, StateProvider } from './DateTimeRangeGlobalState';
import { styled as styledMui } from '@mui/material/styles';
import { Grid, Menu, Tooltip } from '@mui/material';
import { format, addDays, isBefore, isAfter, isEqual } from "date-fns"

import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
    IDatePickerRelativeProfile,
    IDatePickerRelativeProfileCallback
} from './DatePickerRelativeProfile';
import { DatePicker } from '@mui/lab';

/**
 * DateTimePicker Card
 */
const DATE_FORMAT = "yyyy-MM-dd";
const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";

export interface CustomDateTimeRangePaperProps {
    relativeProfile: IDatePickerRelativeProfile[];
    onChangeDefaultValue?: (fromDate: Date, toDate: Date) => void;
    daySelector?: boolean;
    dateOnly?: boolean;
    maxDateTime?: Date;
    minDateTime?: Date;
}

function CustomDateTimeRangePaper({ relativeProfile, onChangeDefaultValue, daySelector, dateOnly, maxDateTime, minDateTime }: CustomDateTimeRangePaperProps): JSX.Element {
    const { state, dispatch } = React.useContext(myContext);

    function onChangeFromDate(value: Date | null) {
        const newValue: Date = value || new Date();
        dispatch!({ fromDate: newValue });

        if (dateOnly)
            dispatch!({ toDate: addDays(newValue, 1) });
    }

    function onChangeToDate(value: Date | null) {
        dispatch!({ toDate: value || new Date() })

    }

    function onTapApplyTimeRange(): void {
        if (onChangeDefaultValue !== undefined) {
            onChangeDefaultValue(state.fromDate, state.toDate);
        }
        state.onChange([state.fromDate, state.toDate]);
        closePopper(dispatch);
    }

    return (

        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start',
            alignContent: 'center',
            flexWrap: 'nowrap',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                alignContent: 'center',
                flexWrap: 'nowrap',
                gap: '20px',
                p: "20px"
            }}>
                <Typography variant="subtitle1" color="initial" >
                    Absolute time range
                </Typography>
                <BasicDateTimePicker
                    label={daySelector ? "Date" : "From Date"}
                    initDate={state.fromDate}
                    callback={onChangeFromDate}
                    dateOnly={dateOnly}
                    maxDateTime={maxDateTime}
                    minDateTime={minDateTime}
                />
                {daySelector ||
                    <BasicDateTimePicker
                        label="To Date"
                        initDate={state.toDate}
                        callback={onChangeToDate}
                        dateOnly={dateOnly}
                        maxDateTime={maxDateTime}
                        minDateTime={minDateTime}
                    />
                }
                <Button variant="contained" color="success" onClick={onTapApplyTimeRange}>Apply time range</Button>
            </Box>
            {daySelector ||
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    alignContent: 'center',
                    flexWrap: 'nowrap',
                    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                    p: "20px",
                    height: "100%",

                }}>
                    <Typography variant="subtitle1" color="initial" sx={{ alignSelf: "flex-start" }}>
                        Relative time ranges
                    </Typography>
                    <GroupOrientation 
                        maxDateTime={maxDateTime}
                        minDateTime={minDateTime}
                        relativeProfile={relativeProfile} 
                        onChangeDefaultValue={onChangeDefaultValue} 
                    />
                </Box>
            }
        </Box>

    );

}
const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    padding: '6px 14px',
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#fff',
    borderRadius: "8px",
    border: "1px solid #E9EBF0",
    '&:hover': {
        backgroundColor: '#fff',
    },
}));

/**
 * A Date time picker
 */
interface IBasicDateTimePicker {
    label?: string;
    initDate: Date;
    callback?: (value: Date | null) => void;
    dateOnly?: boolean;
    maxDateTime?: Date;
    minDateTime?: Date;
}

function closePopper(dispatch: React.Dispatch<any> | undefined) {
    dispatch!({ isOpen: false });
}

function BasicDateTimePicker(props: IBasicDateTimePicker) {
    const [value, setValue] = React.useState<Date | null>(props.initDate);

    const pickerProps = {
        renderInput: (props: any) => <TextField {...props} />,
        value: value,
        onChange: (newValue: Date | null) => {
            setValue(newValue);
            if (props.callback != undefined) {
                props.callback!(newValue);
            }
        },
        inputFormat: props.dateOnly ? DATE_FORMAT : DATE_TIME_FORMAT,
        maxDate: props.maxDateTime,
        minDate: props.minDateTime,
        ...props
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {props.dateOnly ?
                    <DatePicker {...pickerProps} />
                    :
                    <DateTimePicker {...pickerProps} />
                }
            </LocalizationProvider>
        </>
    );
}

function GroupOrientation({ relativeProfile, onChangeDefaultValue, minDateTime, maxDateTime }: CustomDateTimeRangePaperProps) {
    const { state, dispatch } = React.useContext(myContext);

    const onClickOption = (cb: IDatePickerRelativeProfileCallback) => {
        const value = cb(new Date());

        if (onChangeDefaultValue !== undefined) {
            onChangeDefaultValue(value[0], value[1]);
        }

        state.onChange(value);
        dispatch!({ fromDate: value[0] || new Date() })
        dispatch!({ toDate: value[1] || new Date() })
        closePopper(dispatch);
    }

    const btnMustBeDisabled = (cb: IDatePickerRelativeProfileCallback) => {
        console.log('btnMustBeDisabled');
        if (minDateTime === undefined && maxDateTime === undefined) {
            return false;
        }
        const [newFrom,newTo] = cb(new Date());
        return newFrom < minDateTime! || newTo > maxDateTime!;
    }

    return (
        <>
            <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
                variant="text"
                color="inherit"
                size="small"
                sx={{ width: "100%" }}
            >
                {relativeProfile.map((e) =>
                    <Button
                        key={e.label}
                        disabled={btnMustBeDisabled(e.callback)}
                        onClick={() => { onClickOption(e.callback); }}
                        children={e.label}
                    />
                )}
            </ButtonGroup>
        </>
    );
}



/**
 *  Main component
 */

interface IDateTimeRangePickerProps {
    initValue?: Date[];
    onChange: (value: Date[]) => void;
    relativeProfile: IDatePickerRelativeProfile[];
    daySelector?: boolean;
    dateOnly?: boolean;
    noZoom?: boolean;
    maxDateTime?: Date;
    minDateTime?: Date;
}

enum DateTimeCmd {
    SHIFT_LEFT = 0,
    SHIFT_RIGHT,
    ZOOM_IN,
    ZOOM_OUT,
    ZOOM_RESET,
}

function DateTimeRangePickerContent({ initValue, onChange, relativeProfile, daySelector, dateOnly, noZoom, maxDateTime, minDateTime }: IDateTimeRangePickerProps) {
    //TODO: implement Datetime options
    const { state, dispatch } = React.useContext(myContext);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const initVal = React.useRef<Date[]>([]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        dispatch!({ isOpen: !state.isOpen })

    };
    const handleClose = () => {
        dispatch!({ isOpen: false });
        setAnchorEl(null);
    };

    React.useEffect(() => {
        // const initVal = initValue === undefined ? [dateFns.addHours(new Date(), -1), new Date()] : initValue;
        console.log('useEffect in DateTimeRangePickerContent');

        let newValue: Date[];
        if (initValue === undefined) {
            const currentDate = new Date();
            currentDate.setSeconds(0, 0);
            newValue = [dateFns.addHours(currentDate, -1), currentDate];
        }
        else {
            initValue![0].setSeconds(0, 0);
            initValue![1].setSeconds(0, 0);
            newValue = initValue;
        }

        initVal.current = newValue;
        dispatch!({ onChange, fromDate: initVal.current[0], toDate: initVal.current[1] })
    }, [initValue])

    const getShiftDateTime = (cmd: DateTimeCmd) => {
        const dayInMs = 24 * 3600 * 1000;

        let from = state.fromDate.getTime();
        let to = state.toDate.getTime();
        let diff = to - from;
        let change = daySelector ? dayInMs : Math.round(diff / 3);

        let newFrom: Date;
        let newTo: Date;
        switch (cmd) {
            case DateTimeCmd.SHIFT_LEFT:
                change = (change < 0) ? change : change * -1;
                newFrom = new Date(from + change);
                newTo = new Date(to + change);
                break;

            case DateTimeCmd.SHIFT_RIGHT:
                change = (change >= 0) ? change : change * -1;
                newFrom = new Date(from + change);
                newTo = new Date(to + change);
                break;

            case DateTimeCmd.ZOOM_IN:
                change = (change >= 0) ? change : change * -1;
                newFrom = new Date(from + change);
                newTo = new Date(to - change);
                break;

            case DateTimeCmd.ZOOM_OUT:
                change = (change >= 0) ? change : change * -1;
                newFrom = new Date(from - change);
                newTo = new Date(to + change);
                break;

            case DateTimeCmd.ZOOM_RESET:
                newFrom = new Date(initVal.current[0].getTime());
                newTo = new Date(initVal.current[1].getTime());
                break;


        }

        return { newFrom, newTo };

    }

    const handleShiftDateTime = (cmd: DateTimeCmd) => {
        const { newFrom, newTo } = getShiftDateTime(cmd);
        dispatch!({ fromDate: newFrom, toDate: newTo });
        state.onChange([newFrom, newTo]);
    };

    const btnMustBeDisabled = (cmd: DateTimeCmd) => {
        console.log('btnMustBeDisabled');
        if (minDateTime === undefined && maxDateTime === undefined) {
            return false;
        }
        const { newFrom, newTo } = getShiftDateTime(cmd);
        return newFrom < minDateTime! || newTo > maxDateTime!;
    }


    const ShiftLeftBtn = () => (<IconButton children={<ChevronLeftIcon />} onClick={() => { handleShiftDateTime(DateTimeCmd.SHIFT_LEFT) }} disabled={btnMustBeDisabled(DateTimeCmd.SHIFT_LEFT)} />);
    const ShiftRightBtn = () => (<IconButton children={<ChevronRightIcon />} onClick={() => { handleShiftDateTime(DateTimeCmd.SHIFT_RIGHT) }} disabled={btnMustBeDisabled(DateTimeCmd.SHIFT_RIGHT)} />);
    const ZoomInBtn = () => (<Tooltip title="ซูมเข้า"><span><IconButton children={<ZoomInIcon />} onClick={() => { handleShiftDateTime(DateTimeCmd.ZOOM_IN) }} disabled={btnMustBeDisabled(DateTimeCmd.ZOOM_IN)} /></span></Tooltip>);
    const ZoomOutBtn = () => (<Tooltip title="ซูทออก"><span><IconButton children={<ZoomOutIcon />} onClick={() => { handleShiftDateTime(DateTimeCmd.ZOOM_OUT) }} disabled={btnMustBeDisabled(DateTimeCmd.ZOOM_OUT)} /></span></Tooltip>);
    const ZoomResetBtn = () => (<Tooltip title="รีเซ็ทซูม"><span><IconButton children={<RestartAltIcon />} onClick={() => { handleShiftDateTime(DateTimeCmd.ZOOM_RESET) }} disabled={btnMustBeDisabled(DateTimeCmd.ZOOM_RESET)} /></span></Tooltip>);

    const canBeOpen = state.isOpen && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;
    const handleChangeDefaultDate = (fromDate: Date, toDate: Date): void => {
        initVal.current = [fromDate, toDate];
    };

    const dateFormat = dateOnly ? DATE_FORMAT : DATE_TIME_FORMAT;
    const dateStr = daySelector ? format(state.fromDate, dateFormat) : `${format(state.fromDate, dateFormat)} ~ ${format(state.toDate, dateFormat)}`;

    return (
        <Grid>
            <ShiftLeftBtn />
            <ColorButton endIcon={<CalendarTodayOutlinedIcon sx={{ color: "#868788" }} />} onClick={handleClick} >
                <Typography variant="caption" color="initial" noWrap textOverflow="ellipsis" >
                    {dateStr}
                </Typography>
            </ColorButton>
            <Menu
                id={id}
                open={state.isOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handleClose}
            >
                <CustomDateTimeRangePaper
                    relativeProfile={relativeProfile}
                    onChangeDefaultValue={handleChangeDefaultDate}
                    daySelector={daySelector}
                    dateOnly={dateOnly}
                    maxDateTime={maxDateTime}
                    minDateTime={minDateTime}
                />
            </Menu>
            <ShiftRightBtn />
            {!noZoom &&
                <>
                    <ZoomInBtn />
                    <ZoomOutBtn />
                </>
            }
            <ZoomResetBtn />
        </Grid>
    );
}

export default function DateTimeRangePicker(props: IDateTimeRangePickerProps) {
    return (
        <StateProvider>
            <DateTimeRangePickerContent {...props} />
        </StateProvider>
    )
}

export const TimePickerFlexWithBreakpint = styledMui(Grid)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    [theme.breakpoints.down('sm')]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 8,
        marginBottom: 4,
    },
}));
