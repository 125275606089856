import requestInstance from "configure/requestConfig";
import { formatDate } from "../helpers/formatDate";

//API
import {
    STATUS,
} from 'api/useRequest'

/* API response
{
    "name": "My-Gateway",
    "description": "Phumphathai's Gateway",
    "organization_id": 1,
    "ping": false,
    "last_ping": 0,
    "last_ping_sent_at": "0001-01-01T00:00:00Z",
    "network_server_id": 2,
    "gateway_profile_id": "c1723cca-1756-4df3-bdd7-c5e80a837d8c",
    "first_seen_at": "2021-11-19T03:26:54.44337Z",
    "last_seen_at": "2021-12-09T13:32:47.802494Z",
    "latitude": 16.03211,
    "longitude": 100.3707,
    "altitude": 27,
    "service_profile_id": "8741739b-8231-42bc-bdea-2e28e75b21c7",
    "mac": "uCfr//7PcDk=",
    "created_at": "2021-11-19T03:26:35.3004Z",
    "updated_at": "2021-12-09T13:32:47.803893Z",
    "state": "Offline",
    "tag": null,
    "meta_data": null
}
*/
export interface ISensorItem {
    name: string;
    description: string;
    organization_id: number;
    ping: boolean;
    last_ping: number;
    last_ping_sent_at: string;
    network_server_id: number;
    gateway_profile_id: string;
    first_seen_at: string;
    last_seen_at: string;
    latitude: number;
    longitude: number;
    altitude: number;
    service_profile_id: string;
    mac: string;
    created_at: string;
    updated_at: string;
    state: string;
    tag: any;
    meta_data: any;
    [key: string]: any;
}

export const defaultSensorItem: ISensorItem = {
    name: "",
    description: "",
    organization_id: 0,
    ping: false,
    last_ping: 0,
    last_ping_sent_at: "",
    network_server_id: 0,
    gateway_profile_id: "",
    first_seen_at: "",
    last_seen_at: "",
    latitude: 0,
    longitude: 0,
    altitude: 0,
    service_profile_id: "",
    mac: "",
    created_at: "",
    updated_at: "",
    state: "",
    tag: null,
    meta_data: null
}

export const MapStateToDeviceCardState: Record<string, number> = {
    "Online": STATUS.ONLINE,
    "Offline": STATUS.OFFLINE,
    "Error": STATUS.ERROR,
}

export async function reqCurDeviceData() {
    const path = "/api/iot/all";
    const params = {
        limit: "",
        offset: "",
        device: "gateway"
    };

    const res = await requestInstance.get(path, { params: params, });
    return res;
}

export async function reqDeviceData(dateRange: Date[]) {
    const path = "/api/iot/gateway";
    const params = {
        limit: 10000,
        offset: 0,
        // start: formatDate(dateRange[0]),
        // end:formatDate(dateRange[1]),

    };

    const res = await requestInstance.get(path, { params: params, });

    return res;
}

export async function reqFileLayer() {
    const path = "/api/files/types";
    const params = {
        limit: "",
        offset: ""
    };

    const res = await requestInstance.get(path, { params: params, });
    console.log(res);

    return res;
}

