import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, selectedList: readonly string[], theme: Theme) {
    return {
        fontWeight:
            selectedList.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

interface MultipleSelectChipProps {
    label: string;
    name: string;
    allSelect: string[];
    selected: string[];
    error?: boolean;
    helperText?: string;
    onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onSelectedChange?: (event: SelectChangeEvent<string[]>) => void;
}

const MultipleSelectChip: React.FunctionComponent<MultipleSelectChipProps> = (props) => {
    const {
        label,
        name,
        error,
        helperText,
        allSelect,
        selected,
        onBlur,
        onSelectedChange,
    } = props;
    const theme = useTheme();

    const handleChange = (event: SelectChangeEvent<typeof selected>) => {
        const {
            target: { value },
        } = event;

        if (onSelectedChange !== undefined) {
            onSelectedChange(event);
        }

    };

    return (
        <>
            <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-chip-label" >{label}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    name={name}
                    value={selected}
                    error={Boolean(error)}
                    onBlur={onBlur}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label={label} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: '100%' }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {allSelect.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, selected, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
export default MultipleSelectChip;