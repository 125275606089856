import requestInstance from "configure/requestConfig";
import parseDate from "helpers/parseDate";
import parseTime from "helpers/parseTime";
import { formatDate } from "../helpers/formatDate";

/* API response
{
    "device_id": "e6a7010706888801",
    "device_name": "ConveyorT01",
    "power_pri": 15.6,
    "power_sec": 12.4,
    "status": 96,
    "raw": 0,
    "value": 0,
    "state": "Offline",
    "latitude": "18.331394873",
    "longitude": "99.712467163",
    "timestamp": "2021-12-10T13:16:16Z"
}
*/
export interface ISensorItem {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    status: number;
    raw: number;
    value: number;
    state: string;
    latitude: string;
    longitude: string;
    timestamp: string;
    line: string;
    name: string;
    reverse: boolean;
    [key: string]: any;
}

export const defaultSensorItem: ISensorItem = {
    device_id: "",
    device_name: "",
    power_pri: 0,
    power_sec: 0,
    status: 0,
    raw: 0,
    value: 0,
    state: "",
    latitude: "",
    longitude: "",
    timestamp: "",
    line: "",
    name: "",
    reverse: false,
}

export const MapStateToDeviceCardState: Record<string, string> = {
    "Online": "ON",
    "Offline": "OFF",
    "Error": "ERROR",
}

export async function reqCurDeviceData() {
    const path = "/api/iot/all";
    const params = {
        limit: "",
        offset: "",
        device: "conveyor"
    };

    const res = await requestInstance.get(path, { params: params, });
    return res;
}

export async function reqDeviceData(dateRange: Date[], device_id?: string) {

    const path = "/api/iot/conveyor";
    const params = {
        limit: 10000,
        offset: 0,
        start: parseDate(dateRange[0]) + ' ' + parseTime(dateRange[0]),
        end: parseDate(dateRange[1]) + ' ' + parseTime(dateRange[1]),
        device_id
    };

    console.log(params);


    const res = await requestInstance.get(path, { params: params, });
    console.log(res);

    return res;
}

export async function reqFileLayer() {
    const path = "/api/files/types";
    const params = {
        limit: "",
        offset: ""
    };

    const res = await requestInstance.get(path, { params: params, });
    console.log(res);

    return res;
}

