import React, { useState, useEffect, useRef } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ChromePicker } from 'react-color';
import styledComponent from "styled-components";

import parseDateTime from 'helpers/parseDateTime'

import {
  TextField,
  Stack,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material/';

//API
import {
  FILETPYE,
  FILES,
  RequestUploadFile,
  useEditFile,
  RequestEditFileBy,
  useGetFile,
  FIleItem,
  useUploadFile,
  UploadFile
} from 'api/useRequest'

import BaseUpload from 'components/FormUpload/BaseUpload'

interface IDialogProps {
  isOpen: boolean;
  setOpen: any;
  reFlashData: any;
  onFileLoaded?: (response: any) => void;

}

var data: RequestEditFileBy;

export default function ConveyerFileUpload(props: IDialogProps) {

  const [FName_, setFName_] = useState("Conveyor_dashboard");
  const [FileName, setFileName] = useState("");
  const [File, setFile] = React.useState<FileList | undefined>(undefined)
  const [FileType, setFileType] = React.useState("");

  const [validationFileName, setvalidationFileName] = React.useState(false)

  const [DisableBtn, setDisableBtn] = React.useState(false)
  const [HaveError, setHaveError] = React.useState(false)
  const [IsUpload, setIsUpload] = React.useState(false)

  const [Color, setColor] = React.useState<string>("#fff")

  const [DataFile, setDataFile] = React.useState<FIleItem[]>([])

  const [FileLastUpdate, setFileLastUpdate] = React.useState<string>("")

  const UploadApi = useUploadFile()
  const EditFileApi = useEditFile()
  const GetFIleApi = useGetFile()

  const FatchApi = () => {
    GetFIleApi.mutate(FILES.FILE, {
      onSuccess: (FileData: any) => {
        var color: any = ""
        FileData?.data?.file.forEach((e: FIleItem, i: number) => {
          if (e.file_type === "ShapeFile_Conveyer") {
            color = e.color
            setColor(color)
            setFileLastUpdate(parseDateTime(new Date(e?.updated_at)))
          }
        })
        if (props.onFileLoaded !== undefined) {
          props.onFileLoaded!(FileData)
        }
      },
    })
  }

  React.useEffect(() => {
    (() => {
      FatchApi()
    })()
  }, [])

  const handleClose = () => {
    props.setOpen(false)
    clear()
  };

  const handleUpload = () => {
    if (FName_ === "" || File === undefined) {
      if (FName_ === "") setvalidationFileName(true)
      if (File === undefined) alert("กรุณาเลือกไฟล์")
    } else {

      setDisableBtn(true)
      setIsUpload(true)

      GetFIleApi.mutate(FILES.FILE, {
        onSuccess: (FileData: any) => {
          const uploadSuccess = (FileData: any) => {
            console.log(FileData)
            setDisableBtn(false)
            setIsUpload(false)

            clear()
            props.setOpen(false)
            props.reFlashData()

            // FIXME: This is only workaround. It should be fixed later
            window.location.reload();
          };

          const uploadFail = (error: any) => {
            console.log(error)
            setDisableBtn(false)
            setHaveError(true)
            setIsUpload(false)
            alert("ไม่สามารถเพิ่มข้อมูลได้")

            clear()
            props.setOpen(false)
            props.reFlashData()
          };

          var Fid: number = -1;
          FileData?.data?.file.forEach((e: FIleItem, i: number) => {
            if (e.file_type === "ShapeFile_Conveyer") {
              Fid = e.id
            }
          })

          const isEdit = (Fid !== -1);
          const fileDetail: UploadFile = {
            file: File[0],
            file_type: FileType,
            name: FName_,
            color: Color
          }

          if (isEdit) {
            EditFileApi.mutate({
              FILES: FILES.FILE,
              EditFileBy: { file_id: Fid, File: { ...fileDetail } }
            }, {
              onSuccess: uploadSuccess, onError: uploadFail
            });
          }
          else {
            UploadApi.mutate({
              FILES: FILES.UPLOAD,
              UploadFile: { ...fileDetail }
            }, {
              onSuccess: uploadSuccess,
              onError: uploadFail
            });
          }
        }
      })
    }
  }

  const clear = () => {
    setvalidationFileName(false)
    // setFileName("")
    // setFile(undefined)
    setDisableBtn(false)
    setHaveError(false)
    setIsUpload(false)
    // setFName_("")
  }

  const firstRender = useRef(true);
  useEffect(() => {
    // FatchApi()
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
  });

  const fileExtension = (filename: string) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
  }

  const handleChangeComplete = (color: any) => {
    setColor(color.hex)
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        keepMounted
      >
        <DialogTitle id="scroll-dialog-title">อัปโหลดไฟล์</DialogTitle>

        <DialogContent >
          <Grid container direction="column" columnSpacing="5px" rowSpacing="5px" columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
            {/* Layer Name */}
            <Grid item>
              <Typography variant="body1" color="initial">
                ชื่อเลเยอร์
              </Typography>
            </Grid>
            <Grid item>
              <TextField value={FName_} disabled={true} error={validationFileName} id="outlined-basic" placeholder="ชื่อเลเยอร์" variant="outlined" sx={{ width: "450px" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                // setFName_(e.target.value)
                // setvalidationFileName(false)
              }} />
              <br></br>
              อัพเดทเมื่อวันที่: {FileLastUpdate}
            </Grid>
            <br></br>
            {/* File Upload */}
            <Grid item>
              {/* <BaseUpload open = {openFileDialog} /> */}
              <Typography variant="body1" color="initial">
                ไฟล์ : {(FileName ? FileName : "กรุณาเลือกไฟล์")}
              </Typography>
            </Grid>
            <Grid item>
              <div style={{ display: (IsUpload ? "block" : "none"), margin: "20px 0px" }} >
                <LinearProgress />
              </div>
              <BaseUpload btnName="อัพโหลดไฟล์คอนเวเยอร์" accept=".zip" onFile={(f?: any) => {
                console.log(f)
                var fileType = fileExtension(f[0].name)![0]
                if (fileType !== "zip") {
                  alert("กรุณาเลือกไฟล์ .zip")
                } else {
                  setFileName(f[0].name)
                  setFile(f)
                  setFileType(FILETPYE.SHAPEFILECONVEYER)
                }
              }} />
            </Grid>

            {/* File Upload */}
            <Grid item container direction={"column"}>
              <Typography variant="body1" color="initial">
                สี Vector :
              </Typography>
              <BoxColor style={{ backgroundColor: Color }}></BoxColor><ChromePicker
                color={Color}
                onChangeComplete={handleChangeComplete}
              />
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
          <Stack spacing={2} direction="row">
            <CancleButton disabled={DisableBtn} variant="text" onClick={() => handleClose()}>ยกเลิก</CancleButton>
            <UploadButton disabled={DisableBtn} variant="contained" onClick={() => handleUpload()}>อัปโหลด</UploadButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const UploadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'white',
  backgroundColor: '#0739A2',
  '&:hover': {
    borderColor: '#0739A2',
  },
}));

const CancleButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#4C4C4C',
  '&:hover': {
    color: '#4C4C4C',
    // borderColor: 'red',
    // backgroundColor:'red'
  },
}));

const ChooseFileButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderColor: '#EBEBEE',
  color: '#000000',
  '&:hover': {
    color: 'white',
    borderColor: '#0739A2',
    backgroundColor: '#0739A2'
  },
}));
const BoxColor = styledComponent.div`
  width: 225px;
  height: 30px;
  background-color: #fff;
  border-radius: 4px;
`
