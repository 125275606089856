import { addDays, addHours, addMinutes, addMonths } from 'date-fns'
import { addYears } from 'date-fns/esm';

export type IDatePickerRelativeProfileCallback = (current: Date) => Date[];
export interface IDatePickerRelativeProfile {
    label: string,
    callback: IDatePickerRelativeProfileCallback,
}

const getDateWithMidnight = (date: Date) : Date => { date.setHours(0, 0, 0, 0); return date; }

export const RelProfileDefault: IDatePickerRelativeProfile[] = [
    { label: 'Last 5 minutes',      callback: (c) => [ addMinutes(c, -5),   c ] },
    { label: 'Last 15 minutes',     callback: (c) => [ addMinutes(c, -15),  c ] },
    { label: 'Last 30 minutes',     callback: (c) => [ addMinutes(c, -30),  c ] },
    { label: 'Last 1 hours',        callback: (c) => [ addHours(c, -1),     c ] },
    { label: 'Last 3 hours',        callback: (c) => [ addHours(c, -3),     c ] },
    { label: 'Last 6 hours',        callback: (c) => [ addHours(c, -6),     c ] },
    { label: 'Last 12 hours',       callback: (c) => [ addHours(c, -12),    c ] },
    { label: 'Last 24 hours',       callback: (c) => [ addHours(c, -24),    c ] },
    { label: 'Last 2 days',         callback: (c) => [ addDays(c, -2),      c ] },
    { label: 'Last 7 days',         callback: (c) => [ addDays(c, -7),      c ] },
    { label: 'Today',               callback: (c) => [ getDateWithMidnight(c),  addDays(getDateWithMidnight(c), 1) ] },
    { label: 'Yesterday',           callback: (c) => [ addDays(getDateWithMidnight(c), -1), getDateWithMidnight(c) ] },
];

export const RelProfileLandslide: IDatePickerRelativeProfile[] = [
    { label: 'Last 12 hours',       callback: (c) => [ addHours(c, -12), c ] },
    { label: 'Last 24 hours',       callback: (c) => [ addHours(c, -24), c ] },
    { label: 'Last 3 days',         callback: (c) => [ addDays(c, -3),   c ] },
    { label: 'Last week',           callback: (c) => [ addDays(c, -7),   c ] },
    { label: 'Last 2 weeks',        callback: (c) => [ addDays(c, -14),  c ] },
    { label: 'Last month',          callback: (c) => [ addMonths(c, -1), c ] },
    { label: 'Last 2 months',       callback: (c) => [ addMonths(c, -2), c ] },
    { label: 'Last 3 months',       callback: (c) => [ addMonths(c, -3), c ] },
    { label: 'Last 6 months',       callback: (c) => [ addMonths(c, -6), c ] },
    { label: 'Last year',           callback: (c) => [ addYears(c, -1),  c ] },
];

export const RelProfileDumptruck: IDatePickerRelativeProfile[] = [
    { label: '00:00 - 08:00',       callback: (c) => [ getDateWithMidnight(c), addHours(getDateWithMidnight(c), 8) ] },
    { label: '08:00 - 16:00',       callback: (c) => [ addHours(getDateWithMidnight(c), 8), addHours(getDateWithMidnight(c), 16) ] },
    { label: '16:00 - 00:00',       callback: (c) => [ addHours(getDateWithMidnight(c), 16), addDays(getDateWithMidnight(c), 1) ] },
    { label: 'Last 12 hours',       callback: (c) => [ addHours(c, -12), c ] },
    { label: 'Last 24 hours',       callback: (c) => [ addHours(c, -24), c ] },
    { label: 'Last 3 days',         callback: (c) => [ addDays(c, -3),   c ] },
    { label: 'Last week',           callback: (c) => [ addDays(c, -7),   c ] },
    { label: 'Last 2 weeks',        callback: (c) => [ addDays(c, -14),  c ] },
    { label: 'Last month',          callback: (c) => [ addMonths(c, -1), c ] },
];

export const RelProfilePiezometer: IDatePickerRelativeProfile[] = [
    { label: 'Last 12 hours',       callback: (c) => [ addHours(c, -12), c ] },
    { label: 'Last 24 hours',       callback: (c) => [ addHours(c, -24), c ] },
    { label: 'Last 3 days',         callback: (c) => [ addDays(c, -3),   c ] },
    { label: 'Last week',           callback: (c) => [ addDays(c, -7),   c ] },
    { label: 'Last 2 weeks',        callback: (c) => [ addDays(c, -14),  c ] },
    { label: 'Last month',          callback: (c) => [ addMonths(c, -1), c ] },
    { label: 'Last 2 months',       callback: (c) => [ addMonths(c, -2), c ] },
    { label: 'Last 3 months',       callback: (c) => [ addMonths(c, -3), c ] },
    { label: 'Last 6 months',       callback: (c) => [ addMonths(c, -6), c ] },
    { label: 'Last year',           callback: (c) => [ addYears(c, -1),  c ] },
];