import React, { useEffect, useState } from "react";
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField';
import * as GpsTrackingAPI from "api/useGpsTrackingAPI";
import { usePageContext } from "./GpsTrackingPage";
import parseDateTime from "helpers/parseDateTime";

let TimeInterval: any

export function UpdateCurrentDataButton() {
    const [lastUpdateTime, setLastUpdateTime] = useState(parseDateTime(new Date()));
    const { stateContext, dispatchContext } = usePageContext();

    function onClickUpdate() {
        setLastUpdateTime(parseDateTime(new Date()));
        (async () => {
            const res = await GpsTrackingAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            const items = res.data.gps_tracking.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
            dispatchContext!({ curSensorList: items });
        })();
    }

    const ONE_MINUTES = 1000 * 60;
    function setTimeInterval() {
        TimeInterval = setInterval(() => {
            onClickUpdate();
        }, ONE_MINUTES);
    }

    useEffect(() => {
        setTimeInterval();
        return () => {
            clearInterval(TimeInterval)
        }
    }, [])

    return <LastUpdateTextField datetime={lastUpdateTime} onClick={onClickUpdate} />;
}
