import React from 'react'
import styled, { css } from 'styled-components'
import type { RouteComponentProps } from 'react-router-dom'

import color from 'constants/Colors'

import {
  TextField,
  InputAdornment,
  Grid,
} from "@mui/material"

import Button, { ButtonProps } from '@mui/material/Button';

import SearchIcon from 'assets/icons/icon-search.svg';

import LineSettingTable from 'components/Table/LineSettingTable';

/**
 * Localstorage
 */
 import {
  linitifyStorage,
  linenotifyStorageData,
  SETTINGPAGE
} from 'configure/localStorageConfig'

/**
 * API
 */
import {
  ListNotication,
  useListNotication,
  useGetNotication,
  ListNoti,
  Notifications,
} from "api/useRequest"
 
import LineSettingDialog from 'components/Dialog/LineSettingDialog'

interface LineProps {

}

function LineSetting(props: LineProps) {

  const ListnotiApi = useListNotication()
  const GetnotiApi = useGetNotication()

  const [searched, setSearched] = React.useState<string>("");
  const [openUploadDilog, setOpenUploadDilog] = React.useState(false);
  const [NotificationsOption, setNotificationsOption] = React.useState<ListNotication[]>([]);
  const [ListNoti, setListNoti] = React.useState<ListNoti[]>([]);

  const [DefaultOption, setDefaultOption] = React.useState<boolean[]>([])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearched(event.target.value)
  }

  const reflashTable = () => {
    GetnotiApi.mutate(null, {
      onSuccess: (data) => {
        if (data?.status === 204){
          setListNoti([])
        } else if (data?.status === 200){
          setListNoti(data?.data)
        }
      }
    })
  }

  React.useEffect(() => {
    (() => {
      var local:linenotifyStorageData = linitifyStorage.get()
      if (local.from === SETTINGPAGE.NOTI){
        setOpenUploadDilog(true)
      }

      if (local === null){
        var Ldata: linenotifyStorageData = {
          from: SETTINGPAGE.EMAIL,
          setting: [],
          notiToken: '',
          note: "",
          enable: false,
        }
        linitifyStorage.set(Ldata)
      }

      ListnotiApi.mutate(null, {
        onSuccess: (data) => {
          setNotificationsOption(data?.data)
          setDefaultOption(data?.data.map((e: any, i: number) => local.setting[i]?.ID !== undefined))
        }
      })
      GetnotiApi.mutate(null, {
        onSuccess: (data) => {
          if (data?.status === 204){
            setListNoti([])
          } else if (data?.status === 200){
            setListNoti(data?.data)
          }
        }
      })

    })()
  }, [])

  const clear = () => {
    var Ldata: linenotifyStorageData = {
      from: SETTINGPAGE.EMAIL,
      setting: [],
      notiToken: '',
      note: "",
      enable: false,
    }
    linitifyStorage.set(Ldata)
  }

  return (
    <>
    <Grid container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} direction="row" justifyContent="flex-end" alignItems="center">
      <Grid item xs={50} sm={50} md={30} lg={15} xl={15} >
        <SettingLine disabled={false} variant="contained" onClick={() => { setOpenUploadDilog(true) }}>เพิ่มการแจ้งเตือน</SettingLine>
      </Grid>

      <Grid item xs={100} sm={100} md={100} lg={100} xl={100} >
      <LineSettingTable rows={

        ListNoti.map((e:ListNoti, i:Number) => {
          return {
            line_notification_id: e.line_notification_id,
            note: e.note,
            dashboard: e.notifications.map((ee: Notifications) => ee.name).join(","),
            created_at: e.created_at
          }
        })
      } onReflash={() => { reflashTable() }}/>
      </Grid>
    </Grid>

      {/* defind dialog */}
      <LineSettingDialog isOpen={openUploadDilog} NotificationsOption={NotificationsOption} setOpen={setOpenUploadDilog} reFlashData={() => { reflashTable() }} DefaultOption={DefaultOption}/>
    </>
  )
}

export default LineSetting

{/* <SearchTextField
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <img src={SearchIcon} alt="SearchIcon" width="20px" height="20px" />
      </InputAdornment>
    ),
  }}
  placeholder="ค้นหา"
  sx={SearchTextSX}
  onChange={handleSearchChange}
/> */}
// const SearchTextField = styled(TextField)({
//   '& .MuiOutlinedInput-root': {
//     '& fieldset': {
//       borderColor: '#919EAB',
//     },
//     '&:hover fieldset': {
//       borderColor: '#0739A2',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#0739A2',
//     },
//   },
//   marginBottom: '25px',
// });

const SearchTextSX = {
  width: "30%",
  height: "5%",
  backgroundColor: color.white,
}

const SettingLine = styled(Button)<ButtonProps>(({ theme }) => ({
  width: "100%",
  color: 'white',
  backgroundColor: '#0739A2',
  '&:hover': {
    borderColor: '#0739A2',
  },
}));
