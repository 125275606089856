import React, { useState, useEffect } from "react";
import SettingDeviceButton from "components/Button/SettingDeviceBtn";
import DeviceSettingDialog, { DeviceType, IGpsTrackingSetting } from "components/Dialog/DeviceSettingDialog";
import { HEADER_TEXT, MAIN_PATH, usePageContext } from "./GpsTrackingPageDevice";
import { Grid } from "@mui/material";
import { ISetGpsConfig, reqUploadConfig } from "api/useConfigSettingAPI";
import { useParams } from "react-router-dom";
import * as GpsTrackingAPI from "api/useGpsTrackingAPI";
import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import { DEVICES } from "api/useRequest";

export function DeviceSettingButton(props: { disabled?: boolean }) {
    /* Use to set header text */
    const dispatch = useLayoutDispatch();
    const { stateContext, dispatchContext } = usePageContext();
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    let { deviceID } = useParams<{ deviceID: string; }>();
    const [settingData, setSettingData] = useState<IGpsTrackingSetting>({
        device_name: "",
        height: 0,
    });

    async function uploadConfig(values: Record<string, any>) {
        let body: ISetGpsConfig = {
            device_id: deviceID,
            device_name: values.device_name,
            height: Number(values.height),
        }

        const res = await reqUploadConfig(DEVICES.GPS, body);
        if (res.status === 200) {
            setSettingData({
                device_name: values.device_name,
                height: Number(values.height),
            });
        }
        (async () => {
            const res = await GpsTrackingAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            const items = res.data.gps_tracking.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
            const curSensor = await items.find((e: any) => e.device_id === deviceID);

            /* Set Header */
            changeHeaderText(dispatch, {
                texts: [HEADER_TEXT, `${values.device_name}`],
                link: `${window.location.protocol}//${window.location.host}${MAIN_PATH}`
            });

            dispatchContext!({ curSensorList: items, curSensorItem: curSensor });

        })();

    }

    function onDialogSave(values: Record<string, any>) {
        uploadConfig(values);
        setDialogOpen(false);
        //TODO: upload saved data
    };

    function onDialogCancel() {
        setDialogOpen(false);
    };

    function onTapSettingButton() {
        setDialogOpen(true);
    }

    useEffect(() => {
        if (isDialogOpen === true) {
            return
        }
        /* effect */
        setSettingData({
            device_name: stateContext.curSensorItem.device_name,
            height: stateContext.curConfigItem.height ?? 0,
        });

    }, [stateContext.curConfigItem, stateContext.curSensorItem])

    return <Grid container item height="100%" width="100%">
        <SettingDeviceButton disabled={props.disabled} onTap={onTapSettingButton} />
        <DeviceSettingDialog
            isOpen={isDialogOpen}
            deviceType={DeviceType.GpsTracking}
            currentValue={settingData}
            valueDescription={{
                device_name: "Device Name",
                height: "ความสูงรถ (เมตร)",
            }}
            description=""
            onCancel={onDialogCancel}
            onSave={onDialogSave} />
    </Grid>;


}
