export interface HeadCell {
  id: string;
  label: string;
  numeric: boolean;
  isStatus?: boolean;
  isUpper?: boolean;
  isUseFormatTime?: boolean;
  colorPicker?: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: true,
    label: 'ชื่อไฟล์',
    isUseFormatTime: true
  },
  {
    id: 'file_type',
    numeric: true,
    label: 'ชนิดไฟล์',
    isUseFormatTime: true
  },
  {
    id: 'file_type',
    numeric: true,
    label: 'วันที่สร้าง',
    isUseFormatTime: true
  },
  {
    id: 'Color',
    numeric: true,
    label: 'Color',
    colorPicker: true
  },
  {
    id: 'delte_recode',
    numeric: false,
    label: 'ลบไฟล์',
    isUseFormatTime: true
  }
];

export interface Data {
  id: number;
  name: string;
  file_type: string;
  file_name: string;
  created_at: string;
}