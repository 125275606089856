import { Box, IconButton, Paper } from "@mui/material";
import { useParams } from 'react-router-dom'
import { usePageContext } from "./WaterLevelPageDevice";
import styled from 'styled-components'
import { DEVICES } from "api/useRequest";

import BaseMap from "components/Map/BaseMap";
import SensorMarker from "components/Map/Marker/SensorMarker";

const MAP_HEADER_TEXT = "GPS ตำแหน่งอุปกรณ์ ";
export function MapCard() {
    const { stateContext, dispatchContext } = usePageContext();
    return (
        <MapBox >
            <BaseMap>
                <SensorMarker
                    type={DEVICES.WATERLEVEL}
                    state={stateContext.curSensorItem.state}
                    position={[Number(stateContext.curSensorItem.latitude), Number(stateContext.curSensorItem.longitude)]}
                    label={stateContext.curSensorItem.device_name}
                    data={stateContext.curSensorItem}
                />
            </BaseMap>
        </MapBox>
    );
}

const MapBox = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        height: "500px"
    },
    [theme.breakpoints.down('md')]: {
        height: "300px"
    },
}));
