import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import BaseRawTable from "components/BaseTable/BaseRawTable";
import { Data } from "components/WaterLevelDevicesDetailTable";
import { CardTemplate } from "components/Card/CardTemplate";
import { usePageContext } from "./WaterLevelPageDevice";
import { DEVICES } from "api/useRequest";

export const DeviceTable = () => {
    const { stateContext } = usePageContext()
    let { deviceID } = useParams<{ deviceID: string; }>();

    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0">
        <CardTemplate>
            <BaseRawTable tableName={"รายละเอียดอุปกรณ์ตรวจวัดระดับน้ำ"} device={DEVICES.WATERLEVEL} DateTime={stateContext.dateRange} order={"asc"} orderBy={"device_id"} tableCell={headCells} device_id={deviceID} />
        </CardTemplate>
    </Grid>;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    isStatus?: boolean;
    isUpper?: boolean;
    isUseFormatTime?: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'device_id',
        numeric: false,
        isUpper: true,
        disablePadding: false,
        label: 'ID',
    },
    {
        id: 'device_name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'timestamp',
        numeric: false,
        disablePadding: false,
        isUseFormatTime: true,
        label: 'Last Updated',
    },
    {
        id: 'level',
        numeric: true,
        disablePadding: false,
        label: 'ค่าระดับน้ำ (mm)',
    },
    {
        id: 'power_pri',
        numeric: true,
        disablePadding: false,
        label: 'Power In',
    },
    {
        id: 'power_sec',
        numeric: true,
        disablePadding: false,
        label: 'Solar Cell',
    },

];
