import { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
    Formik,
    FormikHelpers,
    FormikProps,
    Form,
    Field,
    FieldProps,
    useFormik,
} from 'formik';
import ThresholdSettingView, { FieldItem } from "./ThresholdSettingView";

export interface ThresholdSettingProps {
    fields: FieldItem[];
    dialogTitle?: string;
    buttonLabel?: string;
    onSubmit?: (values: Record<string, string | number>) => void;
    disabled?: boolean;
}

const ThresholdSetting: FunctionComponent<ThresholdSettingProps> = (props) => {
    const {
        fields,
        buttonLabel = "ตั้งค่า Threshold",
        dialogTitle = "ตั้งค่า Threshold การแจ้งเตือน:",
        onSubmit = (v) => { },
    } = props;
    const [isDialogOpen, setIsDialogOpen] = useState(false);


    const initialValues: Record<string, any> = fields.reduce((pre: any, cur: any) => {
        let item: Record<string, string | number> = { ...pre };
        item[cur.id] = cur.value;
        return item;
    }, {} as Record<string, string | number>);
    console.log('initialValues', initialValues);

    useEffect(() => {
        formik.setValues(initialValues);
    }, [fields])



    const handleSubmit = (values: Record<string, string | number>) => {
        console.log('handleSubmit', values);
        onSubmit(values);
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: handleSubmit,
    });

    const handleClickButton = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <ThresholdSettingView
            open={isDialogOpen}
            formik={formik}
            fields={fields}
            buttonLabel={buttonLabel}
            dialogTitle={dialogTitle}
            onClickButton={handleClickButton}
            onDialogClose={handleDialogClose}
            disabled={props.disabled}
        />
    );
}

export default ThresholdSetting;