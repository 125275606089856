export interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
  isStatus?: boolean;
  isUpper?: boolean;
  isUseFormatTime?: boolean;
  Zero?: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: 'device_id',
    numeric: false,
    isUpper: true,
    label: 'ID',
  },
  {
    id: 'device_name',
    numeric: false,
    label: 'Name',
  },
  {
    id: 'state',
    numeric: false,
    isStatus: true,
    label: 'Status',
  },
  {
    id: 'timestamp',
    numeric: false,
    label: 'Last Updated',
    isUseFormatTime: true
  },
  {
    id: 'mine_n',
    numeric: true,
    label: 'Mine N',
  },
  {
    id: 'mine_e',
    numeric: true,
    label: 'Mine E',
  },
  // {
  //   id: 'raw',
  //   numeric: true,
  //   label: 'Raw',
  // },
  {
    id: 'percent',
    numeric: true,
    Zero: true,
    label: 'Arsenic',
  },
  {
    id: 'power_pri',
    numeric: true,
    Zero: true,
    label: 'Power Battery',
  },
  {
    id: 'power_sec',
    numeric: true,
    Zero: true,
    label: 'Power Solar Cell',
  },
];

export const headCellsDevice: readonly HeadCell[] = headCells.filter(item => (item.id !== 'mine_n' && item.id !== 'mine_e' && item.id !== 'state'));

export interface Data {
  device_id: string;
  device_name: string;
  state: string;
  timestamp: string;
  latitude: number;
  longitude: number;
  mine_n: number;
  mine_e: number;
  percent: number;
  power_pri: number;
  power_sec: number;
  raw: number;
  status: number;
}
