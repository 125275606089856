import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import { usePageContext } from "./GpsTrackingPage";
import BaseCurrentTable from "components/BaseTable/BaseCurrentTable";
import { DEVICES } from "api/useRequest";
import { CardTemplate } from "components/Card/CardTemplate";
import parseDateTime from "helpers/parseDateTime";
import { coordinateDecimalToMineGrid } from 'helpers/geo';

export function DeviceTable() {
    const { stateContext, dispatchContext } = usePageContext();
    const [dataList, setDataList] = useState<IDataOnTable[]>([]);

    useEffect(() => {
        const datas: IDataOnTable[] = stateContext.curSensorList.map((e: any) => {
            const {
                device_id,
                device_name,
                power_pri,
                power_sec,
                state,
                latitude,
                longitude,
                real_altitude,
                speed,
                timestamp,
                gnss_rtk_solution_name,
            } = e;

            const mineGrid = coordinateDecimalToMineGrid(latitude, longitude);

            return {
                device_id,
                device_name,
                power_pri,
                power_sec,
                state,
                latitude: latitude.toFixed(9),
                longitude: longitude.toFixed(9),
                real_altitude,
                mine_n: mineGrid.mineN.toFixed(2),
                mine_e: mineGrid.mineE.toFixed(2),
                speed,
                timestamp,
                gnss_rtk_solution_name,
            };
        });
        setDataList(datas);
    }, [stateContext.curSensorList]);

    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0">
        <CardTemplate>
            <BaseCurrentTable tableName={"รายละเอียดอุปกรณ์"} device={DEVICES.GPSTRACKING} order={"asc"} orderBy={"device_id"} tableCell={headCells} DataCurrentDevice={dataList} />
        </CardTemplate>
    </Grid>;
}

interface IDataOnTable {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    state: string;
    latitude: string;
    longitude: string;
    real_altitude: string;
    mine_n: string;
    mine_e: string;
    speed: string;
    timestamp: string;
    gnss_rtk_solution_name: string;
}

interface HeadCell {
    disablePadding?: boolean;
    id: keyof IDataOnTable;
    label: string;
    numeric: boolean;
    isStatus?: boolean;
    isUpper?: boolean;
    isUseFormatTime?: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'device_id',
        numeric: false,
        isUpper: true,
        label: 'ID',
    },
    {
        id: 'device_name',
        numeric: false,
        label: 'Name',
    },
    {
        id: 'state',
        numeric: false,
        isStatus: true,
        label: 'Status',
    },
    {
        id: 'timestamp',
        numeric: false,
        label: 'Last Updated',
        isUseFormatTime: true
    },
    {
        id: 'latitude',
        numeric: true,
        disablePadding: false,
        label: 'Latitude',
    },
    {
        id: 'longitude',
        numeric: true,
        disablePadding: false,
        label: 'Longitude',
    },
    {
        id: 'real_altitude',
        numeric: true,
        disablePadding: false,
        label: 'Altitude',
    },
    {
        id: 'mine_n',
        numeric: true,
        disablePadding: false,
        label: 'Mine N',
    },
    {
        id: 'mine_e',
        numeric: true,
        disablePadding: false,
        label: 'Mine E',
    },
    {
        id: 'speed',
        numeric: true,
        disablePadding: false,
        label: 'Speed',
    },
    {
        id: 'gnss_rtk_solution_name',
        numeric: false,
        disablePadding: false,
        label: 'RTK',
    },
    {
        id: 'power_pri',
        numeric: true,
        disablePadding: false,
        label: 'Solar Cell',
    },
    {
        id: 'power_sec',
        numeric: true,
        disablePadding: false,
        label: 'Battery',
    },
];
