export interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
  isStatus?: boolean;
  isUpper?: boolean;
  isUseFormatTime?: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: 'device_id',
    numeric: false,
    isUpper: true,
    label: 'ID',
  },
  {
    id: 'device_name',
    numeric: false,
    label: 'Name',
  },
  {
    id: 'state',
    numeric: false,
    isStatus: true,
    label: 'Status',
  },
  {
    id: 'timestamp',
    numeric: false,
    label: 'Last Updated',
    isUseFormatTime: true
  },
  {
    id: 'mine_n',
    numeric: true,
    label: 'Mine N',
  },
  {
    id: 'mine_e',
    numeric: true,
    label: 'Mine E',
  },
  {
    id: 'digit',
    numeric: true,
    label: 'Digit',
  },
  {
    id: 'temperature',
    numeric: true,
    label: 'Temperature',
  },
  {
    id: 'frequency',
    numeric: true,
    label: 'Frequency',
  },
  {
    id: 'kpa',
    numeric: true,
    label: 'kPa',
  },
  {
    id: 'msl',
    numeric: true,
    label: 'MSL',
  },
  {
    id: 'quality',
    numeric: true,
    label: 'Quality',
  },
  {
    id: 'power_sec',
    numeric: true,
    label: 'Solar Cell',
  },
  {
    id: 'power_pri',
    numeric: true,
    label: 'Battery',
  },
];

export const headCellsDevice: readonly HeadCell[] = headCells.filter(item => (item.id !== 'mine_n' && item.id !== 'mine_e' && item.id !== 'state'));

export interface Data {
  device_id: string;
  device_name: string;
  power_pri: number;
  power_sec: number;
  digit: number;
  temperature: number;
  quality: number;
  kpa: number;
  msl: number;
  state: string;
  latitude: number;
  longitude: number;
  mine_n: number;
  mine_e: number;
  timestamp: string;
  frequency: number;
}
