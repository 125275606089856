import React from 'react';
import DetailsCard, { Data, IUtilityButton } from "../Card/DetailsCard";
import styled from 'styled-components';
import { Box } from '@mui/material';
import { SimpleLoading } from 'components/Loading/SimpleLoading';

interface DetailsData {
  name: string;
  value: number;
  value2?: number;
  unit: string;
  unit2?: string;
  status?: string;
}

export interface IDataStation {
  id?: string;
  name: string;
  state?: string;
  date?: string;
  data: Data[];
  location?: string;
  path?: string;
  onTapSeeDashboard?: () => void;
  utilButton?: JSX.Element;
  isSelected?: boolean;
  onTapSelected?: (deviceId: string, preState: boolean, curState: boolean) => void;
}

const DetailCardList: React.FC<any> = ({ dataList, path }: { dataList: IDataStation[], path: string }) => {
  return (
    <>
      <CustomScrollBox sx={{
        maxHeight: '445px',
        overflow: 'auto',
        paddingLeft: "12px",
        paddingRight: "12px",
        bgcolor: "white",
      }}>
        {dataList.map((e: any, index: number) => (
          <DetailsCard key={`${index}_${e.id}`} {...e} /* id={e.id} name={e.name} date={e.date} data={e.data} location={e.location} path={path} */ />
        ))}
      </CustomScrollBox>
    </>
  );
}

const CustomScrollBox = styled(Box)`
    > * {
        margin: 12px 0;
    }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export default DetailCardList;
