import React, { FunctionComponent } from "react";
import { Polyline } from "react-leaflet";
import { LatLngExpression } from "leaflet";

const HistoryLine: FunctionComponent<HistoryLineProps> = ({ latLngHistory }) => {
    return (
        <>
            {latLngHistory && Object.keys(latLngHistory).map(
                id => latLngHistory[id] &&
                    <Polyline
                        key={id}
                        positions={latLngHistory[id]}
                        pathOptions={{ color: 'red' }} />
            )}
        </>
    );
};
interface HistoryLineProps {
    latLngHistory?: Record<string, LatLngExpression[] | LatLngExpression[][]>;
}
export default HistoryLine;
