import React, { useState } from "react";
import { SxProps } from "@mui/system";
import { Grid, Paper } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import DateTimeRangePicker from "components/Datetime/DateTimeRangePicker";
import CustomizedTabs from 'components/TabBar/TabBar';
import {
    IDatePickerRelativeProfile,
    RelProfileDefault
} from 'components/Datetime/DatePickerRelativeProfile';

interface Props {
    tabName: string[];
    tabElement: any[];
    tabWithDatePicker: number[];

    dateTimeRange: Date[];
    onDateTimeChange: (value: Date[]) => void;
    relativeProfile?: IDatePickerRelativeProfile[];
    daySelector?: boolean;
    dateOnly?: boolean;
    noZoom?: boolean;
    maxDateTime?: Date;
    minDateTime?: Date;
}

export const DeviceDataTab = (props: Props) => {
    const [activeTab, setActiveTab] = useState(0);
    const hasDatePicker = props.tabWithDatePicker.includes(activeTab);

    const tabHeaderContainerStyle: SxProps = {
        display: 'flex',
        flexFlow: 'row wrap',
        position: 'sticky',
        width: '100%',
        top: { sm: '64px', xs: '48px' },
        zIndex: 100,
        marginBottom: '6px',
        padding: '0 7px',
    }

    const onTabChange = (event: React.SyntheticEvent, tabIdx: number) => {
        setActiveTab(tabIdx);
    };

    return (
        <TabContext value={`${activeTab}`}>
            <Paper sx={tabHeaderContainerStyle} square>
                <Grid item xs={100} sm={50}>
                    <CustomizedTabs tabList={props.tabName} onChange={onTabChange} />
                </Grid>
                {hasDatePicker &&
                    <Grid
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        container
                        item xs={100} sm={50}
                    >
                        <DateTimeRangePicker
                            initValue={props.dateTimeRange}
                            onChange={props.onDateTimeChange}
                            relativeProfile={props.relativeProfile === undefined ? RelProfileDefault : props.relativeProfile}
                            daySelector={props.daySelector}
                            dateOnly={props.dateOnly}
                            noZoom={props.noZoom}
                            maxDateTime={props.maxDateTime}
                            minDateTime={props.minDateTime}
                        />
                    </Grid>
                }
            </Paper>
            {props.tabElement.map((tabEl, idx) =>
                <TabPanel key={idx} value={`${idx}`} sx={{ padding: "0", width: "100%" }}>{tabEl}</TabPanel>
            )}
        </TabContext>
    );
}
