import React from "react";
import { useHistory } from 'react-router-dom';
import { SelectChangeEvent } from "@mui/material";
import { useParams } from 'react-router-dom';
import SelectDevice from "components/SelectBox/SelectDevice";
import { usePageContext, MAIN_PATH } from "./ConveyorPageDevice";
import { ISensorItem } from "api/useConveyorAPI";

export function SelectDeviceName() {
    let { deviceID } = useParams<{ deviceID: string; }>();
    let history = useHistory();
    const { stateContext } = usePageContext();

    function onChangeDeviceNumber(event: SelectChangeEvent) {
        console.log(event.target);
        history.push(`${MAIN_PATH}/device/${event.target.value}`);
    };
    return <SelectDevice
        width="fit-content"
        selected={deviceID}
        Option={stateContext.curSensorList.map((e: ISensorItem) => ({
            name: e.device_name,
            value: e.device_id,
        }))}
        onChange={onChangeDeviceNumber} />;
}
