import { decimalRound } from './utils'

export const coordinateDecimalToMineGrid = (latitude: number, longitude: number) => {
    return coordinateUTMToMineGrid( coordinateDecimalToUTM(latitude, longitude) );
}

export const coordinateUTMToMineGrid = (utm: { Easting: number, Northing: number, ZoneNumber: number }) => {
    return {
        mineN: -2080911.929 + (0.4158702732164016 * utm.Easting) + (0.909423947240951 * utm.Northing),
        mineE: 317058.273 + (0.909423947240951 * utm.Easting) - (0.415870273264016 * utm.Northing)
    }
}

export const coordinateDecimalToUTM = (latitude: number, longitude: number, precision: number = 9) => {
    const a = 6378137;
    const eccSquared = 0.00669438;

    let LongTemp = longitude;
    let LatRad = latitude * Math.PI / 180
    let LongRad = LongTemp * Math.PI / 180
    let ZoneNumber = 32;

    if (LongTemp >= 8 && LongTemp <= 13 && latitude > 54.5 && latitude < 58) {
        ZoneNumber = 32;
    } else if (latitude >= 56.0 && latitude < 64.0 && LongTemp >= 3.0 && LongTemp < 12.0) {
        ZoneNumber = 32;
    } else {
        ZoneNumber = ((LongTemp + 180) / 6) + 1;

        if (latitude >= 72.0 && latitude < 84.0) {
            if (LongTemp >= 0.0 && LongTemp < 9.0) {
                ZoneNumber = 31;
            } else if (LongTemp >= 9.0 && LongTemp < 21.0) {
                ZoneNumber = 33;
            } else if (LongTemp >= 21.0 && LongTemp < 33.0) {
                ZoneNumber = 35;
            } else if (LongTemp >= 33.0 && LongTemp < 42.0) {
                ZoneNumber = 37;
            }
        }
    }
    ZoneNumber = Math.floor(ZoneNumber);

    var LongOrigin = (ZoneNumber - 1) * 6 - 180 + 3;  //+3 puts origin in middle of zone
    var LongOriginRad = LongOrigin * Math.PI / 180

    var eccPrimeSquared = (eccSquared) / (1 - eccSquared);

    var N = a / Math.sqrt(1 - eccSquared * Math.sin(LatRad) * Math.sin(LatRad));
    var T = Math.tan(LatRad) * Math.tan(LatRad);
    var C = eccPrimeSquared * Math.cos(LatRad) * Math.cos(LatRad);
    var A = Math.cos(LatRad) * (LongRad - LongOriginRad);

    var M = a * ((1 - eccSquared / 4 - 3 * eccSquared * eccSquared / 64 - 5 * eccSquared * eccSquared * eccSquared / 256) * LatRad
        - (3 * eccSquared / 8 + 3 * eccSquared * eccSquared / 32 + 45 * eccSquared * eccSquared * eccSquared / 1024) * Math.sin(2 * LatRad)
        + (15 * eccSquared * eccSquared / 256 + 45 * eccSquared * eccSquared * eccSquared / 1024) * Math.sin(4 * LatRad)
        - (35 * eccSquared * eccSquared * eccSquared / 3072) * Math.sin(6 * LatRad));

    var UTMEasting = Number(0.9996 * N * (A + (1 - T + C) * A * A * A / 6
        + (5 - 18 * T + T * T + 72 * C - 58 * eccPrimeSquared) * A * A * A * A * A / 120)
    + 500000.0);

    var UTMNorthing = Number(0.9996 * (M + N * Math.tan(LatRad) * (A * A / 2 + (5 - T + 9 * C + 4 * C * C) * A * A * A * A / 24
        + (61 - 58 * T + T * T + 600 * C - 330 * eccPrimeSquared) * A * A * A * A * A * A / 720)));

    if (latitude < 0)
        UTMNorthing += 10000000.0;

    UTMNorthing = decimalRound(UTMNorthing,precision);
    UTMEasting = decimalRound(UTMEasting,precision);
    return { Easting: UTMEasting, Northing: UTMNorthing, ZoneNumber: ZoneNumber };
}
