import React, { useEffect, useState } from "react";
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField';
import * as ConveyorAPI from "api/useConveyorAPI";
import { HEADER_TEXT, MAIN_PATH, usePageContext } from "./ConveyorPageDevice";
import { useParams } from "react-router-dom";
import parseDateTime from "helpers/parseDateTime";
import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";

let TimeInterval: any
export function UpdateCurrentDataButton({ callback }: { callback: () => {} }) {
    const [lastUpdateTime, setLastUpdateTime] = useState(parseDateTime(new Date()));
    const { stateContext, dispatchContext } = usePageContext();
    /* Get params */
    let { deviceID } = useParams<{ deviceID: string; }>();
    /* Use to set header text */
    const dispatch = useLayoutDispatch();

    function onClickUpdate() {
        setLastUpdateTime(parseDateTime(new Date()));
        callback();
    }

    const ONE_MINUTES = 1000 * 60;
    function setTimeInterval() {
        TimeInterval = setInterval(() => {
            onClickUpdate();
        }, ONE_MINUTES);
    }

    useEffect(() => {
        clearInterval(TimeInterval)
        setTimeInterval();
        return () => {
            clearInterval(TimeInterval)
        }
    }, [deviceID])


    return <LastUpdateTextField fullWidth datetime={lastUpdateTime} onClick={onClickUpdate} />;
}
