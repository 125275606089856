import React, { useState, useEffect } from "react";
import { Button, Icon } from "@mui/material";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import fontFamily from '../../constants/Fonts'
import SettingsIcon from '@mui/icons-material/Settings';

const LABEL_TEXT = "ตั้งค่าอุปกรณ์";

interface ICallback {
  onTap: () => void,
  disabled?: boolean,
};

const SettingDeviceButton: React.FC<ICallback> = ({ onTap, disabled }) => {
  return (
    <>
      <SettingDeviceBtn variant="contained" startIcon={<SettingsIcon sx={{ color: "#4C4C4C" }} />} size="small" onClick={onTap} disabled={disabled}  >
        {LABEL_TEXT}
      </SettingDeviceBtn>
    </>
  );
};
export default SettingDeviceButton

const SettingDeviceBtn = styled(Button)(({ theme }) => ({
  height: "100%",
  width: "100%",
  minWidth: "138px",
  borderRadius: "8px",
  fontFamily: fontFamily,
  fontSize: "auto",
  // lineHeight: 1,

  color: theme.palette.getContrastText('#FFFFFF'),
  backgroundColor: '#FFFFFF',
  "&:hover": {
    backgroundColor: "#FFFFFF",
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: '#FFFFFF'
    }
  },

}));
