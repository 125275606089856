import TableCell from '@mui/material/TableCell';
import styled from "styled-components";
import Button from "@mui/material/Button";

/**
 * Styles
 */

export const HeaderCell = styled(TableCell)({
    border: "1px solid #c4c4c4",
    backgroundColor: "#001E32",
    color: "#fff",
    fontSize: 12,
    fontWeight: 700,
    padding: "8px"
});
export const ToolButton = styled(Button)({
    border: "1px solid #c4c4c4",
    backgroundColor: "#001E32",
    color: "#fff",
    fontSize: 12,
    fontWeight: 700,
    padding: "8px",
    '&:hover': {
        color: '#0739A2',
    },
});
