import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import type { RouteComponentProps } from 'react-router-dom'
import { useParams, useHistory } from 'react-router-dom'

import { useLayoutDispatch, changeHeaderText, HeaderTextLink } from "context/LayoutContext";
import ArsenicSensorCard from 'components/Card/SensorCard'
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField'

import BaseRawTable from 'components/BaseTable/BaseRawTable'
import {
  headCellsDevice
} from './ArsenicInterfaces'

import SelectDevice from 'components/SelectBox/SelectDevice'

//API
import {
  useGetDeviceAPI,
  ArsenicItem,
  DEVICES,
  Layer,
} from 'api/useRequest'

import useUserContext from 'context/UserContext';

import Icon_Chemistry from "assets/icons/icon-chemistry.png"

import parseDateTime from 'helpers/parseDateTime'

import { SelectChangeEvent } from "@mui/material";

import {
  Box,
  Grid,
  Paper
} from "@mui/material"

import * as dateFns from 'date-fns';

import { CardTemplate } from 'components/Card/CardTemplate';
import { defaultArsenicConfigItem, IArsenicConfigItem, ISetArsenicConfig, reqGetConfig, reqUploadConfig } from 'api/useConfigSettingAPI';
import DeviceSettingDialog, { DeviceType, IArsenicSetting } from 'components/Dialog/DeviceSettingDialog';
import SettingDeviceButton from 'components/Button/SettingDeviceBtn';
import { SimpleLoading } from 'components/Loading/SimpleLoading';

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { GraphListCard } from "../../../components/Card/GraphListCard";
import { ARSENIC_GRAPH_BASE_URL, ARSENIC_GRAPH_PANEL_ID_LIST } from "../../../constants/Graph";
import BaseMap from 'components/Map/BaseMap';
import SensorMarker from 'components/Map/Marker/SensorMarker';
import { FieldItem } from 'components/ThresholdSetting/ThresholdSettingView';
import { editArsenicDeviceNotification, editDeviceNotification, getArsenicDeviceNotification, getDeviceNotification, NotifyGroupItem, setDeviceNotification, SetNotiBody } from 'api/useDeviceNotifyAPI';
import ThresholdSetting from 'components/ThresholdSetting/ThresholdSetting';
import SnackbarUtils from 'helpers/utils';
import { IDevAddrResItem, useDeviceAddressSelector } from 'components/DeviceAddressSelector/useDeviceAddressSelector';
import DeviceAddressSelector from 'components/DeviceAddressSelector/DeviceAddressSelector';
import { width } from '@mui/system';
import DeviceAddressSetting from 'components/DeviceAddressSetting/DeviceAddressSetting';
import DefaultDateTimeRange from '../../../constants/DefaultDateTimeRage';
import StopMeasuring from 'components/StopMeasuring/StopMeasuring';

let TimeInterval: any

type deviceIDParams = {
  deviceID: string;
};
interface Options {
  name: string;
  value: string;
}

const HEADER_TEXT = "ระบบตรวจวัดสารหนู"

function createHeaderTextLink(name: string): HeaderTextLink {
  return {
    texts: [HEADER_TEXT, `${name}`],
    link: `${window.location.protocol}//${window.location.host}/arsenic`
  };
}

const ArsenicPageDevice: React.FC<RouteComponentProps> = () => {
  //fetch data
  let { deviceID } = useParams<deviceIDParams>();
  /* Set threshold to graph */
  /* [LOW,HIGH] */
  const [limit, setLimit] = React.useState([-1, -1]);
  let history = useHistory();
  const dispatch = useLayoutDispatch()
  const isLogedin = useUserContext().auth?.access_token != undefined ? true : false;;
  const isAdmin = useUserContext().permission?.permissions.includes('admin');
  const [thresholdSettingFields, setThresholdSettingFields] = useState<FieldItem[]>([]);
  const notifyGroup = useRef<any>();

  // fetch data arsenic
  const ArsenicAPI = useGetDeviceAPI();

  const FatchApi = () => {
    ArsenicAPI.mutate(DEVICES.ARSENIC, {
      onSuccess: (data: any) => {
        setArsenicListDeviceItem(data?.data?.arsenic?.data?.items)
        data?.data?.arsenic?.data?.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1)).forEach((e: ArsenicItem, i: number) => {
          if (e.device_id === DeviceID) {
            const headerTextLink: HeaderTextLink = createHeaderTextLink(e.device_name);
            changeHeaderText(dispatch, headerTextLink)
            setArsenicListDevice([e])
            /* setting config: get config */
            getConfig(e.device_id)
          }
          setOption([{
            name: e.device_name,
            value: e.device_id,
          }])
        })

      },
    })
  }

  const FatchApiSelectDevice = (device_: string) => {
    var Device_id_ = device_
    setDeviceID(Device_id_)
    history.replace(`/arsenic/device/${Device_id_}`)
    ArsenicListDeviceItem.forEach((e: ArsenicItem, i: number) => {
      if (Device_id_ === e.device_id) {
        const headerTextLink: HeaderTextLink = createHeaderTextLink(e.device_name);
        setArsenicListDevice([e])
        changeHeaderText(dispatch, headerTextLink)
      }
    })
    /* setting config: get config */
    getConfig(device_);
  }
  // const [ElementMap, setElementMap] = React.useState<any>(null);
  const [ArsenicListDevice, setArsenicListDevice] = React.useState<ArsenicItem[]>([])
  const [ArsenicListDeviceItem, setArsenicListDeviceItem] = React.useState<ArsenicItem[]>([])
  const [Option, setOption] = React.useState<Options[]>([])
  const [DeviceID, setDeviceID] = React.useState<string>(deviceID)
  const [LastFetchedTime, setLastFetchedTime] = React.useState<string>(parseDateTime(new Date()));
  const [PickerDateTIme, setPickerDateTIme] = React.useState<Date[]>(DefaultDateTimeRange[DEVICES.ARSENIC](new Date()));

  useEffect(() => {
    changeHeaderText(dispatch, HEADER_TEXT);
    clearInterval(TimeInterval);
    (() => {
      FatchApi()
      TimeInterval = setInterval(() => {
        setLastFetchedTime(parseDateTime(new Date()))
        FatchApi()
      }, 1000 * 60);
    })()
    getArsenicNotify();

    return () => {
      clearInterval(TimeInterval)
    }

  }, [DeviceID]);


  /* setting config: function get config */
  const [curConfigItem, setCurConfigItem] = React.useState<IArsenicConfigItem>(defaultArsenicConfigItem)
  async function getConfig(deviceID: string) {
    const res = await reqGetConfig(DEVICES.ARSENIC);
    if (res.status !== 200) {
      return
    }

    const configs: IArsenicConfigItem[] = res.data;
    const configItem: IArsenicConfigItem = configs.find(e => e.device_id == deviceID) ?? defaultArsenicConfigItem;
    setCurConfigItem(configItem)
  }

  const onDateTimeChange = (value: Date[]) => {
    setPickerDateTIme(value)
  }



  function getArsenicNotify() {
    getArsenicDeviceNotification()
      .then(
        (res: any) => {
          let notifications: any[] = res.data;
          let notiObject = notifications.find((e: any) => e.device_id.toUpperCase() === DeviceID.toUpperCase());
          let newThFileds = [
            {
              id: 'warning_threshold',
              lable: "แจ้งเตือนระดับเฝ้าระวังเมื่อค่า Arsenic มากกว่า",
              value: notiObject ? notiObject.warning_threshold : 0,
            },
            {
              id: 'critical_threshold',
              lable: "แจ้งเตือนระดับวิกฤตเมื่อค่า Arsenic มากกว่า",
              value: notiObject ? notiObject.threshold : 0,
            },
          ];
          setThresholdSettingFields(newThFileds);
          if (notiObject !== undefined) {
            setLimit([notiObject.warning_threshold, notiObject.threshold]);
          }
          notifyGroup.current = notiObject;

        }
      ).catch(
        (err: any) => {
          console.error(err);
        }
      );
  }

  const handleTresholdSubmit = (values: Record<string, string | number>) => {
    let body: any = {
      warning_threshold: Number(values.warning_threshold),
      critical_threshold: Number(values.critical_threshold),
    }

    if (notifyGroup.current === undefined) {
      setDeviceNotification(DEVICES.ARSENIC, body)
        .then(
          (res: any) => {
            setLimit([Number(values.warning_threshold), Number(values.critical_threshold)]);
            SnackbarUtils.success("Setting Devices Notify success !");
          }
        ).catch(
          (err: any) => {
            SnackbarUtils.error("Setting Devices Notify fail !");
          }
        );
    } else {
      editArsenicDeviceNotification(ArsenicListDevice[0].device_id, body)
        .then(
          (res: any) => {
            setLimit([Number(values.warning_threshold), Number(values.critical_threshold)]);
            SnackbarUtils.success("Setting Devices Notify success !");
          }
        ).catch(
          (err: any) => {
            SnackbarUtils.error("Setting Devices Notify fail !");
          }
        );
    }
  };

  const setLastDataByAddr = (addr_des: string) => {

    const devAddrResItem = devAddrRes.find((e: IDevAddrResItem) => e.addr_des === addr_des);
    if (!devAddrResItem) return

    let { config, last_value } = devAddrResItem;

    if (config) {
      setCurConfigItem({ ...curConfigItem, ...config });
    }

    if (last_value) {
      if (!last_value.latitude && !last_value.longitude) {
        last_value.latitude = devAddrResItem.latitude;
        last_value.longitude = devAddrResItem.longitude;
      }
      setArsenicListDevice([{ ...ArsenicListDevice[0], ...last_value }]);
    }
  }

  const cbAddrSelected = (addr_des: string, from: (Date | null), to: (Date | null)) => {
    setPickerDateTIme([from!, to ?? new Date()])
  }
  const { maxDate, minDate, devAddrRes, devAddr, isNotSelectedLastAddr, isMeasuringStop, handleDevAddrChange, fetchDeviceAddress } = useDeviceAddressSelector(DEVICES.ARSENIC, deviceID, PickerDateTIme, cbAddrSelected);
  useEffect(() => {
    setLastDataByAddr(devAddr);
  }, [devAddr])


  return (
    <DashboardContainer>

      <Grid direction="row" container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>

        {/* ====================== First row  ====================== */}
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="baseline"
          alignContent="center"
          wrap="nowrap"
          gap={1}
        >
          {/* column 1 */}
          <SelectDevice selected={DeviceID} Option={Option} onChange={(event: SelectChangeEvent) => {
            FatchApiSelectDevice(event.target?.value)
          }} />
          {/* column 2 */}
          <DeviceAddressSelector address={devAddrRes} value={devAddr} onChange={handleDevAddrChange} />
          {/* column 3 */}
          <Grid
            item
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <Grid item width={'fit-content'} >
              {!isNotSelectedLastAddr ?
                isMeasuringStop ?
                  <DeviceAddressSetting deviceId={DeviceID} fetchAddr={fetchDeviceAddress} /> :
                  <StopMeasuring deviceId={DeviceID} addrDes={devAddr} fetchAddr={fetchDeviceAddress} />
                : null
              }
            </Grid>
            {/* setting device */}
            <Grid item width={'fit-content'} >
              <DeviceSettingButton disabled={isNotSelectedLastAddr} fatchApi={FatchApi} curConfigItem={curConfigItem} curSensorItem={ArsenicListDevice[0]} />
            </Grid>
            <Grid item width={'fit-content'} >
              <ThresholdSetting
                dialogTitle={"ตั้งค่า Threshold การแจ้งเตือน: " + ArsenicListDevice[0]?.device_name}
                fields={thresholdSettingFields}
                onSubmit={handleTresholdSubmit}
                disabled={isNotSelectedLastAddr}
              />
            </Grid>

          </Grid>
        </Grid>


        {/* ====================== Second row  ====================== */}
        {/* Column 1 map */}
        <Grid item xs={100} sm={100} md={50} lg={65} xl={85}>
          <MapBox>
            {ArsenicListDevice.length > 0 ?
              <BaseMap>
                {
                  ArsenicListDevice.map((e: ArsenicItem, i: number) => (
                    <SensorMarker
                      key={`ArsenicMarker_${i}`}
                      type={DEVICES.ARSENIC}
                      state={e.state}
                      position={[Number(e.latitude), Number(e.longitude)]}
                      label={e.device_name}
                      data={e}
                    />
                  ))
                }
              </BaseMap>
              : <SimpleLoading />}
          </MapBox>
        </Grid>

        {/* Column 2 card */}
        {ArsenicListDevice.length === 0 ?
          <Grid item xs={100} sm={100} md={50} lg={35} xl={15} style={{ display: "flex", flexDirection: "column" }}>
            <SimpleLoading />
          </Grid> :
          <Grid item xs={100} sm={100} md={50} lg={35} xl={15} style={{ display: "flex", flexDirection: "column" }}>
            <Grid item >
              <LastUpdateTextField fullWidth datetime={LastFetchedTime} onClick={() => {
                FatchApi()
                setLastFetchedTime(parseDateTime(new Date()))
              }} />
            </Grid>
            <br />
            <ArsenicSensorCard
              id={ArsenicListDevice[0]?.device_id}
              name={"Arsenic"}
              date={parseDateTime(ArsenicListDevice[0]?.timestamp)}
              value={ArsenicListDevice[0]?.percent}
              unit={"ppb"}
              img={<img alt="icon-senosr" src={Icon_Chemistry} />}
            />
            <br />
            <ArsenicSensorCard
              id={1}
              name={"สถานะอุปกรณ์"}
              status={ArsenicListDevice[0]?.state}
              isonline={ArsenicListDevice[0]?.state}
            />
          </Grid>
        }

        {/* Column 3 notification */}
        {/* <Grid item xs={100} sm={100} md={100} lg={100} xl={20}>
          <ArsenicNoticeTable tableName={"การแจ้งเตือนความผิดปกติ"} headCells={headCells} rows={mockTableData}/>
        </Grid> */}

        {/* ====================== Third row  ====================== */}
        {/* column 1 */}
        <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
          <DeviceDataTab
            tabName={["กราฟ", "ข้อมูลดิบ"]}
            tabElement={[
              <GraphListCard
                urlPrefix={ARSENIC_GRAPH_BASE_URL + `?var-limit_high=${limit[1]}&var-limit_low=${limit[0]}`}
                panelIdList={ARSENIC_GRAPH_PANEL_ID_LIST}
                dateTimeRange={PickerDateTIme}
                deviceIdList={[deviceID]}
                yAxisConfigurable
              />,
              <CardTemplate>
                <BaseRawTable tableName={"รายละเอียดอุปกรณ์ตรวจวัดสารหนู (Arsenic)"} device={DEVICES.ARSENIC} DateTime={PickerDateTIme} order={"asc"} orderBy={"device_id"} tableCell={headCellsDevice} device_id={DeviceID} />
              </CardTemplate>
            ]}
            tabWithDatePicker={[0, 1]}
            dateTimeRange={PickerDateTIme}
            onDateTimeChange={onDateTimeChange}
            maxDateTime={maxDate ?? undefined}
            minDateTime={minDate ?? undefined}
          />
        </Grid>


      </Grid>

    </DashboardContainer>
  )
}

export default ArsenicPageDevice

const GraphElement = (props: { link: string[] }) => {
  return (
    <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0" container rowSpacing={2} columnSpacing={2}>
      {props.link !== undefined &&
        props.link.map((e: any, index: number) => {
          return (
            <Grid key={`${index}_Grid`} width="100%" item xs={100} sm={100} md={100} lg={100} xl={100}>
              <CardTemplate
                key={`${index}_CardTemplate`}
              >
                <div
                  key={`${index}_div`}>
                  <iframe
                    key={`${index}_iframe`}
                    src={e}
                    width="100%"
                    height="370"
                    frameBorder="0"
                  >
                  </iframe>
                </div>
              </CardTemplate>
            </Grid>
          )
        })
      }
    </Grid>
  )
}

const DashboardContainer = styled.div`
  margin-left: 10px;
`
const MapBox = styled(Paper)(({ theme }) => ({

  [theme.breakpoints.up('md')]: {
    height: "500px"
  },
  [theme.breakpoints.down('md')]: {
    height: "300px"
  },
}));

function DeviceSettingButton({ disabled, fatchApi, curConfigItem, curSensorItem }: { disabled?: boolean, fatchApi: () => void, curConfigItem: IArsenicConfigItem, curSensorItem: ArsenicItem }) {
  /* Use to set header text */
  let { deviceID } = useParams<{ deviceID: string; }>();
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [settingData, setSettingData] = React.useState<IArsenicSetting>({
    device_name: "",
    sampling_rate: 0,
    threshold: 0,
    consecutive_time: 0,
    latitude: 0,
    longitude: 0,
  });

  async function uploadConfig(values: Record<string, any>) {
    let body: ISetArsenicConfig = {
      device_id: deviceID,
      device_name: values.device_name,
      sampling_rate: parseFloat(values.sampling_rate),
      threshold: parseFloat(values.threshold),
      consecutive_time: parseFloat(values.consecutive_time),
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),

    }

    const res = await reqUploadConfig(DEVICES.ARSENIC, body);
    // if (res.status !== 200) {
    //   return;
    // }
    fatchApi()

  }

  function onDialogSave(values: Record<string, any>) {
    uploadConfig(values);
    setDialogOpen(false);
    //TODO: upload saved data
  };

  function onDialogCancel() {
    setDialogOpen(false);
  };

  function onTapSettingButton() {
    //TODO: implement function ontap of DownloadButton
    console.log("Test onTap DownloadButton");
    setDialogOpen(true);
  }

  useEffect(() => {
    if (isDialogOpen === true) {
      return
    }
    if (curConfigItem === undefined || curSensorItem === undefined) {
      return;
    }
    setSettingData({
      device_name: curSensorItem.device_name,
      sampling_rate: curConfigItem.sampling_rate,
      threshold: curConfigItem.threshold,
      consecutive_time: curConfigItem.consecutive_time,
      latitude: parseFloat(curConfigItem.latitude),
      longitude: parseFloat(curConfigItem.longitude),
    });
  }, [curConfigItem, curSensorItem])

  return <>
    <SettingDeviceButton disabled={disabled} onTap={onTapSettingButton} />
    <DeviceSettingDialog
      isOpen={isDialogOpen}
      deviceType={DeviceType.Arsenic}
      currentValue={settingData}
      valueDescription={{
        device_name: "Device Name",
        latitude: "Latitude",
        longitude: "Longitude",
        sampling_rate: "Sampling Rate",
        threshold: "Threshold",
        consecutive_time: "Consecutive Time",
      }}
      description="Configuration of Arsenic"
      onCancel={onDialogCancel}
      onSave={onDialogSave} />
  </>;


}
