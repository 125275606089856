import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import color from "../../constants/Colors";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './select-btn.css'

interface SelectBtnProps {
    deviceId: string | undefined;
    isSelected: boolean;
    onTapSelected?: (deviceId: string, preState: boolean, curState: boolean) => void;
}

export default function SelectBtn(props: SelectBtnProps) {

    const [isActive, setActive] = React.useState(props.isSelected);

    useEffect(() => {
        setActive(props.isSelected)
    }, [props.isSelected])

    const toggleButton = (deviceId: string | undefined) => (event: React.MouseEvent<unknown>) => {
        setActive(!isActive)
        if (deviceId === undefined || props.onTapSelected === undefined)
            return;

        props.onTapSelected(deviceId, isActive, !isActive)
        console.log(deviceId)
    };

    return (
        <div className={`select-btn-container ${isActive ? "active" : ""}`} onClick={toggleButton(props.deviceId)}>
            <span style={{ fontSize: '12px', marginLeft: '8px' }}>Select</span>
            <div className="select-btn-icon-container">
                <CheckCircleIcon sx={{ fontSize: 14 }} />
            </div>
        </div>
    )
}
