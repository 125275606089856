import { IconButton, Tooltip, Button, Grid, MenuItem } from "@mui/material";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { FunctionComponent, useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDialogController } from "pages/sensors/truck/QrMapping/useDialogController";
import { deviceGroupItem } from "api/useDeviceGroupingAPI";
import * as Yup from 'yup';
import { useFormik } from "formik";
import MultipleSelectChip from "./MultipleSelectChip";
import { CancleButton, SaveButton } from "components/Button/DialogButtonStyles";

interface AddEditGroupDialogProps {
    allItemList: string[];
    editGroupItem?: deviceGroupItem;
    selectedItemList?: string[];
    onSave?: (groupName: string, selectedList: string[]) => void;
    onClose?: () => void;
}

const AddEditGroupDialog: FunctionComponent<AddEditGroupDialogProps> = (props) => {
    const {
        allItemList,
        editGroupItem,
        selectedItemList,
        onSave,
        onClose,
    } = props;

    const isEditMode = (editGroupItem !== undefined);

    const initValues = {
        name: editGroupItem ? editGroupItem.group_name : "",
        selecteds: selectedItemList ? selectedItemList : [],
    }

    const varidationSchema = Yup.object().shape({
        name: Yup.string().required("กรุณาระบุชื่อกลุ่ม"),
        selecteds: Yup.array().of(Yup.string()).min(1),
    });

    const handleSubmit = (values: any) => {
        console.log('handleSubmit', values);
        if (onSave !== undefined) {
            onSave(values.name, values.selecteds);
        }
        handleClose();
    }

    const formik = useFormik({
        initialValues: initValues,
        validationSchema: varidationSchema,
        onSubmit: handleSubmit,
    });

    const { isOpen, handleDialogClose, handleDialogOpen, } = useDialogController();
    const handleClose = () => {
        handleDialogClose();
        if (onClose !== undefined) {
            onClose();
        }
    }

    useEffect(() => {
        formik.setValues(initValues);
    }, [selectedItemList, editGroupItem])


    return (
        <>
            {isEditMode ?
                <MenuItem onClick={handleDialogOpen} >{editGroupItem.group_name}</MenuItem> :
                <Tooltip title="สร้างกลุ่มจากอุปกรณ์ที่เลือก">
                    <IconButton
                        onClick={handleDialogOpen}
                        sx={{
                            color:"#0739A2"
                        }}
                    >
                        <AddLocationAltIcon />
                    </IconButton>
                </Tooltip>
            }
            <Dialog fullWidth maxWidth="sm"
                open={isOpen} onClose={handleClose} aria-labelledby={"dialog-create-group"}>
                <DialogTitle id={"dialog-create-group"}>
                    {"สร้างกลุ่มจากอุปกรณ์ที่เลือก"}
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        {
                            <Grid
                                container
                                direction="column"
                                gap={2}
                            >
                                <Grid item >
                                    <TextField
                                        id="name"
                                        label="ชื่อกลุ่ม"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.errors.name}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item
                                    container
                                    justifyContent="space-around"
                                    alignItems="center"
                                >
                                    <MultipleSelectChip
                                        label="Devices"
                                        name="selecteds"
                                        allSelect={allItemList}
                                        selected={formik.values.selecteds}
                                        onBlur={formik.handleBlur}
                                        onSelectedChange={formik.handleChange}
                                        error={formik.touched.selecteds && Boolean(formik.errors.selecteds)}
                                        helperText={formik.errors.selecteds as string}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                    <DialogActions>
                        <CancleButton
                            onClick={handleClose}
                            color="primary"
                        >
                            ยกเลิก
                        </CancleButton>
                        <SaveButton variant="contained" type="submit"  >
                            บันทึก
                        </SaveButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

export default AddEditGroupDialog;
