import React, { useState, useEffect } from "react";
import DetailCard, { IDetailsProps } from "components/Card/DetailsCard";
import { usePageContext } from "./LandSlidePageDevice";
import parseDateTime from 'helpers/parseDateTime'
import { SimpleLoading } from "components/Loading/SimpleLoading";
import { defaultSensorItem } from "api/useLandSlideAPI";

export function CurDetailCard() {
    const { stateContext, dispatchContext } = usePageContext();
    const [dataStation, setDataStation] = useState<IDetailsProps>();
    useEffect(() => {
        (async () => {
            const data: IDetailsProps = {
                id: `${stateContext.curSensorItem.device_id}`,
                name: stateContext.curSensorItem.device_name,
                date: stateContext.curSensorItem.timestamp,
                state: stateContext.curSensorItem.state,
                data: [
                    { name: "Mine N", value: Math.round(parseFloat(stateContext.curSensorItem.mine_n) * 100) / 100, unit: "" },
                    { name: "Mine E", value: Math.round(parseFloat(stateContext.curSensorItem.mine_e) * 100) / 100, unit: "" },
                    { name: "Diff 2D", value: stateContext.curSensorItem.diff2d, unit: "m" },
                    { name: "Diff 3D", value: stateContext.curSensorItem.diff3d, unit: "m" },
                    { name: "MSL", value: stateContext.curSensorItem.atitude, unit: "m" },
                    // { name: "", value: null, unit: "", status: "" },
                ],
                location: `${stateContext.curSensorItem.latitude}, ${stateContext.curSensorItem.longitude}`,
            };

            setDataStation(data);
        })();
    }, [stateContext.curSensorItem]);
    console.log('Build current sensor card');
    console.log(stateContext.curSensorItem);


    return stateContext.curSensorItem === defaultSensorItem ? <SimpleLoading /> : <DetailCard {...dataStation!} />;
}
