import React, { useEffect, useState } from "react";
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField';
import * as LandSlideAPI from "api/useLandSlideAPI";
import { usePageContext } from "./LandSlidePageDevice";
import { useParams } from "react-router-dom";
import parseDateTime from "helpers/parseDateTime";
import * as dateFns from "date-fns";


let TimeInterval: any
export function UpdateCurrentDataButton() {
    const [lastUpdateTime, setLastUpdateTime] = useState(parseDateTime(new Date()));
    const { stateContext, dispatchContext } = usePageContext();
    /* Get params */
    let { deviceID } = useParams<{ deviceID: string; }>();

    function onClickUpdate() {
        setLastUpdateTime(parseDateTime(new Date()));
        (async () => {
            const res = await LandSlideAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            const items = res.data.landslide.data.items.sort((a: any, b: any) => (a.diff2d < b.diff2d ? 1 : -1));
            items.forEach((element: LandSlideAPI.ISensorItem) => {
                const { timestamp } = element;
                let date: Date = new Date(new Date(timestamp).toLocaleString());
                let hrBetweenTwoDate = dateFns.differenceInHours(new Date(), new Date(timestamp));
                element.state = hrBetweenTwoDate > 1 ? "Offline" : "Online";
            });
            const curSensor = items.find((e: any) => e.device_id === deviceID);
            dispatchContext!({ curSensorList: items, curSensorItem: curSensor });
        })();
    }

    const ONE_MINUTES = 1000 * 60;
    function setTimeInterval() {
        TimeInterval = setInterval(() => {
            onClickUpdate();
        }, ONE_MINUTES);
    }

    useEffect(() => {
        clearInterval(TimeInterval)
        setTimeInterval();
        return () => {
            clearInterval(TimeInterval)
        }
    }, [deviceID])


    return <LastUpdateTextField fullWidth datetime={lastUpdateTime} onClick={onClickUpdate} />;
}
