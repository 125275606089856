export interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
  isStatus?: boolean;
  isUpper?: boolean;
  isUseFormatTime?: boolean;
}

export const headCells: readonly HeadCell[] = [

  {
    id: 'device_id',
    numeric: false,
    isUpper: true,
    label: 'ID',
  },
  {
    id: 'device_name',
    numeric: false,
    label: 'Name',
  },
  {
    id: 'state',
    numeric: false,
    isStatus: true,
    label: 'Status',
  },
  {
    id: 'timestamp',
    numeric: false,
    label: 'Last Updated',
    isUseFormatTime: true
  },
  {
    id: 'mine_n',
    numeric: true,
    label: 'Mine N',
  },
  {
    id: 'mine_e',
    numeric: true,
    label: 'Mine E',
  },
  {
    id: 'rain',
    numeric: true,
    label: 'rain',
  },
  {
    id: 'rain_24hr',
    numeric: true,
    label: 'rain_24hr',
  },
  {
    id: 'temperature',
    numeric: true,
    label: 'temperature',
  },
  {
    id: 'humidity',
    numeric: true,
    label: 'humidity',
  },
  {
    id: 'pm_25',
    numeric: true,
    label: 'pm_25',
  },
  {
    id: 'pm_10',
    numeric: true,
    label: 'pm_10',
  },
  {
    id: 'wind_speed',
    numeric: true,
    label: 'wind_speed',
  },
  {
    id: 'wind_direction',
    numeric: true,
    label: 'wind_direction',
  },
  {
    id: 'power_pri',
    numeric: true,
    label: 'Power Battery',
  },
  {
    id: 'power_sec',
    numeric: true,
    label: 'Power Solar Cell',
  },
];
// "rain": 0,
// "rain_24hr": 0,
// "temperature": 0,
// "humidity": 0,
// "pm_25": 0,
// "pm_10": 0,
// "wind_speed": 0,
// "wind_direction": 0,

export const headCellsDevice: readonly HeadCell[] = headCells.filter(item => (item.id !== 'mine_n' && item.id !== 'mine_e' && item.id !== 'state'));

export interface Data {
  device_id: string;
  device_name: string;
  state: string;
  timestamp: string;
  latitude: number;
  longitude: number;
  mine_n: number;
  mine_e: number;
  power_pri: number;
  power_sec: number;
  status: number;
  rain: number;
  rain_24hr: number;
  temperature: number;
  humidity: number;
  pm_25: number;
  pm_10: number;
  wind_speed: number;
  wind_direction: number;
}
