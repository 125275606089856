import { useMutation } from 'react-query'

import requestInstance, {
    RequestResponse,
    RequestError,
} from 'configure/requestConfig'

export interface AuthenticationRequestData {
    eno: number
    pwd: string
}

export interface AuthenticationResponseData {
    id: number
    eno: number
    status?: string
    message?: string
    access_token: string
}

const useAuthenticationAPI = () =>
    useMutation<
        RequestResponse<AuthenticationResponseData>,
        RequestError,
        AuthenticationRequestData
    >((data: AuthenticationRequestData) =>
        requestInstance.post('/api/user/login', data)
    )

export default useAuthenticationAPI

export interface LogoutResponseData {
}

export const useLogoutAPI = () =>
    useMutation<
        RequestResponse<LogoutResponseData>,
        RequestError
    >(() => requestInstance.delete('/api/user/logout'))