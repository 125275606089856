import { Grid, IconButton, Menu, Tooltip } from "@mui/material";
import { DEVICES } from "api/useRequest";
import { FunctionComponent } from "react";
import SelectGroupButton from "../SelectGroupButton";
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AddEditGroupDialog from "./AddEditGroupDialog";
import { deviceGroupItem } from "api/useDeviceGroupingAPI";
import GroupMenuForEdit, { Action } from "./GroupMenuForEdit";
import { string } from "yup";

interface GroupManagementProps {
    type: DEVICES;
    deviceGroupList: deviceGroupItem[];
    mapDeviceNameId: Record<string, string>;
    selectedItemList?: string[];
    onAddGroup?: (groupName: string, selectedList: string[]) => void;
    onEditGroup?: (groupId: number, groupName: string, selectedList: string[]) => void;
    onDeleteGroup?: (groupId: number) => void;
    onGroupSelected?: (groupName: string, index: number) => void;
    onResetSelected?: () => void;
}

const GroupManagement: FunctionComponent<GroupManagementProps> = (props) => {
    const {
        type,
        deviceGroupList,
        mapDeviceNameId,
        selectedItemList,
        onAddGroup,
        onEditGroup,
        onDeleteGroup,
        onGroupSelected,
        onResetSelected,
    } = props;
    const groupNameList = deviceGroupList.map((e: deviceGroupItem) => e.group_name);
    const deviceNameList = Object.values(mapDeviceNameId);
    const deviceIdList = Object.keys(mapDeviceNameId).map(e => e.toLowerCase());
    const selectedNameList = selectedItemList?.map((id: string) => deviceNameList[deviceIdList.indexOf(id.toLowerCase())]);
    console.log(selectedNameList);

    const handleAddGroupSaved = (groupName: string, selectedList: string[]) => {
        if (onAddGroup !== undefined) {
            console.log(selectedList);

            let selectedIds = selectedList.map((deviceName: string) => deviceIdList[deviceNameList.indexOf(deviceName)])
            onAddGroup(groupName, selectedIds)
        }
    }

    const handleEditGroupSaved = (groupId: number, groupName: string, selectedList: string[]) => {
        if (onEditGroup !== undefined) {
            console.log(selectedList);

            let selectedIds = selectedList.map((deviceName: string) => deviceIdList[deviceNameList.indexOf(deviceName)])
            onEditGroup(groupId, groupName, selectedIds)
        }
    }

    return (
        <>
            <SelectGroupButton
                groupNames={groupNameList}
                onSelected={onGroupSelected}
            />

            <Tooltip title="คืนค่า">
                <IconButton
                    disabled={selectedItemList &&  selectedItemList?.length <= 0}
                    onClick={onResetSelected}
                    sx={{
                        color:"#0739A2"
                    }}
                >
                    <WrongLocationIcon />
                </IconButton>
            </Tooltip>

            <AddEditGroupDialog
                allItemList={deviceNameList}
                selectedItemList={selectedNameList}
                onSave={handleAddGroupSaved}
            />
            <GroupMenuForEdit
                action={Action.Edit}
                deviceGroupList={deviceGroupList}
                mapDeviceNameId={mapDeviceNameId}
                onSave={handleEditGroupSaved}
            />
            <GroupMenuForEdit
                action={Action.Delete}
                deviceGroupList={deviceGroupList}
                mapDeviceNameId={mapDeviceNameId}
                onSave={(id: number, _: string, __: string[]) => onDeleteGroup ? onDeleteGroup(id) : {}}
            />

        </>
    );
}

export default GroupManagement;


