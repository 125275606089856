import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    IconButton,
    TableBody,
    TablePagination,
    styled,
    Button,
    Paper,
} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { Box } from "@mui/system"
import color from 'constants/Colors'
import { useState } from "react";

import parseDateTime from 'helpers/parseDateTime'

import {
    useDeleteLineNotify
} from 'api/useRequest'

interface Data {
    note: string,
    dashboard: string;
    created_at: string;
    line_notification_id: number;
}

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
}

const mockHeadCells: HeadCell[] = [
    {
        id: 'note',
        disablePadding: false,
        label: 'Note',
    },
    {
        id: 'dashboard',
        disablePadding: false,
        label: 'Dashboard',
    },
];


interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: HeadCell[];
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string },
    ) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <TableHead sx={{ backgroundColor: color.headTableBaackground, paddingLeft: "10px" }}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {headCells.length === 2 ?
                    <TableCell align="right"></TableCell>
                    :
                    null
                }

                {numSelected > 0 ?
                    <TableCell align="right">
                        <IconButton aria-label="delete" size="small" color="inherit">
                            <DeleteIcon />
                        </IconButton>
                    </TableCell>
                    :
                    <TableCell align="right"></TableCell>
                }
            </TableRow>
        </TableHead>
    );
}

interface BaseTableProps {
    rows: Data[];
    onReflash: () => void;
}

const LineSettingTable: React.FC<BaseTableProps> = (props) => {
    const deleteNoti = useDeleteLineNotify()

    const [selected, setSelected] = useState<readonly number[]>([]);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('note');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const AcceptDeclineHandler = (line_notification_id: number) => {
        if (window.confirm("คุณต้องการลบการแจ้งเตือนไหม") == true){
            console.log('line_notification_id: ', line_notification_id)

            deleteNoti.mutate(line_notification_id, {
                onSuccess: () => {
                    props.onReflash()
                }
            })

        }
    }

    return (
        <Box>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 20 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.rows.length}
                            headCells={mockHeadCells}
                        />
                        <TableBody>
                            {stableSort(props.rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover

                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="normal"
                                                width="15%"
                                            >
                                                {row.note}
                                            </TableCell>
                                            <TableCell align="left">{row.dashboard}</TableCell>
                                            <TableCell align="left">{parseDateTime(row.created_at)}</TableCell>
                                            <TableCell align="right">
                                                <DeclineButton key="Decline" color="error" variant="outlined" onClick={() => AcceptDeclineHandler(row.line_notification_id)}>Delete</DeclineButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={props.rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    )
}

export default LineSettingTable

const DeclineButton = styled(Button)`
    box-sizing: border-box;
    border-radius: 8px;
    width: 86px;
`