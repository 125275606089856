import React, { useState, useEffect } from "react";
import DetailCard, { IDetailsProps } from "components/Card/DetailsCard";
import { usePageContext } from "./WaterLevelPageDevice";
import parseDateTime from "helpers/parseDateTime";
import { defaultSensorItem } from "api/useWaterLevelAPI";
import { SimpleLoading } from "components/Loading/SimpleLoading";

export function CurDetailCard() {
    const { stateContext, dispatchContext } = usePageContext();
    const [dataStation, setDataStation] = useState<IDetailsProps>();
    useEffect(() => {
        (async () => {

            const data: IDetailsProps = {
                id: `${stateContext.curSensorItem.device_id}`,
                name: stateContext.curSensorItem.device_name,
                date: stateContext.curSensorItem.timestamp,
                state: stateContext.curSensorItem.state,
                data: [
                    { name: "ค่าระดับน้ำ", value: stateContext.curSensorItem.level, unit: "mm" },
                    // { name: "4 - 20 mA", value: stateContext.curSensorItem.raw, unit: "mA" },
                ],
                location: `${stateContext.curSensorItem.latitude}, ${stateContext.curSensorItem.longitude}`,
            };

            setDataStation(data);
        })();
    }, [stateContext.curSensorItem]);
    // console.log('Build current sensor card');
    // console.log(stateContext.curSensorItem);


    return stateContext.curSensorItem === defaultSensorItem ? <SimpleLoading /> : <DetailCard {...dataStation!} />;
}
