import requestInstance from "configure/requestConfig";
import parseDate from "helpers/parseDate";
import parseTime from "helpers/parseTime";
import { formatDate } from "../helpers/formatDate";

/* API response
{
    "device_id": "e6a7010701770001",
    "device_name": "LandslideT01",
    "power_pri": 16.1,
    "power_sec": 13.5,
    "status": 3,
    "top_x": 0,
    "top_y": 0,
    "top_z": 0,
    "bottom_x": 0,
    "bottom_y": 0,
    "bottom_z": 0,
    "gnss_status": 0,
    "latitude": "13.8475275",
    "longitude": "100.605511",
    "atitude": "-2011.4",
    "h_accuracy": 1,
    "v_accuracy": 1,
    "satelite_num": 26,
    "p_dop": 1.73,
    "state": "Online",
    "mine_n": "-408083.97601",
    "mine_e": "292685.60115",
    "diff2d": 0,
    "diff3d": 1.5,
    "gnss_fix_type": 3,
    "gnss_fix_type_name": "3D fix",
    "gnss_rtk_solution": 1,
    "gnss_rtk_solution_name": "Float RTK",
    "timestamp": "2021-12-07T02:54:04Z"
}
*/
export interface ISensorItem {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    status: number;
    top_x: number;
    top_y: number;
    top_z: number;
    bottom_x: number;
    bottom_y: number;
    bottom_z: number;
    gnss_status: number;
    latitude: string;
    longitude: string;
    atitude: string;
    h_accuracy: number;
    v_accuracy: number;
    satelite_num: number;
    p_dop: number;
    state: string;
    mine_n: string;
    mine_e: string;
    diff2d: number;
    diff3d: number;
    gnss_fix_type: number;
    gnss_fix_type_name: string;
    gnss_rtk_solution: number;
    gnss_rtk_solution_name: string;
    timestamp: string;
    [key: string]: any;
}

export const defaultSensorItem: ISensorItem = {
    device_id: "",
    device_name: "",
    power_pri: 0,
    power_sec: 0,
    status: 0,
    top_x: 0,
    top_y: 0,
    top_z: 0,
    bottom_x: 0,
    bottom_y: 0,
    bottom_z: 0,
    gnss_status: 0,
    latitude: "",
    longitude: "",
    atitude: "",
    h_accuracy: 0,
    v_accuracy: 0,
    satelite_num: 0,
    p_dop: 0,
    state: "",
    mine_n: "",
    mine_e: "",
    diff2d: 0,
    diff3d: 0,
    gnss_fix_type: 0,
    gnss_fix_type_name: "",
    gnss_rtk_solution: 0,
    gnss_rtk_solution_name: "",
    timestamp: "",
}

export const MapStateToDeviceCardState: Record<string, number> = {
    "Online": 1,
    "Offline": 0,
    "Error": 5,
}

export async function reqCurDeviceData() {
    const path = "/api/iot/all";
    const params = {
        limit: "",
        offset: "",
        device: "landslide"
    };

    const res = await requestInstance.get(path, { params: params, });
    return res;
}

export async function reqDeviceData(dateRange: Date[], device_id?: string) {

    const path = "/api/iot/landslide";
    const params = {
        limit: 10000,
        offset: 0,
        start: parseDate(dateRange[0]) + ' ' + parseTime(dateRange[0]),
        end: parseDate(dateRange[1]) + ' ' + parseTime(dateRange[1]),
        device_id
    };

    console.log(params);


    const res = await requestInstance.get(path, { params: params, });
    console.log(res);

    return res;
}

export async function reqFileLayer() {
    const path = "/api/files/types";
    const params = {
        limit: "",
        offset: ""
    };

    const res = await requestInstance.get(path, { params: params, });
    console.log(res);

    return res;
}

