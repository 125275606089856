import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import type { RouteComponentProps } from 'react-router-dom'
import logo from '../../assets/logo.svg';
import { useLayoutDispatch, changeHeaderText } from "../../context/LayoutContext";

import {
  Grid,
  Paper,
  Box,
  Button,
  Typography
} from "@mui/material"

import parseDateTime from 'helpers/parseDateTime'
import * as dateFns from 'date-fns'

import { SimpleLoading } from "components/Loading/SimpleLoading";

// component map
import MapDashboard from 'components/Map/MapDashboard.js';

import LastUpdateTextField from '../../components/Datetime/LastUpdateTextField'

import DashboardSensorCard from '../../components/Card/SensorCard'

// arsenic: {data: {…}}
// conveyor: {data: {…}}
// dump_truck: {data: {…}}
// gps_tracking: {data: {…}}
// ground_water: {data: {…}}
// landslide: {data: {…}}
// water_level: {data: {…}}
// weather: {data: {…}}

// image for card
// import arsenic from "assets/icons/icon-chemistry.png"
// import gps_tracking from "assets/icons/icon-gps.png"
// import landslide from "assets/icons/icon-land.svg"
// import water_level from "assets/icons/icon-water.svg"
// import ground_water from "assets/icons/icon-piezometer.png"
// import weather from "assets/icons/icon-air.png"
// import dump_truck from "assets/icons/icon-truck.png"
// import conveyor from "assets/icons/icon-belt.png"
// import gateway from 'assets/icons/icon-gateway.svg'

import arsenic from "assets/icons/DashboardIcon/icon-chemistry.svg"
import gps_tracking from "assets/icons/DashboardIcon/icon-gps.svg"
import landslide from "assets/icons/DashboardIcon/icon-land.svg"
import water_level from "assets/icons/DashboardIcon/icon-air.svg"
import ground_water from "assets/icons/DashboardIcon/icon-water.svg"
import weather from "assets/icons/DashboardIcon/icon-rain.svg"
import dump_truck from "assets/icons/DashboardIcon/icon-truck.svg"
import conveyor from "assets/icons/DashboardIcon/icon-belt.svg"
import gateway from 'assets/icons/DashboardIcon/icon-gateway.svg'

import { width } from '@mui/system';

import getshapefile from 'helpers/getshapefile'

//API
import {
  useGetDeviceAPI,
  ArsenicItem,
  DEVICES,
  STATUS,
  useGetFile,
  FILES,
  mapresponseMapLayer,
  Layer,
  strToDEVICES
} from 'api/useRequest'
import { ISensorItem } from 'api/useLandSlideAPI';
import BaseMap from 'components/Map/BaseMap';
import SensorMarker from 'components/Map/Marker/SensorMarker';

const L = window.L;

let TimeInterval: any

const DashboardPage: React.FC<RouteComponentProps> = () => {

  const dispatch = useLayoutDispatch()

  const DashboardAPI = useGetDeviceAPI();
  const ArseicGetFile = useGetFile()

  const [dataStation, setDataStation] = useState<any[]>([]);
  const [ElementMap, setElementMap] = React.useState<any>(null);
  const [LastFetchedTime, setLastFetchedTime] = React.useState<string>(parseDateTime(new Date()));
  const [LayerFile, setLayerFile] = React.useState<Layer[]>([]);

  const [curDataGroup, setCurDataGroup] = useState<any>({});

  const mapnamedevice = (name: string) => {
    var name_: string = "", image: string = "";
    if (name === DEVICES.ARSENIC) {
      name_ = "Arsenic"
      image = arsenic
    } else if (name === DEVICES.CONVEYOR) {
      name_ = "สายพานลำเลียง"
      image = conveyor
    } else if (name === DEVICES.DUMPTRUCK) {
      name_ = "ระบบตรวจนับรถบรรทุกเทท้าย"
      image = dump_truck
    } else if (name === DEVICES.GPSTRACKING) {
      name_ = "GPS"
      image = gps_tracking
    } else if (name === DEVICES.PIEZOMETER) {
      name_ = "Piezo"
      image = ground_water
    } else if (name === DEVICES.LANDSLIDE) {
      name_ = "Landslide"
      image = landslide
    } else if (name === DEVICES.WATERLEVEL) {
      name_ = "ค่าระดับน้ำ"
      image = water_level
    } else if (name === DEVICES.WEATHER) {
      name_ = "Weather"
      image = weather
    } else if (name === DEVICES.GATEWAY) {
      name_ = "Gateway"
      image = gateway
    }
    return {
      name: name_,
      image: image
    }
  }

  const FatchApi = () => {
    DashboardAPI.mutate(DEVICES.ALL, {
      onSuccess: (data: any) => {
        console.log("DEVICES.ALL", data);
        if (data?.data?.landslide?.data?.items) {
          data?.data?.landslide?.data?.items.forEach((element: ISensorItem) => {
            const { timestamp } = element;
            let hrBetweenTwoDate = dateFns.differenceInHours(new Date(), new Date(timestamp));
            element.state = hrBetweenTwoDate > 1 ? "Offline" : "Online";
          });
        }
        setCurDataGroup(data.data);
        // call list file
        ArseicGetFile.mutate(FILES.FILE, {
          onSuccess: async (FileData: any) => {
            var layer_ = mapresponseMapLayer(FileData?.data?.file)
            setLayerFile(layer_)
            var newList: any = []
            const ShapeData = await getshapefile()
            for (const property in data.data) {
              const { items } = data.data[property].data;
              var checkStat = findOnline(items)
              newList.push({
                tag: property,
                item: items,
                online: checkStat.online,
                max: checkStat.max,
                config: (data.data[property].data.config === null || data.data[property].data.config === undefined ? [] : data.data[property].data.config),
                device: mapnamedevice(property)
              })
            }
            setDataStation(newList)
            // LANDSLIDE
            // ARSENIC
            // GPS
            // PIEZO
            var dddd: any = []
            newList.map((e: any) => {
              var name = e.tag
              if (name === DEVICES.ARSENIC) {
                // have
                e.item.forEach((ee: any) => {
                  if (ee.latitude !== null && ee.longitude !== null) {
                    dddd.push({ "id": ee.device_id, "name": ee.device_name, "tag": DEVICES.ARSENIC, "lat": ee.latitude, "lng": ee.longitude, "status": (ee.state === "Online" ? STATUS.ONLINE : ee.state === "Offline" ? STATUS.OFFLINE : STATUS.ERROR), color: 'red' })
                  }
                })
              } else if (name === DEVICES.CONVEYOR) {
                // have
                e.item.forEach((ee: any) => {
                  if (ee.name !== null && ee.line !== null && ee.name !== undefined && ee.line !== undefined && ee.name !== "" && ee.line !== "") {
                    var result_find: any = ShapeData.find(ef => ef.name === ee.name)
                    if (result_find)
                      dddd.push({ "id": ee.device_id, "name": ee.device_name, "tag": DEVICES.CONVEYOR, "lat": result_find.lat, "lng": result_find.lng, "status": (ee.state === "Online" ? STATUS.ONLINE : ee.state === "Offline" ? STATUS.OFFLINE : STATUS.ERROR), color: 'red' })
                    }
                })
              } else if (name === DEVICES.DUMPTRUCK) {
                // have
                e.item.forEach((ee: any) => {
                  if (ee.latitude !== null && ee.longitude !== null) {
                    dddd.push({ "id": ee.device_id, "name": ee.device_name, "tag": DEVICES.DUMPTRUCK, "lat": ee.latitude, "lng": ee.longitude, "status": (ee.state === "Online" ? STATUS.ONLINE : ee.state === "Offline" ? STATUS.OFFLINE : STATUS.ERROR), color: 'red' })
                  }
                })
              } else if (name === DEVICES.GPSTRACKING) {
                // have
                e.item.forEach((ee: any) => {
                  if (ee.latitude !== null && ee.longitude !== null) {
                    dddd.push({ "id": ee.device_id, "name": ee.device_name, "tag": DEVICES.GPSTRACKING, "lat": ee.latitude, "lng": ee.longitude, "status": (ee.state === "Online" ? STATUS.ONLINE : ee.state === "Offline" ? STATUS.OFFLINE : STATUS.ERROR), color: 'red' })
                  }
                })
              } else if (name === DEVICES.PIEZOMETER) {
                // have
                e.item.forEach((ee: any) => {
                  if (ee.latitude !== null && ee.longitude !== null) {
                    dddd.push({ "id": ee.device_id, "name": ee.device_name, "tag": DEVICES.PIEZOMETER, "lat": ee.latitude, "lng": ee.longitude, "status": (ee.state === "Online" ? STATUS.ONLINE : ee.state === "Offline" ? STATUS.OFFLINE : STATUS.ERROR), color: 'red' })
                  }
                })
              } else if (name === DEVICES.LANDSLIDE) {
                // have
                e.item.forEach((ee: any) => {
                  if (ee.latitude !== null && ee.longitude !== null) {
                    dddd.push({ "id": ee.device_id, "name": ee.device_name, "tag": DEVICES.LANDSLIDE, "lat": ee.latitude, "lng": ee.longitude, "status": (ee.state === "Online" ? STATUS.ONLINE : ee.state === "Offline" ? STATUS.OFFLINE : STATUS.ERROR), color: 'red' })
                  }
                })
              } else if (name === DEVICES.WATERLEVEL) {
                // have
                e.item.forEach((ee: any) => {
                  if (ee.latitude !== null && ee.longitude !== null) {
                    dddd.push({ "id": ee.device_id, "name": ee.device_name, "tag": DEVICES.WATERLEVEL, "lat": ee.latitude, "lng": ee.longitude, "status": (ee.state === "Online" ? STATUS.ONLINE : ee.state === "Offline" ? STATUS.OFFLINE : STATUS.ERROR), color: 'red' })
                  }
                })
              } else if (name === DEVICES.WEATHER) {
                // have
                e.item.forEach((ee: any) => {
                  if (ee.latitude !== null && ee.longitude !== null) {
                    dddd.push({ "id": ee.device_id, "name": ee.device_name, "tag": DEVICES.WEATHER, "lat": ee.latitude, "lng": ee.longitude, "status": (ee.state === "Online" ? STATUS.ONLINE : ee.state === "Offline" ? STATUS.OFFLINE : STATUS.ERROR), color: 'red' })
                  }
                })
              }
            })
            console.log("==========================================================")
            console.log(dddd)
            console.log("==========================================================")
            setElementMap(<MapDashboard
              DataMarker={dddd}
              fileLayer={layer_}
            />)
          },
        })
      },
    })
  }

  useEffect(() => {
    changeHeaderText(dispatch, "");
    (() => {
      FatchApi()
      TimeInterval = setInterval(() => {
        setLastFetchedTime(parseDateTime())
        FatchApi()
      }, 1000 * 60);
    })()
    return () => {
      clearInterval(TimeInterval)
    }
  }, []);

  const findOnline = (item: any) => {
    var online = 0
    var maxdevice = 0
    item.forEach((e: any) => {
      if (e.state === "Online") {
        online++
      }
      maxdevice++
    })
    return {
      online: online,
      max: maxdevice
    }
  }

  return (
    <Container>
      <Grid direction="row" container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>

        {/* column 1 */}
        <Grid item xs={100} sm={100} md={50} lg={65} xl={65} style={{ display: "flex", alignItems: "center", }} >
          <Box id={"Topic"}>
            <NormalText>Dashboard</NormalText>
          </Box>
        </Grid>

        {/* column 2 */}
        <Grid item xs={100} sm={100} md={50} lg={35} xl={35}>

          <Grid direction="row" justifyContent="flex-end" container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>

            <Grid item xs={100} sm={100} md={75} lg={75} xl={75} style={{ display: "flex", justifyContent: "flex-end" }}>
              <LastUpdateTextField datetime={LastFetchedTime} />
            </Grid>

          </Grid>

        </Grid>

        {/* column 2 */}
        <Grid item xs={100} sm={100} md={100} lg={100} xl={100} maxHeight="800px">
          <MapBox>
            {Object.keys(curDataGroup).length > 0 ?
              <BaseMap>
                {
                  Object.keys(curDataGroup).map((type: string, index: number) => {
                    // console.log(strToDEVICES(type));
                    let curSensorItemList = curDataGroup[type].data?.items;
                    // console.log(curDataGroup[type].data?.items);
                    return curSensorItemList.map((e: any, i: number) => (
                      <SensorMarker
                        key={`SensorMarker_${index}_${type}_${i}`}
                        type={strToDEVICES(type)}
                        state={e.state}
                        position={[Number(e.latitude), Number(e.longitude)]}
                        label={(strToDEVICES(type) === DEVICES.GATEWAY) ? e.name : e.device_name}
                      // data={e}
                      />
                    ));
                  })
                }
              </BaseMap> :
              <SimpleLoading />
            }
          </MapBox>
        </Grid>

        {/* column 3 */}

        {
          dataStation.map((e: any, i: number) => {
            return <Grid key={`${i}_${e.device.name}`} item xs={100} sm={50} md={50} lg={25} xl={25} >
              <DashboardSensorCard
                id={10}
                tag={e.tag}
                name={e.device.name}
                normallyValue={30}
                // normally={true}
                online={`${e.online} / ${e.max}`}
                img={<img alt={"icon-sensor-" + e.device.name} src={e.device.image} />}
              />
            </Grid>
          })
        }

        <Grid item xs={100} sm={50} md={50} lg={25} xl={25} >
          {/* <DashboardSensorCard
            id={10}
            name={"Arsenic"}
            normallyValue={1}
            normally={false}
            img={<img alt="icon-sensor-arsenic" src={} />}
            open={"0/1"}
            online={"0/1"}
          /> */}
        </Grid>

      </Grid>
    </Container>
  )
}

export default DashboardPage

const Container = styled.div`
  margin-left: 10px;
`
const MapBox = styled(Paper)(({ theme }) => ({

  [theme.breakpoints.up('md')]: {
    height: "500px"
  },
  [theme.breakpoints.down('md')]: {
    height: "300px"
  },
}));

const NormalText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontSize: '120%',
}));



// {/* card GPS */}
// <Grid item xs={100} sm={50} md={50} lg={25} xl={25} >
//   <DashboardSensorCard
//     id={10}
//     name={"GPS"}
//     normally={true}
//     img={<img alt="icon-sensor-gps" src={Icon_GPS} />}
//     open={"0/1"}
//     online={"0/1"}
//   />
// </Grid>

// {/* card Landslide */}
// <Grid item xs={100} sm={50} md={50} lg={25} xl={25} >
//   <DashboardSensorCard
//     id={10}
//     name={"Landslide"}
//     normally={true}
//     img={<img alt="icon-sensor-landslide" src={Icon_Landslide} style={{height:"44px"}}/>}
//     open={"0/1"}
//     online={"0/1"}
//   />
// </Grid>


// {/* card Peizo */}
// <Grid item xs={100} sm={50} md={50} lg={25} xl={25} >
//   <DashboardSensorCard
//     id={10}
//     name={"Peizo"}
//     normally={true}
//     img={<img alt="icon-sensor-peizo" src={Icon_Peizo}/>}
//     open={"0/1"}
//     online={"0/1"}
//   />
// </Grid>

// {/* card Temperature */}
// <Grid item xs={100} sm={50} md={50} lg={25} xl={25} >
//   <DashboardSensorCard
//     id={10}
//     name={"Temperature"}
//     normally={true}
//     img={<img alt="icon-sensor-temperature" src={Icon_Temperature}/>}
//     open={"0/1"}
//     online={"0/1"}
//   />
// </Grid>


// {/* card Weather */}
// <Grid item xs={100} sm={50} md={50} lg={25} xl={25} >
//   <DashboardSensorCard
//     id={10}
//     name={"Weather"}
//     normally={true}
//     img={<img alt="icon-sensor-weather" src={Icon_Weather}/>}
//     open={"0/1"}
//     online={"0/1"}
//   />
// </Grid>


// {/* card รถบรรทุกเทท้าย */}
// <Grid item xs={100} sm={50} md={50} lg={25} xl={25} >
//   <DashboardSensorCard
//     id={10}
//     name={"รถบรรทุกเทท้าย"}
//     normally={true}
//     img={<img alt="icon-sensor-truck" src={Icon_Truck}/>}
//     open={"0/1"}
//     online={"0/1"}
//   />
// </Grid>


// {/* card สายพานลำเลียง */}
// <Grid item xs={100} sm={50} md={50} lg={25} xl={25} >
//   <DashboardSensorCard
//     id={10}
//     name={"สายพานลำเลียง"}
//     normally={true}
//     img={<img alt="icon-sensor-belt" src={Icon_Belt}/>}
//     open={"0/1"}
//     online={"0/1"}
//   />
// </Grid>

// {/* card Gateway */}
// <Grid item xs={100} sm={50} md={50} lg={25} xl={25} >
//   <DashboardSensorCard
//     id={10}
//     name={"Gateway"}
//     normally={true}
//     img={<img alt="icon-sensor-gateway" src={Icon_Gateway}/>}
//     open={"0/1"}
//     online={"0/1"}
//   />
// </Grid>
