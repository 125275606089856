import React from "react";
import Grid from '@mui/material/Grid';

/* Common components */
interface ICardTemplateProps {
    hasShadow?: Boolean;
    height?: string;
    children: JSX.Element | JSX.Element[];
}
export function CardTemplate({ hasShadow = true, height, children }: ICardTemplateProps) {
    const boxShadow = hasShadow ? "2px 2px 10px rgba(0, 0, 0, 0.05)" : "";
    const boxBorder = hasShadow ? "" : "1px solid #E9EBF0";
    return <Grid item xs
        container
        direction="column"
        padding="16px"
        margin="0px"
        width="100%"
        sx={{
            position: 'relative',
            backgroundColor: '#FFFFFF',
            boxShadow: { boxShadow },
            border: { boxBorder },
            borderRadius: "8px"
        }}
    >
        {children}
    </Grid>;
}
