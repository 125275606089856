import { useState } from "react";
import { useDialogController } from '../../pages/sensors/truck/QrMapping/useDialogController';
import * as yup from 'yup';
import { setDeviceAddress } from "api/useDeviceAddressAPI";
import SnackbarUtils from "helpers/utils";

export const useDevcieAddressSetting = (deviceId: string,fetchAddr: () => Promise<any> ) => {
    const { isOpen, handleDialogClose, handleDialogOpen } = useDialogController();
    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setAddress(event.target.value);
    };

    const handleSave = () => {

        const schema = yup.object().shape({
            address: yup.string().required(),
        });

        schema.validate({ address })
            .then(() => {
                setAddressError(false);
                console.log(address);
                setDeviceAddress(deviceId, address).then((result) => {
                    console.log("result", result);
                    SnackbarUtils.success("เปลี่ยนที่อยู่ติดตั้งสำเร็จ");
                    fetchAddr();
                    handleDialogClose();
                }).catch((err) => {
                    console.log("err", err);
                    SnackbarUtils.error("เกิดข้อผิดพลาดในการเปลี่ยนที่อยู่ติดตั้ง");
                });
            })
            .catch((err) => {
                setAddressError(true);
            });

    };

    return {
        isOpen,
        handleDialogClose,
        handleDialogOpen,
        address,
        addressError,
        handleAddressChange,
        handleSave
    };
};
