import React, { useState } from "react";
import LastUpdateTextField from '../../../components/Datetime/LastUpdateTextField';
import { usePageContext } from "./WaterLevelPage";
import * as WaterLvAPI from "api/useWaterLevelAPI";
import parseDateTime from "helpers/parseDateTime";

export function UpdateCurrentDataButton() {
    const [lastUpdateTime, setLastUpdateTime] = useState(parseDateTime(new Date()));
    const { stateContext, dispatchContext } = usePageContext();

    function onClickUpdateData() {
        setLastUpdateTime(parseDateTime(new Date()));
        (async () => {
            const res = await WaterLvAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            const items = res.data.water_level.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
            // const curSensor = items.find((e: any) => e.device_id === deviceID);
            dispatchContext!({ curSensorList: items });
        })();
    }
    return <LastUpdateTextField datetime={lastUpdateTime} onClick={onClickUpdateData} />;
}
