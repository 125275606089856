import * as React from 'react';
import BaseTable,{ createData, Data} from '../Table/BaseTable'
import type { UserData } from 'api/useAdminAPI'

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
}

const mockHeadCells: HeadCell[] = [
  {
    id: 'employeenumber',
    disablePadding: true,
    label: 'Employee No.',
  },
  {
    id: 'role',
    disablePadding: false,
    label: 'Role',
  }
];

const menuOptions = [
  'ลบ'
]

interface Prop {
  data?: UserData[]
}

const UserTable: React.FC<Prop> = (props) => {
  const listusers: Data[] = [];
  if (props.data) {
    for (let index = 0; index < props.data.length; index++) {
      const role = props.data[index].permissions?.includes("admin") ? "admin" : "user";
      const element = createData(
        props.data[index].id,
        props.data[index].employee_id,
        role,
        ""
      );
      listusers.push(element);
    }
  }
    
  return (
    <BaseTable headCells={mockHeadCells} rows={listusers} menuOptions={menuOptions} selectPage={"pending"}/>
  );
}

export default UserTable;