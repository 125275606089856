import React, { useEffect } from "react";
import MapLandslide from 'components/Map/MapLandslide.js';
import axios from 'axios';
import { MapBox, usePageContext } from "./LandSlidePage";
import { ISensorItem } from "api/useLandSlideAPI";
import * as LandSlideAPI from "api/useLandSlideAPI";
import { getAngle } from "helpers/calLatLongDir";
import { SimpleLoading } from "components/Loading/SimpleLoading";
import { DEVICES, LandslideItem } from "api/useRequest";
import BaseMap from "components/Map/BaseMap";
import SensorMarker from "components/Map/Marker/SensorMarker";

export function LandSlideMapContent({ onTapMarker }: { onTapMarker: (data: any) => void }) {
    const { stateContext, dispatchContext } = usePageContext();

    return (
        <MapBox>
            {stateContext.curSensorList.length > 0 ?
                <BaseMap>
                    {
                        stateContext.curSensorList.map((e: ISensorItem, i: number) => (
                            <SensorMarker
                                key={`LandSlideMarker_${i}`}
                                type={DEVICES.LANDSLIDE}
                                state={e.state}
                                position={[Number(e.latitude), Number(e.longitude)]}
                                label={e.device_name}
                                data={e}
                                isSelected={stateContext.selectedSensor.includes(e.device_id)}
                                onClick={(event: any) => {
                                    console.log(event);
                                    onTapMarker({ id: e.device_id })
                                }}
                            />
                        ))
                    }
                </BaseMap>
                : <SimpleLoading />}
        </MapBox>
    );
}


