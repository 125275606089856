import { KmlOverlayProps } from "./KmlOverlay";

export function EditStyleKmlLine(props: KmlOverlayProps, kmlFileRes: any) {
    const kmlText = kmlFileRes.data;

    const parser = new DOMParser();
    let newKml = parser.parseFromString(kmlText, 'text/xml');
    let elements = newKml.getElementsByTagName('LineStyle');
    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        const children = element.children;
        let widthNode;
        for (let i = 0; i < children.length; i++) {
            const subElement = children[i];
            if (subElement.tagName === 'color') {
                subElement.innerHTML = props.fileType.color.replace("#", "ff");
            } else if (subElement.tagName === 'width') {
                widthNode = subElement;
            }
        }

        if (props.lineWidth === undefined) {
            continue;
        }

        const node = document.createElement("width");
        const textnode = document.createTextNode(props.lineWidth!.toString());
        node.appendChild(textnode);

        if (widthNode === undefined) {
            element.appendChild(node);
        } else {
            element.replaceChild(node, widthNode);
        }

    }
    return newKml;
}
