import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
// import InformationTransmissionDetailTable, { Data } from "components/InformationTransmissionTable";
import * as GpsTrackingAPI from "api/useGpsTrackingAPI";
import { usePageContext } from "./GpsTrackingPageDevice";
import TopsoilDeviceDetailTable, { createData, Data } from "components/TopsoilDeviceDetailTable";
import BaseRawTable from "components/BaseTable/BaseRawTable";
import { DEVICES } from "api/useRequest";
import { useParams } from "react-router-dom";
import { CardTemplate } from "components/Card/CardTemplate";
import parseDateTime from "helpers/parseDateTime";

export function DeviceTable() {
    const { stateContext, dispatchContext } = usePageContext();
    const [dataList, setDataList] = useState<GpsTrackingAPI.ISensorItem[]>([]);
    /* Get params */
    let { deviceID } = useParams<{ deviceID: string; }>();
    // useEffect(() => {
    //     const datas: GpsTrackingAPI.ISensorItem[] = stateContext.allSensorList.map((e: any) => {
    //         const {
    //             device_id,
    //             device_name,
    //             power_pri,
    //             power_sec,
    //             state,
    //             latitude,
    //             longitude,
    //             altitude,
    //             speed,
    //             timestamp,
    //         } = e;

    //         return {
    //             device_id,
    //             device_name,
    //             power_pri,
    //             power_sec,
    //             state,
    //             latitude,
    //             longitude,
    //             altitude,
    //             speed,
    //             timestamp: parseDateTime(timestamp),
    //         };
    //     });
    //     setDataList(datas);
    // }, [stateContext.allSensorList]);

    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0">
        <CardTemplate>
            <BaseRawTable tableName={"รายละเอียดอุปกรณ์"} device={DEVICES.GPSTRACKING} DateTime={stateContext.dateRange} order={"asc"} orderBy={"device_id"} tableCell={headCells} device_id={deviceID} />
        </CardTemplate>
    </Grid>;
}

interface IDataOnTable {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    state: string;
    latitude: string;
    longitude: string;
    real_altitude: string;
    mine_n: string;
    mine_e: string;
    speed: string;
    timestamp: string;
    gnss_rtk_solution_name: string;
}

interface HeadCell {
    disablePadding?: boolean;
    id: keyof IDataOnTable;
    label: string;
    numeric: boolean;
    isStatus?: boolean;
    isUpper?: boolean;
    isUseFormatTime?: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'device_id',
        numeric: false,
        isUpper: true,
        label: 'ID',
    },
    {
        id: 'device_name',
        numeric: false,
        label: 'Name',
    },
    // {
    //     id: 'state',
    //     numeric: false,
    //     isStatus: true,
    //     label: 'Status',
    // },
    {
        id: 'timestamp',
        numeric: false,
        label: 'Last Updated',
        isUseFormatTime: true
    },
    {
        id: 'latitude',
        numeric: true,
        disablePadding: false,
        label: 'Latitude',
    },
    {
        id: 'longitude',
        numeric: true,
        disablePadding: false,
        label: 'Longitude',
    },
    {
        id: 'real_altitude',
        numeric: true,
        disablePadding: false,
        label: 'Altitude',
    },
    {
        id: 'mine_n',
        numeric: true,
        disablePadding: false,
        label: 'Mine N',
    },
    {
        id: 'mine_e',
        numeric: true,
        disablePadding: false,
        label: 'Mine E',
    },
    {
        id: 'speed',
        numeric: true,
        disablePadding: false,
        label: 'Speed',
    },
    {
        id: 'gnss_rtk_solution_name',
        numeric: false,
        disablePadding: false,
        label: 'RTK',
    },
    {
        id: 'power_pri',
        numeric: true,
        disablePadding: false,
        label: 'Solar Cell',
    },
    {
        id: 'power_sec',
        numeric: true,
        disablePadding: false,
        label: 'Battery',
    },
];
