import React, { useState } from "react";
import styled from "styled-components";

import {
  Paper,
  Grid,
  Typography,
} from "@mui/material"

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Compass from './Compass'

interface ISensors {
  tag?: string;
  title?: string;
  value?: string | number;
  unit?: string;
  rain_24h?: string | number;
  rain?: string | number;
  tmin?: string | number;
  tmax?: string | number;
  humidity?: string | number;
  pressure?: string | number;
  pm2_5?: string | number;
  pm1_0?: string | number;
  from?: string | number;
  f_unit?: string | number;
  gusts?: string | number;
  angle?: string | number;
  province?: string;
  img?: string;
  h_unit?: string;
  p_unit?: string;
  pm2_5_unit?: string;
  pm1_0_unit?: string;
  temperature?: string | number;
  t_unit?: string;
  state?: string;
}

const WeatherSensorCard: React.FC<ISensors> = ({ ...props }) => {

  const PM25 = () => {
    return <BoxPM>
      PM 2.5 : <PMstyle>&nbsp;{props?.pm2_5}&nbsp;</PMstyle>{props?.pm2_5_unit}<sup>3</sup>
    </BoxPM>
  }

  const PM10 = () => {
    return <BoxPM>
      PM 10 : <PMstyle>&nbsp;{props?.pm1_0}&nbsp;</PMstyle>{props?.pm1_0_unit}<sup>3</sup>
    </BoxPM>
  }

  return (
    <Grid item xs={100} sm={100} md={100} lg={100} xl={100} >
      <Item>
        <Grid direction="row" container spacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} >
          {/* value sensor */}
          <Grid item xs={60} sm={60} md={60} lg={60} xl={60}>
            <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <NormalText>{props?.title}</NormalText>
            </Grid>
            <SensorsGrid item xs={100} sm={100} md={100} lg={100} xl={100} style={{ margin: "10px auto 0px 10px" }}>
              <SensorText style={(props?.state !== 'Online') ? { color: "#00000099" } : {}}>{props?.value}</SensorText><SmallText>{props?.unit}</SmallText>
            </SensorsGrid>

            <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <AnotherText>{props?.province}</AnotherText>
            </Grid>

            <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <AnotherText>{props?.humidity !== undefined && props?.pressure !== undefined ? <> Rain : &nbsp;<ColorNumber style={(props?.state !== 'Online') ? { color: "#00000099" } : {}}>{props?.rain}</ColorNumber>&nbsp;{"mm"}   </> : null}</AnotherText>
            </Grid>
            <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <AnotherText>{props?.humidity !== undefined && props?.pressure !== undefined ? <> Rain 24h : &nbsp;<ColorNumber style={(props?.state !== 'Online') ? { color: "#00000099" } : {}}>{props?.rain_24h}</ColorNumber>&nbsp;{"mm"}   </> : null}</AnotherText>
            </Grid>


            <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <AnotherText>{props?.tmax !== undefined ? <>สูงสุด: &nbsp;{<ColorNumber style={(props?.state !== 'Online') ? { color: "#00000099" } : {}}>{props?.tmax}</ColorNumber>}&nbsp;{props?.unit}   ต่ำสุด: &nbsp;<ColorNumber style={(props?.state !== 'Online') ? { color: "#00000099" } : {}}>{props?.tmin}</ColorNumber>&nbsp;{props?.unit}</> : null}</AnotherText>
            </Grid>

            {/* <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <AnotherText>{props?.humidity !== undefined && props?.pressure !== undefined && props?.temperature !== undefined ? <> Humidity : &nbsp;<ColorNumber>{props?.humidity}</ColorNumber>&nbsp;{props?.h_unit}   </> : null}</AnotherText>
            </Grid>

            <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <AnotherText>{props?.humidity !== undefined && props?.pressure !== undefined && props?.temperature !== undefined ? <> Temperature : &nbsp;<ColorNumber>{props?.temperature}</ColorNumber>&nbsp;{props?.t_unit}   </> : null}</AnotherText>
            </Grid> */}

            <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <AnotherText style={{ paddingLeft: 20 }}>{props?.pm2_5 !== undefined ? PM10() : null}</AnotherText>
            </Grid>

            <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <AnotherText style={{ paddingLeft: 20 }}>{props?.pm1_0 !== undefined ? PM25() : null}</AnotherText>
            </Grid>

            <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <AnotherText>{(props?.from !== undefined ? <>Direction : &nbsp;<ColorNumber style={(props?.state !== 'Online') ? { color: "#00000099" } : {}}>{props?.from}</ColorNumber>&nbsp;{props?.f_unit}</> : null)}</AnotherText>
            </Grid>

            <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
              <AnotherText>{(props?.gusts !== undefined ? <>Gusts : &nbsp;<ColorNumber style={(props?.state !== 'Online') ? { color: "#00000099" } : {}}>{props?.gusts}</ColorNumber></> : null)}</AnotherText>
            </Grid>

          </Grid>
          {/* image sensor */}
          <IconGrid item xs={25} sm={25} md={25} lg={25} xl={25}>
            {
              (props?.tag === "wind" ? /* <Compass angle={props?.from} />  */ null : <IconImage src={props?.img} alt={"icon-sensor"} style={(props?.state !== 'Online') ? { opacity: "70%" } : {}} />)
            }
          </IconGrid>
        </Grid>
      </Item>
    </Grid>
  )
}

export default WeatherSensorCard

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  elevation: 0,
  textAlign: 'left',
  maxWidth: '100%',
  minWidth: '100px',
  height: '100%',
  minHeight: "100px"
}));

const NormalText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontSize: '120%',
  // paddingBottom: theme.spacing(0.5), 
  fontWeight: 500
}));

const SensorText = styled(Typography)(({ theme }) => ({
  fontSize: '200%',
  // paddingBottom: theme.spacing(0.5), 
  color: '#0739A2',
  fontWeight: 700
}));

const SmallText = styled(Typography)(({ theme }) => ({
  fontSize: '100%',
  // paddingBottom: theme.spacing(0.5), 
  color: 'rgba(0, 0, 0, 0.6)',
}));

const AnotherText = styled(Grid)(({ theme }) => ({
  color: '#00000099',
  fontSize: '110%',
  // paddingBottom: theme.spacing(0.5), 
  fontWeight: 1
}));

const SensorsGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "baseline"
}));

const IconGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center"
}));

const PMstyle = styled(Typography)(({ theme }) => ({
  color: "#0739A2",
  fontWeight: 1000,
  fontSize: "250%"
}));

const ColorNumber = styled.span`
  color: #0739A2;
  font-weight: 600;
`

// const GridResponsiveHeight = styled(Grid)(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     height: "500px"
//   },
//   [theme.breakpoints.down('md')]: {
//     height: "300px"
//   },
// }));

const IconImage = styled.img`
  width: 70px;
  height: 80px;  
`

const BoxPM = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: baseline;
  font-size: 24px;
  font-weight: 500;
`

const BoxValue = styled.div`
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
`
const Value = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: rgba(7, 57, 162, 1);
`
const Unit = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: rgba(7, 57, 162, 1);
`
const Stauts = styled.span`
  margin-left: 10px;
  fontSize: '110%',
`
const CheckCircleIconOn = styled(CheckCircleIcon)(({ theme }) => ({
  color: 'rgba(22, 155, 0, 1)',
}));
const CheckCircleIconOff = styled(CheckCircleIcon)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.2)',
}));

const CheckCircleIconOnline = styled(CheckCircleIcon)(({ theme }) => ({
  color: 'rgba(22, 155, 0, 1)',
}));
const CheckCircleIconOffline = styled(CheckCircleIcon)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.2)',
}));
