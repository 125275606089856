import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import React, { useEffect, useContext, useState } from "react";
import type { RouteComponentProps } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Button, { ButtonProps } from '@mui/material/Button';
import NormalText from 'constants/TextStyles';
import styled from 'styled-components'
import { Paper, Typography } from "@mui/material";
// component map
import { CurrentDataList } from "./CurrentDataList";
import * as dateFns from 'date-fns';
import { UpdateCurrentDataButton } from "./UpdateCurrentDataButton";
import { ConveyorMapContent } from "./ConveyorMapContent";

import ConveyerFileUploadDialog from 'components/Dialog/ConveyerFileUpload'

/* API */
import * as ConveyorAPI from "api/useConveyorAPI";
import { ISensorItem } from "api/useConveyorAPI";
import { IConveyorConfigItem, reqGetConfig } from "api/useConfigSettingAPI";
import { DEVICES } from "api/useRequest";
import useUserContext from "context/UserContext";
import parseDateTime from "helpers/parseDateTime";

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { GraphListPanel } from "./GraphListPanel";
import { DeviceTable } from "./DeviceTable";
import DefaultDateTimeRange from "constants/DefaultDateTimeRage";


interface IConveyorLine {
    line: string;
    sensorsInLine: ISensorItem[];
}

export interface IPageContext {
    curSensorList: ISensorItem[];
    curSensorItem: ISensorItem;
    curConfigList: IConveyorConfigItem[];
    allSensorList: ISensorItem[];
    dateRange: Date[];
    limit: string[];
}

const defaultContext: IPageContext = {
    curSensorList: [],
    curSensorItem: ConveyorAPI.defaultSensorItem,
    curConfigList: [],
    allSensorList: [],
    dateRange: DefaultDateTimeRange[DEVICES.CONVEYOR](new Date()),
    limit: [],
}
const pageContext = React.createContext<{ stateContext: IPageContext, dispatchContext?: React.Dispatch<any>; }>({ stateContext: defaultContext });

export const usePageContext = () => useContext(pageContext);

interface ICurentCsvReport {
    data: ISensorItem[],
    headers: { label: string; key: string; }[],
    filename: 'Current_devices_data.csv'
}

const HEADER_TEXT = "ระบบตรวจวัดสถานะสายพาน"
const ConveyorPage: React.FC<RouteComponentProps> = () => {

    const [mapCaption, setMapCaption] = useState<string>("");
    const [countOnline, setCountOnline] = useState<string>("");
    const [isLoadingCurrentData, setIsLoadingCurrentData] = useState(true);
    const [isLoadingConveyorFile, setIsLoadingConveyorFile] = useState(true);

    async function getConfig() {
        const res = await reqGetConfig(DEVICES.CONVEYOR);
        if (res.status !== 200) {
            return
        }
        const configs: IConveyorConfigItem[] = res.data;
        // const configItem: IConveyorConfigItem = res.data.find((e: any) => e.device_id == deviceID) ?? defaultConveyorConfigItem;

        dispatchContext!({ curConfigList: configs })
        // dispatchContext!({ curConfigItem: configItem })
    }

    async function getDataByDateTimeRange() {
        const res = await ConveyorAPI.reqDeviceData(stateContext.dateRange);
        if (res.status != 200) {
            return [];
        }
        const { items } = res.data;
        dispatchContext!({ allSensorList: items });
    };

    async function getCurrentData() {
        setIsLoadingCurrentData(true)
        const res = await ConveyorAPI.reqCurDeviceData();
        if (res.status != 200) {
            setIsLoadingCurrentData(false)
            return [];
        }
        const items = res.data.conveyor.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
        const online = items.filter((e: any) => (e.state === 'Online')).length;
        setCountOnline(`${online}/${items.length}`)
        await getConfig();
        dispatchContext!({ curSensorList: items });
        setIsLoadingCurrentData(false)

    };
    const isAdmin = useUserContext().permission?.permissions.includes('admin');

    /* Set page header */
    const dispatch = useLayoutDispatch()
    useEffect(() => {
        changeHeaderText(dispatch, HEADER_TEXT);

        getDataByDateTimeRange()
        getCurrentData()
    }, [])

    /* Create global state in page */
    const [stateContext, dispatchContext] = React.useReducer((state: IPageContext, action: any) => {
        return { ...state, ...action }
    }, defaultContext);

    const [openUploadDilog, setOpenUploadDilog] = React.useState(false);

    const onDateTimeChange = (value: Date[]) => {
        dispatchContext!({ dateRange: value });
    };

    return (
        <pageContext.Provider value={{ stateContext, dispatchContext }}>
            <ConveyerFileUploadDialog isOpen={openUploadDilog} setOpen={setOpenUploadDilog} reFlashData={() => { /** update context global variable here. */ }} onFileLoaded={(res) => {
                let target_file = res?.data?.file.find((e: any) => (e.file_type === "ShapeFile_Conveyer"))
                console.log('ShapeFile_Conveyer', target_file);
                if (target_file !== undefined) {
                    setMapCaption(`ไฟล์สายพานอัพเดตล่าสุด: ${parseDateTime(new Date(target_file.updated_at))}`)
                }
                setIsLoadingConveyorFile(false)

            }} />
            <DashboardContainer>
                <Grid
                    width="100%"
                    height="100%"
                    container
                    direction="column"
                    rowSpacing={2}
                    columnSpacing={2}
                    columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                >
                    {/* <HeaderRow /> */}
                    <Grid
                        item
                        container
                        direction="row"
                        columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                    >
                        <Grid item xs={100} sm={100} md={100} lg={30} xl={30} container alignItems="center" >
                            <NormalText>
                                {HEADER_TEXT}
                            </NormalText>
                        </Grid>
                        <Grid item xs={100} sm={100} md={100} lg={70} xl={70}>
                            <Grid container rowSpacing={5} columnSpacing={5} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} justifyContent="flex-end">
                                <Grid item xs={100} sm={100} md={100} lg={100} xl={100} container justifyContent="flex-end" gap={"10px"} >
                                    <UpdateCurrentDataButton />
                                    <UploadFileMainConveyer variant="outlined" onClick={() => setOpenUploadDilog(true)}>อัพโหลดไฟล์คอนเวเยอร์</UploadFileMainConveyer>
                                </Grid>
                                {/* {isAdmin ?
                                    <>
                                        <Grid item xs={100} sm={100} md={30} lg={32} xl={60} container justifyContent="flex-end" >
                                            <UpdateCurrentDataButton />
                                        </Grid>
                                        <Grid item xs={100} sm={100} md={30} lg={30} xl={20} container justifyContent="flex-end" >
                                            <ThresholdSettingButton sensorName='conveyor' />
                                        </Grid>
                                        <Grid item xs={100} sm={100} md={30} lg={30} xl={20} container justifyContent="flex-end" >
                                            <UploadFileMainConveyer variant="outlined" onClick={() => setOpenUploadDilog(true)}>อัพโหลดไฟล์คอนเวเยอร์</UploadFileMainConveyer>
                                        </Grid>
                                    </>
                                    : <>
                                        <Grid item xs={100} sm={100} md={60} lg={70} xl={80} container justifyContent="flex-end" >
                                            <UpdateCurrentDataButton />
                                        </Grid>
                                        <Grid item xs={100} sm={100} md={40} lg={30} xl={20} container justifyContent="flex-end" >
                                            <UploadFileMainConveyer variant="outlined" onClick={() => setOpenUploadDilog(true)}>อัพโหลดไฟล์คอนเวเยอร์</UploadFileMainConveyer>
                                        </Grid>
                                    </>
                                } */}
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <MapRow /> */}
                    <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}  >
                        <Grid item xs={100} sm={100} md={50} lg={70} xl={70} maxHeight="500px">
                            <ConveyorMapContent />
                        </Grid>
                        <Grid
                            item
                            xs={100} sm={100} md={50} lg={30} xl={30}
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            alignContent="stretch"
                            bgcolor={'#fff'}
                        // maxHeight="500px"
                        >
                            {(isLoadingCurrentData || isLoadingConveyorFile) === false ? <Grid item width={'100%'}
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                alignContent="stretch"
                                wrap="wrap"
                                padding="16px"
                                paddingBottom="0"

                            >
                                <Typography variant="body2" color="initial" fontWeight={600} >อุปกรณ์ออนไลน์ {countOnline}</Typography>
                                <Typography color="initial" fontSize={10} >{mapCaption}</Typography>
                            </Grid> : <Grid item width={'100%'}
                                padding="16px"
                                paddingBottom="0"></Grid>}
                            <Grid item width={'100%'}   >
                                <CurrentDataList />
                            </Grid>
                        </Grid>
                    </Grid>


                    {/* <TableZone /> */}
                    <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
                        <DeviceDataTab
                            tabName={["กราฟ", "อุปกรณ์"]}
                            tabElement={[<GraphListPanel />, <DeviceTable />]}
                            tabWithDatePicker={[0]}
                            dateTimeRange={stateContext.dateRange}
                            onDateTimeChange={onDateTimeChange}
                        />
                    </Grid >


                </Grid>
            </DashboardContainer>
        </pageContext.Provider>
    );
}

export default ConveyorPage

const DashboardContainer = styled.div`
  margin-left: 10px;
`

export const MapBox = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        height: "500px"
    },
    [theme.breakpoints.down('md')]: {
        height: "300px"
    },
}));

const UploadFileMainConveyer = styled(Button)<ButtonProps>(({ theme }) => ({
    borderColor: '#0739A2',
    color: '#0739A2',
    '&:hover': {
        color: '#0739A2',
        borderColor: '#0739A2',
        backgroundColor: 'rgba(25,118,210,0.04)'
    },
}));



