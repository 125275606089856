const addDigiNubmerStr = (snum:number) => {
  return ((Number(snum) < 10) ? "0" + snum.toString(): snum.toString())
}

export default function parseDate (date: Date | undefined):string {
  if (date === undefined || date === null){
    return ""
  }
  var time = new Date(date)
  return `${addDigiNubmerStr(time.getUTCDate())}-${addDigiNubmerStr(time.getUTCMonth() + 1)}-${time.getUTCFullYear()}`
}