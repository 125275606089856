import { FileType } from "components/Map/BaseMap";
import requestInstance from "configure/requestConfig";
import React, { createContext, useState, useCallback, useContext, useEffect } from "react";



export interface MapContextValue {
    addOverlayData: (key: string, data: any) => void;
    getOverlayData: (fileName: string) => any;
    getAllOverlayData: () => any;
    OverlayDataSize: number;
    getFileList: () => any;
    setFileList: React.Dispatch<any>;
}



const defaultContextValue = {
    addOverlayData: (key: string, data: any) => { },
    getOverlayData: (fileName: string) => { },
    getAllOverlayData: () => { },
    OverlayDataSize: 0,
    getFileList: () => { },
    setFileList: (fileList: any[]) => { },
}

const MapContext = createContext<MapContextValue>(defaultContextValue)


export const MapContextProvider: React.FC = ({ children }) => {
    const [overlayData, setOverlayData] = useState<Record<string, any>>({})
    const [fileList, setFileList] = useState<any>([])
    const [overlaySize, setOverlaySize] = useState(0)

    function addOverlayData(key: string, data: any) {
        let newData = overlayData
        newData[key] = data
        setOverlayData(newData)
        setOverlaySize(Object.keys(newData).length)
    }


    const value: MapContextValue = {
        addOverlayData: addOverlayData,
        getOverlayData: (fileName: string) => (overlayData[fileName]),
        getAllOverlayData: () => overlayData,
        OverlayDataSize: overlaySize,
        getFileList: () => fileList,
        setFileList: setFileList,
    }

    // useEffect(() => {
    //     (async () => {
    //         const files: any[] = await getMapFileTypeData()
    //         setFileList(files)
    //         for (let index = 0; index < files.length; index++) {
    //             let element = files[index];
    //             const fileName = element.file_name;
    //             if (element.file_type == FileType.GeoTiff) {
    //                 continue;
    //             }
    //             const fileRes = await getMapFile(fileName)
    //             let data = fileRes.data;

    //             addOverlayData(fileName, data)

    //         }
    //     })()
    // }, [])

    return <MapContext.Provider value={value}>{children}</MapContext.Provider>
}

const useMapContext = () => useContext(MapContext)

export default useMapContext

export async function getMapFileTypeData() {
    const path = "/api/files/types";
    const params = {
        limit: "",
        offset: ""
    };

    const file = await requestInstance.get(path, { params: params, }).then(
        (res) => {
            /* Save data to context */
            return res.data.file
        }
    ).catch(
        (res) => {
            return []
        }
    );
    console.log("getMapFileTypeData", file);



    return file;
}

export async function getMapFile(fileName: string) {
    const path = `/api/files/${fileName}`;


    const res = await requestInstance.get(path);
    console.log(`getMapFile ${fileName}:`, res);

    return res;
}