import React, { createContext, useState, useCallback, useContext, useEffect } from "react";

import { authStorage, permissionsStorage } from '../configure/localStorageConfig';
import { setAuthorization, unsetAuthorization } from '../configure/requestConfig';

import { AuthenticationResponseData } from 'api/useAuthenticationAPI';
import type { PermissionResponseData } from 'api/usePermissionAPI';
import { PermissionDetail } from "components/Dialog/Permissions";


interface UserContextValue {
    auth?: AuthenticationResponseData
    permission?: PermissionResponseData
    setAuth: (data: AuthenticationResponseData) => void
    setPermissions: (data: PermissionResponseData) => void
    clearAuth: () => void
    listPermissions?: PermissionDetail[]
    setListPermissions: (data: PermissionDetail[]) => void
}

const defaultContextValue = {
    setAuth: () => { },
    setPermissions: () => { },
    clearAuth: () => { },
    setListPermissions: () => { },
}

const UserContext = createContext<UserContextValue>(defaultContextValue)


export const UserContextProvider: React.FC = ({ children }) => {
    const [auth, setAuth] = useState<AuthenticationResponseData | undefined>(
        authStorage.get()
    )
    const [permission, setPermission] = useState<PermissionResponseData | undefined>(
        permissionsStorage.get()
    )
    const [listPermissions, setListPermissions] = useState<PermissionDetail[] | undefined>()

    const handleSetPermissions = useCallback((data: PermissionResponseData) => {
        setPermission(data)
        permissionsStorage.set(data)
    }, [])

    const handleSetAuth = useCallback((data: AuthenticationResponseData) => {
        setAuthorization(data.access_token, data.eno)
        setAuth(data)
        authStorage.set(data)
    }, [])

    const handleClearAuth = useCallback(() => {
        setAuth(undefined)
        setPermission(undefined)
        authStorage.remove()
        permissionsStorage.remove()
        unsetAuthorization()
    }, [])

    const value: UserContextValue = {
        auth,
        permission,
        listPermissions,
        setAuth: handleSetAuth,
        setPermissions: handleSetPermissions,
        clearAuth: handleClearAuth,
        setListPermissions: setListPermissions, 
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const useUserContext = () => useContext(UserContext)

export default useUserContext
