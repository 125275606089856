import * as React from 'react';

import Button, { ButtonProps } from '@mui/material/Button';

import { styled } from '@mui/material/styles';

interface IBaseUploadProps {
  onFile: (f?: any) => void;
  accept:string;
  btnName: string;
}

const BaseUpload = (props: IBaseUploadProps) => {
  
  const inputFile = React.createRef<HTMLInputElement>();

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    console.log("handleSelectFile")
    props.onFile(event.target.files)
    event.target.files = null
  }

  return (
    <>
      <ChooseFileButton variant="outlined" onClick={() => { inputFile?.current?.click() }} >{props.btnName}</ChooseFileButton>
      <input
        style={{ width: "20vw", display: 'none' }}
        id="file"
        type="file"
        ref={inputFile}
        accept={props.accept}
        onChange={handleSelectFile}
      />
    </>
  );
}

export default BaseUpload;


const ChooseFileButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderColor: '#0739A2',
  color: '#0739A2',
  '&:hover': {
    color: '#0739A2',
    borderColor: '#0739A2',
    backgroundColor:'rgba(25,118,210,0.04)'
  },
}));