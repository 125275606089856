import { DEVICES, strToDEVICES } from "api/useRequest";
import { FunctionComponent } from "react";
import { Tooltip } from "react-leaflet";
import { ArsenicItem } from "api/useArsenicAPI";
import { PiezometerItem } from "api/usePiezometerAPI";
import { WeathereData as WeatherItem } from "api/useWeatherAPI";
import { ISensorItem as ConveyorItem } from "api/useConveyorAPI";
import { ISensorItem as GatewayItem } from "api/useGatewayAPI";
import { ISensorItem as GpsTrackingItem } from "api/useGpsTrackingAPI";
import { ISensorItem as LandSlideItem } from "api/useLandSlideAPI";
import { ISensorItem as TruckItem } from "api/useTruckAPI";
import { ISensorItem as WaterLevelItem } from "api/useWaterLevelAPI";
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parseDateTime from "helpers/parseDateTime";

type SensorItem = (
    ArsenicItem |
    PiezometerItem |
    WeatherItem |
    ConveyorItem |
    GatewayItem |
    GpsTrackingItem |
    LandSlideItem |
    TruckItem |
    WaterLevelItem
);

interface TooltipMarkerProps {
    type: DEVICES | string;
    data: SensorItem | any;
}

const TooltipMarker: FunctionComponent<TooltipMarkerProps> = (props) => {
    let data = props.data;
    console.log({data});
    
    let displayKeys: string[] = [];
    let keyWords: string[] = [];
    switch (props.type) {
        case DEVICES.ARSENIC:
            displayKeys = ['timestamp', 'percent', 'power_pri', 'power_sec'];
            keyWords = ['Last Update', 'Percent', 'Solar Cell', 'Battery'];
            break;
        case DEVICES.CONVEYOR:
            displayKeys = ['device_name', 'line', 'name', 'value'];
            keyWords = ['ชื่ออุปกรณ์', 'Line', 'Name', 'สถานะ'];
            break;
        case DEVICES.DUMPTRUCK:
        case DEVICES.TRUCK:
            break;
        case DEVICES.GATEWAY:
            break;
        case DEVICES.GPS:
        case DEVICES.GPSTRACKING:
            break;
        case DEVICES.LANDSLIDE:
            displayKeys = ['device_name', 'timestamp', 'diff2d', 'atitude'];
            keyWords = ['Name', 'Last Update', 'd2D', 'dH'];
            break;
        case DEVICES.PIEZO:
        case DEVICES.PIEZOMETER:
            displayKeys = ['timestamp', 'device_name', 'kpa', 'msl', 'observation', 'tip_elevation'];
            keyWords = ['Last Update', 'Name', 'Pore Pressure [kPa]', 'Total head [msl]', 'Observation', 'Tip Elevation [msl]'];
            break;
        case DEVICES.WATERLEVEL:
            displayKeys = ['timestamp', 'level', 'power_pri', 'power_sec'];
            keyWords = ['Last Update', 'Level', 'Solar Cell', 'Battery'];
            break;
        case DEVICES.WEATHER:
            displayKeys = ['timestamp', 'power_pri', 'power_sec', 'rain', 'rain_24hr', 'temperature', 'humidity', 'pm_25', 'pm_10', 'wind_speed', 'wind_direction'];
            keyWords = ['Last Update', 'Solar Cell', 'Battery', 'Rain', 'Rain 24hr', 'Temperature', 'Humidity', 'PM 2.5', 'PM 10', 'Wind Speed', 'Wind Direction'];
            break;
        default:
            return null;
    }
    return (
        data !== undefined && displayKeys.length !== 0 && keyWords.length === displayKeys.length ?
            <Tooltip className="request-tooltip" >
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    width="200px"
                    margin={0.5}
                >
                    {displayKeys.map((key: string, index: number) => (
                        <Grid
                            key={`${index}_${keyWords[index]}`}
                            item
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="baseline"
                            wrap="nowrap"
                            marginTop="2px"
                        >
                            <Typography variant="caption" color="initial">{keyWords[index]}</Typography>
                            <Typography variant="caption" color="initial">{formatValue(strToDEVICES(props.type), data, key)}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Tooltip > : null
    );
}

export default TooltipMarker;

function formatValue(type: DEVICES, data: SensorItem | any, key: string) {
    let val;
    if (key === 'timestamp') {
        val = parseDateTime(data[key]);
    } else if (type === DEVICES.CONVEYOR && key === 'value') {
        val = data[key] > 0 ? "กำลังทำงาน" : "หยุดการทำงาน";
    } else if (typeof data[key] === "number") {
        val = data[key].toFixed(3);
    } else {
        val = data[key]
    }

    return val;

}