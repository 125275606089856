import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => <Tabs {...props} />)({
    "& .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#0739A2"
    }
});

interface StyledTabProps {
    label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: "none",
    color: "#4C4C4C",
    "&.Mui-selected": {
        color: "#0739A2"
    }
    // "&.Mui-focusVisible": {
    //   backgroundColor: "rgba(100, 95, 228, 0.32)"
    // }
}));

interface ICustomizedTabProps {
    tabList: Array<string>,
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export default function CustomizedTabs({ tabList, onChange }: ICustomizedTabProps) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        onChange(event, newValue);
        setValue(newValue);
    };

    return (
        <>

            <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
            >
                {tabList.map((e: any, index: number) => <StyledTab key={`${index}_${e}`} label={e} />)}
            </StyledTabs>

        </>
    );
}

/* Tab Panel */
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                { children }
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}