import React, { useEffect, useState } from "react";
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField';
import { useParams } from 'react-router-dom';
import * as WaterLvAPI from "api/useWaterLevelAPI";
import { usePageContext } from "./WaterLevelPageDevice";
import parseDateTime from "helpers/parseDateTime";
let TimeInterval: any

export function UpdateCurrentDataButton() {
    const [lastUpdateTime, setLastUpdateTime] = useState(parseDateTime(new Date()));
    const { stateContext, dispatchContext } = usePageContext();
    /* Get params */
    let { deviceID } = useParams<{ deviceID: string; }>();

    function onClickUpdate() {
        setLastUpdateTime(parseDateTime(new Date()));
        (async () => {
            const res = await WaterLvAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            const items = res.data.water_level.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
            const curSensor = items.find((e: any) => e.device_id === deviceID);
            dispatchContext!({ curSensorList: items, curSensorItem: curSensor });
        })();
    }

    const ONE_MINUTES = 1000 * 60;
    function setTimeInterval() {
        TimeInterval = setInterval(() => {
            onClickUpdate();
        }, ONE_MINUTES);
    }

    useEffect(() => {
        clearInterval(TimeInterval)
        setTimeInterval();
        return () => {
            clearInterval(TimeInterval)
        }
    }, [deviceID])
    return <LastUpdateTextField fullWidth datetime={lastUpdateTime} onClick={onClickUpdate} />;
}
