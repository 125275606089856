import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormGroup,
    Checkbox,
    Button,
    MenuItem
} from "@mui/material";
import { MenuOption } from "../MenuButton/MenuButtonView";
import useEditPermissionDialog from "./useEditPermissionDialog";
import { useDialogController } from "./useDialogController";

export interface DialogData {
    employeeID: string;
    employeeNumber: string;
    role: "Admin" | "User";
    permissions: string[];
    allPermission: { name: string, description: string }[];
}

export interface EditPermissionDialogProps {
    open: boolean;
    data: DialogData;
    handleClose: () => void;
    reloadfn: () => void;

}

const EditPermissionDialog: FunctionComponent<EditPermissionDialogProps> = (props) => {
    const { data, open, handleClose, reloadfn } = props;
    const { allPermission } = data;
    const {
        permissions,
        employeeNumber,
        selectedRole,
        selectedAllPermission,
        handleChangeRole,
        handleSelectPermission,
        handleAllPermission,
        handleEditUserPermission
    } = useEditPermissionDialog(data, reloadfn);



    return (
        <Dialog
            key={`edit-permission-dialog-${employeeNumber}`}
            open={open}
            onClose={handleClose}
            scroll={"body"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">แก้ไขผู้ใช้</DialogTitle>
            <DialogContent >
                <DialogContentText>Employee No.</DialogContentText>
                <DialogContentText>{employeeNumber}</DialogContentText>
                <DialogContentText>Role</DialogContentText>
                <DialogActions sx={{ justifyContent: "left" }}>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="role" name="row-radio-buttons-group" value={selectedRole}
                            onChange={handleChangeRole}
                        >
                            <FormControlLabel value="User" control={<Radio />} label="User" />
                            <FormControlLabel value="Admin" control={<Radio />} label="Admin" />
                        </RadioGroup>
                    </FormControl>
                </DialogActions>
                <DialogContentText>Dashboard Permission</DialogContentText>
                <DialogActions>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={selectedRole === "Admin" ? true : selectedAllPermission} onChange={handleAllPermission} name="all" />
                                }
                                label="เลือกทั้งหมด"
                                disabled={selectedRole === "Admin"}
                            />
                            {
                                allPermission?.map((permission: { name: string, description: string }, index: number) => {
                                    const isSelected = permissions?.includes(permission.name) || selectedRole === "Admin";
                                    return (
                                        <FormControlLabel
                                            key={`${index}_${permission.name}`}
                                            control={
                                                <Checkbox key={`${index}_${permission.name}_checkbox`} checked={isSelected} onChange={(event) => handleSelectPermission(event, permission.name)} name={permission.name} />
                                            }
                                            label={permission.description}
                                            disabled={selectedRole == "Admin"}
                                        />
                                    );
                                }
                                )
                            }

                        </FormGroup>
                    </FormControl>
                </DialogActions>
            </DialogContent>


            <DialogActions>
                {/* ยกเลิก */}
                <Button onClick={handleClose}>ยกเลิก</Button>
                {/* บันทึก */}
                <Button onClick={()=>{ 
                    handleEditUserPermission();
                    handleClose();
                }}>บันทึก</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditPermissionDialog;