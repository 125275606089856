import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const SaveButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: 'white',
    backgroundColor: '#0739A2',
    '&:hover': {
        borderColor: '#0739A2',
    },
}));
export const CancleButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: '#4C4C4C',
    '&:hover': {
        color: '#4C4C4C',
        // borderColor: 'red',
        // backgroundColor:'red' 
    },
}));
