import { TileLayer, useMap } from 'react-leaflet';
import * as Leaflet from 'leaflet';
import 'leaflet-easyprint';
import { useEffect } from 'react';
const DownloadButton = () => {
  const map = useMap();
  useEffect(() => {
    (Leaflet as any).easyPrint({
      exportOnly: true,
      title: 'My awesome print button',
      position: 'topleft',
      sizeModes: ['A4Portrait', 'A4Landscape'],
      hideControlContainer: true
    }).addTo(map);
    (Leaflet as any).easyPrint({
      title: 'My awesome print button',
      position: 'topleft',
      sizeModes: ['A4Landscape'],
      hideControlContainer: true
    }).addTo(map);
  }, [])

  return (
    <>
    </>
  );
};

export default DownloadButton;