import React from "react";
import styled from "styled-components";
import {
    Grid
} from "@mui/material";
import { HeaderTextLink } from "context/LayoutContext";

const Footer: React.FC<any> = () => {

  return <>
    <Container>
      <Grid direction="row" container rowSpacing={2} columnSpacing={2} columns={ { xs: 100,  sm: 100, md: 100,  lg: 100, xl: 100 } }> 
        <Grid item xs={100} sm={100} md={100} lg={100} xl={100} >
          <FooterContent>
            ผู้รับผิดชอบ นายสมศักดิ์ กล่ำกลาย ผู้อำนวยการฝ่ายปฏิบัติการเทคโนโลยีสารสนเทศ ผู้ดูแลเว็บไซต์ นางสาวนิดา มุกลีมาศ หบดน4-ห., กบดน-ห., อปท., รวห. Email: <u style={{color: "#6099e1"}}>wanida.m@egat.co.th</u>
          </FooterContent>
        </Grid>
      </Grid>
    </Container>
  </>
}

export default Footer

const Container = styled.div`
  margin-left: 10px;
`

const FooterContent = styled.div`
  padding-top: 20px;
  font-size: 14px;
  text-align: right;
`