import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import React, { useEffect, useContext, useState, FunctionComponent } from "react";
import type { RouteComponentProps } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Button, { ButtonProps } from '@mui/material/Button';
import NormalText from 'constants/TextStyles';
import styled from 'styled-components'
import { Box, Paper, Typography } from "@mui/material";

// component map
import { CurrentDataList, SortByOptions } from "./CurrentDataList";
import * as dateFns from 'date-fns';
import { UpdateCurrentDataButton } from "./UpdateCurrentDataButton";
import { TruckMapContent } from "./TruckMapContent";
import ConveyerFileUploadDialog from 'components/Dialog/ConveyerFileUpload'

/* API */
import * as TruckAPI from "api/useTruckAPI";
import { ISensorItem } from "api/useTruckAPI";
import useUserContext from "context/UserContext";
import { ITruckConfigItem } from "api/useConfigSettingAPI";
import SnackbarUtils from "helpers/utils";
import SortBySelector from "components/SelectBox/SortBySelector";

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { DeviceTable } from "./DeviceTable";
import SummaryTable from "./SummaryTable";
import { RelProfileDumptruck } from 'components/Datetime/DatePickerRelativeProfile';
import CountingTableByTimeRange from "./CountingTable/CountingTableByTimeRange";
import TruckCountingPanel from "./TruckCountingPanel";
import QrMappingDialogButton from "./QrMapping/QrMappingDialogButton";
import { useQrMapping } from "./useQrMapping";
import DefaultDateTimeRange from "constants/DefaultDateTimeRage";
import { DEVICES } from "api/useRequest";
import TruckTimeline from "./TruckTimeline";

interface IPageContext {
    curSensorList: ISensorItem[];
    curSensorItem: ISensorItem;
    curConfigList: ITruckConfigItem[];
    allSensorList: ISensorItem[];
    dateRange: Date[];
}

const defaultContext: IPageContext = {
    curSensorList: [],
    curSensorItem: TruckAPI.defaultSensorItem,
    curConfigList: [],
    allSensorList: [],
    dateRange: DefaultDateTimeRange[DEVICES.TRUCK](new Date()),
}
const pageContext = React.createContext<{ stateContext: IPageContext, dispatchContext?: React.Dispatch<any>; }>({ stateContext: defaultContext });

export const usePageContext = () => useContext(pageContext);

export const HEADER_TEXT = "ระบบตรวจนับรถบรรทุกเทท้าย"
const TruckPage: React.FC<RouteComponentProps> = () => {
    /* Create global state in page */
    const [stateContext, dispatchContext] = React.useReducer((state: IPageContext, action: any) => {
        return { ...state, ...action }
    }, defaultContext);
    return (
        <pageContext.Provider value={{ stateContext, dispatchContext }}>
            <TruckPageContent />
        </pageContext.Provider>
    );
}

export default TruckPage

const TruckPageContent: FunctionComponent<{}> = () => {
    /* Set page header */
    const dispatch = useLayoutDispatch()
    const { stateContext, dispatchContext } = usePageContext();
    const isAdmin = useUserContext().permission?.permissions.includes('admin');
    const [countOnline, setCountOnline] = useState<string>("");
    const [sortTypeCurData, setSortTypeCurData] = useState('Device Name');
    const [isLoadingCurrentData, setIsLoadingCurrentData] = useState(true);
    const [openUploadDilog, setOpenUploadDilog] = React.useState(false);

    const {
        qrMaps,
        getMapQrTruck,
        handleAddQrMapItem,
        handleEditQrMapItem,
        handleDeleteQrMapItem,
    } = useQrMapping();

    const getDeviceDataByTime = async (value: Date[]) => {
        TruckAPI.reqDeviceData(value)
            .then((res: any) => {
                const { items } = res.data;
                stateContext.allSensorList = items;
                dispatchContext!({ allSensorList: items });
            }).catch((err: any) => {

            });
    }

    const getCurrentDeviceData = async () => {
        setIsLoadingCurrentData(true)
        TruckAPI.reqCurDeviceData()
            .then((res: any) => {
                const items = res.data.dump_truck.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
                const online = items.filter((e: any) => (e.state === 'Online')).length;
                setCountOnline(`${online}/${items.length}`)
                dispatchContext!({ curSensorList: items });
                setIsLoadingCurrentData(false)
            }).catch((err: any) => {
                setIsLoadingCurrentData(false)
            });
    }

    useEffect(() => {
        changeHeaderText(dispatch, HEADER_TEXT);
        getDeviceDataByTime(stateContext.dateRange);
        getCurrentDeviceData();
        getMapQrTruck();
    }, [])

    const onDateTimeChange = (value: Date[]) => {
        console.log('date time change value', value);

        dispatchContext!({ dateRange: value });
        getDeviceDataByTime(value);

    };

    return (
        <>
            <ConveyerFileUploadDialog isOpen={openUploadDilog} setOpen={setOpenUploadDilog} reFlashData={() => { /** update context global variable here. */ }} />
            <DashboardContainer>
                <Grid
                    width="100%"
                    height="100%"
                    container
                    direction="column"
                    rowSpacing={2}
                    columnSpacing={2}
                    columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                >
                    {/* <HeaderRow /> */}
                    <Grid
                        item
                        container
                        direction="row"
                        columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                    >
                        <Grid item xs={100} sm={100} md={30} lg={30} xl={30} container alignItems="center" >
                            <NormalText>
                                {HEADER_TEXT}
                            </NormalText>
                        </Grid>
                        <Grid item xs={100} sm={100} md={70} lg={70} xl={70}>
                            <Grid container rowSpacing={5} columnSpacing={5} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} justifyContent="flex-end">
                                <Grid item xs={100} sm={100} md={100} lg={100} xl={100} container justifyContent="flex-end" >
                                    <UpdateCurrentDataButton />
                                    {isAdmin ? <>
                                        <div style={{ marginLeft: "10px" }} />
                                        <QrMappingDialogButton
                                            qrMaps={qrMaps}
                                            onAddQrMapItem={handleAddQrMapItem}
                                            onEditQrMapItem={handleEditQrMapItem}
                                            onDeleteQrMapItem={handleDeleteQrMapItem}
                                        />
                                    </> : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <MapRow /> */}
                    <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} >
                        <Grid item xs={100} sm={100} md={50} lg={70} xl={70} maxHeight="500px">
                            <TruckMapContent />
                        </Grid>
                        <Grid
                            item
                            xs={100} sm={100} md={50} lg={30} xl={30}
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            alignContent="stretch"
                            bgcolor={'#fff'}
                        // maxHeight="500px"
                        >
                            {!isLoadingCurrentData ? <Grid item width={'100%'}
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                alignContent="stretch"
                                wrap="nowrap"
                                padding="16px"
                                paddingBottom="0"

                            >
                                <Typography variant="body2" color="initial" fontWeight={600} >อุปกรณ์ออนไลน์ {countOnline}</Typography>
                                <SortBySelector label="เรียงลำดับ"
                                    value={sortTypeCurData}
                                    options={Object.keys(SortByOptions)}
                                    onSelectedChange={(opt: string) => {
                                        setSortTypeCurData(opt)
                                    }} />
                            </Grid> : <Grid item width={'100%'}
                                padding="16px"
                                paddingBottom="0"></Grid>}
                            <Grid item width={'100%'}   >
                                <CurrentDataList
                                    sortby={sortTypeCurData}
                                />
                            </Grid>
                        </Grid>
                    </Grid>


                    {/* <TableZone /> */}
                    <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
                        <DeviceDataTab
                            tabName={["สรุปจำนวนรถเทท้าย", "ไทม์ไลน์", "ข้อมูลดิบ"]}
                            tabElement={[
                                <TruckCountingPanel
                                    date={stateContext.dateRange[0]}
                                    dataSource={stateContext.allSensorList}
                                    deviceNameList={stateContext.curSensorList.map((e: ISensorItem) => e.device_name)}
                                    qrMaps={qrMaps}
                                />,
                                <TruckTimeline dateRange={stateContext.dateRange} />,
                                <DeviceTable qrMaps={qrMaps} />
                            ]}
                            tabWithDatePicker={[0, 1, 2]}
                            dateTimeRange={stateContext.dateRange}
                            onDateTimeChange={onDateTimeChange}
                            relativeProfile={RelProfileDumptruck}
                            daySelector
                            dateOnly
                            noZoom
                        />
                    </Grid >


                </Grid>
            </DashboardContainer>
        </>
    );
}



const DashboardContainer = styled.div`
  margin-left: 10px;
`

export const MapBox = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        height: "500px"
    },
    [theme.breakpoints.down('md')]: {
        height: "300px"
    },
}));

const UploadFileMainConveyer = styled(Button)<ButtonProps>(({ theme }) => ({
    borderColor: '#0739A2',
    color: '#0739A2',
    '&:hover': {
        color: '#0739A2',
        borderColor: '#0739A2',
        backgroundColor: 'rgba(25,118,210,0.04)'
    },
}));
