import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CommentIcon from '@mui/icons-material/Comment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography'


interface CommentDialogProps {
    title: string;
    comment: string;
    onSave?: (text: string) => void;
}

const CommentDialog: React.FunctionComponent<CommentDialogProps> = (props) => {
    const {
        title,
        comment,
        onSave,
    } = props;
    const [open, setOpen] = React.useState(false);
    const [isEdited, setIsEdited] = React.useState(false);
    const [commentText, setCommentText] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
        setCommentText(comment);
        setIsEdited(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        if (onSave !== undefined) {
            onSave(commentText);
        }
        handleClose();
    };

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setIsEdited(true);
    };

    return (
        <div>
            {/* <Button size='small' variant="contained" color="primary">
                <IconButton children={<CommentIcon />} onClick={handleClickOpen} />
                <Typography variant="body1" color="initial">คอมเมนต์</Typography>
            </Button> */}
            <Tooltip title="คอมเมนต์">
                <IconButton children={<CommentIcon />} onClick={handleClickOpen} />
            </Tooltip>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth
                onClose={handleClose}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <TextareaAutosize
                        maxRows={4}
                        minRows={4}
                        defaultValue={commentText}
                        style={{
                            width: "95%",
                        }}
                        onChange={handleTextAreaChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={isEdited === false} onClick={handleSave}>บันทึก</Button>
                    <Button onClick={handleClose}>ปิด</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CommentDialog;