import React, { FunctionComponent } from 'react';
import { CSVLink } from "react-csv";

export interface IExportHeader {
    label: string;
    key: string;
}
export interface IExportButtonProps {
    buttonLabel: string;
    fileName: string;
    headers: IExportHeader[];
    datas: any;
    onClick: () => void;
}

const ExportButton: FunctionComponent<IExportButtonProps> = (props) => {
    const { buttonLabel, fileName, headers, datas, onClick } = props;
    let date: string = new Date().toLocaleDateString()
    const csvReport = {
        data: [...datas],
        headers: headers,
        filename: `${fileName}_${date}.csv`
    };


    return (
        <CSVLink {...csvReport} style={{ height: '100%', color: "white", textDecoration: "none" }}
            onClick={onClick}
        >
            {buttonLabel}
            {props.children}
        </CSVLink>
    );
}

export default ExportButton;