import * as React from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import styled from "styled-components";
import { Typography } from "@mui/material";

interface IDataProps {
  datetime: string;
  fullWidth?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function LastUpdateTextField(props: IDataProps) {
  var lastUpdatedDatetime = "อัพเดตล่าสุด : " + props.datetime;
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        width: props.fullWidth ? "100%":"fit-content",
        padding: props.fullWidth ? 0:"4px 10px",
      }}
    >
      <IconButton aria-label="update" size="small" onClick={props.onClick}>
        <CachedIcon />
      </IconButton>
      {lastUpdatedDatetime}
    </Paper>
  );
}

const InputText = styled(Typography)`
  margin-left: 1px;
  flex: 1;
  margin-right: 10px;
  font-size: small;
`
