import requestInstance from "configure/requestConfig";
import { formatDate } from "../helpers/formatDate";
import { DEVICES } from "./useRequest";


export interface NotifyGroupItem {
    id: number;
    name: string;
    device_ids: string[];
    deivce_type: string;
    warning_threshold: number;
    critical_threshold: number;
}

export function deviceTypeToNotiTypeStr(deviceType: DEVICES | string) {
    let retVal = "";
    switch (deviceType) {
        case DEVICES.ARSENIC:
            retVal = "arsenic";
            break;
        case DEVICES.CONVEYOR:
            retVal = "conveyor";
            break;
        case DEVICES.DUMPTRUCK:
        case DEVICES.TRUCK:
            retVal = "dump_truck_counter";
            break;
        case DEVICES.GPS:
        case DEVICES.GPSTRACKING:
            retVal = "gps_tracker";
            break;
        case DEVICES.LANDSLIDE:
            retVal = "landslide";
            break;
        case DEVICES.PIEZO:
        case DEVICES.PIEZOMETER:
            retVal = "ground_water_pressure";
            break;
        case DEVICES.WATERLEVEL:
            retVal = "water_level";
            break;
        case DEVICES.WEATHER:
            retVal = "weather_station";
            break;
        default:
            retVal = deviceType;
            break;
    }
    return retVal;
}
export const getAllDeviceNotification = async () => {
    let path = "/api/device_notification";
    return requestInstance.get(path);
}
export const getDeviceNotification = async (deviceType: DEVICES | string) => {
    let type: string = deviceTypeToNotiTypeStr(deviceType);
    let path = `/api/device_notification/${type}`;
    return requestInstance.get(path);
}

export interface SetNotiBody {
    device_ids: string[];
    warning_threshold: number;
    critical_threshold: number;
}

export const setDeviceNotification = async (deviceType: DEVICES | string, body: SetNotiBody) => {
    let type: string = deviceTypeToNotiTypeStr(deviceType);
    let path = `/api/device_notification/${type}`;
    return requestInstance.post(path, body);
}

export const editDeviceNotification = async (deviceType: DEVICES | string, id: number, body: SetNotiBody) => {
    let type: string = deviceTypeToNotiTypeStr(deviceType);
    let path = `/api/device_notification/${type}/${id}`;
    return requestInstance.patch(path, body);
}

export const deleteDeviceNotification = async (deviceType: DEVICES | string, id: number) => {
    let type: string = deviceTypeToNotiTypeStr(deviceType);
    let path = `/api/device_notification/${type}/${id}`;
    return requestInstance.delete(path);
}


export const getArsenicDeviceNotification = async () => {
    let type: string = deviceTypeToNotiTypeStr(DEVICES.ARSENIC);
    let path = `/api/device_notification/${type}`;
    return requestInstance.get(path);
}

export const editArsenicDeviceNotification = async (id: string, body: any) => {
    let type: string = deviceTypeToNotiTypeStr(DEVICES.ARSENIC);
    let path = `/api/device_notification/${type}/${id}`;
    return requestInstance.patch(path, body);
}