import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';
import styledComponent from "styled-components";
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';
import { usePageContext } from 'pages/sensors/landslide/LandSlidePage';

export interface Data {
  device_id: string;
  device_name: string;
  power_pri: number;
  power_sec: number;
  status: number;
  top_x: number;
  top_y: number;
  top_z: number;
  bottom_x: number;
  bottom_y: number;
  bottom_z: number;
  gnss_status: number;
  latitude: string;
  longitude: string;
  atitude: string;
  h_accuracy: number;
  v_accuracy: number;
  satelite_num: number;
  p_dop: number;
  state: string;
  mine_n: string;
  mine_e: string;
  diff2d: number;
  diff3d: number;
  gnss_fix_type: number;
  gnss_fix_type_name: string;
  gnss_rtk_solution: number;
  gnss_rtk_solution_name: string;
  timestamp: string;
}

export function createData(
  device_id: string,//
  device_name: string,//
  power_pri: number,//
  power_sec: number,//
  status: number,//
  top_x: number,//
  top_y: number,//
  top_z: number,//
  bottom_x: number,//
  bottom_y: number,//
  bottom_z: number,//
  gnss_status: number,//
  latitude: string,//
  longitude: string,//
  atitude: string,//
  h_accuracy: number,//
  v_accuracy: number,//
  satelite_num: number,//
  p_dop: number,//
  state: string,//
  mine_n: string,//
  mine_e: string,//
  diff2d: number,//
  diff3d: number,//
  gnss_fix_type: number,//
  gnss_fix_type_name: string,//
  gnss_rtk_solution: number,//
  gnss_rtk_solution_name: string,//
  timestamp: string,//
): Data {
  return {
    device_id,
    device_name,
    power_pri,
    power_sec,
    status,
    top_x,
    top_y,
    top_z,
    bottom_x,
    bottom_y,
    bottom_z,
    gnss_status,
    latitude,
    longitude,
    atitude,
    h_accuracy,
    v_accuracy,
    satelite_num,
    p_dop,
    state,
    mine_n,
    mine_e,
    diff2d,
    diff3d,
    gnss_fix_type,
    gnss_fix_type_name,
    gnss_rtk_solution,
    gnss_rtk_solution_name,
    timestamp,
  };
}



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'device_id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'device_name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: 'Online / Offline',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Last Updated',
  },
  {
    id: 'latitude',
    numeric: true,
    disablePadding: false,
    label: 'Latitude',
  },
  {
    id: 'longitude',
    numeric: true,
    disablePadding: false,
    label: 'Longitude',
  },
  {
    id: 'mine_n',
    numeric: true,
    disablePadding: false,
    label: 'Mine N',
  },
  {
    id: 'mine_e',
    numeric: true,
    disablePadding: false,
    label: 'Mine E',
  },
  {
    id: 'atitude',
    numeric: true,
    disablePadding: false,
    label: 'Attitude',
  },
  {
    id: 'diff2d',
    numeric: true,
    disablePadding: false,
    label: 'Diff 2D',
  },
  {
    id: 'diff3d',
    numeric: true,
    disablePadding: false,
    label: 'Diff 3D',
  },
  {
    id: 'top_x',
    numeric: true,
    disablePadding: false,
    label: 'Top x',
  },
  {
    id: 'top_y',
    numeric: true,
    disablePadding: false,
    label: 'Top y',
  },
  {
    id: 'top_z',
    numeric: true,
    disablePadding: false,
    label: 'Top z',
  },
  {
    id: 'bottom_x',
    numeric: true,
    disablePadding: false,
    label: 'Bottom x',
  },
  {
    id: 'bottom_y',
    numeric: true,
    disablePadding: false,
    label: 'Bottom y',
  },
  {
    id: 'bottom_z',
    numeric: true,
    disablePadding: false,
    label: 'Bottom z',
  },
  {
    id: 'h_accuracy',
    numeric: true,
    disablePadding: false,
    label: 'Vertical Acc',
  },
  {
    id: 'v_accuracy',
    numeric: true,
    disablePadding: false,
    label: 'Horizontal Acc',
  },
  {
    id: 'satelite_num',
    numeric: true,
    disablePadding: false,
    label: 'Satelite Number',
  },
  {
    id: 'p_dop',
    numeric: true,
    disablePadding: false,
    label: 'P dot',
  },
  {
    id: 'power_pri',
    numeric: true,
    disablePadding: false,
    label: 'Power',
  },
  {
    id: 'power_sec',
    numeric: true,
    disablePadding: false,
    label: 'Solar',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'gnss_status',
    numeric: true,
    disablePadding: false,
    label: 'GNSS status',
  },
  {
    id: 'gnss_fix_type',
    numeric: true,
    disablePadding: false,
    label: 'GNSS fix type',
  },
  {
    id: 'gnss_fix_type_name',
    numeric: false,
    disablePadding: false,
    label: 'GNSS fix type name',
  },
  // {
  //   id: 'gnss_rtk_solution',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'GNSS rtk solution',
  // },
  // {
  //   id: 'gnss_rtk_solution_name',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'GNSS rtk solution name',
  // },



];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={true}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const curent_csv_headers: { label: string, key: string }[] = [
  { label: "device_id", key: "device_id" },
  { label: "device_name", key: "device_name" },
  { label: "power_pri", key: "power_pri" },
  { label: "power_sec", key: "power_sec" },
  { label: "status", key: "status" },
  { label: "top_x", key: "top_x" },
  { label: "top_y", key: "top_y" },
  { label: "top_z", key: "top_z" },
  { label: "bottom_x", key: "bottom_x" },
  { label: "bottom_y", key: "bottom_y" },
  { label: "bottom_z", key: "bottom_z" },
  { label: "gnss_status", key: "gnss_status" },
  { label: "latitude", key: "latitude" },
  { label: "longitude", key: "longitude" },
  { label: "atitude", key: "atitude" },
  { label: "h_accuracy", key: "h_accuracy" },
  { label: "v_accuracy", key: "v_accuracy" },
  { label: "satelite_num", key: "satelite_num" },
  { label: "p_dop", key: "p_dop" },
  { label: "state", key: "state" },
  { label: "mine_n", key: "mine_n" },
  { label: "mine_e", key: "mine_e" },
  { label: "diff2d", key: "diff2d" },
  { label: "diff3d", key: "diff3d" },
  { label: "gnss_fix_type", key: "gnss_fix_type" },
  { label: "gnss_fix_type_name", key: "gnss_fix_type_name" },
  { label: "gnss_rtk_solution", key: "gnss_rtk_solution" },
  { label: "gnss_rtk_solution_name", key: "gnss_rtk_solution_name" },
  { label: "timestamp", key: "timestamp" },

];


const CsvReport = {
  data: [{}],
  headers: curent_csv_headers,
  filename: 'Current_devices_data.csv'
};

const EnhancedTableToolbar = () => {
  const [csvReport, setCsvReport] = React.useState<any>(CsvReport)
  const { stateContext, dispatchContext } = usePageContext();
  React.useEffect(() => {
    setCsvReport(
      {
        data: stateContext.curSensorList,
        headers: curent_csv_headers,
        filename: 'Current_devices_data.csv'
      }
    )
  }, [stateContext.curSensorList])
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >

      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
        align="left"
        fontSize="16px"
        marginLeft="20px"
      >
        รายละเอียดอุปกรณ์ตรวจจับความเคลื่อนตัวชั้นหน้าดิน
      </Typography>

      <Tooltip title="Search">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="ค้นหา..."
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
      </Tooltip>
      <CSVLink {...csvReport}>
        <DownloadIcon
          sx={{ color: "black", cursor: "pointer" }} />
      </CSVLink>
    </Toolbar>
  );
};


export default function TopsoilDeviceDetailTable({ rows }: { rows: Data[] }) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('device_id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };




  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  return (
                    <TableRow>
                      <TableCell align="right">{row.device_id}</TableCell>
                      <TableCell align="left">{row.device_name}</TableCell>
                      {
                        row.state === 'Offline' ? (
                          <TableCell align="right"><BoxRed />{row.state}</TableCell>
                        ) : (
                          <TableCell align="right"><BoxGreen />{row.state}</TableCell>
                        )
                      }
                      <TableCell align="right">{row.timestamp}</TableCell>
                      <TableCell align="right">{row.latitude}</TableCell>
                      <TableCell align="right">{row.longitude}</TableCell>
                      <TableCell align="right">{row.mine_n}</TableCell>
                      <TableCell align="right">{row.mine_e}</TableCell>
                      <TableCell align="right">{row.atitude}</TableCell>
                      <TableCell align="right">{row.diff2d}</TableCell>
                      <TableCell align="right">{row.diff3d}</TableCell>
                      <TableCell align="right">{row.top_x}</TableCell>
                      <TableCell align="right">{row.top_y}</TableCell>
                      <TableCell align="right">{row.top_z}</TableCell>
                      <TableCell align="right">{row.bottom_x}</TableCell>
                      <TableCell align="right">{row.bottom_y}</TableCell>
                      <TableCell align="right">{row.bottom_z}</TableCell>
                      <TableCell align="right">{row.h_accuracy}</TableCell>
                      <TableCell align="right">{row.v_accuracy}</TableCell>
                      <TableCell align="right">{row.satelite_num}</TableCell>
                      <TableCell align="right">{row.p_dop}</TableCell>
                      <TableCell align="right">{row.power_pri}</TableCell>
                      <TableCell align="right">{row.power_sec}</TableCell>
                      <TableCell align="right">{row.status}</TableCell>
                      <TableCell align="right">{row.gnss_status}</TableCell>
                      {(row.gnss_rtk_solution === 0)
                        ? (<TableCell align="right">{row.gnss_fix_type}</TableCell>)
                        : (<TableCell align="right">{row.gnss_rtk_solution}</TableCell>)}
                      {(row.gnss_rtk_solution === 0)
                        ? (<TableCell align="right">{row.gnss_fix_type_name}</TableCell>)
                        : (<TableCell align="right">{row.gnss_rtk_solution_name}</TableCell>)}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


const BoxRed = styledComponent.div`
  float: left;
  margin-top: 10px;
  margin-left: 50px;
  height: 5px;
  width: 5px;
  border: 1px solid solid;
  background-color: red;
`

const BoxGreen = styledComponent.div`
  float: left;
  margin-top: 10px;
  margin-left: 50px;
  height: 5px;
  width: 5px;
  border: 1px solid solid;
  background-color: green;
`