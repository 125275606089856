import * as React from 'react';
import {
  Box,
} from '@mui/material/';

import { SelectChangeEvent } from "@mui/material";

import styled from 'styled-components'
import { styled as styledMui } from '@mui/material/styles';

import {
  MenuItem,
  InputBase,
  Select,
  FormControl
} from '@mui/material';

const SelectDeviceInput = styledMui(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '5px 16px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

interface Option {
  name: string;
  value: string;
}

interface ISelectBoxProps {
  label?: string;
  height?: string;
  width?: string;
  selected: string;
  Option: Option[];
  onChange?: (event: SelectChangeEvent) => void;
}

export default function SelectDevice(props: ISelectBoxProps) {
  const height = props.height || "";
  const width = props.width || "";
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: "center",
      width: 'fit-content'
    }}>
      <Label>{props.label === undefined ? "อุปกรณ์" : props.label}</Label>
      <FormControl >

        <Select
          sx={{ height: { height }, width: { width } }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.selected}
          input={<SelectDeviceInput />}
          onChange={props.onChange}
        >
          {
            props.Option.map((e: any, index: number) => {
              return <MenuItem key={`${index}_${e.name}`} value={e.value} >{e.name}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </Box>
  );
}

const Label = styled.span`
  padding-right: 10px;
  width: 'fit-content';
`