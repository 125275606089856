import React from "react";
import styled, { css } from "styled-components";
import sideBarIcon from '../../assets/icons/icon-double-arrow-down.svg';
import { SideDrawerProps } from "../Sidebar/Sidebar";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import useUserContext from "context/UserContext";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {
    AppBar,
    Toolbar,
    IconButton,
    Icon,
    Typography,
    Link,
} from "@mui/material";
import { HeaderTextLink } from "context/LayoutContext";

interface ElevationScrollProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}

function ElevationScroll(props: ElevationScrollProps) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 1,
    });
}

interface Props {
    layoutState: {
        headerText: string | HeaderTextLink
        isSidebarOpened: boolean
    },
    toggleSidebar: () => void
}

const Header: React.FC<Props> = (props) => {
    const { layoutState, toggleSidebar } = props;
    const userContext = useUserContext().auth;
    const isLoggedIn = userContext?.access_token !== undefined;
    return (
        <React.Fragment>
            <ElevationScroll {...props}>
                <AppBarHeader position="fixed">
                    <ToolBarHeader open={layoutState.isSidebarOpened}>
                        <ToggleSideBar
                            color="inherit"
                            aria-label="open sidebar"
                            onClick={() => toggleSidebar()}
                            edge="start"
                            sx={{ ...(layoutState.isSidebarOpened && { display: 'none' }) }}
                        >
                            <ArrowOpenIcon_Header>
                                <img src={sideBarIcon}></img>
                            </ArrowOpenIcon_Header>
                        </ToggleSideBar>
                        {Header(layoutState.headerText)}
                        <Grow />
                        <ProfileHeader
                            name={userContext?.eno ? userContext?.eno.toString() : ""}
                            eno={userContext?.eno ? userContext?.eno.toString() : ""} isLoggedIn={isLoggedIn} email={"daniel@deaware.com"} />
                    </ToolBarHeader>
                </AppBarHeader>
            </ElevationScroll>
        </React.Fragment>
    )

    function Header(headerText: string | HeaderTextLink) {
        if (typeof headerText === "string") {
            return <HeaderText variant="body1">
                {layoutState.headerText}
            </HeaderText>;
        }
        const { texts, link } = headerText;
        return (
            <>
                {texts.map((e: any, index: number) => {
                    if (e === texts[0]) {
                        return <HeaderLink key={`${index}_${"HeaderLink"}`} href={link}>
                            {e}
                        </HeaderLink>
                    }
                    return <HeaderText key={`${index}_${"HeaderText"}`} variant="body1">
                        {` > ${e}`}
                    </HeaderText>;
                })}
            </>
        );

    }
}

export default Header

const AppBarHeader = styled(AppBar)`
    width: 100%;
    height: 64px;
    ${(props: any) => props.theme.breakpoints.down("sm")} {
        height: 48px;
    };
    background: #FAFAFA;
    z-index: ${(props: any) => props.theme.zIndex.drawer + 0};
    transition: ${(props: any) => props.theme.transitions.create(["margin"], {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen
})};
`

const ToolBarHeader = styled(Toolbar).attrs((props: SideDrawerProps) => ({
    open: props.open,
})) <SideDrawerProps>`
    ${(props) => props.open ? `
    width: calc(100vw - ${props.theme.sidebarWidth}px - 36px);
    margin-left: ${props.theme.sidebarWidth}px;
    transition: ${props.theme.transitions.create(['margin', 'width'], {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.enteringScreen,
})};
    ` : 'width: calc(100vw - 36px)'};
    transition: ${(props) => props.theme.transitions.create(['margin', 'width'], {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen,
})};
    
    padding-left: ${(props: any) => props.theme.spacing(2)};
    padding-right: ${(props: any) => props.theme.spacing(2)};
    min-height: 64px;
    ${(props: any) => props.theme.breakpoints.down("sm")} {
        min-height: 48px;
        width: calc(100vw - 36px);
        margin-left: 0;
    };
`
const ToggleSideBar = styled(IconButton)`
    ${(props: any) => props.theme.breakpoints.down("sm")} {
      margin-left: 0;
    };
    padding: ${(props: any) => props.theme.spacing(0.5)};
    margin-right: ${(props: any) => props.theme.spacing(2)};
    margin-left: 0;
`
const headerIcon = css`
    font-size: 28;
    color: "rgba(255, 255, 255, 0.35)";
    color: "white";
`
const ArrowOpenIcon_Header = styled(Icon)`
    ${headerIcon};
    transform: rotate(180deg);
`

const HeaderText = styled(Typography)`
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    line-height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
`
const HeaderLink = styled(Link)`
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    line-height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
`
const Grow = styled.div`
    flex-grow: 1
`
