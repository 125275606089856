import { DEVICES } from "api/useRequest";
import { LatLngExpression } from "leaflet";
import { FunctionComponent, useState } from "react";
import { Marker, Popup, Tooltip, useMap, useMapEvents } from "react-leaflet";
import * as Leaflet from 'leaflet';
import IconMarker from "./IconMarker";
import TooltipMarker from "./TooltipMarker";

interface SensorMarkerProps {
    type: DEVICES;
    state: string;
    position: LatLngExpression;
    label: string;
    data?: any;
    isSelected?: boolean;
    onClick?: Leaflet.LeafletMouseEventHandlerFn;
    onAdd?: (map: Leaflet.Map, e: Leaflet.LeafletEvent) => void;
    conveyorStatus?: boolean;
}

const SensorMarker: FunctionComponent<SensorMarkerProps> = (props) => {
    const { type, state, position, label, isSelected = false, conveyorStatus } = props;
    const map = useMap();
    let icon = (Leaflet as any).divIcon({
        html: IconMarker(type, state, label, isSelected, conveyorStatus),
        tooltipAnchor: [0, 0],
        className: {
            color: 'null',
            border: 0
        }
    })

    return (
        <Marker position={position} icon={icon} eventHandlers={{
            click: (e) => {
                if (props.onClick !== undefined) {
                    props.onClick(e);
                }
            },
            add: (e) => {

                if (props.onAdd !== undefined) {
                    props.onAdd(map, e);
                }
            }
        }} >
            {props.data && <TooltipMarker type={props.type} data={props.data} />}
        </Marker>
    );
}

export default SensorMarker;

