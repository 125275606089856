import { FunctionComponent, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { OutlinedInput } from '@mui/material';

interface SortBySelectorProps {
    label: string;
    value: string;
    options: string[];
    onSelectedChange: (option: string) => void;
}

const SortBySelector: FunctionComponent<SortBySelectorProps> = (props) => {
    const [selectedItem, setSelectedItem] = useState("");
    const handleChange = (event: SelectChangeEvent) => {
        setSelectedItem(event.target.value as string);
        props.onSelectedChange(event.target.value as string)
    };
    return (
        <Box sx={{ minWidth: 110, }}>
            <FormControl fullWidth size="small">
                <InputLabel id="sort-by-options" margin='dense' >{props.label}</InputLabel>
                <Select
                    margin='dense'
                    labelId="sort-by-options"
                    id="sort-by-options"
                    value={props.value}
                    label={props.label}
                    onChange={handleChange}
                // input={<OutlinedInput sx={{ padding: 0 }} />}
                >
                    {props.options.map((opt: string, index: number) => (<MenuItem key={`${index}_${opt}`} value={opt}>{opt}</MenuItem>))}
                </Select>
            </FormControl>
        </Box>);
}
export default SortBySelector;
