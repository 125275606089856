import React, { useState, useEffect, useRef } from 'react';
import {
  useHistory
} from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  TextField,
  Stack,
  Grid,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  Switch,
  TextareaAutosize
} from '@mui/material/';

/**
 * API
 */
import {
  ListNotication,
  useListNotication,
  ReqLineCreate,
  useCreateNotication,
  useGetNoticationConfig,
} from "api/useRequest"

/**
 * Localstorage
 */
import {
  linitifyStorage,
  linenotifyStorageData,
  SETTINGPAGE
} from 'configure/localStorageConfig'

import icon_line from 'assets/icons/lineLogo/Line-Logo.svg'

interface IDialogProps {
  isOpen: boolean;
  setOpen: any;
  reFlashData: any;
  NotificationsOption: ListNotication[];
  DefaultOption: boolean[];
}

export default function LineSettingDialog(props: IDialogProps) {
  let history = useHistory();
  const ListnotiApi = useListNotication()
  const NotiConfigApi = useGetNoticationConfig()
  const CreateNotiApi = useCreateNotication()
  const [EnableNoti, setEnableNoti] = React.useState<boolean>(false)
  const [NoteNoti, setNoteNoti] = React.useState<string>('')
  const [TokenNoti, setTokenNoti] = React.useState<string>('')
  const [NotificationsOption, setNotificationsOption] = React.useState<ListNotication[]>([]);

  const [HaveToken, setHaveToken] = React.useState<boolean>(false)

  React.useLayoutEffect(() => {

    return
  })

  React.useEffect(() => {
    (() => {
      ListnotiApi.mutate(null, {
        onSuccess: (data) => {
          var local: linenotifyStorageData = linitifyStorage.get()
          var result: ListNotication[] = []
          if (local.from === SETTINGPAGE.NOTI) {
            result = data?.data.map((e: any, i: number) => {
              return {
                ID: e.ID,
                name: e.name,
                key: e.key,
                description: e.description,
                check: local.setting[i].check,
              }
            })
            if (local.notiToken !== "") {
              setTokenNoti(local.notiToken)
              setHaveToken(true)
              setNoteNoti(local.note)
              setEnableNoti(local.enable)
            }
          } else {
            result = data?.data.map((e: any, i: number) => {
              return {
                ID: e.ID,
                name: e.name,
                key: e.key,
                description: e.description,
                check: false
              }
            })
            var Ldata: linenotifyStorageData = {
              from: local.from,
              setting: result,
              notiToken: local.notiToken,
              note: local.note,
              enable: local.enable
            }
            linitifyStorage.set(Ldata)
          }
          setNotificationsOption(Object.assign([], result))
        }
      })
    })()
  }, [])

  const handleClose = () => {
    props.setOpen(false)

    var lineInitData: linenotifyStorageData = {
      from: SETTINGPAGE.EMAIL,
      setting: [],
      notiToken: "",
      note: "",
      enable: false
    }
    linitifyStorage.set(lineInitData)
  };

  const handleClickGotoLineNotiPage = () => {
    // https://notify-bot.line.me/oauth/authorize?response_type=code&scope=notify&client_id=EUZEZTc3MJ3ZLxqa90LrSE&state=6&redirect_uri=http://dwmonitors.site/Callback
    // history.push(`/line_noti?code=aONfXtULWs3XPfnhOmfiJ1&state=6`)

    NotiConfigApi.mutate(null, {
      onSuccess: (data) => {
        console.log("NotiConfigApi: ", data)
        var client_id: string = data.data.client_id
        var client_secret: string = data.data.client_secret
        var redirectURL: string | undefined = process.env.REACT_APP_LINE_NOTI_REDIRECT_URL
        var notiRedirect: string = `https://notify-bot.line.me/oauth/authorize?response_type=code&scope=notify&client_id=${client_id}&state=6&redirect_uri=${redirectURL}`
        window.location.replace(notiRedirect);
      },
      onError: (error) => {
        console.log(error)
      }
    })
    // var notiRedirect: string = `https://notify-bot.line.me/oauth/authorize?response_type=code&scope=notify&client_id=EUZEZTc3MJ3ZLxqa90LrSE&state=6&redirect_uri=http://dwmonitors.site/Callback`
  }

  const clear = () => {
    setEnableNoti(false)
    setNoteNoti('')
    setTokenNoti('')
    setHaveToken(false)
    setNotificationsOption(Object.assign([], NotificationsOption.map((e: any) => {
      return {
        ID: e.ID,
        name: e.name,
        key: e.key,
        description: e.description,
        check: false
      }
    })))
  }

  const handleNotiCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var checked: boolean = event.target.checked
    var index: number = Number(event.target.name)
    var TempNoti: ListNotication[] = NotificationsOption
    TempNoti[index].check = checked
    setNotificationsOption(Object.assign([], TempNoti))


    var local: linenotifyStorageData = linitifyStorage.get()
    local.setting[index].check = checked
    linitifyStorage.set(local)

  }

  const handleSelectEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableNoti(event.target.checked)
    var local: linenotifyStorageData = linitifyStorage.get()
    local.enable = event.target.checked
    linitifyStorage.set(local)
  }

  const handleInputNoteChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNoteNoti(event.target.value as string)
    var local: linenotifyStorageData = linitifyStorage.get()
    local.note = event.target.value as string
    linitifyStorage.set(local)
  }

  const handleSaveToken = () => {
    var local: linenotifyStorageData = linitifyStorage.get()

    //save token
    var access_token: string = TokenNoti
    var note: string = NoteNoti
    var notification_ids: number[] = local.setting.map((e: any, i: number) => (e.check ? e.ID : undefined)).filter(function (element) { return element !== undefined; });
    console.log("------------DATA------------")
    console.log("SAVE TO API: ",
      access_token,
      note,
      notification_ids,
    )
    console.log("---------------------------")

    var param: ReqLineCreate = {
      access_token: access_token,
      note: note,
      notification_ids: notification_ids,
    }

    CreateNotiApi.mutate(param, {
      onSuccess: (data) => {
        if (data.status === 201) {
          alert("เพิ่ม Notification สำเร็จ")
          var result: any = local.setting.map((e: any, i: number) => {
            return {
              ID: e.ID,
              name: e.name,
              key: e.key,
              description: e.description,
              check: false,
            }
          })
          var lineInitData: linenotifyStorageData = {
            from: SETTINGPAGE.EMAIL,
            setting: result,
            notiToken: "",
            note: "",
            enable: false
          }
          linitifyStorage.set(lineInitData)
          clear()
          props.setOpen(false)
          props.reFlashData()
        }
      },
      onError: (error) => {
        alert("ไม่สามารถขอ token line ได้กรุณาติดต่อ admin")

        var result: any = local.setting.map((e: any, i: number) => {
          return {
            ID: e.ID,
            name: e.name,
            key: e.key,
            description: e.description,
            check: false,
          }
        })
        var lineInitData: linenotifyStorageData = {
          from: SETTINGPAGE.EMAIL,
          setting: result,
          notiToken: "",
          note: "",
          enable: false
        }
        linitifyStorage.set(lineInitData)
        clear()
        props.setOpen(false)
        props.reFlashData()

      },
    })
  }

  return (
    <div>
      <Dialog
        open={props.isOpen}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        keepMounted
      >
        <DialogTitle id="scroll-dialog-title" style={{ width: "400px" }}>การแจ้งเตือนผ่าน Line Notify</DialogTitle>

        <DialogContent >
          <Grid container direction="row" columnSpacing="5px" rowSpacing="5px" columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
            {/* Section 1 */}
            {/* <Grid item xs={40} sm={40} md={40} lg={40} xl={40} style={{
                display: "flex",
                alignItems: "center",
              }}>
                เปิดการแจ้งเตือน
              </Grid>
              <Grid item xs={60} sm={60} md={60} lg={60} xl={60} >
                <FormControlLabel
                  control={
                    <Switch checked={EnableNoti} name="all" onChange={handleSelectEnableChange}/>
                  }
                  label=""
                />
              </Grid> */}

            {/* Section 2 */}
            <Grid item xs={40} sm={40} md={40} lg={40} xl={40} style={{
              marginTop: 10
            }}>
              ส่งแจ้งเตือนสำหรับ
            </Grid>
            <Grid item xs={60} sm={60} md={60} lg={60} xl={60} >
              {/* <FormControlLabel
                  control={
                    <Switch name="all" />
                  }
                  label=""
                /> */}

              {NotificationsOption.map((e: any, i: number) => (
                <Grid key={`NotificationsOption-${i}`} item xs={100} sm={100} md={100} lg={100} xl={100} >
                  <FormControlLabel
                    control={
                      <Checkbox checked={e.check} onChange={handleNotiCheckChange} name={i.toString()} />
                    }
                    label={e.description}
                  />
                </Grid>
              ))}

              {/* Section 3 */}
              <Grid item xs={40} sm={40} md={40} lg={40} xl={40} style={{
                display: "flex",
                alignItems: "center",
              }}>
                Note
              </Grid>
              <Grid item xs={60} sm={60} md={60} lg={60} xl={60} >
                <FormControlLabel
                  control={
                    <TextareaAutosize
                      aria-label="note"
                      minRows={4}
                      placeholder="รายละเอียดการแจ้งเตือน"
                      style={{ width: "300px" }}
                      onChange={handleInputNoteChange}
                      value={NoteNoti}
                    />
                  }
                  label=""
                />
              </Grid>

              {/* Section 4 */}
              <Grid item xs={40} sm={40} md={40} lg={40} xl={40} style={{
                display: "flex",
                alignItems: "center",
              }}>
                Line
              </Grid>
              <Grid item xs={60} sm={60} md={60} lg={60} xl={60} >
                <FormControlLabel
                  control={
                    <ButtonLine disabled={HaveToken || NoteNoti === ""} startIcon={<img src={icon_line} alt="icon_line" />} onClick={() => {
                      handleClickGotoLineNotiPage()
                    }}>เชื่อมต่อกับ LINE Notify</ButtonLine>
                  }
                  label=""
                />
              </Grid>

            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Stack spacing={2} direction="row">
            <CancleButton disabled={false} variant="text" onClick={() => handleClose()}>ยกเลิก</CancleButton>
            <UploadButton disabled={false} variant="contained" onClick={() => handleSaveToken()}>ยืนยัน</UploadButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const UploadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'white',
  backgroundColor: '#0739A2',
  '&:hover': {
    borderColor: '#0739A2',
  },
}));

const CancleButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#4C4C4C',
  '&:hover': {
    color: '#4C4C4C',
    // borderColor: 'red',
    // backgroundColor:'red' 
  },
}));

const ButtonLine = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'white',
  backgroundColor: "#00B900",
  '&:hover': {
    // color: '#f2f2f2',
    // borderColor: 'red',
    backgroundColor: '#00B900'
  },
}));