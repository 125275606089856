import { Box, Paper } from "@mui/material";
import { usePageContext } from "./WaterLevelPage";
import { ISensorItem } from "api/useWaterLevelAPI";

//API
import {
    DEVICES,
} from 'api/useRequest'
import SensorMarker from "components/Map/Marker/SensorMarker";
import BaseMap from "components/Map/BaseMap";
import styled from 'styled-components'

export function WaterLevelMapContent() {
    const { stateContext, dispatchContext } = usePageContext();

    return (
        <MapBox>
            <BaseMap>
                {
                    stateContext.curSensorList.map((e: ISensorItem, i: number) => (
                        <SensorMarker
                            key={`WaterLevelMarker_${i}`}
                            type={DEVICES.WATERLEVEL}
                            state={e.state}
                            position={[Number(e.latitude), Number(e.longitude)]}
                            label={e.device_name}
                            data={e}
                        />
                    ))
                }
            </BaseMap>
        </MapBox>
    );
}

const MapBox = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        height: "500px"
    },
    [theme.breakpoints.down('md')]: {
        height: "300px"
    },
}));