import React, { useEffect } from "react";
import { MapBox, usePageContext } from "./TruckPage";
import { ISensorItem } from "api/useTruckAPI";
import * as TruckAPI from "api/useTruckAPI";
import { DEVICES, STATUS } from "api/useRequest";
import { SimpleLoading } from "components/Loading/SimpleLoading";
import BaseMap from "components/Map/BaseMap";
import SensorMarker from "components/Map/Marker/SensorMarker";


export function TruckMapContent() {
    const { stateContext, dispatchContext } = usePageContext();

    return (
        <MapBox>
            {stateContext.curSensorList.length > 0 ?
                <BaseMap>
                    {
                        stateContext.curSensorList.map((e: ISensorItem, i: number) => (
                            <SensorMarker
                                key={`TruckMarker_${i}`}
                                type={DEVICES.TRUCK}
                                state={e.state}
                                position={[Number(e.latitude), Number(e.longitude)]}
                                label={e.device_name}
                                data={e}
                            />
                        ))
                    }
                </BaseMap>
                : <SimpleLoading />}
        </MapBox>
    );
}



