import React from 'react'
import { useLayoutState, useLayoutDispatch, toggleSidebar } from "../../context/LayoutContext";
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { SideDrawerProps } from "../Sidebar/Sidebar";
import styled from "styled-components";
import color from 'constants/Colors';

import Footer from 'components/Footer/Footer'

const DefaultLayout: React.FC = ({ children }) => {
    var { isSidebarOpened, headerText } = useLayoutState();
    var location = window.location;
    var layoutDispatch = useLayoutDispatch();
    return (
        <Root>
            <Header layoutState={{ headerText, isSidebarOpened }} toggleSidebar={() => toggleSidebar(layoutDispatch)} />    
            <Sidebar layoutState={{ isSidebarOpened, location}} toggleSidebar={() => toggleSidebar(layoutDispatch)}/>
            <Div open={isSidebarOpened}>
                {children}
                <Footer />
            </Div>
        </Root>
    );
}

export default DefaultLayout

const Root = styled.div`
    display: "flex";
    max-width: "100vw";
    overflow-x: "hidden";
    margin: auto;
`

const Div = styled.div.attrs((props: SideDrawerProps) => ({
    open: props.open,
})) <SideDrawerProps>`
    ${(props) => props.open ? `
    width: calc(98vw - ${props.theme.sidebarWidth+10}px);
    margin-left: ${props.theme.sidebarWidth + 10}px;
    margin-right: auto;
    ` : `
    width: calc(98vw);
    margin-right: auto;
    margin-left: auto;
    `};
    
    transition: ${(props: any) => props.theme.transitions.create(['margin', 'width'], {
        easing: props.theme.transitions.easing.sharp,
        duration: props.open ? props.theme.transitions.duration.complex : props.theme.transitions.duration.enteringScreen
    })};
    flex-grow: 1;
    padding: ${props => props.theme.spacing(2)};
    min-height: 60vh;
    background-color: ${color.background};

    &:not(:first-child) {
      padding-left:5px;
      padding-right:5px;
      ${(props: any) => props.theme.breakpoints.down("sm")} {
        padding-left:5px;
        padding-right:5px;
        margin-right: auto;
        margin-left: auto;
      };
    }
    margin-top: 64px;
    ${(props: any) => props.theme.breakpoints.down("sm")} {
        margin-top: 48px;
        width: 98vw;
        margin-right: auto;
        margin-left: auto;
    };
`