import React, { useState, useEffect } from "react";
import SettingDeviceButton from "components/Button/SettingDeviceBtn";
import DeviceSettingDialog, { DeviceType, IConveyorSetting } from "components/Dialog/DeviceSettingDialog";
import { HEADER_TEXT, MAIN_PATH, usePageContext } from "./ConveyorPageDevice";
import { Grid } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DEVICES } from "api/useRequest";
import { ISetConveyorConfig, reqUploadConfig } from "api/useConfigSettingAPI";
import * as ConveyorAPI from "api/useConveyorAPI";
import { ISensorItem } from "api/useConveyorAPI";
import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
// import { reqGetConfig, reqUploadConfig } from "api/useConveyorAPI";

export function DeviceSettingButton(props: { disabled?: boolean }) {
    /* Use to set header text */
    const dispatch = useLayoutDispatch();
    let { deviceID } = useParams<{ deviceID: string; }>();
    const { stateContext, dispatchContext } = usePageContext();
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const [settingData, setSettingData] = useState<IConveyorSetting>({
        device_name: "",
        sampling_rate: 0,
        threshold: 0,
        // latitude: 0,
        // longitude: 0,
        line: "",
        name: "",
        reverse: false,
    });

    const [selector, setSelector] = useState<Record<string, string[]>>({});

    async function uploadConfig(values: Record<string, any>) {
        let body: ISetConveyorConfig = {
            device_id: deviceID,
            device_name: values.device_name,
            sampling_rate: values.sampling_rate,
            threshold: values.threshold,
            // latitude: values.latitude,
            // longitude: values.longitude,
            line: values.line,
            name: values.name,
            reverse: values.reverse,
        }



        const res = await reqUploadConfig(DEVICES.CONVEYOR, body);
        // if (res.status !== 200) {
        //     return;
        // }
        (async () => {
            const res = await ConveyorAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            const items = res.data.conveyor.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
            const curSensor = await items.find((e: any) => e.device_id === deviceID);

            /* Set Header */
            changeHeaderText(dispatch, {
                texts: [HEADER_TEXT, `${values.device_name}`],
                link: `${window.location.protocol}//${window.location.host}${MAIN_PATH}`
            });

            dispatchContext!({ curSensorList: items, curSensorItem: curSensor });

        })();

    }

    function onDialogSave(values: Record<string, any>) {
        uploadConfig(values);
        setDialogOpen(false);
    };

    function onDialogCancel() {
        setDialogOpen(false);
    };

    function onTapSettingButton() {
        setDialogOpen(true);
    }

    useEffect(() => {
        if (isDialogOpen === true) {
            return
        }
        setSettingData({
            device_name: stateContext.curSensorItem.device_name,
            sampling_rate: stateContext.curConfigItem.sampling_rate,
            threshold: stateContext.curConfigItem.threshold,
            // latitude: parseFloat(stateContext.curConfigItem.latitude),
            // longitude: parseFloat(stateContext.curConfigItem.longitude),
            line: stateContext.curConfigItem.line,
            name: stateContext.curConfigItem.name,
            reverse: stateContext.curConfigItem.reverse,
        });
    }, [stateContext.curSensorItem, stateContext.curConfigItem]);

    return <Grid container item height="100%" width="100%">
        <SettingDeviceButton disabled={props.disabled} onTap={onTapSettingButton} />
        <DeviceSettingDialog
            isOpen={isDialogOpen}
            deviceType={DeviceType.Conveyor}
            currentValue={settingData}
            valueDescription={{
                device_name: "Device Name",
                sampling_rate: "Sampling Rate",
                threshold: "Threshold",
                line: "Line",
                name: "Name",
                reverse: "Reverse",
            }}
            currentSelector={stateContext.lineConfigList}
            description="Configuration of conveyor device"
            onCancel={onDialogCancel}
            onSave={onDialogSave} />
    </Grid>;


}

