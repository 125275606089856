import { DEVICES } from "api/useRequest";
import * as dateFns from 'date-fns';
const DefaultDateTimeRange: Record<string,(d:Date)=>Date[]> = {
    [DEVICES.ARSENIC]: (d:Date)=>[dateFns.addWeeks(d, -1), d],
    [DEVICES.CONVEYOR]: (d:Date)=>[dateFns.addDays(d, -1), d],
    [DEVICES.GPS]: (d:Date)=>[dateFns.addHours(d, -1), d],
    [DEVICES.GPSTRACKING]: (d:Date)=>[dateFns.addHours(d, -1), d],
    [DEVICES.LANDSLIDE]: (d:Date)=>[dateFns.addDays(d, -14), d],
    [DEVICES.PIEZO]: (d:Date)=>[dateFns.addDays(d, -1), d],
    [DEVICES.PIEZOMETER]: (d:Date)=>[dateFns.addDays(d, -1), d],
    [DEVICES.TRUCK]: (d:Date)=>[new Date(dateFns.format(d, "yyyy/MM/dd")), dateFns.addDays(new Date(dateFns.format(d, "yyyy/MM/dd")), 1)],
    [DEVICES.WATERLEVEL]: (d:Date)=>[dateFns.addHours(d, -1), d],
    [DEVICES.WEATHER]: (d:Date)=>[dateFns.addDays(d, -1), d],
};

export default DefaultDateTimeRange;