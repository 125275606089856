import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import TopsoilDeviceDetailTable, { createData } from "components/TopsoilDeviceDetailTable";
import { Data } from "components/TopsoilDeviceDetailTable";
import * as LandSlideAPI from "api/useLandSlideAPI";
import { usePageContext } from "./LandSlidePage";
import BaseCurrentTable from "components/BaseTable/BaseCurrentTable";
import { DEVICES } from "api/useRequest";
import { CardTemplate } from "components/Card/CardTemplate";
import parseDateTime from "helpers/parseDateTime";

export function DeviceTable() {
    const { stateContext, dispatchContext } = usePageContext();
    const [dataList, setDataList] = useState<Data[]>([]);
    useEffect(() => {
        const datas: Data[] = stateContext.curSensorList.map((e: any) => {
            const {
                device_id,
                device_name,
                power_pri,
                power_sec,
                status,
                top_x,
                top_y,
                top_z,
                bottom_x,
                bottom_y,
                bottom_z,
                gnss_status,
                latitude,
                longitude,
                atitude,
                h_accuracy,
                v_accuracy,
                satelite_num,
                p_dop,
                state,
                mine_n,
                mine_e,
                diff2d,
                diff3d,
                gnss_fix_type,
                gnss_fix_type_name,
                gnss_rtk_solution,
                gnss_rtk_solution_name,
                timestamp,
            } = e;
            let date = parseDateTime(timestamp);
            return createData(device_id, device_name, power_pri, power_sec, status, top_x, top_y, top_z, bottom_x, bottom_y, bottom_z, gnss_status, latitude.toFixed(7), longitude.toFixed(7), parseFloat(atitude).toFixed(3), h_accuracy, v_accuracy, satelite_num, p_dop, state, mine_n.toFixed(2), mine_e.toFixed(2), diff2d, diff3d, gnss_fix_type, gnss_fix_type_name, gnss_rtk_solution, gnss_rtk_solution_name, timestamp);
        });
        setDataList(datas);
    }, [stateContext.curSensorList]);
    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0">
        <CardTemplate>
            <BaseCurrentTable tableName={"รายละเอียดอุปกรณ์ตรวจจับความเคลื่อนตัวชั้นหน้าดิน"} device={DEVICES.LANDSLIDE} order={"asc"} orderBy={"device_id"} tableCell={headCells} DataCurrentDevice={dataList} />
        </CardTemplate>
    </Grid>;
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    isStatus?: boolean;
    isUpper?: boolean;
    isUseFormatTime?: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'device_id',
        numeric: false,
        isUpper: true,
        disablePadding: false,
        label: 'ID',
    },
    {
        id: 'device_name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'state',
        numeric: false,
        isStatus: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'timestamp',
        numeric: false,
        disablePadding: false,
        isUseFormatTime: true,
        label: 'Last Updated',
    },
    {
        id: 'latitude',
        numeric: true,
        disablePadding: false,
        label: 'Latitude',
    },
    {
        id: 'longitude',
        numeric: true,
        disablePadding: false,
        label: 'Longitude',
    },
    {
        id: 'mine_n',
        numeric: true,
        disablePadding: false,
        label: 'Mine N',
    },
    {
        id: 'mine_e',
        numeric: true,
        disablePadding: false,
        label: 'Mine E',
    },
    {
        id: 'atitude',
        numeric: true,
        disablePadding: false,
        label: 'MSL',
    },
    {
        id: 'diff2d',
        numeric: true,
        disablePadding: false,
        label: 'Diff 2D',
    },
    {
        id: 'diff3d',
        numeric: true,
        disablePadding: false,
        label: 'Diff 3D',
    },
    {
        id: 'top_x',
        numeric: true,
        disablePadding: false,
        label: 'Top x',
    },
    {
        id: 'top_y',
        numeric: true,
        disablePadding: false,
        label: 'Top y',
    },
    {
        id: 'top_z',
        numeric: true,
        disablePadding: false,
        label: 'Top z',
    },
    {
        id: 'bottom_x',
        numeric: true,
        disablePadding: false,
        label: 'Bottom x',
    },
    {
        id: 'bottom_y',
        numeric: true,
        disablePadding: false,
        label: 'Bottom y',
    },
    {
        id: 'bottom_z',
        numeric: true,
        disablePadding: false,
        label: 'Bottom z',
    },
    {
        id: 'h_accuracy',
        numeric: true,
        disablePadding: false,
        label: 'Vertical Acc',
    },
    {
        id: 'v_accuracy',
        numeric: true,
        disablePadding: false,
        label: 'Horizontal Acc',
    },
    {
        id: 'satelite_num',
        numeric: true,
        disablePadding: false,
        label: 'Satelite Number',
    },
    {
        id: 'p_dop',
        numeric: true,
        disablePadding: false,
        label: 'PDOP',
    },
    {
        id: 'power_pri',
        numeric: true,
        disablePadding: false,
        label: 'Solar Cell',
    },
    {
        id: 'power_sec',
        numeric: true,
        disablePadding: false,
        label: 'Battery',
    },
    {
        id: 'gnss_rtk_solution_name',
        numeric: false,
        disablePadding: false,
        label: 'RTK',
    },



];
