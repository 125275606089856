import * as ConveyorAPI from "api/useConveyorAPI";

declare global {
  interface Window {
    shp: any;
    cw: any;
    L: any;
  }
}

const shp = window.shp;
const cw = window.cw;
const L = window.L;
const locatIndex = 0;

interface ShapeFile {
  layer: string;
  line: string;
  name: string;
}

export default async function Shp ():Promise<ShapeFile[]> {

  const res = await ConveyorAPI.reqFileLayer();
  var fileDashboard: string = '';
  res.data.file.forEach((element:any) => {
    if (element.file_type === L._FILETPYE.SHAPEFILECONVEYER){
      fileDashboard = element.file_name
    }
  });

  var ShpData: ShapeFile[] = []

  if (fileDashboard) {
    var url = `${process.env.REACT_APP_API_BASE_URL}/api/files/${fileDashboard}`
    var shape_data_conveyor = await shp(cw.makeUrl(url))
    ShpData = shape_data_conveyor?.features?.map((e: any) => {
      return {
        layer: e?.properties?.layer,
        line: e?.properties?.line?.replaceAll(/  /g,' '),
        name: e?.properties?.name?.replaceAll(/  /g,' '),
        lat: e?.geometry?.coordinates[locatIndex][1],
        lng: e?.geometry?.coordinates[locatIndex][0],
        lat2: e?.geometry?.coordinates[locatIndex + 1][1],
        lng2: e?.geometry?.coordinates[locatIndex + 1][0]
      }
    })
  }
  return ShpData
}
