import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import type { RouteComponentProps } from 'react-router-dom'
import { useLayoutDispatch, changeHeaderText } from "../../context/LayoutContext";
import { visuallyHidden } from '@mui/utils';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material"

import {
  headCells
} from './MapSettingInterface'

import BaseFileTable from 'components/BaseTable/BaseFileTable'

import color from 'constants/Colors'

const HEADER_TEXT = "ตั้งค่าเลเยอร์แผนที่";

const MapLayerSettingPage: React.FC<RouteComponentProps> = () => {
  const dispatch = useLayoutDispatch()

  useEffect(() => {
    changeHeaderText(dispatch, HEADER_TEXT)
  }, []);

  

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [openUploadDilog, setOpenUploadDilog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [fileName, setFileName] = useState("");
  const [layerName, setLayerName] = useState("");

  useEffect(() => {

  }, [])

  return (
    <Container>
      <Grid container direction="column" columnSpacing="5px" rowSpacing="5px" columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
        <Grid item>

          <Grid
            item
            container
            direction="row"
            columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
          >
            <BaseFileTable tableName={HEADER_TEXT} order={"asc"} orderBy={"name"} tableCell={headCells} />
          </Grid>

          
        </Grid>
      </Grid>
    </Container>
  )
}

export default MapLayerSettingPage

const SaveButton = styled(Button)`
  margin-top: 10px;
`

const Container = styled.div`
  align-items: left;
  justify-content: left;
  
  > p {
    line-height: 1;
    font-size: 20px;
  }
`
const HeaderText = styled(Typography)`
  color: "#000000";
  font-size: 120%;
`

const SearchTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#919EAB',
    },
    '&:hover fieldset': {
      borderColor: '#0739A2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0739A2',
    },
  },
  marginBottom: '25px',
});

const SearchTextSX = {
  width: "30%",
  height: "5%",
  backgroundColor: color.white,
}