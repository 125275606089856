import { IconButton, Menu, MenuItem } from "@mui/material";
import { FunctionComponent, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditPermissionDialog, { DialogData } from "../EditPermissionDialog/EditPermissionDialog";
import { useDialogController } from '../EditPermissionDialog/useDialogController';
import DeleteUserDialog from "../DeleteUserDialog/DeleteUserDialog";

export type MenuOption = 'แก้ไข' | 'ลบ';
export interface MenuButtonProps {
    data: DialogData;
    reloadfn: () => void;
}

const MenuButton: FunctionComponent<MenuButtonProps> = (props) => {
    const { data, reloadfn } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const editDialogController = useDialogController();
    const deleteDialogController = useDialogController();

    const menuHandleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const menuHandleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={menuHandleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={menuHandleClose}
                PaperProps={{
                    style: {
                        maxHeight: '100%',
                        width: '20ch',
                    },
                }}
            >
                <MenuItem key="แก้ไข" onClick={() => {
                    editDialogController.handleDialogOpen();
                    menuHandleClose();
                }}>
                    แก้ไข
                </MenuItem>
                <MenuItem key="ลบ" onClick={() => {
                    deleteDialogController.handleDialogOpen();
                    menuHandleClose();
                }}>
                    ลบ
                </MenuItem>
            </Menu>
            <EditPermissionDialog
                open={editDialogController.isOpen}
                handleClose={editDialogController.handleDialogClose}
                data={data}
                reloadfn={reloadfn}
            />
            <DeleteUserDialog
                open={deleteDialogController.isOpen}
                handleClose={deleteDialogController.handleDialogClose}
                employeeNumber={data.employeeNumber}
            />
        </>
    );
}

export default MenuButton;