import {
    Home as HomeIcon,
    NotificationsNone as NotificationsIcon,
} from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RoutePath from 'constants/RoutePath';
import RoutePermission from 'constants/RoutePermission';


export const structure = [
    {
        id: 1,
        label: "หน้าหลัก",
        link: RoutePath.dashboard,
        icon: <HomeIcon />,
        requireAuthen: RoutePermission.none,
    },
    {
        id: 2,
        label: "ตั้งค่าแจ้งเตือน",
        link: RoutePath.setting,
        icon: <SettingsIcon />,
        requireAuthen: RoutePermission.setting
    },
    {
        id: 3,
        label: "ตั้งค่าเลเยอร์แผนที่",
        link: RoutePath.maplayersetting,
        icon: <SettingsIcon />,
        requireAuthen: RoutePermission.maplayersetting
    },
    {
        id: 4,
        label: "จัดการหน้าผู้ใช้",
        link: RoutePath.usermanagement,
        icon: <PersonOutlineIcon />,
        requireAuthen: RoutePermission.usermanagement
    },
    { id: 5, type: "divider" },
    {
        id: 6,
        label: "แดชบอร์ด",
        children: [
            {
                label: "ระบบสื่อสารไร้สาย",
                link: RoutePath.wireless,
                requireAuthen: RoutePermission.wireless
            },
            {
                label: "ระบบตรวจวัดการเคลื่อนตัวชั้นหน้าดิน",
                link: RoutePath.landslide,
                requireAuthen: RoutePermission.landslide
            },
            {
                label: "ระบบติดตามเครื่องจักรขนาดใหญ่ด้วย GPS",
                link: RoutePath.gpstracking,
                requireAuthen: RoutePermission.gpstracking
            },
            {
                label: "ระบบตรวจวัดแรงดันน้ำใต้ดิน",
                link: RoutePath.piezometer,
                requireAuthen: RoutePermission.piezometer
            },
            {
                label: "ระบบตรวจวัดระดับน้ำ",
                link: RoutePath.waterlevel,
                requireAuthen: RoutePermission.waterlevel
            },
            {
                label: "ระบบตรวจวัดสารหนู",
                link: RoutePath.arsenic,
                requireAuthen: RoutePermission.arsenic
            },
            {
                label: "ระบบตรวจวัดคุณภาพอากาศ",
                link: RoutePath.weather,
                requireAuthen: RoutePermission.weather
            },
            {
                label: "ระบบตรวจวัดสถานะสายพาน",
                link: RoutePath.conveyor,
                requireAuthen: RoutePermission.conveyor
            },
            {
                label: "ระบบตรวจนับรถบรรทุกเทท้าย",
                link: RoutePath.truck,
                requireAuthen: RoutePermission.truck
            },
        ],
        requireAuthen: [],
    },
];
