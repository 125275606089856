import React from 'react'
import styled, { css } from 'styled-components'

import { Prompt } from 'react-router'

import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Switch,
  TextField,
  Box,
  Button
} from "@mui/material"
import { LoadingButton } from '@mui/lab';
import SavedIcon from '../../assets/icons/icon-success.svg';

import useUserContext from 'context/UserContext';
import color from 'constants/Colors'

/**
 * API
 */
import {
  ListNotication,
  useListNotication,
  useEmailenable,
  useEmaildisable,
  useGetEmailNotication,
  reqEmailNoti,
  useCreateEmailNotication
} from 'api/useRequest'

/**
 * Localstorage
 */
 import {
  emailtifyStorage,
  emailnotifyStorageData,
} from 'configure/localStorageConfig'

import SnackbarUtils from 'helpers/utils';

import { ConstructionOutlined } from '@mui/icons-material';

const HEADER_TEXT = "ตั้งค่าแจ้งเตือน";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

interface EmailProps {

}

function EmailSetting(props: EmailProps) {

  const ListnotiApi = useListNotication()
  const userPermission = useUserContext().permission?.permissions
  const GetNotiEmail = useGetEmailNotication()
  const UpdateNotiEmail = useCreateEmailNotication()
  const emailenable = useEmailenable()
  const emaildisable = useEmaildisable()
  
  const [userPermissionRule, setuserPermissionRule] = React.useState<string[] | undefined>([""])
  const [NotificationsOption, setNotificationsOption] = React.useState<ListNotication[]>([]);
  const [listNotificationsFilter, setListNotificationsFilter] = React.useState<ListNotication[]>([])

  // for leave setting page.
  const [leavesetting, setleavesetting] = React.useState<boolean>(false)
  const [saved, setSaved] = React.useState(false)

  // state for checkbox selected.
  const [selected, setselected] = React.useState<number[]>([])

  // state input for email.
  const [email, setemail] = React.useState<string>("")

  // noti enable/disable
  const [enable, setenable] = React.useState<boolean>(false)

  React.useEffect(() => {
    (() => {
      setuserPermissionRule(userPermission)
      ListnotiApi.mutate(null, {
        onSuccess: (data) => {
          setNotificationsOption(data?.data)
          if (userPermission?.includes("admin") || userPermission?.includes("setting")){ // check admin.
            setListNotificationsFilter(data?.data)

            var local:emailnotifyStorageData = emailtifyStorage.get()
            if (!local.leavesetting) {
              console.log("GET API")
              GetNotiEmail.mutate(null, {
                onSuccess: (data: any) => {
                  console.log("GetNotiEmail: ", data?.data)
                  if (data?.data === "") {
                    var Ldata: emailnotifyStorageData = {
                      enable: false,
                      email: '',
                      notification_ids: [],
                      leavesetting: false
                    }
                    emailtifyStorage.set(Ldata)
                    setselected([]) 
                    setemail("")
                    setenable(false)
                  } else {
                    var Ldata: emailnotifyStorageData = {
                      enable: data?.data.is_active,
                      email: data?.data.email,
                      notification_ids: data?.data.notification_ids,
                      leavesetting: false
                    }
                    emailtifyStorage.set(Ldata)
                    setselected(data?.data.notification_ids)
                    setemail(data?.data.email)
                    setenable(data?.data.is_active)
                  }
                }
              })
            } else {
              console.log("GET LOCAL")
              var Ldata: emailnotifyStorageData = {
                enable: true,
                email: local.email,
                notification_ids: local.notification_ids,
                leavesetting: true
              }
              emailtifyStorage.set(Ldata)
              setselected(local.notification_ids)
            }
          } else {
            window.location.replace("/dashboard")
          }
        }
      })
    })()
  }, []);

  const handleUpdateEmail = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    var data: reqEmailNoti = {
      email: email,
      notification_ids: selected,
    }
    if (selected.length === 0){
      SnackbarUtils.warning('กรุณาเลือกการแจ้งเตือน');
    } else {
      UpdateNotiEmail.mutate(data, {
        onSuccess: (data: any) => {
          setSaved(true)
          SnackbarUtils.success('บันทึกสำเร็จ');
          setTimeout(() => {
            setSaved(false)

            var Ldata: emailnotifyStorageData = {
              enable: true,
              email: '',
              notification_ids: [],
              leavesetting: false
            }
            emailtifyStorage.set(Ldata)

          }, 3000);
        },
        onError: (error:any) => {
          SnackbarUtils.error('บันทึกผิดพลาด');
        }
      })
    }
  }

  // check value in array.
  const isSelected = (ID: number) => selected.indexOf(ID) !== -1;

  const selectItem = (ID: number) => {
    const selectedIndex = selected.indexOf(ID);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    var local:emailnotifyStorageData = emailtifyStorage.get()
    var Ldata: emailnotifyStorageData = {
      enable: local.enable,
      email: local.email,
      notification_ids: newSelected,
      leavesetting: local.leavesetting
    }
    emailtifyStorage.set(Ldata)
    setselected(newSelected);
  }

  const handleNotiSelectChange = (event: React.ChangeEvent<HTMLInputElement>, ID: number) => {
    selectItem(ID)
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    var local:emailnotifyStorageData = emailtifyStorage.get()
    var Ldata: emailnotifyStorageData = {
      enable: local.enable,
      email: event.target.value,
      notification_ids: local.notification_ids,
      leavesetting: local.leavesetting
    }
    emailtifyStorage.set(Ldata)
    setemail(event.target.value)
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    var local:emailnotifyStorageData = emailtifyStorage.get()
    var Ldata: emailnotifyStorageData = {
      enable: event.target.checked,
      email: local.email,
      notification_ids: local.notification_ids,
      leavesetting: local.leavesetting
    }
    emailtifyStorage.set(Ldata)
    setenable(event.target.checked)

    if (event.target.checked)
      emailenable.mutate(null, {})
    else 
      emaildisable.mutate(null, {})
  }

  return (
    <Container>
      <Prompt
        when={!saved}
        message={(location, action) => {
          if (action === 'PUSH') {
            // check state have action from user.
            var local:emailnotifyStorageData = emailtifyStorage.get()
            var Ldata: emailnotifyStorageData = {
              enable: local.enable,
              email: local.email,
              notification_ids: local.notification_ids,
              leavesetting: true
            }
            emailtifyStorage.set(Ldata)
          }
          return 'ท่านยังไม่ได้บันทึกการตั้งค่าแจ้งเตือนล่าสุด ยืนยันจะออกจากหน้านี้ ?'
        }}
      />
      <Grid container direction="column" columnSpacing="5px" rowSpacing="5px" columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} style={{ marginLeft: "10px" }}>
        <Grid container rowSpacing={0} columnSpacing={1} justifyContent="flex-start" alignItems="flex-start">
          {/* row1 */}
          <Grid item xs={100}>
            <p>การแจ้งเตือนผ่านอีเมล</p>
          </Grid>

          {/* row2 */}
          <Grid item xs={50} sm={30} md={20} lg={15} xl={12}>
            <p>เปิดการแจ้งเตือน</p>
          </Grid>
          <Grid item xs={50} sm={70} md={80} lg={85} xl={88} style={{ margin: "auto 0px" }}>
            <Switch {...label} defaultChecked checked={enable} onChange={handleSwitchChange}/>
          </Grid>

          {/* row3 */}
          <Grid item xs={50} sm={30} md={20} lg={15} xl={12}>
            <p>อีเมล</p>
          </Grid>
          <Grid item xs={50} sm={70} md={80} lg={85} xl={88}>
            <SearchTextField
              placeholder="อีเมล"
              sx={EmailSX}
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>

          {/* row4 */}
          <Grid item xs={50} sm={30} md={20} lg={15} xl={12}>
            <p style={{ marginTop: "10px" }}>ส่งแจ้งเตือนสำหรับ</p>
          </Grid>
          <Grid item xs={50} sm={70} md={80} lg={85} xl={88}>
            <DivOneColumn>
              <FormControlLabel
                control={
                  <Checkbox name="all" onChange={(e) => {
                    if (e.target.checked){
                      setselected(NotificationsOption.map(e => e.ID))
                      var local:emailnotifyStorageData = emailtifyStorage.get()
                      var Ldata: emailnotifyStorageData = {
                        enable: local.enable,
                        email: local.email,
                        notification_ids: NotificationsOption.map(e => e.ID),
                        leavesetting: local.leavesetting
                      }
                      emailtifyStorage.set(Ldata)
                    } else {
                      setselected([])  
                      var local:emailnotifyStorageData = emailtifyStorage.get()
                      var Ldata: emailnotifyStorageData = {
                        enable: local.enable,
                        email: local.email,
                        notification_ids: [],
                        leavesetting: local.leavesetting
                      }
                      emailtifyStorage.set(Ldata)
                    }
                  }}/>
                }
                label="เลือกทั้งหมด"
              />

              {NotificationsOption.map((e: any, index: number) => (
                <FormControlLabel
                  key={`${index}_${e.ID}_FormControlLabel`}
                  control={
                    <Checkbox key={`${index}_${e.ID}_Checkbox`} checked={isSelected(e.ID)} onChange={(event) => handleNotiSelectChange(event, e.ID)}/>
                  }
                  label={e.description}
                />
              ))}
            </DivOneColumn>
          </Grid>
        </Grid>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} />


        <Grid container rowSpacing={0} columnSpacing={1} justifyContent="flex-start" alignItems="flex-start">
          {
            UpdateNotiEmail.isLoading ? 
              <LoadingSaveButton loading variant="contained">
                บันทึก
              </LoadingSaveButton> 
            : 
            (saved ? 
                <SavedButton >
                  <Icon>
                    <img src={SavedIcon} alt="saved icon" /><MarginLeftP>บันทึกสำเร็จ</MarginLeftP>
                  </Icon>
                </SavedButton> 
              : 
                <SaveButton variant="contained" onClick={handleUpdateEmail}>บันทึก</SaveButton>  
              )
              // <SavedButton >
              //   <Icon>
              //     <img src={SavedIcon} alt="saved icon" /><MarginLeftP>บันทึกสำเร็จ</MarginLeftP>
              //   </Icon>
              // </SavedButton>
              // <SaveButton variant="contained" onClick={handleUpdateEmail}>บันทึก</SaveButton>  
          }
        </Grid>

      </Grid>
    </Container>
  )
}

export default EmailSetting

const Container = styled.div`
  align-items: left;
  justify-content: left;
  
  > p {
    line-height: 1;
    font-size: 20px;
  }
`
const HeaderText = styled(Typography)`
  color: "#000000";
  font-size: 120%;
`
const EmailSX = {
  minWidth: "150px",
  width: "40%",
  height: "5%",
  backgroundColor: color.white,
}

const DivOneColumn = styled.div`
  display: flex;
  flex-direction: column
`

const SearchTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#919EAB',
    },
    '&:hover fieldset': {
      borderColor: '#0739A2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0739A2',
    },
  },
  marginBottom: '25px',
});

const LoginCss = css`
    // margin: 24px auto 60px auto;
    /* width: 80%; */
    font-size: 18px;
`

const LoadingSaveButton = styled(LoadingButton)`
    ${LoginCss};   
    margin-top: 20px;
`
const SavedButton = styled.div`
    margin-top: 20px;
    color: green;
`
const Icon = styled.div`
    color: green;
`
const MarginLeftP = styled.label`
    margin-left: 10px;
    height: fit-content;
`
const SaveButton = styled(Button)`
    ${LoginCss};
    margin-top: 20px;
    background-color: ${(props) => props.theme.color.loginButton};
    &:hover {
        background-color: ${(props) => props.theme.color.loginButton};
        opacity:0.8;
    }
    text-transform: none;
`