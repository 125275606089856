import store from 'store'

/**
 * API
 */
 import {
    ListNotication,
  } from "api/useRequest"
import { AuthenticationResponseData } from '../api/useAuthenticationAPI'
import { PermissionResponseData } from 'api/usePermissionAPI'

const version = '1'

const prefix = 'egat:'
const versionKeyName = `${prefix}version`
const authKeyName = `${prefix}auth`
const permissionKeyName = `${prefix}permission`

// key for setting line notify page.
const lineNitifyName = `${prefix}linenoti`
const emailNitifyName = `${prefix}emailnoti`

const setupUserStorage = () => {
    const userStorageCurrentVersion = store.get(versionKeyName)

    if (userStorageCurrentVersion !== version) {
        store.clearAll()
        store.set(versionKeyName, version)
    }
    /**
     * ====================================== inite line notify local store ====================================== 
     * */
    const StoragelinenotifyStorage = store.get(lineNitifyName)
    if (StoragelinenotifyStorage === null || StoragelinenotifyStorage === undefined){
        var lineInitData:linenotifyStorageData = {
            from: SETTINGPAGE.EMAIL,
            setting: [],
            notiToken: "",
            note: "",
            enable: false,
          }
        linitifyStorage.set(lineInitData)
        window.location.replace("/dashboard")
    }
    /**
     * ====================================== inite email notify local store ====================================== 
     * */
    const StorageemailnotifyStorage = store.get(emailNitifyName)
    if (StorageemailnotifyStorage === null || StorageemailnotifyStorage === undefined){
        var emailInitData:emailnotifyStorageData = {
            enable: false,
            email: "",
            notification_ids: [],
            leavesetting: false,
          }
        emailtifyStorage.set(emailInitData)
        window.location.replace("/dashboard")
    } else {
        var emailInitData:emailnotifyStorageData = {
            enable: StorageemailnotifyStorage.enable,
            email: StorageemailnotifyStorage.email,
            notification_ids: StorageemailnotifyStorage.notification_ids,
            leavesetting: false,
          }
        emailtifyStorage.set(emailInitData)
    }
}

export default setupUserStorage

export const authStorage = {
    get: (): AuthenticationResponseData => store.get(authKeyName),
    set: (data: AuthenticationResponseData) => store.set(authKeyName, data),
    remove: () => store.remove(authKeyName),
}
export const permissionsStorage = {
    get: (): PermissionResponseData => store.get(permissionKeyName),
    set: (data: PermissionResponseData) => store.set(permissionKeyName, data),
    remove: () => store.remove(permissionKeyName),
}

// Storage for line notify page.
export const linitifyStorage = {
    get: (): linenotifyStorageData => store.get(lineNitifyName),
    set: (data: linenotifyStorageData) => store.set(lineNitifyName, data),
    remove: () => store.remove(lineNitifyName),
}

export interface linenotifyStorageData {
    from: string; // setting_email, setting_line, line_noti
    setting: ListNotication[];
    notiToken: string;
    note: string;
    enable: boolean;
}

// Storage for email notify page.
export const emailtifyStorage = {
    get: (): emailnotifyStorageData => store.get(emailNitifyName),
    set: (data: emailnotifyStorageData) => store.set(emailNitifyName, data),
    remove: () => store.remove(emailNitifyName),
}

export interface emailnotifyStorageData {
    enable: boolean;
    email: string;
    notification_ids: number[];
    leavesetting?: boolean;
}

export enum SETTINGPAGE {
    EMAIL = "setting_email",
    LINE = "setting_line",
    NOTI = "line_noti",
}