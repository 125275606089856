import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { QrMapItem } from "api/useTruckAPI";
import { useFormik } from "formik";
import { FunctionComponent } from "react";
import { useDialogController } from "./useDialogController";
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { CancleButton, SaveButton } from "components/Button/DialogButtonStyles";
import * as Yup from 'yup';

export interface QrMapItemSaved {
    id?: number;
    name: string;
    qr_values: string[];
}

interface AddEditQrMapDialogProps {
    qrMaps: QrMapItem[];
    qrItem?: QrMapItem;
    onSave?: (item: QrMapItemSaved) => void;
}

const AddEditQrMapDialog: FunctionComponent<AddEditQrMapDialogProps> = (props) => {
    const {
        qrMaps,
        qrItem,
        onSave,
    } = props;

    const {
        isOpen,
        handleDialogClose,
        handleDialogOpen,
    } = useDialogController();

    const initValues = qrItem ? {
        name: qrItem.name,
        qr_values: qrItem.qr_values.join(","),
    } : {
        name: "",
        qr_values: "",
    }



    const varidationSchema = Yup.object().shape({
        name: Yup.string().required("กรุณาระบุหมายเลขรถ"),
        qr_values: Yup.string()
            .required("กรุณาระบุหมายเลข QR")
            .matches(/^DW\d{4}-\d{5}(,DW\d{4}-\d{5})*$/, "หมายเลข QR จะต้องอยู่ในรูปแบบ[DW + เดือน + ปี + '-' + หมายเลข 5 หลัก เช่น DW1264-00001")
            .test("test-qr-duplicate", "", function (value) {
                const { path, createError } = this;
                return (
                    (value && qrMaps.filter((item: QrMapItem) => item.qr_values.includes(value)).length === (qrItem ? 1 : 0)) ||
                    createError({ path, message: "ไม่สามารถใช้หมายเลข QR ซ้ำได้" })
                )
            })
    });

    const handleSubmit = (values: any) => {
        console.log('handleSubmit', values);
        if (onSave !== undefined) {
            let qrMapSaved: QrMapItemSaved = {
                id: qrItem ? qrItem.id : undefined,
                name: values.name,
                qr_values: [...values.qr_values.split(",")],
            };
            onSave(qrMapSaved);
        }
    };
    const formik = useFormik({
        initialValues: initValues,
        validationSchema: varidationSchema,
        onSubmit: handleSubmit,
    });






    return (
        <>
            <Button color="primary" onClick={handleDialogOpen} >{qrItem ? "แก้ไข" : "เพิ่ม"}</Button>
            <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleDialogClose} aria-labelledby={"add-edit-qr-map"}>
                <DialogTitle id={"add-edit-qr-map"}>
                    {qrItem ? "แก้ไขข้อมูลเลขรถและเลข QR" : "เพิ่มเลขรถและเลข QR ใหม่"}
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <Grid container direction="column" gap={3} padding={2} >
                            <TextField
                                id="name"
                                label="หมายเลขรถ"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.name)}
                                helperText={formik.errors.name}
                                size="small"
                            />
                            <TextField
                                id="qr_values"
                                label="หมายเลข QR"
                                name="qr_values"
                                variant="outlined"
                                color="primary"
                                margin="none"
                                size="small"
                                value={formik.values.qr_values}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.errors.qr_values)}
                                helperText={formik.errors.qr_values}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <CancleButton
                            onClick={handleDialogClose}
                            color="primary"
                        >
                            ยกเลิก
                        </CancleButton>
                        <SaveButton variant="contained" type="submit" >
                            บันทึก
                        </SaveButton>
                    </DialogActions>
                </form>
            </Dialog></>
    );


}

export default AddEditQrMapDialog;