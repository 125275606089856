import { useQuery } from 'react-query'
import requestInstance, {
    RequestResponse,
    RequestError,
} from 'configure/requestConfig'
import { StringifyOptions } from 'querystring'


export interface PermissionResponseData {
    permissions : string[]
    status_code?: number
    status?: string
    message?: string
}

const usePermissionAPI = (enabled?:boolean) => {
    const queryKey = 'userPermissions'
    const fetcher = () => requestInstance.get('/api/user/permissions')
    return useQuery<RequestResponse<PermissionResponseData>, RequestError>(
        queryKey,
        fetcher,
        {enabled: enabled}
    )
}

export default usePermissionAPI

export interface PermissionData {
    name: string
    description: string
}

export interface ListPermissionResponseData {
    permissions : PermissionData[]
    status_code?: number
    status?: string
    message?: string
}

export const useListPermissionAPI = (enabled?:boolean) => {
    const queryKey = 'ListPermissions'
    const fetcher = () => requestInstance.get('/api/permissions/')
    return useQuery<RequestResponse<ListPermissionResponseData>, RequestError>(
        queryKey,
        fetcher,
        {enabled: enabled}
    )
}