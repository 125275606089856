import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import AppRoutes from './pages/AppRoutes';
import setupUserStorage from 'configure/localStorageConfig'
import dayjs from 'dayjs';
import 'dayjs/locale/th'
import { SnackbarUtilsConfigurator } from 'helpers/utils';
import ScrollToTop from 'pages/ScrollToTop';

function App() {
  useEffect(() => {
    dayjs.locale('th')
    setupUserStorage()
  }, [])
  return (
    <Router>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <SnackbarUtilsConfigurator />
        <ScrollToTop>
          <AppRoutes />
        </ScrollToTop>
      </SnackbarProvider>
    </Router>
  );
}

export default App;
