import * as React from 'react';
import BaseDialog from '../Dialog/BaseDialog'

import { PermissionDetail } from './Permissions';

interface IDeleteDialogProps {
  open: boolean;
  setOpen: () => void;
  employeeNumber: number;
  employeeID: number;
  role: string;
  permissions: PermissionDetail[]|undefined;
}

const Options = [
  'ยกเลิก',
  'ลบ'
]

const DeleteDialog = (props: IDeleteDialogProps) => {
  //console.log("Delete Dia props"+props)
  const {open, setOpen, employeeNumber, role, permissions, employeeID} = props
  return (
    <BaseDialog
    init={open}
    setOpen={setOpen}
    scrollType={true}
    buttonMenus={Options}
    HeaderText={"ลบ"}
    employeeNumber={employeeNumber}
    employeeID={employeeID}
    role={role}
    permissions={permissions}/>
  );
}

export default DeleteDialog;