import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import { TabContext, TabPanel } from "@mui/lab";
import CustomizedTabs from 'components/TabBar/TabBar';
import DateTimeRangePicker, { TimePickerFlexWithBreakpint } from "components/Datetime/DateTimeRangePicker";
import * as dateFns from 'date-fns';
import * as GatewayAPI from "api/useGatewayAPI";
import { usePageContext } from "./WirelessPage";
import { DeviceTable } from "./DeviceTable";

export function TableZone() {
    const [tabValue, setTabValue] = React.useState(0);
    const { stateContext, dispatchContext } = usePageContext();
    useEffect(() => {
        onDateTimeChange([dateFns.addHours(new Date(), -1), new Date()]);
    }, []);
    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };
    const onDateTimeChange = (value: Date[]) => {
        console.log("onDateTimeChange");

        dispatchContext!({ dateRange: value });
        (async () => {
            const res = await GatewayAPI.reqDeviceData(value);
            if (res.status != 200) {
                return [];
            }
            console.log(res);

            const { items } = res.data;
            dispatchContext!({ allSensorList: items });
        })();
    };
    return (
        <TabContext value={`${tabValue}`}>
            <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} bgcolor="#FFF" marginBottom="6px">
                <Grid item xs={100} sm={50} md={50} lg={50} xl={50}>
                    <CustomizedTabs tabList={["อุปกรณ์"]} onChange={onTabChange} />
                </Grid>
                {/* {(tabValue != 1)
                    ? (
                        <TimePickerFlexWithBreakpint item xs={100} sm={50} md={50} lg={50} xl={50}>
                            <DateTimeRangePicker onChange={onDateTimeChange} />
                        </TimePickerFlexWithBreakpint>
                    ) : null} */}
            </Grid>
            <TabPanel value="0" sx={{ padding: "0", width: "100%" }}>
                <DeviceTable />
            </TabPanel>
        </TabContext>
    );
}

