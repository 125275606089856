import { useState } from "react";
import { RowItem } from "../useUserTable";
import { HeadCell, Order } from "./EnhancedTableHead";


const useEnhancedTableHead = () => {
    const defaultHeadCells: HeadCell[] = [
        {
            id: 'employeeNumber',
            disablePadding: true,
            label: 'Employee No.',
        },
        {
            id: 'role',
            disablePadding: false,
            label: 'Role',
        },
        {
            id: 'permissionLable',
            disablePadding: false,
            label: 'Dashboard Permission',
        },
    ];
    const [orderType, setOrderType] = useState('asc' as Order);
    const [orderBy, setOrderBy] = useState('employeeNumber' as keyof RowItem);
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof RowItem) => {
        const isAsc = orderBy === property && orderType === 'asc';
        setOrderType(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (
        a: { [key in Key]: any },
        b: { [key in Key]: any },
    ) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
   

    return {
        headCells: defaultHeadCells,
        order: orderType,
        orderBy: orderBy,
        handleRequestSort,
        stableSort,
        getComparator,
    };
}

export default useEnhancedTableHead;