import { FunctionComponent } from "react";
import { CustomButton } from "components/DeviceAddressSetting/DeviceAddressSetting";
import { useDialogController } from "pages/sensors/truck/QrMapping/useDialogController";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { SaveButton } from "components/Button/DialogButtonStyles";
import { stopMeasuring } from "api/useDeviceAddressAPI";
import SnackbarUtils from "helpers/utils";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface StopMeasuringProps {
    deviceId: string;
    addrDes: string;
    disabled?: boolean;
    fetchAddr: () => Promise<any>;
}

const StopMeasuring: FunctionComponent<StopMeasuringProps> = (props) => {
    const { deviceId, addrDes, disabled, fetchAddr } = props;
    const { isOpen, handleDialogClose, handleDialogOpen } = useDialogController();
    const buttonText = "หยุดการตรวจวัด";

    const handleSubmit = () => {
        console.log("submit");
        console.log("deviceId", deviceId);
        console.log("addrDes", addrDes);
        
        
        stopMeasuring(deviceId, addrDes).then((result) => {
            console.log("StopMeasuring result", result);
            if (result) {
                SnackbarUtils.success("หยุดการตรวจวัดสำเร็จ");
                fetchAddr();
            } else {
                SnackbarUtils.error("หยุดการตรวจวัดไม่สำเร็จ");
            }            
        }).catch((err) => {
            console.error(err);
            SnackbarUtils.error("ไม่สามารถหยุดการตรวจวัดได้");
        });
        handleDialogClose();
    };

    return (
        <>
            <CustomButton
                variant="contained"
                color="primary"
                size="small"
                disabled={disabled}
                startIcon={<StopCircleIcon sx={{ color: "#4C4C4C" }} />}
                onClick={handleDialogOpen}
            >
                {buttonText}
            </CustomButton>
            <Dialog open={isOpen} onClose={handleDialogClose} aria-labelledby="stop-measure">
                <DialogTitle id={"stop-measure"}>
                    {buttonText}
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                        padding={2}
                    >   
                        <WarningRoundedIcon sx={{ color: "yellow" }} />
                        <Typography variant="h4" color="initial">ต้องการหยุดการตรวจวัด ?</Typography>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        color="primary"
                    >
                        ยกเลิก
                    </Button>
                    <SaveButton
                        onClick={handleSubmit}
                        color="primary"
                    >
                        ยืนยัน, หยุดการตรวจวัด
                    </SaveButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default StopMeasuring;