import React, { useEffect } from 'react'
import styled from 'styled-components'
import type { RouteComponentProps } from 'react-router-dom'
import { useParams, useHistory } from 'react-router-dom'

import { useLayoutDispatch, changeHeaderText, HeaderTextLink } from "context/LayoutContext";
import WeatherSensorCard from 'components/Card/WeatherSensorCard'
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField'

import BaseRawTable from 'components/BaseTable/BaseRawTable'
import {
  headCellsDevice
} from './WeatherInterfaces'

import SelectDevice from 'components/SelectBox/SelectDevice'

// component map
import MapWeather from 'components/Map/MapWeather.js';

//API
import {
  useGetDeviceAPI,
  WeatherItem,
  DEVICES,
  STATUS,
  useGetFile,
  FILES,
  mapresponseMapLayer,
  Layer
} from 'api/useRequest'

import useUserContext from 'context/UserContext';

import SettingDevice from 'components/Button/SettingDevice'

import Icon_Chemistry from "assets/icons/icon-chemistry.png"

import parseDateTime from 'helpers/parseDateTime'

import { SelectChangeEvent } from "@mui/material";

import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  Paper
} from "@mui/material"

// wireless datetimepicker
import DateTimeRangePicker from "components/Datetime/DateTimeRangePicker";

import { styled as styledMui } from '@mui/material/styles';

import * as dateFns from 'date-fns';

import icon_temperature from 'assets/icons/weatherIcon/icon-tempereture.svg'
import icon_rain from 'assets/icons/weatherIcon/icon-rain.svg'
import icon_air from 'assets/icons/weatherIcon/icon-air.svg'
import { TabContext, TabPanel } from "@mui/lab";
import CustomizedTabs from 'components/TabBar/TabBar';
import { CardTemplate } from 'components/Card/CardTemplate';

import { defaultWeatherConfigItem, ISetWeatherConfig, IWeatherConfigItem, reqGetConfig, reqUploadConfig } from 'api/useConfigSettingAPI';
import DeviceSettingDialog, { DeviceType, IWeatherSetting } from 'components/Dialog/DeviceSettingDialog';
import SettingDeviceButton from 'components/Button/SettingDeviceBtn';
import DetailsCard from 'components/Card/DetailsCard';
import { SimpleLoading } from 'components/Loading/SimpleLoading';

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { GraphListCard } from "../../../components/Card/GraphListCard";
import { WEATHER_STATION_GRAPH_BASE_URL, WEATHER_STATION_GRAPH_PANEL_ID_LIST } from "../../../constants/Graph";
import BaseMap from 'components/Map/BaseMap';
import SensorMarker from 'components/Map/Marker/SensorMarker';
import { IDevAddrResItem, useDeviceAddressSelector } from 'components/DeviceAddressSelector/useDeviceAddressSelector';
import DeviceAddressSelector from 'components/DeviceAddressSelector/DeviceAddressSelector';
import DeviceAddressSetting from 'components/DeviceAddressSetting/DeviceAddressSetting';
import DefaultDateTimeRange from 'constants/DefaultDateTimeRage';
import StopMeasuring from 'components/StopMeasuring/StopMeasuring';

let TimeInterval: any
let IntervalTime: number = 60

type deviceIDParams = {
  deviceID: string;
};
interface Options {
  name: string;
  value: string;
}

const HEADER_TEXT = "ระบบตรวจวัดคุณภาพอากาศ"
const L = window.L;

function createHeaderTextLink(name: string): HeaderTextLink {
  return {
    texts: [HEADER_TEXT, `${name}`],
    link: `${window.location.protocol}//${window.location.host}/weather`
  };
}

const WeatherPageDevice: React.FC<RouteComponentProps> = () => {
  //fetch data
  let { deviceID } = useParams<deviceIDParams>();
  let history = useHistory();
  const dispatch = useLayoutDispatch()
  const isLogedin = useUserContext().auth?.access_token != undefined ? true : false;;

  const FatchApi = () => {
    WeatherAPI.mutate(DEVICES.WEATHER, {
      onSuccess: (data: any) => {
        var option_: Options[] = []
        setWeatherListDeviceRaw(data?.data?.weather?.data?.items)
        data?.data?.weather?.data?.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1)).forEach((e: WeatherItem, i: number) => {
          if (e.device_id === DeviceID) {
            setWeatherTitle(e.device_name)
            const headerTextLink: HeaderTextLink = createHeaderTextLink(e.device_name);
            changeHeaderText(dispatch, headerTextLink)
            setWeatherListDevice([e])

          }
          option_.push({
            name: e.device_name,
            value: e.device_id,
          })
        })
        getConfig(DeviceID)

        setOption(option_)
      },
    })
  }


  // fetch data weather
  const WeatherAPI = useGetDeviceAPI();
  const ArseicGetFile = useGetFile()

  const [ElementMap, setElementMap] = React.useState<any>(null);
  const [WeatherListDevice, setWeatherListDevice] = React.useState<WeatherItem[]>([])
  const [WeatherListDeviceRaw, setWeatherListDeviceRaw] = React.useState<WeatherItem[]>([])
  const [WeatherTitle, setWeatherTitle] = React.useState<String>('')
  const [Option, setOption] = React.useState<Options[]>([])
  const [DeviceID, setDeviceID] = React.useState<string>(deviceID)
  const [LastFetchedTime, setLastFetchedTime] = React.useState<string>(parseDateTime(new Date()));
  const [PickerDateTIme, setPickerDateTIme] = React.useState<Date[]>(DefaultDateTimeRange[DEVICES.WEATHER](new Date()));

  useEffect(() => {
    changeHeaderText(dispatch, "ระบบตรวจวัดคุณภาพอากาศ");
    clearInterval(TimeInterval);
    (() => {
      FatchApi()
      TimeInterval = setInterval(() => {
        setLastFetchedTime(parseDateTime(new Date()))
        FatchApi()
      }, 1000 * 60);
    })()

    return () => {
      clearInterval(TimeInterval)
    }

  }, [DeviceID]);
  const [curConfigItem, setCurConfigItem] = React.useState<IWeatherConfigItem>(defaultWeatherConfigItem)
  async function getConfig(deviceID: string) {
    const res = await reqGetConfig(DEVICES.WEATHER);
    if (res.status !== 200) {
      return
    }

    const configs: IWeatherConfigItem[] = res.data;
    const configItem: IWeatherConfigItem = configs.find(e => e.device_id == deviceID) ?? defaultWeatherConfigItem;
    setCurConfigItem(configItem)
  }

  /* Set threshold */
  const queryParams = new URLSearchParams(window.location.search);
  const limitHigh = queryParams.get('limitHigh');
  const limitLow = queryParams.get('limitLow');
  const [limit, setLimit] = React.useState<string[]>([])
  useEffect(() => {
    if (limitLow !== null && limitHigh !== null) {
      setLimit([limitLow!, limitHigh!])
    }
  }, [])

  const onDateTimeChange = (value: Date[]) => {
    setPickerDateTIme(value)
  }

  const setLastDataByAddr = (addr_des: string) => {

    const devAddrResItem = devAddrRes.find((e: IDevAddrResItem) => e.addr_des === addr_des);
    if (!devAddrResItem) return

    let { config, last_value } = devAddrResItem;

    if (config) {
      setCurConfigItem({ ...curConfigItem, ...config });
    }

    if (last_value) {
      if (!last_value.latitude && !last_value.longitude) {
        last_value.latitude = devAddrResItem.latitude;
        last_value.longitude = devAddrResItem.longitude;
      }
      setWeatherListDevice([{ ...WeatherListDevice[0], ...last_value }]);
    }
  }

  const cbAddrSelected = (addr_des: string, from: (Date | null), to: (Date | null)) => {
    setPickerDateTIme([from!, to ?? new Date()])
  }
  const { maxDate, minDate, devAddrRes, devAddr, isNotSelectedLastAddr, isMeasuringStop, handleDevAddrChange, fetchDeviceAddress } = useDeviceAddressSelector(DEVICES.WEATHER, deviceID, PickerDateTIme, cbAddrSelected);
  useEffect(() => {
    setLastDataByAddr(devAddr);
  }, [devAddr])

  return (
    <DashboardContainer>

      <Grid direction="row" container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>

        {/* ====================== First row  ====================== */}
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="baseline"
          alignContent="center"
          wrap="nowrap"
          gap={1}
        >
          {/* column 1 */}
          <SelectDevice selected={DeviceID} Option={Option} onChange={(event: SelectChangeEvent) => {
            var Device_id_ = event.target?.value
            setDeviceID(Device_id_)
            history.replace(`/weather/device/${Device_id_}`)
            WeatherListDeviceRaw.forEach((e: WeatherItem, i: number) => {
              if (Device_id_ === e.device_id) {
                const headerTextLink: HeaderTextLink = createHeaderTextLink(e.device_name);
                setWeatherListDevice([e])
                changeHeaderText(dispatch, headerTextLink)
                setWeatherTitle(e.device_name)
              }
            })
            getConfig(Device_id_);
          }} />
          {/* column 2 */}
          <DeviceAddressSelector address={devAddrRes} value={devAddr} onChange={handleDevAddrChange} />
          {/* column 3 */}
          <Grid
            item
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <Grid item width={'fit-content'} >
              {!isNotSelectedLastAddr ?
                isMeasuringStop ?
                  <DeviceAddressSetting deviceId={DeviceID} fetchAddr={fetchDeviceAddress} /> :
                  <StopMeasuring deviceId={DeviceID} addrDes={devAddr} fetchAddr={fetchDeviceAddress} />
                : null
              }
            </Grid>
            {/* setting device */}
            <Grid item width={'fit-content'} >
              <DeviceSettingButton disabled={isNotSelectedLastAddr} fatchApi={FatchApi} curConfigItem={curConfigItem} curSensorItem={WeatherListDevice[0]} />
            </Grid>

          </Grid>
        </Grid>

        {/* ====================== Second row  ====================== */}
        {/* Column 1 map */}
        <Grid item xs={100} sm={100} md={100} lg={100} xl={60}>
          <MapBox>
            <BaseMap>
              {
                WeatherListDevice.map((e: WeatherItem, i: number) => (
                  <SensorMarker
                    key={`WeatherMarker_${i}`}
                    type={DEVICES.WEATHER}
                    state={e.state}
                    position={[Number(e.latitude), Number(e.longitude)]}
                    label={e.device_name}
                    data={e}
                  />
                ))
              }
            </BaseMap>
          </MapBox>
        </Grid>

        {/* Column 2 card */}
        {WeatherListDevice.length === 0 ?
          <Grid item xs={100} sm={100} md={100} lg={100} xl={40} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
            <SimpleLoading />
          </Grid> :
          <Grid item xs={100} sm={100} md={100} lg={100} xl={40} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
            <Grid item width="100%" marginLeft="-8px" >
              <LastUpdateTextField fullWidth datetime={LastFetchedTime} onClick={() => {
                FatchApi()
                setLastFetchedTime(parseDateTime(new Date()))
              }} />
            </Grid>
            <br />
            {WeatherListDevice.map((e: any, i: number) => (
              <DetailsCard
                key={`${i}_${e.device_id}`}
                id={e.device_id}
                name={e.device_name}
                date={e.timestamp}
                state={e.state}
                data={[
                  { name: "PM 2.5", value: e.pm_25, unit: "ug/m³", enlarge: true },
                  { name: "PM 10", value: e.pm_10, unit: "ug/m³", enlarge: true },
                  { name: "Temperature", value: e.temperature, unit: "°C" },
                  { name: "Humidity", value: e.humidity, unit: "%" },
                  { name: "", value: "", unit: "" },
                  { name: "", value: "", unit: "" },

                ]}
                WeatherData={[e]}
                location={`${0}, ${0}`}
              />
            ))}
          </Grid>
        }

        {/* Column 3 notification */}
        {/* <Grid item xs={100} sm={100} md={100} lg={100} xl={20}>
          <ArsenicNoticeTable tableName={"การแจ้งเตือนความผิดปกติ"} headCells={headCells} rows={mockTableData}/>
        </Grid> */}

        {/* ====================== Third row  ====================== */}
        {/* column 1 */}
        <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
          <DeviceDataTab
            tabName={["กราฟ", "ข้อมูลดิบ"]}
            tabElement={[
              <GraphListCard
                urlPrefix={WEATHER_STATION_GRAPH_BASE_URL + `&var-limit_high=${limit[1]}&var-limit_low=${limit[0]}`}
                panelIdList={WEATHER_STATION_GRAPH_PANEL_ID_LIST}
                dateTimeRange={PickerDateTIme}
                deviceIdList={[deviceID]}
                yAxisConfigurable
              />,
              <CardTemplate>
                <BaseRawTable tableName={"รายละเอียดระบบตรวจวัดคุณภาพอากาศ"} device={DEVICES.WEATHER} DateTime={PickerDateTIme} order={"asc"} orderBy={"device_id"} tableCell={headCellsDevice} device_id={DeviceID} />
              </CardTemplate>
            ]}
            tabWithDatePicker={[0, 1]}
            dateTimeRange={PickerDateTIme}
            onDateTimeChange={onDateTimeChange}
            maxDateTime={maxDate ?? undefined}
            minDateTime={minDate ?? undefined}
          />
        </Grid>


      </Grid>

    </DashboardContainer>
  )
}

export default WeatherPageDevice

const GraphElement = (props: { link: string[], deviceID: string }) => {
  return (
    <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0" container rowSpacing={2} columnSpacing={2}>
      {props.link !== undefined &&
        props.link.map((e: any, index: number) => {
          if ((e.includes('&panelId=14') && props.deviceID !== 'e6a7010705000001') || (e.includes('&panelId=15') && props.deviceID !== 'e6a7010705000002')) {
            return (<div key={`${index}_Graph_link_panelId=14`}></div>);
          }
          return (
            <Grid key={`${index}_Graph_link_Grid`} width="100%" item xs={100} sm={100} md={100} lg={100} xl={100}>
              <CardTemplate
                key={`${index}_Graph_link_CardTemplate`}
              >
                <div key={`${index}_Graph_link_div`}>
                  <iframe
                    key={`${index}_Graph_link_iframe`}
                    src={e}
                    width="100%"
                    height={(e.includes('&panelId=14') || e.includes('&panelId=15')) ? "500px" : "370"}
                    frameBorder="0"
                  >
                  </iframe>
                </div>
              </CardTemplate>
            </Grid>
          );
        })
      }
    </Grid>
  )
}

const DashboardContainer = styled.div`
  margin-left: 10px;
`

const NormalText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontSize: '120%',
  paddingBottom: theme.spacing(0.5),
}));

const MapBox = styled(Paper)(({ theme }) => ({
  minHeight: "315px",
  [theme.breakpoints.up('md')]: {
    height: "100%"
  }
}));

const BoxTabPicker = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-btween;
`

const Mdivider = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 10px;
  margin-bottom: 10px;
`

function DeviceSettingButton({ disabled, fatchApi, curConfigItem, curSensorItem }: { disabled?: boolean, fatchApi: () => void, curConfigItem: IWeatherConfigItem, curSensorItem: WeatherItem }) {
  /* Use to set header text */
  let { deviceID } = useParams<{ deviceID: string; }>();
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [settingData, setSettingData] = React.useState<IWeatherSetting>({
    device_name: "",
    sampling_rate: 0,
    latitude: 0,
    longitude: 0,
  });

  async function uploadConfig(values: Record<string, any>) {
    let body: ISetWeatherConfig = {
      device_id: deviceID,
      device_name: values.device_name,
      sampling_rate: values.sampling_rate,
      latitude: values.latitude,
      longitude: values.longitude,
    }
    console.log(body);


    const res = await reqUploadConfig(DEVICES.WEATHER, body);

    // if (res.status !== 200) {
    //   return;
    // }
    fatchApi()

  }

  function onDialogSave(values: Record<string, any>) {
    uploadConfig(values);
    setDialogOpen(false);
    //TODO: upload saved data
  };

  function onDialogCancel() {
    setDialogOpen(false);
  };

  function onTapSettingButton() {
    //TODO: implement function ontap of DownloadButton
    console.log("Test onTap DownloadButton");
    setDialogOpen(true);
  }

  useEffect(() => {
    if (isDialogOpen === true) {
      return
    }
    if (curConfigItem === undefined || curSensorItem === undefined) {
      return;
    }
    setSettingData({
      device_name: curSensorItem.device_name,
      sampling_rate: curConfigItem.sampling_rate,
      latitude: parseFloat(curConfigItem.latitude),
      longitude: parseFloat(curConfigItem.longitude),
    });
  }, [curConfigItem, curSensorItem])

  return <>
    <SettingDeviceButton disabled={disabled} onTap={onTapSettingButton} />
    <DeviceSettingDialog
      isOpen={isDialogOpen}
      deviceType={DeviceType.Weather}
      currentValue={settingData}
      valueDescription={{
        device_name: "Device Name",
        sampling_rate: "Sampling Rate",
        latitude: "Latitude",
        longitude: "Longitude",
      }}
      description="Configuration of Weather"
      onCancel={onDialogCancel}
      onSave={onDialogSave} />
  </>;


}
