import requestInstance from "configure/requestConfig";
import { formatDate } from "../helpers/formatDate";

//API
import {
    STATUS,
} from 'api/useRequest'
import parseDate from "helpers/parseDate";
import parseTime from "helpers/parseTime";

export interface ISensorItem {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    level: number;
    raw: number;
    state: string;
    latitude: number;
    longitude: number;
    timestamp: string;
    [key: string]: any;
}

export const defaultSensorItem: ISensorItem = {
    "device_id": "",
    "device_name": "",
    "power_pri": 0,
    "power_sec": 0,
    "level": 0,
    "raw": 0,
    "state": "",
    "latitude": 0,
    "longitude": 0,
    "timestamp": ""
}

export const MapStateToDeviceCardState: Record<string, number> = {
    "Online": STATUS.ONLINE,
    "Offline": STATUS.OFFLINE,
    "Error": STATUS.ERROR,
}
export async function reqCurDeviceData() {
    const path = "/api/iot/all";
    const params = {
        limit: "",
        offset: "",
        device: "water_level"
    };

    const res = await requestInstance.get(path, { params: params, });
    return res;
}

export async function reqDeviceData(dateRange: Date[], device_id?: string) {

    const path = "/api/iot/water_level";
    const params = {
        limit: 10000,
        offset: 0,
        start: parseDate(dateRange[0]) + ' ' + parseTime(dateRange[0]),
        end: parseDate(dateRange[1]) + ' ' + parseTime(dateRange[1]),
        device_id
    };

    const res = await requestInstance.get(path, { params: params, });

    return res;
}


export async function reqFileLayer() {
    const path = "/api/files/types";
    const params = {
        limit: "",
        offset: ""
    };

    const res = await requestInstance.get(path, { params: params, });
    console.log(res);

    return res;
}

export async function reqSummary() {
    const path = "/api/iot/water_level_summary";
    return await requestInstance.get(path);
}
