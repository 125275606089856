const RoutePath = {
    dashboard:'/dashboard',
    setting: '/setting',
    usermanagement: '/usermanagement',
    weather: '/weather',
    weatherDevice: '/weather/device/:deviceID',
    arsenic: "/arsenic",
    arsenicDevice: "/arsenic/device/:deviceID",
    wireless: '/wireless',
    conveyor: '/conveyor',
    conveyorDevice: '/conveyor/device/:deviceID',
    landslide: '/landslide',
    landslideDevice: "/landslide/device/:deviceID",
    waterlevel: '/waterlevel',
    waterlevelDevice: "/waterlevel/device/:deviceID",
    piezometer: '/piezometer',
    piezometerDevice: "/piezometer/device/:deviceID",
    maplayersetting: "/maplayersetting",
    gpstracking: '/gpstracking',
    gpstrackingDevice: "/gpstracking/device/:deviceID",
    truck: '/truck',
    noti_line: "/line_noti",
}
export default RoutePath