import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import { usePageContext } from "./ConveyorPage";
import BaseCurrentTable from "components/BaseTable/BaseCurrentTable";
import { DEVICES } from "api/useRequest";
import { CardTemplate } from "components/Card/CardTemplate";
import parseDateTime from "helpers/parseDateTime";
import { GetDashboardButton } from "components/Card/DetailsCard";
import { useHistory } from "react-router-dom";


export function DeviceTable() {
    let history = useHistory()
    const { stateContext, dispatchContext } = usePageContext();
    const [dataList, setDataList] = useState<any[]>([]);

    useEffect(() => {
        const datas: any[] = stateContext.curSensorList.map((e: any) => {
            const {
                device_id,
                device_name,
                power_pri,
                power_sec,
                status,
                raw,
                value,
                // latitude,
                // longitude,
                state,
                timestamp,
                line,
                name
            } = e;
            return {
                device_id: device_id.toUpperCase(),
                device_name,
                power_pri,
                power_sec,
                raw,
                value: ConveyorValueToString(value,state),
                status,
                // latitude,
                // longitude,
                state,
                timestamp,
                line,
                name,
                see_dashboard: (<GetDashboardButton variant="outlined" type="submit" style={{ width: "fit-content" }}
                    onClick={() => {
                        history.push(`/conveyor/device/${device_id}?limitLow=${stateContext.limit[0]}&limitHigh=${stateContext.limit[1]}`)
                    }}
                >
                    {"ดูแดชบอร์ด"}
                </GetDashboardButton>)
            };
        });
        setDataList(datas);
    }, [stateContext.curSensorList]);

    const ConveyorValueToString = (value: number, state: string) => {
        return state === "Offline" ? "-" : value ? "ทำงาน" : "หยุดทำงาน"
    }

    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0">
        <CardTemplate>
            <BaseCurrentTable tableName={"รายละเอียดการทำงานสายพานลำเลียงดิน"} device={DEVICES.CONVEYOR} order={"asc"} orderBy={"line"} tableCell={headCells} DataCurrentDevice={dataList} />
        </CardTemplate>
    </Grid>;
}

interface HeadCell {
    disablePadding?: boolean;
    id: keyof any;
    subid?: keyof any;
    label: string;
    sublabel?: string;
    numeric: boolean;
    isStatus?: boolean;
    isOnline?: boolean;
    isUpper?: boolean;
    isUseFormatTime?: boolean;
    Zero?: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'state',
        numeric: false,
        isOnline: true,
        label: '',
    },
    {
        id: 'device_name',
        subid: 'device_id',
        numeric: false,
        label: 'Device Name',
        sublabel: 'ID'
    },
    {
        id: 'line',
        numeric: false,
        isUpper: true,
        label: 'Line',
    },
    {
        id: 'name',
        numeric: false,
        label: 'Name',
    },
    // {
    //     id: 'device_id',
    //     numeric: false,
    //     isUpper: true,
    //     label: 'ID',
    // },
    // {
    //     id: 'latitude',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Latitude',
    // },
    // {
    //     id: 'longitude',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Longitude',
    // },
    {
        id: 'value',
        numeric: false,
        disablePadding: false,
        Zero: true,
        label: 'สถานะสายพาน',
    },
    {
        id: 'timestamp',
        numeric: false,
        label: 'Last Updated',
        isUseFormatTime: true
    },
    // {
    //     id: 'raw',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Raw',
    // },
    {
        id: 'power_pri',
        numeric: true,
        disablePadding: false,
        label: 'Solar Cell (V)',
    },
    {
        id: 'power_sec',
        numeric: true,
        disablePadding: false,
        label: 'Battery (V)',
    },
    {
        id: 'see_dashboard',
        numeric: false,
        disablePadding: false,
        label: 'Dashboard',
    },
];
