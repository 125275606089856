import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { usePageContext } from "./ConveyorPageDevice";
import { useParams } from "react-router-dom";
import { GraphCard, IGraphCard } from "components/Card/GraphCard";
import { BarChart, IBarChartDataSets } from "components/Graph/BarChart";
import { differenceInMilliseconds } from "date-fns";
import { CardTemplate } from "components/Card/CardTemplate";
import { SimpleLoading } from "components/Loading/SimpleLoading";
import useUserContext from "context/UserContext";


// const GRAPH_CARD_DATA: IGraphCard[] = [
//     // { title: "Conveyor", link: `${process.env.REACT_APP_GRAFANABASE_BASE_URL}/d-solo/conv/conveyor?orgId=1&var-device_id=All&from=${new Date().getTime()}&to=${new Date().getTime()}&panelId=10`, onTapDownload: () => { } },
//     // { title: "Primary power", link: `${process.env.REACT_APP_GRAFANABASE_BASE_URL}/d-solo/conv/conveyor?orgId=1&var-device_id=All&from=${new Date().getTime()}&to=${new Date().getTime()}&panelId=12`, onTapDownload: () => { } },
//     // { title: "Secondary power", link: `${process.env.REACT_APP_GRAFANABASE_BASE_URL}/d-solo/conv/conveyor?orgId=1&var-device_id=All&from=${new Date().getTime()}&to=${new Date().getTime()}&panelId=9`, onTapDownload: () => { } },
// ];
export function GraphListPanel() {
    const { stateContext, dispatchContext } = usePageContext();
    const [graphCards, setGraphCards] = useState<IGraphCard[]>([]);
    const [barChartLabels, setBarChartLabels] = useState<string[]>([]);
    const [barChartDataSets, setBarChartDataSets] = useState<IBarChartDataSets[]>([])

    const accessToken = useUserContext().auth?.access_token

    /* Get params */
    let { deviceID } = useParams<{ deviceID: string; }>();
    useEffect(() => {

        /* List of device id */
        let labelNames: string[] = []
        let labels: string[] = []

        let dataSet: IBarChartDataSets[] = []

        let operateHr: any = {}
        /*
            {
                device_id:{
                    onTime:0,
                    offTime:0,
                }
            }
        */
        /* pre loop time */
        let tempTimestamp: Date = new Date()
        /* pre loop value */
        let tempValue: number = 0
        let listByID: any = {}
        /* split by id */
        stateContext.allSensorList.forEach((e: any) => {
            if (labels.includes(e.device_id) === false) {
                /* Add id to labels */
                labels.push(e.device_id)
                labelNames.push(e.device_name)
                /* init perateHr */
                operateHr[`${e.device_id}`] = {
                    onTime: 0,
                    offTime: 0
                }

                listByID[`${e.device_id}`] = [e]
            } else {
                listByID[`${e.device_id}`].push(e)
            }
        })

        for (const key in listByID) {
            if (Object.prototype.hasOwnProperty.call(listByID, key)) {
                const list = listByID[key].sort((a: any, b: any) => (new Date(a.timestamp) > new Date(b.timestamp) ? 1 : -1));
                /* init */
                tempTimestamp = stateContext.dateRange[0]
                tempValue = list[0]?.value
                for (let index = 0; index <= list.length; index++) {
                    const element = list[index];
                    let element_date = new Date()
                    if (index === list.length) {
                        element_date = stateContext.dateRange[1]
                    } else {
                        element_date = new Date(element.timestamp)
                    }

                    if (tempValue === 0) {
                        /* add off */
                        operateHr[key].offTime = operateHr[key]?.offTime + differenceInMilliseconds(element_date, tempTimestamp)
                    } else if (tempValue === 1) {
                        /* add on */
                        operateHr[key].onTime = operateHr[key]?.onTime + differenceInMilliseconds(element_date, tempTimestamp)
                    } else {

                    }
                    tempTimestamp = new Date(element_date)
                    tempValue = element?.value
                }

            }
        }


        setBarChartDataSets([
            {
                label: 'Turn On',
                data: labels.map((e: string) => operateHr[e].onTime / (1000 * 60 * 60)),
                backgroundColor: '#62BC3B',
            },
            {
                label: 'Turn Off',
                data: labels.map((e: string) => operateHr[e].offTime / (1000 * 60 * 60)),
                backgroundColor: '#C4C4C4',
            }
        ]
        )

        setBarChartLabels(labelNames);
        setGraphCards([
            { title: "State timeline", linkPrefix: `${process.env.REACT_APP_GRAFANABASE_BASE_URL}/d-solo/conv/conveyor?orgId=1`, dateTimeRange: [], panelId: 17, onTapDownload: () => { } },
            // { title: "Primary power", link: `${process.env.REACT_APP_GRAFANABASE_BASE_URL}/d-solo/conv/conveyor?orgId=1&var-device_id=${deviceID}&from=${stateContext.dateRange[0].getTime()}&to=${stateContext.dateRange[1].getTime()}&panelId=12`, onTapDownload: () => { } },
            // { title: "Secondary power", link: `${process.env.REACT_APP_GRAFANABASE_BASE_URL}/d-solo/conv/conveyor?orgId=1&var-device_id=${deviceID}&from=${stateContext.dateRange[0].getTime()}&to=${stateContext.dateRange[1].getTime()}&panelId=9`, onTapDownload: () => { } },
        ]);
    }, [stateContext.allSensorList, stateContext.limit]);
    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0" container rowSpacing={2} columnSpacing={2}>
        <Grid width="100%" item xs={100} sm={100} md={100} lg={100} xl={100}>
            <CardTemplate>
                <Grid
                    item
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                >

                    {stateContext.allSensorList.length === 0 ? <SimpleLoading /> : <BarChart
                        title="Operating hours"
                        labels={barChartLabels}
                        datasets={barChartDataSets}
                    />}
                </Grid>
            </CardTemplate>
        </Grid>
        {graphCards.map((e: any, index: number) => (
            <GraphCard
                key={`${index}_${e.title}`}
                title={e.title}
                dateTimeRange={stateContext.dateRange}
                linkPrefix={e.linkPrefix + `&auth_token=${accessToken}`}
                panelId={e.panelId}
                onTapDownload={e.onTapDownload}
            />
        ))}
    </Grid>;
}
