import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { HeaderCell } from "../CountingTable/Styles";
import Typography from "@mui/material/Typography";
import { FunctionComponent } from 'react';
import { QrMapItem } from 'api/useTruckAPI';
import Grid from '@mui/material/Grid'
import { Chip, Button } from '@mui/material';
import AddEditQrMapDialog, { QrMapItemSaved } from './AddEditQrMapDialog';

interface QrMappingTableProps {
    qrMaps: QrMapItem[];
    onEditQrMapItem?: (values: QrMapItemSaved) => void;
    onDeleteQrMapItem?: (id: number) => void;
}

const QrMappingTable: FunctionComponent<QrMappingTableProps> = (props) => {
    const {
        qrMaps,
        onEditQrMapItem,
        onDeleteQrMapItem,
    } = props;
    return (
        <TableContainer /* sx={{ maxHeight: 440 }} */>
            <Table stickyHeader size="small" aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <HeaderCell variant="head" align="center" colSpan={1}>{"หมายเลขรถ"}</HeaderCell>
                        <HeaderCell variant="head" align="center" colSpan={1}>{"หมายเลข QR"}</HeaderCell>
                        <HeaderCell variant="head" align="center" colSpan={1}>{"แก้ไข/ลบ"}</HeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {qrMaps
                        .map((row: QrMapItem, index: number) => {

                            return (
                                <TableRow key={`data-row-${index}`}>

                                    {Object.keys(row).map((key: any, idx: number) => {
                                        const e = (row as Record<string, any>)[key];
                                        if (key === "name") {
                                            return (
                                                <TableCell key={`data-row-${index}-cell-${idx}`} variant="body" align="center" colSpan={1} sx={{ border: "1px solid #c4c4c4" }}>
                                                    <Typography variant="body1" color="initial"  >
                                                        {e !== 0 ? e : ""}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        } if (key === "qr_values") {
                                            return (
                                                <TableCell key={`data-row-${index}-cell-${idx}`} variant="body" align="center" colSpan={1} sx={{ border: "1px solid #c4c4c4" }}>
                                                    <Grid container gap={1} >
                                                        {e
                                                            .map((qr: string, i: number) => {
                                                                return (
                                                                    <Chip key={`qr-${qr}-${i}`} label={qr} />
                                                                    // <Typography key={`qr-${qr}-${i}`} variant="body1" color="initial"  >
                                                                    // {qr}
                                                                    // </Typography>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                </TableCell>
                                            );
                                        }
                                        return null;
                                    })}
                                    <TableCell variant="body" align="center" colSpan={1} sx={{ border: "1px solid #c4c4c4" }}>
                                        <AddEditQrMapDialog qrMaps={qrMaps} qrItem={row} onSave={onEditQrMapItem} />/
                                        <Button color="primary" onClick={() => onDeleteQrMapItem ? onDeleteQrMapItem(row.id) : () => { }}>ลบออก</Button>
                                    </TableCell>

                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default QrMappingTable;

