import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useLayoutDispatch, changeHeaderText } from "context/LayoutContext";
import DetailsCard, { GetDashboardButton } from 'components/Card/DetailsCard'
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField'
// component map
import MapPiezometer from 'components/Map/MapPiezometer.js';

// component table
import BaseCurrentTable from 'components/BaseTable/BaseCurrentTable'
import {
  headCells
} from './PiezometerInterfaces'

import * as dateFns from 'date-fns';

import { styled as styledMui } from '@mui/material/styles';

//API
import {
  useGetDeviceAPI,
  PiezometerItem,
  DEVICES,
  useGetFile,
  FILES,
  STATUS,
  Layer,
  mapresponseMapLayer
} from 'api/useRequest';

import useUserContext from 'context/UserContext';

import parseDateTime from 'helpers/parseDateTime'

import {
  Button,
  Box,
  Grid,
  Typography,
  Paper,
  Tooltip,
  IconButton,
} from "@mui/material"

import { CardTemplate } from 'components/Card/CardTemplate';
import { SimpleLoading } from 'components/Loading/SimpleLoading';
import { coordinateDecimalToMineGrid } from 'helpers/geo';
import SortBySelector from 'components/SelectBox/SortBySelector';

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { GraphListCard } from "../../../components/Card/GraphListCard";
import { PIEZOMETER_DEVICE_GRAPH_PANEL_ID_LIST, PIEZOMETER_GRAPH_BASE_URL, PIEZOMETER_GRAPH_PANEL_ID_LIST } from "../../../constants/Graph";
import BaseMap from 'components/Map/BaseMap';
import SensorMarker from 'components/Map/Marker/SensorMarker';
import CommentDialog from './CommentDialog';
import GroupManagement from '../landslide/GroupManagement/GroupManagement';
import { deleteDeviceGroup, getDeviceGroups, registerDeviceGroup, updateDeviceGroup } from 'api/useDeviceGroupingAPI';
import { RelProfilePiezometer } from 'components/Datetime/DatePickerRelativeProfile';
import { IPiezoConfigItem, ISetPiezoConfig, reqGetConfig, reqUploadConfig } from 'api/useConfigSettingAPI';
import DefaultDateTimeRange from 'constants/DefaultDateTimeRage';

let TimeInterval: any
const L = window.L;

const PiezometerPage: React.FC<RouteComponentProps> = () => {
  //fetch data
  const isLogedin = useUserContext().auth?.access_token != undefined ? true : false;
  const isAdmin = useUserContext().permission?.permissions.includes('admin');
  const apiPiezometer = useGetDeviceAPI();

  const FatchApi = () => {
    apiPiezometer.mutate(DEVICES.PIEZOMETER, {
      onSuccess: (data: any) => {
        let newData = data?.data?.ground_water?.data?.items;
        if (newData !== undefined) {
          console.log('fetch piezometer data', newData);

          newData = newData.map((item: any) => {
            if (item.hasOwnProperty('latitude') || item.hasOwnProperty('longtitude')) {
              const mineGrid = coordinateDecimalToMineGrid(item.latitude, item.longitude);
              item.mine_n = mineGrid.mineN.toFixed(2);
              item.mine_e = mineGrid.mineE.toFixed(2);
            }
            return item;
          });
        }

        setPiezometerDevice(newData.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1)))

        const online = newData.filter((e: any) => (e.state === 'Online')).length;
        setCountOnline(`${online}/${newData.length}`)
        setIsLoadingCurrentData(false)
      },
    })
  }

  //end fetch data
  const dispatch = useLayoutDispatch()
  useEffect(() => {
    changeHeaderText(dispatch, "ระบบตรวจวัดแรงดันน้ำใต้ดิน");
    (() => {
      FatchApi()
      TimeInterval = setInterval(() => {
        setLastFetchedTime(parseDateTime(new Date()))
        FatchApi()
      }, 1000 * 60);
    })()
    return () => {
      clearInterval(TimeInterval)
    }

  }, []);

  const [value, setValue] = React.useState(0);
  const [LastFetchedTime, setLastFetchedTime] = React.useState<string>(parseDateTime(new Date()));
  const [PickerDateTIme, setPickerDateTIme] = React.useState<Date[]>(DefaultDateTimeRange[DEVICES.PIEZOMETER](new Date()));
  const [PiezometerDevice, setPiezometerDevice] = React.useState<any[]>([]);



  /* push to lv 2 */
  let history = useHistory();


  const SortByOptions: Record<string, any> = {
    'Device ID': 'device_id',
    'Device Name': 'device_name',
    "kPa": 'kpa',
    "MSL": 'msl',
  }
  const [countOnline, setCountOnline] = useState<string>("");
  const [sortTypeCurData, setSortTypeCurData] = useState('Device Name');
  const [isLoadingCurrentData, setIsLoadingCurrentData] = useState(true);
  function sortDataList(opt: string) {
    if (SortByOptions[opt] === undefined) {
      return
    }
    if (SortByOptions[opt] === 'device_id' || SortByOptions[opt] === 'device_name') {
      setPiezometerDevice(PiezometerDevice.sort((a: any, b: any) => (a[SortByOptions[opt]] > b[SortByOptions[opt]] ? 1 : -1)))
    } else {
      setPiezometerDevice(PiezometerDevice.sort((a: any, b: any) => (a[SortByOptions[opt]] < b[SortByOptions[opt]] ? 1 : -1)))
    }
  }

  const onDateTimeChange = (value: Date[]) => {
    setPickerDateTIme(value)
  }

  const [selectedSensor, setSelectedSensor] = useState<string[]>([]);

  const onTapMarker = (data: { id: string; }) => {
    if (selectedSensor.includes(data.id) === false) {
      setSelectedSensor([...selectedSensor, data.id]);
      let targetCard = document.getElementById(`detail-card-${data.id}`);
      targetCard?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      let newSelected = [...selectedSensor];
      const index = newSelected.indexOf(data.id, 0);
      if (index > -1) {
        newSelected.splice(index, 1);
      }
      setSelectedSensor(newSelected);
    }
  }

  const [deviceGroup, setDeviceGroup] = useState<any[]>([]);

  useEffect(() => {
    getDeviceGroup();
  }, []);

  const getDeviceGroup = () => {
    getDeviceGroups(DEVICES.PIEZO).then((result) => {
      setDeviceGroup(result);
    }).catch((err) => {
      console.log({ err });
    });
  }

  const handleSelectedGroup = (groupName: string, index: number): void => {
    let idList: string[] = [];
    if (deviceGroup[index]?.group_name === groupName) {
      idList = deviceGroup[index].device_ids.map((e: string) => e.toLowerCase());
    }
    console.log('idList', idList);
    setSelectedSensor(idList);
  }

  const handleAddDeviceGroup = (groupName: string, selectedList: string[]) => {
    console.log("handleAddDeviceGroup", { groupName, selectedList });
    console.log("handleAddDeviceGroup", { groupName, selectedList });
    registerDeviceGroup(DEVICES.PIEZO, groupName, selectedList).then((result) => {
      getDeviceGroup();
    }).catch((err) => {

    });
  };
  const handleResetSelection = () => {
    setSelectedSensor([]);
  };
  const handleEditGroup = (groupId: number, groupName: string, selectedList: string[]) => {
    console.log("handleEditGroup", { groupId, groupName, selectedList });
    updateDeviceGroup(DEVICES.PIEZO, groupName, selectedList, groupId).then((result) => {
      getDeviceGroup();
    }).catch((err) => {

    });
  };
  const handleDeleteGroup = (groupId: number) => {
    console.log("handleDeleteGroup", groupId);
    deleteDeviceGroup(groupId).then((result) => {
      getDeviceGroup();
    }).catch((err) => {

    });
  };


  const [devConfigList, setDevConfigList] = useState<IPiezoConfigItem[]>([]);

  useEffect(() => {
    getDevConfigList();
  }, [])

  const getDevConfigList = () => {
    reqGetConfig(DEVICES.PIEZO).then((res) => {
      const configs: IPiezoConfigItem[] = res.data;
      setDevConfigList(configs);
    }).catch((err) => {
      console.error({ err });
    });
  }

  const setCommentConfig = (devId: string, comment: string) => {
    try {
      const config = devConfigList.find((e: IPiezoConfigItem) => e.device_id === devId);
      const dev = PiezometerDevice.find((e: PiezometerItem) => e.device_id === devId)
      if (config === undefined) throw new Error("config not found");

      const setPiezoConfig: ISetPiezoConfig = {
        device_id: devId,
        device_name: dev.device_name,
        sampling_rate: config.sampling_rate,
        ntc_res: config.ntc_res,
        ntc_beta: config.ntc_beta,
        piezo_g: config.piezo_g,
        piezo_k: config.piezo_k,
        piezo_zero_read: config.piezo_zero_read,
        piezo_temp: config.piezo_temp,
        tip_elevation: config.tip_elevation,
        latitude: parseFloat(config.latitude),
        longitude: parseFloat(config.longitude),
        area: config.area,
        observation: config.observation,
        elevation: config.elevation,
        remark: comment,
      }

      setDevConfig(setPiezoConfig);
    } catch (error) {
      console.error({ error });
    }

  }

  const setDevConfig = (config: ISetPiezoConfig) => {
    try {
      reqUploadConfig(DEVICES.PIEZO, config).then((result) => {
        getDevConfigList();
      }).catch((err) => {
        console.error({ err });
      });
    } catch (error) {
      console.error({ error });
    }
  }


  return (
    <DashboardContainer>
      <Grid direction="row" container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
        {/* ====================== First row  ====================== */}
        {/* column 1 */}
        <Grid item style={{ display: "flex", alignItems: "center", }} xs={100} sm={100} md={30} lg={30} xl={30}>
          <Box id={"Topic"}>
            <NormalText>ระบบตรวจวัดแรงดันน้ำใต้ดิน</NormalText>
          </Box>
        </Grid>

        {/* column 2 */}
        <Grid item xs={100} sm={100} md={70} lg={70} xl={70}>

          <Grid direction="row" container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>

            <Grid item xs={100} sm={100} md={100} lg={100} xl={100} style={{ display: "flex", justifyContent: "flex-end" }}>
              <LastUpdateTextField datetime={LastFetchedTime} onClick={() => {
                FatchApi()
                setLastFetchedTime(parseDateTime(new Date()))
              }} />
            </Grid>

          </Grid>

        </Grid>

        {/* ====================== Second row  ====================== */}
        <Grid direction="column" container item xs={100} sm={100} md={100} lg={100} xl={100}>
          <Grid direction="row" container item xs={100} sm={100} md={100} lg={100} xl={100} style={{ backgroundColor: "white" }}>
            {/* column 1 */}
            <Grid item xs={100} sm={100} md={50} lg={65} xl={65} >
              <MapBox>
                {/* component map here. */}
                {PiezometerDevice.length > 0 ?
                  <BaseMap>
                    {
                      PiezometerDevice.map((e: PiezometerItem, i: number) => {
                        let conf = devConfigList.find((c:IPiezoConfigItem)=> e.device_id === c.device_id);
                        return (
                          <SensorMarker
                            key={`PiezoMeterMarker_${i}`}
                            type={DEVICES.PIEZO}
                            state={e.state}
                            position={[Number(e.latitude), Number(e.longitude)]}
                            label={e.device_name}
                            data={{...e , ...conf}}
                            isSelected={selectedSensor.includes(e.device_id)}
                            onClick={(event: any) => {
                              console.log(event);
                              onTapMarker({ id: e.device_id })
                            }}
                          />
                        )
                      })
                    }
                  </BaseMap>
                  : <SimpleLoading />}
              </MapBox>
            </Grid>

            {/* column 2 */}
            <Grid
              item
              xs={100} sm={100} md={50} lg={35} xl={35}
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="stretch"
              bgcolor={'#fff'}
            // maxHeight="500px"
            >
              {!isLoadingCurrentData ? <Grid item width={'100%'}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="stretch"
                wrap="nowrap"
                padding="8px"

              >
                <Typography variant="body2" color="initial" fontWeight={600} >อุปกรณ์ออนไลน์ {countOnline}</Typography>
                <SortBySelector label="เรียงลำดับ"
                  value={sortTypeCurData}
                  options={Object.keys(SortByOptions)}
                  onSelectedChange={(opt: string) => {
                    // setSortTypeCurData("")
                    setSortTypeCurData(opt)
                    sortDataList(opt)
                  }} />
              </Grid> : <Grid item width={'100%'}
                padding="16px"
                paddingBottom="0"></Grid>}
              <Grid item width={'100%'} /* xs={100} sm={100} md={100} lg={100} xl={100} */ >
                {PiezometerDevice.length === 0 ? <MapBox><SimpleLoading /></MapBox> : <CustomScrollBox sx={{
                  maxHeight: '445px',
                  overflow: 'auto',
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  bgcolor: "white",
                }}>
                  {PiezometerDevice.map((e: any, i: number) => {
                    let conf = devConfigList.find((c: IPiezoConfigItem) => e.device_id === c.device_id);
                    const {mineN, mineE} = coordinateDecimalToMineGrid(e.latitude,e.longitude);
                    return (
                      <DetailsCard key={`${i}_${e.device_id}`} id={e.device_id} name={e.device_name} state={e.state} date={e.timestamp}
                        path="/piezometer"
                        data={[
                          { name: "Mine N",               value: mineN,                         unit: ""      },
                          { name: "Total head",           value: e.msl,                         unit: "m.MSL" },
                          { name: "Mine E",               value: mineE,                         unit: ""      },
                          { name: "Pore Pressure",        value: e.kpa || "0",                  unit: "kPa"   },
                          { name: "Elevation",            value: conf?.elevation || "",         unit: "msl"     },
                          { name: "Digit",                value: e.digit,                       unit: ""      },
                          { name: "Tip Elevation",        value: conf?.tip_elevation || "",     unit: "msl"   },
                          { name: "Temperature",          value: e.temperature,                 unit: "°C"    },
                          { name: "Observation",          value: conf?.observation || "-",       unit: ""     },
                        ]}
                        location={`${e.latitude}, ${e.longitude}`}
                        isSelected={(selectedSensor.indexOf(e.device_id) !== -1)}
                        onTapSelected={(id, preState, curState) => {
                          if (curState === true && selectedSensor.includes(id) === false) {
                            setSelectedSensor([...selectedSensor, id]);
                          } else if (curState === false) {
                            let newSelected = [...selectedSensor];
                            const index = newSelected.indexOf(id, 0);
                            if (index > -1) {
                              newSelected.splice(index, 1);
                              setSelectedSensor(newSelected);
                            }
                          }
                        }}
                        utilButton={
                          <Grid item
                            container
                            direction="column"
                            alignItems={'flex-end'}
                            gap={1}
                          >
                            <CommentDialog
                              title={e.device_name}
                              comment={conf?.remark || ""}
                              onSave={(txt) => {
                                //TODO: Implement save comment to server.
                                setCommentConfig(e.device_id, txt);
                              }}
                            />
                            {/* <GetDashboardButton variant="outlined" type="submit" onClick={() => {
                              history.push(`/piezometer/device/${e.device_id}`)
                            }}>ดูแดชบอร์ด</GetDashboardButton> */}
                          </Grid>
                        }
                      />
                    )

                  }
                  )}

                </CustomScrollBox>}

              </Grid>

            </Grid>
          </Grid>
        </Grid>

        <Grid item direction="row" container justifyContent="flex-end" columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
          <GroupManagement
            type={DEVICES.PIEZO}
            deviceGroupList={deviceGroup}
            selectedItemList={selectedSensor}
            mapDeviceNameId={PiezometerDevice.reduce((pre: any, cur: any) => {
              let item: Record<string, string> = pre;
              item[cur.device_id] = cur.device_name;
              return item;
            }, {})}
            onAddGroup={handleAddDeviceGroup}
            onEditGroup={handleEditGroup}
            onDeleteGroup={handleDeleteGroup}
            onGroupSelected={handleSelectedGroup}
            onResetSelected={handleResetSelection}
          />
        </Grid>


        {/* ====================== Third row  ====================== */}
        {/* column 1 */}
        <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
          <DeviceDataTab
            tabName={["กราฟข้อมูลทั่วไป", "กราฟข้อมูลอุปกรณ์", "ตารางข้อมูลอุปกรณ์"]}
            tabElement={[
              <GraphListCard
                urlPrefix={PIEZOMETER_GRAPH_BASE_URL}
                panelIdList={PIEZOMETER_GRAPH_PANEL_ID_LIST}
                deviceIdList={selectedSensor}
                dateTimeRange={PickerDateTIme}
                yAxisConfigurable
              />,
              <GraphListCard
                urlPrefix={PIEZOMETER_GRAPH_BASE_URL}
                panelIdList={PIEZOMETER_DEVICE_GRAPH_PANEL_ID_LIST}
                deviceIdList={selectedSensor}
                dateTimeRange={PickerDateTIme}
                yAxisConfigurable
              />,
              <CardTemplate>
                <BaseCurrentTable tableName={"รายละเอียดอุปกรณ์ตรวจวัดคุณภาพน้ำ (Piezometer)"} device={DEVICES.PIEZOMETER} order={"asc"} orderBy={"device_id"} tableCell={headCells} DataCurrentDevice={PiezometerDevice} />
              </CardTemplate>
            ]}
            tabWithDatePicker={[0, 1]}
            dateTimeRange={PickerDateTIme}
            onDateTimeChange={onDateTimeChange}
            relativeProfile={RelProfilePiezometer}
          />
        </Grid>


      </Grid>
    </DashboardContainer >
  )
}

export default PiezometerPage

const DashboardContainer = styled.div`
  margin-left: 10px;
`
const NormalText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontSize: '120%',
  paddingBottom: theme.spacing(0.5),
}));

const TopDownloadButton = styled(Button)`
    border: 1px solid #0739A2;
    color: white;
    box-sizing: border-box;
    border-radius: 8px;
    width: 30%;
`
const MapBox = styled(Paper)`
  height: 500px;
`

export const CustomScrollBox = styled(Box)(({ theme }) => ({
  "> *": {
    margin: "12px 0"
  },
  '&::-webkit-scrollbar': {
    width: "6px"
  },
  '&::-webkit-scrollbar-track': {
    background: "#f1f1f1"
  },
  '&::-webkit-scrollbar-thumb': {
    background: "#888",
    borderRadius: "6px"
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: "#555"
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: '445px',
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: '300px',
    marginTop: "20px"
  },
}));

const BoxTabPicker = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-btween;
`

const TimePickerFlexWithBreakpint = styledMui(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  [theme.breakpoints.down('sm')]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
}));

const Mdivider = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 10px;
  margin-bottom: 10px;
`


