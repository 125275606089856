import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import styledComponent from "styled-components";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';

import { visuallyHidden } from '@mui/utils';

import color from "../../constants/Colors";

//API
import {
  useGatRawAPI,
  DEVICES
} from 'api/useRequest'

// helpers
import parseDateTime from 'helpers/parseDateTime'
import parseTime from 'helpers/parseTime'
import parseDate from 'helpers/parseDate'
import { coordinateDecimalToMineGrid } from 'helpers/geo';

type Order = 'asc' | 'desc';

function stableSort(array: any, comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el: any, index: number) => [el, index] as [any, number]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  headCells?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  // const createSortHandler =
  //   (property: any) => (event: React.MouseEvent<unknown>) => {
  //     onRequestSort(event, property);
  //   };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell: any, index: number) => (
          <TableCell
            key={`${index}_${headCell.id}`}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


interface ITableProps {
  tableName: string;
  onDownloadReport?: () => void;
}

const EnhancedTableToolbar = (props: ITableProps) => {

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
        align="left"
        fontSize="16px"
        marginLeft="20px"
      >
        {
          props.tableName
        }
      </Typography>

      <Tooltip title="ดาวน์โหลดรายงาน">
        <Search>
          <ReportButton variant="outlined" type="submit" startIcon={<DownloadIcon />} size="small" style={{ width: "100%", minWidth: 150 }} onClick={props.onDownloadReport}>
            ดาวน์โหลดรายงาน
          </ReportButton>
        </Search>
      </Tooltip>
    </Toolbar>
  );
};

interface ICurrentProps {
  tableName: string;
  tableCell?: any;
  device: string;
  orderBy: string;
  order: Order;
  DateTime: Date[];
  device_id: string;
}

export default function CurrentTable(props: ICurrentProps) {

  const RawData = useGatRawAPI(props.device);

  const [order, setOrder] = React.useState<Order>(props.order);
  const [orderBy, setOrderBy] = React.useState<string>(props.orderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [HeaderCell] = React.useState<any>(props.tableCell)
  const [DataCurrentDevice, setDataCurrentDevice] = React.useState<any[]>([])
  const [DateTime, setDateTime] = React.useState<Date[] | undefined>(props.DateTime);
  const [DeviceID, setDeviceID] = React.useState(props.device_id);
  const [DataLength, setDataLength] = React.useState(0);

  React.useLayoutEffect(() => {
    if (props.device_id !== DeviceID || (props.DateTime![0] !== DateTime![0] || props.DateTime![1] !== DateTime![1])) {
      setDateTime(props.DateTime)
      setDeviceID(props.device_id)
      var start: string = parseDate(props.DateTime![0]) + " " + parseTime(props.DateTime![0])
      var end: string = parseDate(props.DateTime![1]) + " " + parseTime(props.DateTime![1])

      reqData({ offset: page * rowsPerPage, limit: rowsPerPage, device_id: props.device_id, start: start, end: end });
    }
    return
  })

  const reqData = (options: { offset: number, limit: number, device_id: string, start: string, end: string }) => {
    RawData.mutate(
      options,
      { onSuccess: (data: any) => {
        const dataList = data.data.items.map( (row: any) => {

          if (row.hasOwnProperty('latitude') || row.hasOwnProperty('longitude')) {
            const mineGrid = coordinateDecimalToMineGrid(row.latitude, row.longitude);
            row.mine_n = mineGrid.mineN.toFixed(2);
            row.mine_e = mineGrid.mineE.toFixed(2);
          }

          handleDeviceValue(row);
          return row;
        })
        setDataLength(data.data.count)
        setDataCurrentDevice(dataList)
      }}
    )
  }

  const handleDeviceValue = (row: any) => {
    switch (props.device) {
      case DEVICES.CONVEYOR:
        if (row.value != undefined) {
          row.value = row.value ? "ทำงาน" : "หยุดทำงาน";
        }
        break;
    }
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    var start: string = parseDate(DateTime![0]) + " " + parseTime(DateTime![0])
    var end: string = parseDate(DateTime![1]) + " " + parseTime(DateTime![1])
    reqData({ offset: newPage * rowsPerPage, limit: rowsPerPage, device_id: DeviceID, start: start, end: end });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    var start: string = parseDate(DateTime![0]) + " " + parseTime(DateTime![0])
    var end: string = parseDate(DateTime![1]) + " " + parseTime(DateTime![1])
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    reqData({ offset: 0, limit: parseInt(event.target.value, 10), device_id: DeviceID, start: start, end: end });
  };
  const CheckStatus = (status: string): any => {

    if (status === "Online") {
      return <StatusShow>
        <BoxGreen />{"Online"}
      </StatusShow>
    } else {
      return <StatusShow>
        <BoxRed />{"Offline"}
      </StatusShow>
    }
  }

  const ConfigCell = (row: any, Headder: any): any => {
    var Cell: any = []

    Headder.forEach((e: any, i: number) => {
      Cell.push(
        (e.isStatus
          ? <TableCell key={`${i}_${e.id}`} align={e.numeric ? "right" : "left"}>{CheckStatus(row[e.id])}</TableCell> /* status */
          : e.id == "mine_n"
            ? <TableCell key={`${i}_${e.id}`}>{Math.round(parseFloat(row[e.id]) * 100) / 100}</TableCell>
            : e.id == "mine_e"
              ? <TableCell key={`${i}_${e.id}`}>{Math.round(parseFloat(row[e.id]) * 100) / 100}</TableCell>
              : <TableCell key={`${i}_${e.id}`} align={e.numeric ? "right" : "left"}>{
                (e.isUpper
                  ? (Boolean(row[e.id]) === false ? (e.Zero ? "0" : "-") : row[e.id].toUpperCase())
                  : (e.isUseFormatTime
                    ? parseDateTime(row[e.id])
                    : (Boolean(row[e.id]) === false ? (e.Zero ? "0" : "-") : row[e.id])
                  ))
              }</TableCell>
        )
      )
    })
    return Cell
  }

  if (RawData.isIdle) {
    var start: string = parseDate(DateTime![0]) + " " + parseTime(DateTime![0])
    var end: string = parseDate(DateTime![1]) + " " + parseTime(DateTime![1])
    reqData({ offset: page * rowsPerPage, limit: rowsPerPage, device_id: DeviceID, start: start, end: end });
  }

  return (
    <Box sx={{ width: '100%' }}>
      <EnhancedTableToolbar tableName={props.tableName} onDownloadReport={() => {
        var start: string = parseDate(DateTime![0]) + " " + parseTime(DateTime![0])
        var end: string = parseDate(DateTime![1]) + " " + parseTime(DateTime![1])
        RawData.mutate({ offset: 0, limit: 9999999, device_id: props.device_id, start: start, end: end }, {
          onSuccess: (data: any) => {
            var title: any[] = HeaderCell.map((e: any) => e.label)
            var csvHeader: string;
            var body: any[]
            csvHeader = title.join(",")
            var csv: string = `data:text/csv;charset=utf-8,${csvHeader},\r\n`;
            data.data.items.forEach((be: any, bi: any) => {
              body = HeaderCell.map((he: any, hi: number) => {
                return (he.isUpper ?
                  be[he.id].toUpperCase() :
                  (he.isUseFormatTime ?
                    parseDateTime(be[he.id]) :
                    he.id.includes('mine_n') && be['latitude'] !== undefined && be['longitude'] !== undefined ?
                      coordinateDecimalToMineGrid(Number(be.latitude), Number(be.longitude)).mineN :
                      he.id.includes('mine_e') && be['latitude'] !== undefined && be['longitude'] !== undefined ?
                        coordinateDecimalToMineGrid(Number(be.latitude), Number(be.longitude)).mineE :
                        be[he.id]))
              })
              csv += body.join(",") + "\r\n"
            })

            // create link download.
            var encodedUri = encodeURI(csv);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `EGAT_IOT_CURRENT_EXPORT${parseDateTime(new Date())}.csv`);
            document.body.appendChild(link);
            link.click();
          },
        })

      }} />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={HeaderCell}
          />
          <TableBody>
            {DataCurrentDevice.map((row: any, index: number) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`${index}_${row.name}`}
                >
                  {
                    ConfigCell(row, HeaderCell).map((e: any) => { return e })
                  }
                </TableRow>
              );
            })}
            {(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - DataLength) : 0) > 0 && (
              <TableRow>
                <TableCell colSpan={HeaderCell.length} >
                  ไม่มีข้อมูลแล้ว
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={DataLength}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"จำนวนแถวต่อหน้า:"}
      />
    </Box>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const BoxRed = styledComponent.div`
  margin-right: 14px;
  height: 5px;
  width: 5px;
  border: 1px solid solid;
  background-color: red;
`

const BoxGreen = styledComponent.div`
  margin-right: 10px;
  height: 5px;
  width: 5px;
  border: 1px solid solid;
  background-color: green;
`

const StatusShow = styledComponent.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
`
const ReportButton = styled(Button)`
    border: 1px solid ${color.iconBlue};
    color: ${color.iconBlue};
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
`
