import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
} from '@mui/material/';

import { PermissionDetail } from './Permissions'
import useUserContext from 'context/UserContext'
import SnackbarUtils from 'helpers/utils';
import useAdminListUsersAPI,
{
  useAdminUpdateUsersPermissionAPI,
  useAdminDeleteUserAPI
} from 'api/useAdminAPI';


interface IDialogProps {
  init: boolean;
  setOpen: any;
  scrollType: boolean;
  buttonMenus: string[];
  HeaderText: string;
  employeeNumber: number;
  employeeID: number;
  role: string;
  permissions: PermissionDetail[] | undefined;
}

interface InputProps {
  permissions: string[]
}

export default function BaseDialog(props: IDialogProps) {
  //console.log(props)
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
  const [allChecked, setAllChecked] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [role, setRole] = useState<string>(props.role);
  const saveAPI = useAdminUpdateUsersPermissionAPI(props.employeeID);
  const deleteAPI = useAdminDeleteUserAPI(props.employeeID);
  const listActivatedUserAPI = useAdminListUsersAPI("activated", "0", "9999999");
  const listPendingUserAPI = useAdminListUsersAPI("created", "0", "9999999");

  const userContext = useUserContext()
  const [listPermissions, setListPermissions] = useState<PermissionDetail[]>([])
  useLayoutEffect(() => {
    if (userContext.listPermissions) {
      setListPermissions(userContext.listPermissions)
    }
  }, [userContext.listPermissions])

  const handleClose = () => {
    props.setOpen()
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const button = event.currentTarget.name;
    if (button === "บันทึก") {
      saveAPI.mutate({
        permissions: role === "admin" ? ["admin"] : selected
      },
        {
          onSuccess: (resp) => {
            SnackbarUtils.success('บันทึกสำเร็จ');
            listActivatedUserAPI.refetch()
            listPendingUserAPI.refetch()
            handleClose()
          },
          onError: () => {
            SnackbarUtils.error('บันทึกไม่สำเร็จ');
            handleClose()
          }
        }
      )
      console.log("id:", props.employeeID)
    } else if (button === "ลบ") {
      deleteAPI.mutate(undefined, {
        onSuccess: () => {
          SnackbarUtils.success('ลบสำเร็จ');
          listActivatedUserAPI.refetch()
          listPendingUserAPI.refetch()
          handleClose()
        },
        onError: () => {
          SnackbarUtils.error('ลบไม่สำเร็จ');
          handleClose()
        }
      })
    } else if (button === "ยกเลิก") {
      handleClose()
    }
  };

  const selectItem = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    return newSelected;
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    //var newSelected = selectItem(name)
    selectItem(name)
  };
  useEffect(() => {
    setAllCheckedCallback(selected.length == (listPermissions ? listPermissions.length : 0)
      && (listPermissions ? listPermissions.length !== 0 : false))
    console.log("selected", selected)
  }, [selected.length])

  const handleAllRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllCheckedCallback(!allChecked)
  }
  const setAllCheckedCallback = useCallback((value: boolean) => {
    setAllChecked(value)
  }, [allChecked])
  useEffect(() => {
    if (allChecked) {
      setSelected(listPermissions.map(permission => permission.name))
    }
    else if (!allChecked && selected.length == (listPermissions ? listPermissions.length : 0)) {
      setSelected([])
    }
    console.log("allChecked", allChecked)
  }, [allChecked]);

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value)
  }
  useLayoutEffect(() => {
    setRole(props.role)
  }, [props.role])

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    //console.log("componentDidUpdateFunction");
  });

  React.useEffect(() => {
    props.scrollType ? setScroll('body') : setScroll('paper')

  }, [props.scrollType]);
  //console.log("Base: " + props.employeeNumber + " " + props.role)
  return (
    <div>
      <Dialog
        open={props.init}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{props.HeaderText}</DialogTitle>
        {props.HeaderText === "แก้ไขผู้ใช้" ?
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText>Employee No.</DialogContentText>
            <DialogContentText>{props.employeeNumber}</DialogContentText>
            <DialogContentText>Role</DialogContentText>
            <DialogActions sx={{ justifyContent: "left" }}>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="role" name="row-radio-buttons-group" defaultValue={props.role}
                  onChange={handleChangeRole}
                >
                  <FormControlLabel value="user" control={<Radio />} label="User" />
                  <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                </RadioGroup>
              </FormControl>
            </DialogActions>
            <DialogContentText>Dashboard Permission</DialogContentText>
            <DialogActions>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={role === "admin" ? true : allChecked} onChange={handleAllRadio} name="all" />
                    }
                    label="เลือกทั้งหมด"
                    disabled={role === "admin"}
                  />
                  {listPermissions.map((permission: PermissionDetail, index: number) =>
                    <FormControlLabel
                      key={`${index}_${permission.name}`}
                      control={
                        <Checkbox key={`${index}_${permission.name}_checkbox`} checked={role === "admin" ? true : isSelected(permission.name)} onChange={(event) => handleRadioChange(event, permission.name)} name={permission.name} />
                      }
                      label={permission.thName}
                      disabled={role === "admin"}
                    />
                  )}

                </FormGroup>
              </FormControl>
            </DialogActions>
          </DialogContent>
          :
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText>ลบผู้ใช้ {props.employeeNumber} ?</DialogContentText>
            <DialogContentText>ท่านยืนยันที่จะลบผู้ใช้ {props.employeeNumber} ออกจากระบบ</DialogContentText>
          </DialogContent>
        }

        <DialogActions>
          {props.buttonMenus.map((buttonMenu) => (
            <Button key={buttonMenu} name={buttonMenu} onClick={handleClick}>{buttonMenu}</Button>
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );
}