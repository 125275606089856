import React from 'react';

const L =  window["L"],
UTMLatLng = window['UTMLatLng'],
cw = window["cw"],
shp = window['shp'];

let map, level_zoom = 13, EGAT_Device_Group;

function SensorWeather(props) {
  const ref = React.createRef()

  React.useLayoutEffect(() => {
    if (props.DataMarker !== undefined && EGAT_Device_Group !== undefined){
      EGAT_Device_Group.setNewData(props.DataMarker)
      EGAT_Device_Group.showGroup()
      if (props.DataMarker.length === 1){
        // EGAT_Device_Group.CenterGroup()
        EGAT_Device_Group.setCenterZoomOneDevice()
      }
    }
    return
  })

  React.useEffect(() => {
    const render_map = async () => {
      map = L.map(ref.current, {
        zoom: level_zoom,
        attributionControl: false,
        center: new L.latLng([18.33839487268557, 99.71946716308595]),
        zoomControl:false,

        fullscreenControl: true,
        // max zoom
        maxZoom: 22,
        editable: true,
        printable: true,
        downloadable: true,
      });
      var DataMarker = props.DataMarker
      var fileLayer = props.fileLayer
      // check class undefined.
      if (
        typeof(L) === 'undefined'
      ){
        return
      } else {

        // show mine grid
        L.control.mousePosition(new UTMLatLng()).addTo(map);

        // Control Layer
        var EGATControlLayer = new L.control.ControlLayer({
          layer: fileLayer,
          cw: cw,
          shp: shp,
          main_url: `${process.env.REACT_APP_API_BASE_URL}/api/files/`
        })
        EGATControlLayer.addTo(map);

        // Marker
        EGAT_Device_Group = new L.DefaultMarker(DataMarker)
        EGAT_Device_Group.addTo(map)
        EGAT_Device_Group.showGroup()
        if (DataMarker.length > 0){
          // EGAT_Device_Group.CenterGroup()
        }
      }

    }

    render_map()

    return () => {
      // // unmount component
      // map.remove()
    }
  }, [])

  return (
    <>
      <div ref={ref} style={{ height: "100%", width: "100%", textAlign: "center", zIndex: 5 }}></div>
    </>
  );
}

export default SensorWeather;
