import { useMutation, useQuery } from 'react-query'

import requestInstance, {
    RequestResponse,
    RequestError,
} from 'configure/requestConfig'

export interface UserData {
    id: number
    employee_id: number
    status: string
    permissions: null|string[]
    created_at: string
    updated_at: string
}

export interface ListUsersResponseData {
    count: number
    offset: number
    limit: number
    users: UserData[]
}

const useAdminListUsersAPI = (status:string,offset?:string,limit?:string) => {
    const queryKey = `list${status}Users`
    const fetcher = () => requestInstance.get(`/api/users/?status=${status}&offset${offset? offset:""}=&limit=${limit? limit:""}`)
    return useQuery<RequestResponse<ListUsersResponseData>, RequestError>(
        queryKey,
        fetcher
    )
}

export default useAdminListUsersAPI

export interface UpdateUsersPermissionRequestData {
    permissions: string[]
}

export interface UpdateUsersPermissionResponseData {
    id:number
    permissions:string[]
}

const useAdminUpdateUsersPermissionAPI = (id:number) => 
    useMutation<
        RequestResponse<UpdateUsersPermissionResponseData>,
        RequestError,
        UpdateUsersPermissionRequestData
    >((data: UpdateUsersPermissionRequestData) =>
        requestInstance.patch(`/api/users/${id.toString()}`, data)
    )


export interface ActivatedUserResponseData {
    id: number
    eno: number
    status: string
}

const useAdminDeactivatedUserAPI = (id:number,) => 
    useMutation<
        RequestResponse<ActivatedUserResponseData>,
        RequestError
    >(() =>
        requestInstance.patch(`/api/users/${id.toString()}/deactivated`)
    )

const useAdminReactivatedUserAPI = (id:number,) => 
    useMutation<
        RequestResponse<ActivatedUserResponseData>,
        RequestError,
        UpdateUsersPermissionRequestData
    >((data: UpdateUsersPermissionRequestData) =>
        requestInstance.patch(`/api/users/${id.toString()}/reactivated`, data)
    )

const useAdminDeleteUserAPI = (id:number,) =>
    useMutation<
        RequestResponse<ActivatedUserResponseData>,
        RequestError
    >(()=>
        requestInstance.delete(`/api/users/${id}`)
    )


export {useAdminUpdateUsersPermissionAPI, useAdminDeactivatedUserAPI, useAdminReactivatedUserAPI,useAdminDeleteUserAPI}