import React, { useEffect, useState } from "react";
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField';
import * as dateFns from "date-fns";
import * as LandSlideAPI from "api/useLandSlideAPI";
import { usePageContext } from "./LandSlidePage";
import parseDateTime from "helpers/parseDateTime";

let TimeInterval: any

export function UpdateCurrentDataButton() {
    const [lastUpdateTime, setLastUpdateTime] = useState(parseDateTime(new Date()));
    const { stateContext, dispatchContext } = usePageContext();

    function onClickUpdate() {
        setLastUpdateTime(parseDateTime(new Date()));
        (async () => {
            const res = await LandSlideAPI.reqCurDeviceData();

            if (res.status != 200) {
                return [];
            }
            const items = res.data.landslide.data.items.sort((a: any, b: any) => (a.diff2d < b.diff2d ? 1 : -1));
            items.forEach((element: LandSlideAPI.ISensorItem) => {
                const { timestamp } = element;
                let date: Date = new Date(new Date(timestamp).toLocaleString());
                let hrBetweenTwoDate = dateFns.differenceInHours(new Date(), new Date(timestamp));
                element.state = hrBetweenTwoDate > 1 ? "Offline" : "Online";
            });
            dispatchContext!({ curSensorList: items });
        })();
    }

    const ONE_MINUTES = 1000 * 60;
    function setTimeInterval() {
        TimeInterval = setInterval(() => {
            onClickUpdate();
        }, ONE_MINUTES);
    }

    useEffect(() => {
        setTimeInterval();
        return () => {
            clearInterval(TimeInterval)
        }
    }, [])

    return <LastUpdateTextField datetime={lastUpdateTime} onClick={onClickUpdate} />;
}
