import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import type { RouteComponentProps } from 'react-router-dom'
import { useLayoutDispatch, changeHeaderText } from "../../context/LayoutContext";

import {
  Box,
  Grid,
  Typography,
  TextField,
  Tabs,
  Tab,
  Button,
} from "@mui/material"

import color from 'constants/Colors'
import { LoadingButton } from '@mui/lab';

import LineSetting from './LineSetting'

import EmailSetting from './EmailSetting'

/**
 * Localstorage
 */
import {
  linitifyStorage,
  linenotifyStorageData,
  SETTINGPAGE
} from 'configure/localStorageConfig'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HEADER_TEXT = "ตั้งค่าแจ้งเตือน";

const SettingPage: React.FC<RouteComponentProps> = () => {
  const dispatch = useLayoutDispatch()

  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    (() => {
      changeHeaderText(dispatch, HEADER_TEXT)
    })()
  }, []);

  useEffect(() => {
    (() => {
      var local: linenotifyStorageData = linitifyStorage.get()
      // set default
      if (local === null) {
        var lineInitData: linenotifyStorageData = {
          from: SETTINGPAGE.EMAIL,
          setting: [],
          notiToken: "",
          note: "",
          enable: false,
        }
        linitifyStorage.set(lineInitData)
      }
      // check tabs action.
      if (local.from === SETTINGPAGE.NOTI) {
        setTabValue(1)
      }
    })()
  }, [])
  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Container>
      {/* use when use forget save state. */}
      {/* <Prompt
        when={!saved && editted}
        message={() => 'ท่านยังไม่ได้บันทึกการตั้งค่าแจ้งเตือนล่าสุด ยืนยันจะออกจากหน้านี้ ?'}
      /> */}
      <Grid container direction="column" columnSpacing="5px" rowSpacing="5px" columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} style={{ marginLeft: "10px" }}>
        <Grid item>
          <HeaderText>{HEADER_TEXT}</HeaderText>
        </Grid>
        <Grid item>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="อีเมล" {...a11yProps(0)} />
                <Tab label="line" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>

              <EmailSetting />

            </TabPanel>
            <TabPanel value={tabValue} index={1}>

              <LineSetting />

            </TabPanel>
          </Box>
        </Grid>
      </Grid>


    </Container>
  )
}

export default SettingPage


const Container = styled.div`
  align-items: left;
  justify-content: left;
  
  > p {
    line-height: 1;
    font-size: 20px;
  }
`
const HeaderText = styled(Typography)`
  color: "#000000";
  font-size: 120%;
`

const SearchTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#919EAB',
    },
    '&:hover fieldset': {
      borderColor: '#0739A2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0739A2',
    },
  },
  marginBottom: '25px',
});

const SearchTextSX = {
  width: "30%",
  height: "5%",
  backgroundColor: color.white,
}

const EmailSX = {
  minWidth: "150px",
  width: "40%",
  height: "5%",
  backgroundColor: color.white,
}

const DivOneColumn = styled.div`
  display: flex;
  flex-direction: column
`
const LoginCss = css`
    margin: 24px auto 60px auto;
    /* width: 80%; */
    font-size: 18px;
`
const SaveButton = styled(Button)`
    ${LoginCss};
    margin-top: 20px;
    background-color: ${(props) => props.theme.color.loginButton};
    &:hover {
        background-color: ${(props) => props.theme.color.loginButton};
        opacity:0.8;
    }
    text-transform: none;
`
const LoadingSaveButton = styled(LoadingButton)`
    ${LoginCss};   
`

const SavedButton = styled.div`
    margin-top: 20px;
    color: green;
`

const Icon = styled.div`
    color: green;

`

const MarginLeftP = styled.label`
    margin-left: 10px;
    height: fit-content;
`