import { getDeviceAddress } from "api/useDeviceAddressAPI";
import { useEffect, useState } from "react";
import { DEVICES } from '../../api/useRequest';
import DefaultDateTimeRange from '../../constants/DefaultDateTimeRage';

export interface IDevAddrResItem {
    device_id: string;
    addr_des: string;
    addr_id: number;
    start_date: string;
    end_date: string;
    latitude: number;
    longitude: number;
    config: any;
    last_value: any;
}

export const useDeviceAddressSelector = (deviceType: DEVICES, deviceID: string, firstDateTime?: Date[], cbAddrSelected?: (addr_des: string, from: (Date | null), to: (Date | null)) => void) => {
    const [devAddrRes, setdevAddrRes] = useState([] as IDevAddrResItem[]);
    const [devAddrList, setDevAddrList] = useState([] as string[]);
    const [devAddr, setDevAddr] = useState("");
    const [maxDate, setMaxDate] = useState(null as Date | null);
    const [minDate, setMinDate] = useState(null as Date | null);
    const isNotSelectedLastAddr = devAddrList.length > 0 && devAddr !== devAddrList[0];
    const isMeasuringStop = devAddrRes.length > 0 && devAddrRes[0]?.end_date !== "";

    const getMaxMinDate = (addr_des: string, ObjList: any[]) => {
        let max = null;
        let min = null;
        const selected = ObjList.find((item) => item.addr_des === addr_des);
        if (selected) {
            max = (selected.end_date ? new Date(selected.end_date) : null);
            min = (new Date(selected.start_date));
            // add 7 hours
            max?.setHours(max.getHours() + 7);
            min.setHours(min.getHours() + 7);

        }

        return { max, min };
    }

    const initDevAddr = (addr_des: string, ObjList: any[]) => {
        setDevAddr(addr_des);
        let { max, min } = getMaxMinDate(addr_des, ObjList);
        setMaxDate(max);
        setMinDate(min);
        max = (max ? max : new Date());
        if (firstDateTime) {
            const firstFrom = (firstDateTime[0] > min! ? firstDateTime[0] : min!);
            const firstTo = (firstDateTime[1] < max ? firstDateTime[1] : max);

            if (cbAddrSelected) {
                cbAddrSelected(addr_des, firstFrom, firstTo);
            }
        }

    }

    const handleDevAddrChange = (value: string) => {
        console.log("handleDevAddrChange", value);
        setDevAddr(value);
        let { max, min } = getMaxMinDate(value, devAddrRes);
        max = (max ? max : new Date());
        let [from, to] = DefaultDateTimeRange[deviceType](max);
        min = (from > min! ? from : min!);
        setMaxDate(max);
        setMinDate(min);
        if (cbAddrSelected) {
            cbAddrSelected(value, min, max);
        }
    };

    const fetchDeviceAddress = async () => {
        const res = await getDeviceAddress(deviceID);
        if (res.status != 200) {
            return [];
        }
        console.log("getDeviceAddress", res.data.items);

        const { items } = res.data;
        setdevAddrRes(items || []);
        if (items?.length > 0) {
            setDevAddrList(items.map((e: any) => e.addr_des));
            initDevAddr(items[0].addr_des, items);
        }
    }

    useEffect(() => {
        fetchDeviceAddress();
    }, [deviceID]);

    return { devAddrRes, devAddrList, devAddr, maxDate, minDate, isNotSelectedLastAddr, isMeasuringStop, handleDevAddrChange, fetchDeviceAddress };
};

/* 
    {
    "device_id": "e6a7010702000014",
    "addr_des": "hello addr_des4",
    "addr_id": 7,
    "start_date": "2022-09-20 10:38:54.54723",
    "end_date": ""
    }
*/