import React, { FunctionComponent } from "react";
import { ISensorItem } from "api/useGpsTrackingAPI";
import { CircleMarker, Tooltip } from "react-leaflet";
import { Grid, Typography } from "@mui/material";
import parseDateTime from "helpers/parseDateTime";

const HistoryPoint: FunctionComponent<HistoryPointProps> = ({ historyDataByID }) => {
    return (
        <>
            {historyDataByID &&
                Object.keys(historyDataByID).map(
                    id => historyDataByID[id] &&
                        historyDataByID[id].map(
                            (e: any, i: number) => {
                                const displayData: any[] = [
                                    {
                                        label: "Date :",
                                        value: `${parseDateTime(e.timestamp)}`,
                                    },
                                    {
                                        label: "MineN :",
                                        value: `${e.mine_n}`,
                                    },
                                    {
                                        label: "MineE :",
                                        value: `${e.mine_e}`,
                                    },
                                    {
                                        label: "Altitude :",
                                        value: `${e.altitude}`,
                                    },
                                    {
                                        label: "Speed (km/hr) :",
                                        value: `${e.speed}`,
                                    },
                                ];
                                return (
                                    <CircleMarker
                                        key={`HistoryPoint-${id}-${i}`}
                                        center={[Number(e.latitude), Number(e.longitude)]}
                                        pathOptions={{ color: 'red' }}
                                        radius={0}>
                                        <Tooltip className="request-tooltip">
                                            <Grid
                                                container
                                                direction="column"
                                                wrap="nowrap"
                                                minWidth="200px"
                                                margin={0.5}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    spacing={1}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="baseline"
                                                    wrap="nowrap"
                                                    marginTop="2px"
                                                >
                                                    <Typography variant="caption" color="initial">{e.device_name}</Typography>
                                                </Grid>
                                                {
                                                    displayData.map((e: any, i: number) => (
                                                        <Grid
                                                            key={`display-value-${i}`}
                                                            item
                                                            container
                                                            spacing={1}
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="baseline"
                                                            wrap="nowrap"
                                                            marginTop="2px"
                                                        >
                                                            <Typography variant="caption" color="initial">{e.label}</Typography>
                                                            <Typography variant="caption" color="initial">{e.value}</Typography>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </Tooltip>
                                    </CircleMarker>)
                            }
                        )

                )}
        </>
    );
};
interface HistoryPointProps {
    historyDataByID?: Record<string, ISensorItem[]>;
}
export default HistoryPoint;
