import { FunctionComponent } from "react";
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

interface DeleteUserDialogProps {
    open: boolean;
    handleClose: () => void;
    employeeNumber: string;
}

const DeleteUserDialog: FunctionComponent<DeleteUserDialogProps> = (props) => {
    const { open, handleClose, employeeNumber } = props;
    const handleDeleteUser = () => {
        console.log("Delete user");
        handleClose();
    }
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby={`delete-user-${employeeNumber}-dialog`}>
            <DialogTitle id={`delete-user-${employeeNumber}-dialog`}>{`ลบผู้ใช้ ${employeeNumber}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    คุณต้องการลบผู้ใช้ {employeeNumber} ใช่หรือไม่
                </DialogContentText>
                <DialogContentText>
                    การลบผู้ใช้จะทำให้ข้อมูลทั้งหมดของผู้ใช้นั้นหายไป
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                    autoFocus
                >
                    ยกเลิก
                </Button>
                <Button
                    onClick={handleDeleteUser}
                    color="primary"
                >
                    ยืนยัน
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteUserDialog;