import {
    IconButton,
    Icon,
    Menu,
    MenuItem,
    Typography,
    Box,
    Button,
    Divider,
    SvgIcon
} from "@mui/material";
import color from "../../constants/Colors";
import iconArrowBack from "../../assets/icons/icon-arrow-back.svg"
import {ReactComponent as iconEdit } from "../../assets/icons/icon-edit.svg"
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import avatar from "../../assets/icons/avatar.svg";
import useUserContext from 'context/UserContext';
import SnackbarUtils from 'helpers/utils';
import { useLogoutAPI } from 'api/useAuthenticationAPI';

interface Props {
    name?: string;
    eno: string;
    children?: React.ReactNode;
    prefixCls?: string;
    email?: string;
}

const Logout: React.FC<Props> = (props) => {
    const [profileMenu, setProfileMenu] = useState<HTMLDivElement | null>(null);
    const elementRef = useRef<HTMLDivElement>(null);
    const clearAuth = useUserContext().clearAuth;
    const logout = useLogoutAPI();
    const handleLogout = () => {
        logout.mutate(undefined,
            {
                onSuccess:()=>{
                    SnackbarUtils.warning("Logged Out");
                    clearAuth();
                },
                onError:()=>{
                    SnackbarUtils.error('Logged Out');
                    clearAuth();
                }
            });
    }
    return (
        <Div>
            <ProfileButton
                aria-haspopup="true"
                aria-controls="profile-menu"
                onClick={() => setProfileMenu(elementRef.current)}
            >
                <ProfileIcon>
                    <img src={avatar} alt={"avatar icon"}></img>
                </ProfileIcon>
            </ProfileButton>
            <HeaderText variant="body1" onClick={() => setProfileMenu(elementRef.current)}>
                {props.name}
            </HeaderText>
            <Box {...{ ref: elementRef } as any}>
                <IconButton onClick={() => setProfileMenu(elementRef.current)}>
                    <img src={iconArrowBack}></img>
                </IconButton>
            </Box>
            <ProfileMenu
                id="profile-menu"
                open={Boolean(profileMenu)}
                anchorEl={profileMenu}
                onClose={() => setProfileMenu(null)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 24,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                disableAutoFocusItem
            >
                {/* <ProfileMenuItem onClick={() => console.log("click profile!")}>
                    <div>
                        <ProfileText variant="inherit" onClick={() => console.log("click profile!")}>{props.name}</ProfileText>
                        <DivOneRow>
                        <ProfileSmallText variant="inherit" sx={{flexGrow:0}}>#{props.eno}</ProfileSmallText>
                        
                        <SvgIcon component={iconEdit}/>
                        </DivOneRow>
                        <ProfileSmallText variant="inherit">{props.email}</ProfileSmallText>
                    </div>
                </ProfileMenuItem>
                <Divider /> */}
                <ProfileMenuItem onClick={handleLogout}>
                    <ProfileMenuButton variant="outlined">Logout</ProfileMenuButton>
                </ProfileMenuItem>
            </ProfileMenu>
        </Div>
    );
}

export default Logout;

const Div = styled(Box)`
    display: inline-flex;
    -webkit-align-items: center;
    align-items: center;
`
const DivOneRow = styled.div`
    display: inline-flex;
`

const ProfileButton = styled(IconButton)`
    margin-left: ${(props: any) => props.theme.spacing(2)};
    padding: ${(props: any) => props.theme.spacing(0.5)};
`
const ProfileIcon = styled(Icon)`
    position: static;
    width: 32px;
    height: 32px;
    border-radius: 100px;
`
const HeaderText = styled(Typography)`
    margin: 0px 10px;
    ${(props: any) => props.theme.breakpoints.down("sm")} {
        color: ${color.mobileHeaderText};
    };
`

const ProfileMenu = styled(Menu)`
    margin-top: ${(props) => props.theme.spacing(3)};
    ${(props: any) => props.theme.breakpoints.down("sm")} {
      margin-top: ${(props: any) => props.theme.spacing(1.5)};
    };
    & .MuiPaper-root {
        border-radius: 8px;
        min-width: 220px;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
`

const ProfileText = styled(Typography)`
    margin: 0px 10px;
    text-decoration: "none";
    &:hover {
      cursor: pointer;
    }
`
const ProfileSmallText = styled(ProfileText)`
    font-size: 14px;
    line-height: 21px;
    color: ${color.smallText};
    width: 150px;
`

const ProfileMenuButton = styled(Button)`
    width: 100%;
`
const ProfileMenuItem = styled(MenuItem)`
    color: ${(props) => props.theme.palette.text.hint};
    margin: 0px 10px;
    &:hover && &:focus {
      background-color: ${(props) => props.theme.palette.background.light};
    }
    margin-left: auto;
    margin-right: auto;
`