import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import { usePageContext } from "./ConveyorPageDevice";
import BaseRawTable from "components/BaseTable/BaseRawTable";
import { DEVICES } from "api/useRequest";
import { useParams } from "react-router-dom";
import { CardTemplate } from "components/Card/CardTemplate";
import * as ConveyorAPI from "api/useConveyorAPI";
import { ISensorItem } from "api/useConveyorAPI";
import parseDateTime from "helpers/parseDateTime";

export function DeviceTable() {
    const { stateContext, dispatchContext } = usePageContext();
    const [dataList, setDataList] = useState<any[]>([]);
    /* Get params */
    let { deviceID } = useParams<{ deviceID: string; }>();
    // useEffect(() => {
    //     const datas: any[] = stateContext.allSensorList.map((e: any) => {
    //         const {
    //             device_id,
    //             device_name,
    //             power_pri,
    //             power_sec,
    //             status,
    //             raw,
    //             value,
    //             latitude,
    //             longitude,
    //             state,
    //             timestamp,
    //         } = e;
    //         return {
    //             device_id,
    //             device_name,
    //             power_pri,
    //             power_sec,
    //             raw,
    //             value: (value == 1) ? "ทำงาน" : "ไม่ทำงาน",
    //             status,
    //             latitude,
    //             longitude,
    //             state,
    //             timestamp: parseDateTime(timestamp),
    //         };
    //     });
    //     setDataList(datas);
    // }, [stateContext.allSensorList]);
    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0">
        <CardTemplate>
            <BaseRawTable tableName={"รายละเอียดการส่งข้อมูล"} device={DEVICES.CONVEYOR} DateTime={stateContext.dateRange} order={"asc"} orderBy={"device_id"} tableCell={headCells} device_id={deviceID} />
        </CardTemplate>
    </Grid>;
}
interface HeadCell {
    disablePadding?: boolean;
    id: keyof any;
    label: string;
    numeric: boolean;
    isStatus?: boolean;
    isUpper?: boolean;
    isUseFormatTime?: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'timestamp',
        numeric: false,
        label: 'Last Updated',
        isUseFormatTime: true
    },
    {
        id: 'device_id',
        numeric: false,
        isUpper: true,
        label: 'ID',
    },
    {
        id: 'device_name',
        numeric: false,
        label: 'Name',
    },
    // {
    //     id: 'state',
    //     numeric: false,
    //     isStatus: true,
    //     label: 'Status',
    // },
    // {
    //     id: 'latitude',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Latitude',
    // },
    // {
    //     id: 'longitude',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Longitude',
    // },
    {
        id: 'value',
        numeric: false,
        disablePadding: false,
        label: 'สถานะสายพาน',
    },
    // {
    //     id: 'raw',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Raw',
    // },
    {
        id: 'power_pri',
        numeric: true,
        disablePadding: false,
        label: 'Solar Cell (V)',
    },
    {
        id: 'power_sec',
        numeric: true,
        disablePadding: false,
        label: 'Battery (V)',
    },
];
