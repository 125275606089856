import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import React, { useEffect, useContext } from "react";
import { RouteComponentProps, useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { useParams } from 'react-router-dom'
import { MapCard } from "./ConveyorDeviceMapCard";
import * as dateFns from 'date-fns';
import styled from 'styled-components'
import { SelectDeviceName } from "./SelectDeviceName";
import { DeviceSettingButton } from "./DeviceSettingButton";
import { CurDetailCard } from "./CurDetailCard";
import { defaultConveyorConfigItem, IConveyorConfigItem, reqGetConfig } from "api/useConfigSettingAPI";
import { DEVICES } from "api/useRequest";

/* API */
import * as ConveyorAPI from "api/useConveyorAPI";
import { ISensorItem } from "api/useConveyorAPI";
import { UpdateCurrentDataButton } from "./UpdateCurrentDataButton";
/* helpers */
import getShapeFile from 'helpers/getshapefile'
import groupBy from 'helpers/groupBy'

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { GraphListPanel } from "./GraphListPanel";
import { DeviceTable } from "./DeviceTable";
import { IDevAddrResItem, useDeviceAddressSelector } from "components/DeviceAddressSelector/useDeviceAddressSelector";
import DeviceAddressSelector from "components/DeviceAddressSelector/DeviceAddressSelector";
import DeviceAddressSetting from "components/DeviceAddressSetting/DeviceAddressSetting";
import DefaultDateTimeRange from "constants/DefaultDateTimeRage";
import StopMeasuring from "components/StopMeasuring/StopMeasuring";

interface IPageContext {
    curSensorList: ISensorItem[];
    curSensorItem: ISensorItem;
    curConfigList: IConveyorConfigItem[];
    curConfigItem: IConveyorConfigItem;
    allSensorList: ISensorItem[];
    lineConfigList: Record<string, string[]>;
    dateRange: Date[];
    limit: string[];
}

const defaultContext: IPageContext = {
    curSensorList: [],
    curSensorItem: ConveyorAPI.defaultSensorItem,
    curConfigList: [],
    curConfigItem: defaultConveyorConfigItem,
    allSensorList: [],
    lineConfigList: {},
    dateRange: DefaultDateTimeRange[DEVICES.CONVEYOR](new Date()),
    limit: [],
}
const pageContext = React.createContext<{ stateContext: IPageContext, dispatchContext?: React.Dispatch<any>; }>({ stateContext: defaultContext });

export const usePageContext = () => useContext(pageContext);

export const HEADER_TEXT = "ระบบตรวจวัดสถานะสายพาน"
export const MAIN_PATH = "/conveyor"
const ConveyorPageDevice: React.FC<RouteComponentProps> = () => {


    /* Create global state in page */
    const [stateContext, dispatchContext] = React.useReducer((state: IPageContext, action: any) => {
        return { ...state, ...action }
    }, defaultContext);


    return (
        <pageContext.Provider value={{ stateContext, dispatchContext }}>
            <ConveyorPageDeviceContent />
        </pageContext.Provider>
    );



}

export default ConveyorPageDevice

const DashboardContainer = styled.div`
  margin-left: 10px;
`

function ConveyorPageDeviceContent() {
    /* Use to set header text */
    const dispatch = useLayoutDispatch();
    /* Get params */
    let { deviceID } = useParams<{ deviceID: string; }>();
    const queryParams = new URLSearchParams(window.location.search);
    const limitHigh = queryParams.get('limitHigh');
    const limitLow = queryParams.get('limitLow');

    const { stateContext, dispatchContext } = usePageContext()
    async function getConfig() {
        const res = await reqGetConfig(DEVICES.CONVEYOR);
        if (res.status !== 200) {
            return
        }
        const configs: IConveyorConfigItem[] = res.data;
        const configItem: IConveyorConfigItem = res.data.find((e: any) => e.device_id == deviceID) ?? defaultConveyorConfigItem;

        dispatchContext!({ curConfigList: configs })
        dispatchContext!({ curConfigItem: configItem })
    }

    async function getDataByDateTimeRange() {
        const res = await ConveyorAPI.reqDeviceData(stateContext.dateRange, deviceID);
        if (res.status != 200) {
            return [];
        }
        const { items } = res.data;

        dispatchContext!({ allSensorList: items });
    };

    async function getCurrentData() {
        const res = await ConveyorAPI.reqCurDeviceData();
        if (res.status != 200) {
            return [];
        }
        const items = res.data.conveyor.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
        const curSensor = await items.find((e: any) => e.device_id === deviceID);

        /* Set Header */
        changeHeaderText(dispatch, {
            texts: [HEADER_TEXT, `${curSensor.device_name}`],
            link: `${window.location.protocol}//${window.location.host}${MAIN_PATH}`
        });

        dispatchContext!({ curSensorList: items, curSensorItem: curSensor });
        getConfig();

    };

    async function getLineConfig() {
        var shpres = await getShapeFile()
        let lineConfigs = groupBy(shpres, "line");
        console.log(lineConfigs);

        dispatchContext!({ lineConfigList: lineConfigs });
    }

    useEffect(() => {
        getLineConfig();
        if (limitLow !== undefined || limitHigh !== undefined) {
            dispatchContext!({ limit: [limitLow, limitHigh] });
        }
    }, [])

    useEffect(() => {
        getDataByDateTimeRange()
        getCurrentData()

    }, [deviceID])

    const onDateTimeChange = (value: Date[]) => {
        dispatchContext!({ dateRange: value });
        (async () => {
            const res = await ConveyorAPI.reqDeviceData(value, deviceID);
            if (res.status != 200) {
                return [];
            }
            const { items } = res.data;
            console.log(items);

            dispatchContext!({ allSensorList: items });
        })();
    };

    const setLastDataByAddr = (addr_des:string) => {
        
        const devAddrResItem = devAddrRes.find((e:IDevAddrResItem) => e.addr_des === addr_des);
        if (!devAddrResItem) return

        let { config, last_value } = devAddrResItem;

        if (config) {
            dispatchContext!({ curConfigItem: {...stateContext.curConfigItem, ...config} });
        }

        if (last_value) {
            if (!last_value.latitude && !last_value.longitude) {
                last_value.latitude = devAddrResItem.latitude;
                last_value.longitude = devAddrResItem.longitude;
            }

            dispatchContext!({ curSensorItem: {...stateContext.curSensorItem, ...last_value} });
        }
    }

    const cbAddrSelected = (addr_des:string , from:(Date | null), to:(Date | null)) => {
        dispatchContext!({ dateRange: [from!,to ?? new Date()] });
    }
    const { maxDate, minDate, devAddrRes, devAddr, isNotSelectedLastAddr, isMeasuringStop, handleDevAddrChange, fetchDeviceAddress } = useDeviceAddressSelector(DEVICES.CONVEYOR, deviceID, stateContext.dateRange, cbAddrSelected);
    useEffect(() => {
        setLastDataByAddr(devAddr);
    }, [devAddr])
    
    return <DashboardContainer>
        <Grid
            width="100%"
            height="100%"
            container
            direction="column"
            rowSpacing={2}
            columnSpacing={2}
            columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
        >
            {/* <HeaderRow /> */}
            <Grid
                item
                container
                direction="row"
                columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
            >

                <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
                    <Grid
                        container
                        wrap="nowrap"
                        gap={1}
                    >
                        {/* select device */}
                        <SelectDeviceName />
                        <DeviceAddressSelector address={devAddrRes} value={devAddr} onChange={handleDevAddrChange} />
                        {/* upload data */}
                        <Grid
                            container
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                            gap={1}
                            wrap="nowrap"
                        >
                            {!isNotSelectedLastAddr ?
                                isMeasuringStop ?
                                <DeviceAddressSetting deviceId={deviceID} fetchAddr={fetchDeviceAddress} /> :
                                <StopMeasuring deviceId={deviceID} addrDes={devAddr} fetchAddr={fetchDeviceAddress} />
                                : null
                            }
                            <div>
                                <DeviceSettingButton disabled={isNotSelectedLastAddr}  />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <MapRow /> */}
            <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="strech"
                rowSpacing={2}
                columnSpacing={2}
                columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
            >

                {/* Column 1 map */}
                <Grid item xs={100} sm={100} md={50} lg xl
                    minHeight="500px"
                >
                    <MapCard />
                </Grid>

                {/* Column 2 card */}
                <Grid item xs={100} sm={100} md={50} lg={35} xl={15}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    gap={2}
                >   
                    <UpdateCurrentDataButton callback={getCurrentData} />
                    <Grid item xs  paddingLeft="16px">
                        <CurDetailCard />
                    </Grid>
                </Grid>

                {/* Column 3 notification */}
                {/* <Grid item xs={100} sm={100} md={100} lg={100} xl={20}>
            <ErrorNotificationCard />
        </Grid> */}

            </Grid>

            {/* <TableZone /> */}
            <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
                <DeviceDataTab
                    tabName={["กราฟ", "ข้อมูลดิบ"]}
                    tabElement={[<GraphListPanel />, <DeviceTable />]}
                    tabWithDatePicker={[0, 1]}
                    dateTimeRange={stateContext.dateRange}
                    onDateTimeChange={onDateTimeChange}
                    maxDateTime={maxDate ?? undefined}
                    minDateTime={minDate ?? undefined}
                />
            </Grid>

        </Grid>
    </DashboardContainer>;
}


