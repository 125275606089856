import React, { useState, useEffect } from "react";
import { Button, Icon } from "@mui/material";
import { purple } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import fontFamily from '../../constants/Fonts'
import DownloadIcon from '../../assets/icons/icon-download.png'
const DOWNLOAD_BTN_TEXT = "ดาวน์โหลด";
const DownloadBtn = styled(Button)(({ theme }) => ({
  width: "100%",
  minWidth: "90px",
  borderRadius: "8px",
  fontFamily: fontFamily,
  fontSize: "auto",

  // lineHeight: 1,

  color: theme.palette.getContrastText('#0739A2'),
  backgroundColor: '#0739A2',
  "&:hover": {
    backgroundColor: "#0739A2",
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: '#0739A2'
    }
  },

}));

const imgIcon = (
  <Icon>
    <img src={DownloadIcon} alt="icon-download" width="20px" height="20px" />
  </Icon>
);

interface ICallback {
  onTap: () => void,
};

const DownloadButton: React.FC<ICallback> = ({ onTap }) => {
  return (
    <DownloadBtn variant="contained" startIcon={imgIcon} /* size="small" */ onClick={onTap}>
      {DOWNLOAD_BTN_TEXT}
    </DownloadBtn>
  );
};
export default DownloadButton;