import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import styledComponent from "styled-components";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';

import { visuallyHidden } from '@mui/utils';

import color from "../../constants/Colors";

import parseDateTime from 'helpers/parseDateTime'

import DeleteLayer from 'components/Button/DeleteLayer'

import UploadMapLayerDialog from 'components/Dialog/UploadMapLayer'

// icon
import AddIcon from '@mui/icons-material/Add';

//API
import {
  useGetFile,
  RequestDeleteFileByID,
  useDeleteFile,
  FILES,
  FIleItem,
} from 'api/useRequest'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el: any, index: number) => [el, index] as [any, number]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  headCells?: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell: any, index: number) => (
          <TableCell
            key={`${index}_${headCell.id}`}
            align={headCell.numeric ? 'left' : 'right'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


interface ITableProps {
  tableName: string;
  onOpenDialog?: () => void;
}

const EnhancedTableToolbar = (props: ITableProps) => {

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
        align="left"
        fontSize="16px"
        marginLeft="20px"
      >
        {
          props.tableName
        }
      </Typography>

      <Tooltip title="ตั้งค่าไฟล์เลเยอร์แผนที่">
        <Search>
          <ReportButton variant="outlined" type="submit" startIcon={<AddIcon />} size="small" style={{ width: "100%", minWidth: 180 }} onClick={props.onOpenDialog}>
            เพิ่มไฟล์เลเยอร์แผนที่
          </ReportButton>
        </Search>
      </Tooltip>
    </Toolbar>
  );
};

interface ICurrentProps {
  tableName: string;
  tableCell?: any;
  orderBy: string;
  order: Order;
}

export default function CurrentTable(props: ICurrentProps) {

  const GetFile = useGetFile()
  const DeletFile = useDeleteFile()

  const [order, setOrder] = React.useState<Order>(props.order);
  const [orderBy, setOrderBy] = React.useState<string>(props.orderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [HeaderCell] = React.useState<any>(props.tableCell)

  const [DataFile, setDataFile] = React.useState<FIleItem[]>([])

  const [openUploadDilog, setOpenUploadDilog] = React.useState(false);

  const DeleteFile = (id: number) => {
    var data: RequestDeleteFileByID;
    data = {
      FILES: FILES.FILE,
      DeleteFileByID: {
        id: id
      }
    }
    DeletFile.mutate(data, {
      onSuccess: (FileData: any) => {
        FatchApi()
      },
      onError: (error: any) => {
        alert("ไม่สามารถลบไฟล์ได้")
      }
    })
  }

  const FatchApi = () => {
    GetFile.mutate(FILES.FILE, {
      onSuccess: (FileData: any) => {
        var file: any = []
        FileData?.data?.file.forEach((e: any) => {
          if (e.file_type !== "ShapeFile_Conveyer") {
            file.push(e)
          }
        })
        setDataFile(file)
      },
    })
  }

  React.useEffect(() => {
    (() => {
      FatchApi()
    })()
  }, [])

  React.useLayoutEffect(() => {
    // setDataFileFileData([])
    return
  })

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - DataFile.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <UploadMapLayerDialog isOpen={openUploadDilog} setOpen={setOpenUploadDilog} reFlashData={() => { FatchApi() }} />
      <EnhancedTableToolbar tableName={props.tableName} onOpenDialog={() => {
        setOpenUploadDilog(true)
      }} />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={HeaderCell}
          />
          <TableBody>
            {stableSort(DataFile, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.file_type}</TableCell>
                    <TableCell>{parseDateTime(row.created_at)}</TableCell>
                    <TableCell>
                      {
                        row.file_type !== "GeoTiff" ? <BoxColor style={{ backgroundColor: row.color }}></BoxColor> : null
                      }
                    </TableCell>
                    <TableCell width={60}>
                      <DeleteLayer onClick={() => {
                        var result = window.confirm(`คุณต้องการลบไฟล์ ${row.name}`);
                        if (result) DeleteFile(row.id)
                      }} />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (53) * emptyRows,
                }}
              >
                <TableCell colSpan={HeaderCell.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={DataFile.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"จำนวนแถวต่อหน้า:"}
      />
    </Box>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const BoxColor = styledComponent.div`
  width: 100px;
  height: 30px;
  background-color: #ff00ff;
  border-radius: 4px;
`

const BoxRed = styledComponent.div`
  margin-right: 14px;
  height: 5px;
  width: 5px;
  border: 1px solid solid;
  background-color: red;
`

const BoxGreen = styledComponent.div`
  margin-right: 10px;
  height: 5px;
  width: 5px;
  border: 1px solid solid;
  background-color: green;
`

const StatusShow = styledComponent.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
`
const ReportButton = styled(Button)`
    border: 1px solid ${color.iconBlue};
    color: ${color.iconBlue};
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
`