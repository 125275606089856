import { ISensorItem } from 'api/useLandSlideAPI';
import React, { FunctionComponent, useEffect, useState } from 'react';
import ExportButton, { IExportHeader } from 'components/Button/ExportCSV';
import { usePageContext } from './LandSlidePage';
import parseDateTime from 'helpers/parseDateTime';

interface ExportLandSlideProps {
}

const ExportLandSlide: FunctionComponent<ExportLandSlideProps> = (props) => {
    const { stateContext, dispatchContext } = usePageContext();
    const [exHeaders, setExHeaders] = useState<IExportHeader[]>([]);
    const [exDataList, setExDataList] = useState<any>([]);
    function onClickExport() {
        let headers: IExportHeader[] = []
        let data: Record<string, any> = {}

        const sensorDataList = [...stateContext.allSensorList].sort((a: any, b: any) => (a.timestamp > b.timestamp ? 1 : -1));
        let tempTimestamp: string[] = []
        let tempHeaderList: string[] = []
        for (let index = 0; index < sensorDataList.length; index++) {
            const element = sensorDataList[index];
            if (stateContext.selectedSensor.length !== 0 && stateContext.selectedSensor.includes(element.device_id) === false) {
                continue
            }
            let new_data = {}
            const keyTimeStamp = parseDateTime(element.timestamp);
            if (tempTimestamp.includes(keyTimeStamp)) {
                new_data = { ...data[keyTimeStamp], ...getDataExportFormat(element) }
            } else {
                tempTimestamp.push(keyTimeStamp)
                new_data = { timestamp: keyTimeStamp, ...getDataExportFormat(element) }
            }
            data[keyTimeStamp] = new_data

            Object.keys(new_data).forEach((e: string, index: number) => {
                if (tempHeaderList.includes(e) === false) {
                    tempHeaderList.push(e)
                    headers.push({ label: e, key: e })
                }
            })
        }

        const timestamp: IExportHeader = headers.shift()!;
        headers.sort((a: any, b: any) => (a.label > b.label ? 1 : -1))
        headers.unshift(timestamp);

        setExHeaders(headers)
        setExDataList(Object.values(data))
    }

    return (
        <ExportButton
            buttonLabel=""
            fileName='landslide_export'
            headers={exHeaders}
            datas={exDataList}
            onClick={onClickExport}
        >
            {props.children}
        </ExportButton>
    );
}

export default ExportLandSlide;


function getDataExportFormat(element: ISensorItem) {
    let object: Record<string, any> = { ...element }
    let result: Record<string, any> = {}
    for (const key in object) {
        if (key === element.timestamp) { // filter
            continue
        }
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            const value = object[key];
            result[`${element.device_name}_${key}`] = value;
        }
    }
    return result
}