import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import React, { useEffect, useContext, useState, useRef } from "react";
import { RouteComponentProps } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { useParams } from 'react-router-dom'
import { MapCard } from "./GpsTrackingDeviceMapCard";
import * as dateFns from 'date-fns';

/* API */
import * as GpsTrackingAPI from "api/useGpsTrackingAPI";
import { ISensorItem } from "api/useGpsTrackingAPI";
import { DEVICES } from "api/useRequest";
import { defaultGpsTrackingConfigItem, IGpsTrackingConfigItem, reqGetConfig } from "api/useConfigSettingAPI";

import { UpdateCurrentDataButton } from "./UpdateCurrentDataButton";
import styled from 'styled-components'
import { SelectDeviceName } from "./SelectDeviceName";
import { DeviceSettingButton } from "./DeviceSettingButton";
import { CurDetailCard } from "./CurDetailCard";

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { DeviceTable } from "./DeviceTable";
import { GraphListCard } from "../../../components/Card/GraphListCard";
import { GPS_TRACKING_GRAPH_BASE_URL, GPS_TRACKING_GRAPH_PANEL_ID_LIST } from "../../../constants/Graph";
import ThresholdSetting from "components/ThresholdSetting/ThresholdSetting";
import useUserContext from "context/UserContext";
import { FieldItem } from "components/ThresholdSetting/ThresholdSettingView";
import { editDeviceNotification, getDeviceNotification, NotifyGroupItem, setDeviceNotification, SetNotiBody } from "api/useDeviceNotifyAPI";
import SnackbarUtils from "helpers/utils";
import { Axios, AxiosResponse } from "axios";
import DeviceAddressSelector from "components/DeviceAddressSelector/DeviceAddressSelector";
import DeviceAddressSetting from "components/DeviceAddressSetting/DeviceAddressSetting";
import { IDevAddrResItem, useDeviceAddressSelector } from "components/DeviceAddressSelector/useDeviceAddressSelector";
import DefaultDateTimeRange from "constants/DefaultDateTimeRage";
import StopMeasuring from "components/StopMeasuring/StopMeasuring";
interface IPageContext {
    curSensorList: ISensorItem[];
    curSensorItem: ISensorItem;
    curConfigList: IGpsTrackingConfigItem[];
    curConfigItem: IGpsTrackingConfigItem;
    allSensorList: ISensorItem[];
    dateRange: Date[];
    limit: string[];

}

const defaultContext: IPageContext = {
    curSensorList: [],
    curSensorItem: GpsTrackingAPI.defaultSensorItem,
    curConfigList: [],
    curConfigItem: defaultGpsTrackingConfigItem,
    allSensorList: [],
    dateRange: DefaultDateTimeRange[DEVICES.GPSTRACKING](new Date()),
    limit: [],
}


const pageContext = React.createContext<{ stateContext: IPageContext, dispatchContext?: React.Dispatch<any>; }>({ stateContext: defaultContext });

export const usePageContext = () => useContext(pageContext);


export const HEADER_TEXT = "ระบบติดตามเครื่องจักรขนาดใหญ่ด้วย GPS"
export const MAIN_PATH = "/gpstracking"
const GpsTrackingPageDevice: React.FC<RouteComponentProps> = () => {


    /* Create global state in page */
    const [stateContext, dispatchContext] = React.useReducer((state: IPageContext, action: any) => {
        return { ...state, ...action }
    }, defaultContext);


    return (
        <pageContext.Provider value={{ stateContext, dispatchContext }}>
            <GpsTrackingPageDeviceContent />
        </pageContext.Provider>
    );



}

export default GpsTrackingPageDevice

const DashboardContainer = styled.div`
  margin-left: 10px;
`

function GpsTrackingPageDeviceContent() {
    /* Use to set header text */
    const dispatch = useLayoutDispatch();
    /* Get params */
    let { deviceID } = useParams<{ deviceID: string; }>();
    const isAdmin = useUserContext().permission?.permissions.includes('admin');
    const [thresholdSettingFields, setThresholdSettingFields] = useState<FieldItem[]>([]);
    const notifyGroup = useRef<NotifyGroupItem>();


    const { stateContext, dispatchContext } = usePageContext()

    async function getConfig() {
        const res = await reqGetConfig(DEVICES.GPS);
        console.log(res);
        if (res.status !== 200) {
            return
        }
        const configs: IGpsTrackingConfigItem[] = res.data;
        const configItem: IGpsTrackingConfigItem = configs.find(e => e.device_id == deviceID) ?? defaultGpsTrackingConfigItem;

        dispatchContext!({ curConfigList: configs })
        dispatchContext!({ curConfigItem: configItem })
    }


    useEffect(() => {
        (async () => {
            const res = await GpsTrackingAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            const items = res.data.gps_tracking.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
            const curSensor = await items.find((e: any) => e.device_id === deviceID);

            /* Set Header */
            changeHeaderText(dispatch, {
                texts: [HEADER_TEXT, `${curSensor.device_name}`],
                link: `${window.location.protocol}//${window.location.host}${MAIN_PATH}`
            });


            dispatchContext!({ curSensorList: items, curSensorItem: curSensor });

        })();

        (async () => {
            console.log(deviceID);

            const res = await GpsTrackingAPI.reqDeviceData(stateContext.dateRange, deviceID);
            if (res.status != 200) {
                return [];
            }
            const { items } = res.data;
            console.log(items);

            dispatchContext!({ allSensorList: items });
        })();

        getConfig();
        getGpsTrackingNotify();
        /* Set page header */
    }, [deviceID])

    const onDateTimeChange = (value: Date[]) => {
        dispatchContext!({ dateRange: value });
        (async () => {
            const res = await GpsTrackingAPI.reqDeviceData(value, deviceID);
            if (res.status != 200) {
                return [];
            }
            const { items } = res.data;
            console.log(items);

            dispatchContext!({ allSensorList: items });
        })();
    };

    function getGpsTrackingNotify() {
        getDeviceNotification(DEVICES.GPS)
            .then(
                (res: any) => {
                    let { notifications } = res.data;
                    let notiObject = notifications.find((e: any) => e.device_ids.includes(deviceID.toUpperCase()));
                    let newThFileds = [
                        {
                            id: 'warning_threshold',
                            lable: "แจ้งเตือนระดับเฝ้าระวังเมื่อค่า Altitude มากกว่า",
                            value: notiObject ? notiObject.warning_threshold : 0,
                        },
                    ];
                    setThresholdSettingFields(newThFileds);
                    notifyGroup.current = notiObject;
                }
            ).catch(
                (err: any) => {
                    console.error(err);
                }
            );
    }

    const handleTresholdSubmit = (values: Record<string, string | number>) => {
        let body: SetNotiBody = {
            device_ids: [deviceID.toUpperCase()],
            warning_threshold: Number(values.warning_threshold),
            critical_threshold: 0,
        }

        console.log("handleTresholdSubmit", body);
        if (notifyGroup.current === undefined) {
            setDeviceNotification(DEVICES.GPS, body)
                .then(
                    (res: any) => {
                        SnackbarUtils.success("Setting Devices Notify success !");
                    }
                ).catch(
                    (err: any) => {
                        SnackbarUtils.error("Setting Devices Notify fail !");
                    }
                );
        } else {
            editDeviceNotification(DEVICES.GPS, notifyGroup.current.id, body)
                .then(
                    (res: any) => {
                        SnackbarUtils.success("Setting Devices Notify success !");
                    }
                ).catch(
                    (err: any) => {
                        SnackbarUtils.error("Setting Devices Notify fail !");
                    }
                );
        }


    };

    const setLastDataByAddr = (addr_des:string) => {
        
        const devAddrResItem = devAddrRes.find((e:IDevAddrResItem) => e.addr_des === addr_des);
        if (!devAddrResItem) return

        let { config, last_value } = devAddrResItem;

        if (config) {
            dispatchContext!({ curConfigItem: {...stateContext.curConfigItem, ...config} });
        }

        if (last_value) {
            if (!last_value.latitude && !last_value.longitude) {
                last_value.latitude = devAddrResItem.latitude;
                last_value.longitude = devAddrResItem.longitude;
            }

            dispatchContext!({ curSensorItem: {...stateContext.curSensorItem, ...last_value} });
        }
    }

    const cbAddrSelected = (addr_des:string , from:(Date | null), to:(Date | null)) => {
        dispatchContext!({ dateRange: [from!,to ?? new Date()] });
    }
    const { maxDate, minDate, devAddrRes, devAddr, isNotSelectedLastAddr, isMeasuringStop, handleDevAddrChange, fetchDeviceAddress } = useDeviceAddressSelector(DEVICES.GPSTRACKING, deviceID, stateContext.dateRange, cbAddrSelected);
    useEffect(() => {
        setLastDataByAddr(devAddr);
    }, [devAddr])

    return <DashboardContainer>
        <Grid
            width="100%"
            height="100%"
            container
            direction="column"
            rowSpacing={2}
            columnSpacing={2}
            columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
        >
            {/* <HeaderRow /> */}
            <Grid
                item
                container
                direction="row"
                columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
            >
                <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
                    <Grid 
                        container
                        wrap="nowrap"
                        gap={1}
                    >
                        {/* select device */}
                        <SelectDeviceName />
                        <DeviceAddressSelector address={devAddrRes} value={devAddr} onChange={handleDevAddrChange}  />
                        {/* upload data */}
                        <Grid 
                            container 
                            justifyContent={"flex-end"} 
                            alignItems={"center"}
                            gap={1}
                            wrap="nowrap" 
                        >
                            {/* <UpdateCurrentDataButton /> */}
                            {!isNotSelectedLastAddr ?
                                isMeasuringStop ?
                                <DeviceAddressSetting deviceId={deviceID} fetchAddr={fetchDeviceAddress} /> :
                                <StopMeasuring deviceId={deviceID} addrDes={devAddr} fetchAddr={fetchDeviceAddress} />
                                : null
                            }
                            <div>
                                <DeviceSettingButton disabled={isNotSelectedLastAddr}  />
                            </div>
                            <ThresholdSetting
                                    dialogTitle={"ตั้งค่า Threshold การแจ้งเตือน: " + stateContext.curSensorItem.device_name}
                                    fields={thresholdSettingFields}
                                    onSubmit={handleTresholdSubmit}
                                    disabled={isNotSelectedLastAddr}
                                />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <MapRow /> */}
            <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="strech"
                rowSpacing={2}
                columnSpacing={2}
                columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
            >

                {/* Column 1 map */}
                <Grid item xs={100} sm={100} md={50} lg xl
                    minHeight="500px"
                >
                    <MapCard />
                </Grid>

                {/* Column 2 card */}
                <Grid item xs={100} sm={100} md={50} lg={35} xl={15}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    gap={2}
                >   
                    <UpdateCurrentDataButton />
                    <Grid item xs paddingLeft="16px">
                        <CurDetailCard />
                    </Grid>


                </Grid>

                {/* Column 3 notification */}
                {/* <Grid item xs={100} sm={100} md={100} lg={100} xl={20}>
            <ErrorNotificationCard />
        </Grid> */}

            </Grid>

            {/* <TableZone /> */}
            <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
                <DeviceDataTab
                    tabName={["กราฟ", "ข้อมูลดิบ"]}
                    tabElement={[
                        <GraphListCard
                            urlPrefix={GPS_TRACKING_GRAPH_BASE_URL}
                            panelIdList={GPS_TRACKING_GRAPH_PANEL_ID_LIST}
                            dateTimeRange={stateContext.dateRange}
                            deviceIdList={[deviceID]}
                            yAxisConfigurable
                        />,
                        <DeviceTable />
                    ]}
                    tabWithDatePicker={[0, 1]}
                    dateTimeRange={stateContext.dateRange}
                    onDateTimeChange={onDateTimeChange}
                    maxDateTime={maxDate ?? undefined}
                    minDateTime={minDate ?? undefined}
                />
            </Grid>

        </Grid>
    </DashboardContainer>;
}


