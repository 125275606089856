import { useState } from "react";

export const useDialogController = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleDialogClose = () => { setIsOpen(false); };
    const handleDialogOpen = () => { setIsOpen(true); };
    return {
        isOpen,
        handleDialogClose,
        handleDialogOpen,
    };
};
