import requestInstance from "configure/requestConfig";
import { formatDate } from "../helpers/formatDate";
import mockImage1 from 'assets/icons/mocktruck/messageImage_1639939412279.jpg'
import mockImage2 from 'assets/icons/mocktruck/messageImage_1639939567231.jpg'
import mockImage3 from 'assets/icons/mocktruck/messageImage_1639939606522.jpg'
import parseDateTime from "helpers/parseDateTime";
import parseDate from "helpers/parseDate";
import parseTime from "helpers/parseTime";

/* API response
{
    "device_id": "e6a7010708888804",
    "device_name": "Dump truck01",
    "power_pri": 0,
    "power_sec": 0,
    "state": "Offline",
    "latitude": null,
    "longitude": null,
    "timestamp": "2021-12-22T20:10:29Z",
    "qr_detected_image": "/dumptruck/image/dt04-1640203824.jpg",
    "qr_detected_at": "2021-12-22T20:10:24Z",
    "dump_lift_image": "/dumptruck/image/dt04-1640203829.jpg",
    "qr_value": "DW1264-99999"
}
*/
export interface ISensorItem {
    device_id: string;
    device_name: string;
    power_pri: number;
    power_sec: number;
    state: string;
    latitude?: string;
    longitude?: string;
    timestamp: string;
    qr_detected_image: string;
    qr_detected_at: string;
    dump_lift_image: string;
    qr_value: string;
    car_name: string;
    [key: string]: any;
}

export const defaultSensorItem: ISensorItem = {
    device_id: '',
    device_name: '',
    power_pri: 0,
    power_sec: 0,
    state: '',
    latitude: '',
    longitude: '',
    timestamp: '',
    qr_detected_image: '',
    qr_detected_at: '',
    dump_lift_image: '',
    qr_value: '',
    car_name: '',
}

export const MapStateToDeviceCardState: Record<string, string> = {
    "Online": "ON",
    "Offline": "OFF",
    "Error": "ERROR",
}

export interface QrMapItem {
    id: number;
    name: string;
    qr_values: string[];
    created_at: string;
    updated_at: string;
}

export async function reqCurDeviceData() {
    const path = "/api/iot/all";
    const params = {
        limit: "",
        offset: "",
        device: "dump_truck"
    };

    return requestInstance.get(path, { params: params, });
}

export async function reqDeviceData(dateRange: Date[], device_id?: string) {
    console.log(dateRange);

    const path = "/api/iot/dump_truck";
    const params = {
        limit: 10000,
        offset: 0,
        start: parseDate(dateRange[0]) + ' ' + parseTime(dateRange[0]),
        end: parseDate(dateRange[1]) + ' ' + parseTime(dateRange[1]),
        device_id
    };

    console.log(params);


    return requestInstance.get(path, { params: params, });
}

export async function reqFileLayer() {
    const path = "/api/files/types";
    const params = {
        limit: "",
        offset: ""
    };

    const res = await requestInstance.get(path, { params: params, });
    console.log(res);

    return res;
}


export async function getCarNumberMapping() {
    const path = "/api/iot/cars";
    const params = {
        limit: "999999",
        offset: "",
        qr_values: ""
    };

    return requestInstance.get(path, { params: params, });
}


export async function editCarNumberMapping(id: number, name: string, qr_values: string[]) {
    const path = `/api/iot/cars/${id}`;
    const params = {
        name,
        qr_values
    };

    const res = await requestInstance.patch(path, params);
    console.log(res);

    return res;
}

export async function addCarNumberMapping(name: string, qr_values: string[]) {
    const path = "/api/iot/cars";
    const params = {
        name,
        qr_values
    };

    const res = await requestInstance.post(path, params);
    console.log(res);

    return res;
}
export async function deleteCarNumberMapping(id: number) {
    const path = `/api/iot/cars/${id}`;


    const res = await requestInstance.delete(path);
    console.log(res);

    return res;
}
