import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import TopsoilDeviceDetailTable, { createData } from "components/TopsoilDeviceDetailTable";
import { Data } from "components/TopsoilDeviceDetailTable";
import * as TruckAPI from "api/useTruckAPI";
import { ISensorItem } from "api/useTruckAPI";
import { usePageContext } from "./TruckPage";
import BaseCurrentTable from "components/BaseTable/BaseCurrentTable";
import { DEVICES } from "api/useRequest";
import { CardTemplate } from "components/Card/CardTemplate";
import parseDateTime from "helpers/parseDateTime";
import parseTime from "helpers/parseTime";
import { SimpleLoading } from "components/Loading/SimpleLoading";
import { format } from "date-fns";
import TruckDontDump from 'assets/images/truck-dont-dump.png';
import { SelectDeviceName } from "./SelectDeviceName";
import { SelectChangeEvent } from "@mui/material";

export function DeviceTable(props: any) {
    const { stateContext, dispatchContext } = usePageContext();
    const [dataList, setDataList] = useState<any[]>([]);
    const [filterID, setFilterID] = useState("all");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        (async () => {
            const datas: any[] = stateContext.allSensorList.map((e: any) => {
                const {
                    device_id,
                    device_name,
                    state,
                    timestamp,
                    qr_detected_image,
                    qr_detected_at,
                    dump_lift_image,
                    qr_value,
                } = e;

                let new_dump_lift_image;
                if (dump_lift_image !== undefined && dump_lift_image !== '')
                    new_dump_lift_image = process.env.REACT_APP_API_BASE_URL + dump_lift_image;
                else
                    new_dump_lift_image = TruckDontDump;

                let truckName = (props.qrMaps as TruckAPI.QrMapItem[]).find((e: TruckAPI.QrMapItem) => e.qr_values.includes(qr_value))?.name;

                return {
                    device_id,
                    device_name,
                    state: 'Online',
                    timestamp,//: parseDateTime(new Date(timestamp).toLocaleString()),
                    dump_lift_time: format(new Date(timestamp), 'HH:mm'),
                    qr_detected_image: <img src={process.env.REACT_APP_API_BASE_URL + qr_detected_image} alt="detected-image" width="150px" height="100px" />,
                    qr_detected_at: format(new Date(qr_detected_at), 'HH:mm'),
                    dump_lift_image: <img src={new_dump_lift_image} alt="dump-lift-image" width="150px" height="100px" />,
                    qr_value: truckName ? truckName : "ระบุหมายเลขรถไม่ได้",
                };
            });
            if (filterID !== "all") {
                setDataList(datas.filter((element: ISensorItem) => element.device_id.toLowerCase() === filterID.toLowerCase()));
            } else {
                setDataList(datas);
            }
            setIsLoading(false)
        })();
    }, [stateContext.allSensorList, filterID]);
    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0">
        <SelectDeviceName
            noAll={false}
            onChange={(value: string) => {
                setFilterID(value)
            }}
        />
        <CardTemplate>
            {isLoading ?
                <SimpleLoading />
                :
                <BaseCurrentTable
                    tableName={"รายละเอียดอุปกรณ์ตรวจนับเที่ยวรถบรรทุกเทท้าย"}
                    device={DEVICES.DUMPTRUCK}
                    order={"desc"}
                    orderBy={"timestamp"}
                    tableCell={headCells}
                    DataCurrentDevice={dataList}
                />}
        </CardTemplate>
    </Grid>;
}
interface HeadCell {
    disablePadding?: boolean;
    id: keyof any;
    label: string;
    numeric: boolean;
    isStatus?: boolean;
    isUpper?: boolean;
    isUseFormatTime?: boolean;
    notDownload?: boolean;
}

// {
//     timestamp,
//     device_name,
//     device_id,
//     car_number,
//     come_time,
//     dump_time,
//     leave_time,
//     state,
//     latitude,
//     longitude,
// }
const headCells: readonly HeadCell[] = [
    {
        id: 'device_id',
        numeric: false,
        isUpper: true,
        disablePadding: false,
        label: 'ID',
    },
    {
        id: 'device_name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'state',
        numeric: false,
        isStatus: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'timestamp',
        numeric: false,
        disablePadding: false,
        isUseFormatTime: true,
        label: 'Last Updated',
    },
    {
        id: 'qr_value',
        numeric: false,
        disablePadding: false,
        label: 'หมายเลขรถ',
    },
    {
        id: 'qr_detected_at',
        numeric: false,
        disablePadding: false,
        label: 'เวลาเข้า',
    },
    {
        id: 'dump_lift_time',
        numeric: false,
        disablePadding: false,
        label: 'เวลาเทท้าย',
    },
    // {
    //     id: 'leave_time',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'เวลาออก',
    // },
    {
        id: 'qr_detected_image',
        numeric: false,
        disablePadding: false,
        label: 'รถเข้า Crusher',
        notDownload: true,
    },
    {
        id: 'dump_lift_image',
        numeric: false,
        disablePadding: false,
        label: 'รถเทท้าย',
        notDownload: true,
    },


];
