import React, { useState, useEffect } from "react";
import DetailCard, { IDetailsProps } from "components/Card/DetailsCard";
import { usePageContext } from "./ConveyorPageDevice";
import parseDateTime from "helpers/parseDateTime";
import { SimpleLoading } from "components/Loading/SimpleLoading";
import { defaultSensorItem } from "api/useConveyorAPI";

export function CurDetailCard() {
    const { stateContext, dispatchContext } = usePageContext();
    const [dataStation, setDataStation] = useState<IDetailsProps>();
    useEffect(() => {
        (async () => {

            let dataName: string = stateContext.curSensorItem.value !== 0 ? 'ทำงาน' : 'ไม่ทำงาน';

            const data: IDetailsProps = {
                id: `${stateContext.curSensorItem.device_id}`,
                name: stateContext.curSensorItem.device_name,
                date: stateContext.curSensorItem.timestamp,
                state: stateContext.curSensorItem.state,//(stateContext.curSensorItem.value === 0 ? "Offline" : "Online"),
                data: [
                    { name: "สถานะสายพาน", value: dataName, unit: "" },
                    { name: "", value: '', unit: "" },
                    { name: "ไลน์", value: Boolean(stateContext.curSensorItem.line) ? stateContext.curSensorItem.line : "-", unit: "" },
                ],
                location: `${stateContext.curSensorItem.latitude}, ${stateContext.curSensorItem.longitude}`,
            };

            setDataStation(data);
        })();
    }, [stateContext.curSensorItem]);
    console.log(stateContext.curSensorItem);

    return stateContext.curSensorItem === defaultSensorItem ? <SimpleLoading /> : <DetailCard {...dataStation!} />;
}
