import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useDialogController } from "pages/sensors/truck/QrMapping/useDialogController";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { FunctionComponent } from "react";
import { deviceGroupItem } from "api/useDeviceGroupingAPI";
import AddEditGroupDialog from "./AddEditGroupDialog";
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { useMenuController } from "./useMenuController";
import LocationOffIcon from '@mui/icons-material/LocationOff';

export enum Action {
    Edit = 0,
    Delete = 1,
}

interface GroupMenuForEditProps {
    action: Action;
    deviceGroupList: deviceGroupItem[];
    mapDeviceNameId: Record<string, string>;
    onSave?: (groupId: number, groupName: string, selectedList: string[]) => void;
}

const GroupMenuForEdit: FunctionComponent<GroupMenuForEditProps> = (props) => {
    const {
        action,
        deviceGroupList,
        mapDeviceNameId,
        onSave,
    } = props;
    const {
        anchorEl,
        open,
        handleClick,
        handleClose
    } = useMenuController();

    const deviceNameList = Object.values(mapDeviceNameId);
    const deviceIdList = Object.keys(mapDeviceNameId).map(e => e.toLowerCase());
    return (
        <>
            <Tooltip title={action === Action.Edit ? "แก้ไขกลุ่ม" : "ลบกลุ่ม"}>
                <IconButton 
                    disabled={deviceGroupList && deviceGroupList.length <= 0}
                    onClick={handleClick}
                    sx={{
                        color: "#0739A2"
                    }}
                >
                    {action === Action.Edit ?
                        <EditLocationAltIcon /> :
                        <LocationOffIcon />
                    }
                </IconButton>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {deviceGroupList.map(
                    (e: deviceGroupItem) => {
                        return (
                            action === Action.Edit ?
                                < AddEditGroupDialog
                                    allItemList={deviceNameList}
                                    editGroupItem={e}
                                    selectedItemList={e.device_ids.map((id: string) => deviceNameList[deviceIdList.indexOf(id.toLowerCase())])}
                                    onSave={(groupName: string, selectedList: string[]) => {
                                        if (onSave !== undefined) {
                                            onSave(e.group_id, groupName, selectedList);
                                        }
                                    }}
                                    onClose={handleClose}
                                /> :
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        if (onSave) { onSave(e.group_id, e.group_name, []); }
                                    }}
                                >
                                    {e.group_name}
                                </MenuItem>
                        )


                    })
                }
            </Menu>
        </>
    );
}

export default GroupMenuForEdit;