import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import styled, { css } from 'styled-components';

import type { AuthenticationResponseData } from 'api/useAuthenticationAPI';
import useAuthenticationAPI from 'api/useAuthenticationAPI';
import SnackbarUtils from 'helpers/utils';
import useUserContext from 'context/UserContext';


interface Props {
    open: boolean;
    handleClose: () => void;
    onLoggedInSuccess?: (data: AuthenticationResponseData) => void
}

interface State {
    eno: number | null;
    password: string;
    showPassword: boolean;
}

const LoginFrom: React.FC<Props> = (props) => {
    const userContext = useUserContext()
    const apiAuthentication = useAuthenticationAPI()
    
    const [input, setInput] = useState<State>({
        eno: null,
        password: '',
        showPassword: false,
    })

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setInput({
            ...input,
            [prop]: event.target.value
        })
    }
    const handleClickShowPassword = () => {
        setInput({
            ...input,
            showPassword: !input.showPassword,
        });
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        apiAuthentication.mutate(
            {
                eno: input.eno === null ? 0 : Number(input.eno),
                pwd: input.password,
            },
            {
                onSuccess: (resp) => {
                    userContext.setAuth(resp.data);
                    if (typeof props.onLoggedInSuccess === 'function') {
                        props.onLoggedInSuccess(resp.data)
                    }
                    if (resp.data.access_token !== undefined||userContext.auth?.access_token !== undefined) {
                        SnackbarUtils.success('Logged In Success');
                    }
                    else {
                        SnackbarUtils.info('บัญชีของท่านอยู่ระว่างการตรวจสอบ');
                    }
                },
                onError: (error) => {
                    SnackbarUtils.error('Login Failed');
                },
            }
        )
    }


    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={props.open}
                onClose={props.handleClose}>
                <Title>Login
                    <IconButton
                        aria-label="close"
                        onClick={props.handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Title>
                <form onSubmit={onSubmit}>
                    <DialogContent>
                        <FormControl fullWidth>
                            <Eno
                                autoFocus
                                margin="dense"
                                name="eno"
                                placeholder="Employee No."
                                type="number"
                                onChange={handleChange('eno')}
                                required
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <PasswordInput
                                margin="dense"
                                name="pwd"
                                placeholder="Password"
                                type={input.showPassword ? 'text' : 'password'}
                                value={input.password}
                                onChange={handleChange('password')}
                                required
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {input.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            {apiAuthentication.isLoading ?
                                <LoadingLoginButton loading variant="contained">
                                    Submit
                                </LoadingLoginButton> :
                                <LoginButton variant="contained" type="submit" >Login</LoginButton>
                            }
                        </FormControl>
                    </DialogContent>
                </form>
            </Dialog>
        </div>
    );
}

export default LoginFrom;

const Title = styled(DialogTitle)`
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px;
    font-size: 32px;
`

const Eno = styled(TextField)`
    margin: 0px auto 0 auto;
    width: 80%;
    & ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
    };
`

const PasswordInput = styled(OutlinedInput)`
    margin: 24px auto 0 auto;
    width: 80%;
    & ::-ms-clear,& ::-ms-reveal, & ::-webkit-caps-lock-indicator, & ::-webkit-textfield-decoration-container {
        display: none;

    & ::-webkit-credentials-auto-fill-button,& ::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        height: 0;
        width: 0;
        margin: 0;
    }
}
`
const LoginCss = css`
    margin: 24px auto 60px auto;
    width: 80%;
    font-size: 18px;
`
const LoginButton = styled(Button)`
    ${LoginCss};
    background-color: ${(props) => props.theme.color.loginButton};
    &:hover {
        background-color: ${(props) => props.theme.color.loginButton};
        opacity:0.8;
    }
    text-transform: none;
`
const LoadingLoginButton = styled(LoadingButton)`
    ${LoginCss};
`