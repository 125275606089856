import { useState } from "react";

interface UseTabMenuProps {
    initialTab: number;

}

export const useTabMenu = (props: UseTabMenuProps) => {
    const [value, setValue] = useState(props.initialTab || 0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return {
        value,
        handleChange,
    };
}