import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import React, { useEffect, useContext, useState, useRef } from "react";
import type { RouteComponentProps } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import NormalText from 'constants/TextStyles';
import styled from 'styled-components'
import { Box, IconButton, Paper, Skeleton, Tooltip, Typography } from "@mui/material";
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField'
import useUserContext from 'context/UserContext';
// component map
import { CurrentDataList, SortByOptions } from "./CurrentDataList";
import { styled as styledMui } from '@mui/material/styles';
import * as dateFns from 'date-fns';
import { UpdateCurrentDataButton } from "./UpdateCurrentDataButton";
import { LandSlideMapContent } from "./LandSlideMapContent";
/* API */
import * as LandSlideAPI from "api/useLandSlideAPI";
import { ISensorItem } from "api/useLandSlideAPI";
import { DownloadCsvButton } from "./DownloadCsvButton";
import { GetDashboardButton } from "components/Card/DetailsCard";
import ExportLandSlide from "./ExportLandSlide";
import SortBySelector from "../../../components/SelectBox/SortBySelector";
import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { DeviceTable } from "./DeviceTable";
import { GraphListCard } from "components/Card/GraphListCard";
import { LANDSLIDE_ABSOLUTE_GRAPH_PANEL_ID_LIST, LANDSLIDE_DEVICE_GRAPH_PANEL_ID_LIST, LANDSLIDE_GRAPH_BASE_URL, LANDSLIDE_GRAPH_PANEL_ID_LIST, LANDSLIDE_RELATIVE_GRAPH_PANEL_ID_LIST } from "constants/Graph";
import ThresholdSetting, { ThresholdSettingProps } from "components/ThresholdSetting/ThresholdSetting";
import { FieldItem, SettingButton } from "components/ThresholdSetting/ThresholdSettingView";
import { DEVICES } from "api/useRequest";
import { editDeviceNotification, getDeviceNotification, NotifyGroupItem, setDeviceNotification, SetNotiBody } from "api/useDeviceNotifyAPI";
import SnackbarUtils from "helpers/utils";
import { RelProfileLandslide } from 'components/Datetime/DatePickerRelativeProfile';
import { deleteDeviceGroup, getDeviceGroups, registerDeviceGroup, updateDeviceGroup } from "api/useDeviceGroupingAPI";
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SelectGroupButton from "./SelectGroupButton";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import GroupManagement from "./GroupManagement/GroupManagement";
import DefaultDateTimeRange from "constants/DefaultDateTimeRage";


interface IPageContext {
    curSensorList: ISensorItem[];
    curSensorItem: ISensorItem;
    allSensorList: ISensorItem[];
    dateRange: Date[];
    selectedSensor: string[];
    compareDevice: boolean;
}

const defaultContext: IPageContext = {
    curSensorList: [],
    curSensorItem: LandSlideAPI.defaultSensorItem,
    allSensorList: [],
    dateRange: DefaultDateTimeRange[DEVICES.LANDSLIDE](new Date()),
    selectedSensor: [],
    compareDevice: false,
}
const pageContext = React.createContext<{ stateContext: IPageContext, dispatchContext?: React.Dispatch<any>; }>({ stateContext: defaultContext });

export const usePageContext = () => useContext(pageContext);

interface ICurentCsvReport {
    data: ISensorItem[],
    headers: { label: string; key: string; }[],
    filename: 'Current_devices_data.csv'
}

const HEADER_TEXT = "ระบบตรวจวัดการเคลื่อนตัวชั้นหน้าดิน"
const LandSlidePage: React.FC<RouteComponentProps> = () => {

    /* Create global state in page */
    const [stateContext, dispatchContext] = React.useReducer((state: IPageContext, action: any) => {
        return { ...state, ...action }
    }, defaultContext);

    return (
        <pageContext.Provider value={{ stateContext, dispatchContext }}>
            <LandSlidePageContent />
        </pageContext.Provider>
    );
}

const LandSlidePageContent = () => {

    const isAdmin = useUserContext().permission?.permissions.includes('admin');
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingCurrentData, setIsLoadingCurrentData] = useState(true);
    const { stateContext, dispatchContext } = usePageContext();
    const [countOnline, setCountOnline] = useState<string>("");
    const [sortTypeCurData, setSortTypeCurData] = useState('Diff 2D');
    const [thresholdSettingFields, setThresholdSettingFields] = useState<FieldItem[]>([]);
    const notifyGroup = useRef<NotifyGroupItem>();



    /* Set page header */
    const dispatch = useLayoutDispatch()

    useEffect(() => {
        changeHeaderText(dispatch, HEADER_TEXT);
        onDateTimeChange(DefaultDateTimeRange[DEVICES.LANDSLIDE](new Date()));

        (async () => {
            setIsLoadingCurrentData(true)
            const res = await LandSlideAPI.reqCurDeviceData();
            if (res.status != 200) {
                setIsLoadingCurrentData(false)
                return [];
            }
            let items = res.data.landslide.data.items.sort((a: any, b: any) => (a.diff2d < b.diff2d ? 1 : -1));
            items.forEach((element: ISensorItem) => {
                const { timestamp } = element;
                let hrBetweenTwoDate = dateFns.differenceInHours(new Date(), new Date(timestamp));
                element.state = hrBetweenTwoDate > 1 ? "Offline" : "Online";
            });
            const online = items.filter((e: any) => (e.state === 'Online')).length;
            setCountOnline(`${online}/${items.length}`)

            getLandSlideNotify(items as ISensorItem[]);
            dispatchContext!({ curSensorList: items });
            setIsLoadingCurrentData(false)
        })();

        (async () => {
            const res = await LandSlideAPI.reqDeviceData(stateContext.dateRange);
            if (res.status != 200) {
                return [];
            }
            const { items } = res.data;
            dispatchContext!({ allSensorList: items });
            setIsLoading(false)
        })();

    }, []);

    const onDateTimeChange = (value: Date[]) => {
        dispatchContext!({ dateRange: value });
    };

    function getLandSlideNotify(curSensorList: ISensorItem[]) {
        getDeviceNotification(DEVICES.LANDSLIDE)
            .then(
                (res: any) => {
                    console.log(res);
                    let { notifications } = res.data;
                    let notiObject = notifications.find((e: any) => e.device_ids.length === curSensorList.length);
                    let newThFileds = [
                        {
                            id: 'warning_threshold',
                            lable: "แจ้งเตือนระดับเฝ้าระวังเมื่อค่า Diff 2D มากกว่า",
                            value: notiObject ? notiObject.warning_threshold : 0,
                        },
                    ]
                    setThresholdSettingFields(newThFileds);
                    notifyGroup.current = notiObject;
                }
            ).catch(
                (err: any) => {
                    console.error(err);
                }
            );
    }

    const handleTresholdSubmit = (values: Record<string, string | number>) => {
        console.log("LandSlide::onSubmit::values", values);
        let body: SetNotiBody = {
            device_ids: stateContext.curSensorList.map(e => e.device_id),
            warning_threshold: Number(values.warning_threshold),
            critical_threshold: 0,
        }

        if (notifyGroup.current === undefined) {
            setDeviceNotification(DEVICES.LANDSLIDE, body)
                .then(
                    (res: any) => {
                        SnackbarUtils.success("Setting Devices Notify success !");
                    }
                ).catch(
                    (err: any) => {
                        SnackbarUtils.error("Setting Devices Notify fail !");
                    }
                );
        } else {
            editDeviceNotification(DEVICES.LANDSLIDE, notifyGroup.current.id, body)
                .then(
                    (res: any) => {
                        SnackbarUtils.success("Setting Devices Notify success !");
                    }
                ).catch(
                    (err: any) => {
                        SnackbarUtils.error("Setting Devices Notify fail !");
                    }
                );
        }
    };

    const [deviceGroup, setDeviceGroup] = useState<any[]>([]);


    useEffect(() => {
        getDeviceGroup();
    }, []);

    const getDeviceGroup = () => {
        getDeviceGroups(DEVICES.LANDSLIDE).then((result) => {
            setDeviceGroup(result);
        }).catch((err) => {
            console.log({err});
        });
    }
    const handleSelectedGroup = (groupName: string, index: number): void => {
        let idList: string[] = [];
        if (deviceGroup[index]?.group_name === groupName) {
            idList = deviceGroup[index].device_ids.map((e: string) => e.toLowerCase());
        }

        console.log('idList', idList);

        stateContext.selectedSensor = idList;
        dispatchContext!({ selectedSensor: idList });
    }

    const handleAddDeviceGroup = (groupName: string, selectedList: string[]) => {
        console.log("handleAddDeviceGroup", { groupName, selectedList });
        registerDeviceGroup(DEVICES.LANDSLIDE, groupName, selectedList).then((result) => {
            getDeviceGroup();
        }).catch((err) => {
            
        });
    };
    const handleResetSelection = () => {
        let count = [...stateContext.selectedSensor].length;
        for (let index = 0; index < count; index++) {
            stateContext.selectedSensor.pop();
        }
        dispatchContext!({ selectedSensor: stateContext.selectedSensor });
        dispatchContext!({ compareDevice: !stateContext.compareDevice });
    };
    const handleEditGroup = (groupId: number, groupName: string, selectedList: string[]) => {
        console.log("handleEditGroup", { groupId, groupName, selectedList });
        updateDeviceGroup(DEVICES.LANDSLIDE, groupName, selectedList, groupId).then((result) => {
            getDeviceGroup();
        }).catch((err) => {
            
        });
    };
    const handleDeleteGroup = (groupId: number) => {
        console.log("handleDeleteGroup", groupId);
        deleteDeviceGroup(groupId).then((result) => {
            getDeviceGroup();
        }).catch((err) => {
            
        });
    };
    return (
        < DashboardContainer >
            <Grid
                width="100%"
                height="100%"
                container
                direction="column"
                rowSpacing={2}
                columnSpacing={2}
                columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
            >
                {/* <HeaderRow /> */}
                <Grid
                    item
                    container
                    direction="row"
                    columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                >
                    <Grid item xs={100} sm={100} md={30} lg={30} xl={30} container alignItems="center" >
                        <NormalText>
                            {HEADER_TEXT}
                        </NormalText>
                    </Grid>
                    <Grid item xs={100} sm={100} md={70} lg={70} xl={70}>
                        <Grid container rowSpacing={5} columnSpacing={5} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} justifyContent="flex-end">
                            <Grid item xs={100} sm={100} md={100} lg={100} xl={100} container justifyContent="flex-end" >
                                <UpdateCurrentDataButton />
                                {isAdmin &&
                                    <>
                                        <div style={{ marginLeft: "10px" }} />
                                        <ThresholdSetting
                                            dialogTitle="ตั้งค่า Threshold การแจ้งเตือน: Land Slide"
                                            fields={thresholdSettingFields}
                                            onSubmit={handleTresholdSubmit}
                                        />
                                    </>
                                }
                            </Grid>
                            {/* <Grid item xs={100} sm={100} md={35} lg={35} xl={35} container justifyContent="flex-end" >
                                    <DownloadCsvButton />
                                </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>

                {/* <MapRow /> */}
                <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} height={'fit-content'} >
                    <Grid item xs={100} sm={100} md={50} lg={70} xl={70} maxHeight="500px">
                        <LandSlideMapContent
                            onTapMarker={(data) => {
                                if (stateContext.selectedSensor.includes(data.id) === false) {
                                    stateContext.selectedSensor.push(data.id)
                                    dispatchContext!({ selectedSensor: stateContext.selectedSensor })
                                    let targetCard = document.getElementById(`detail-card-${data.id}`);
                                    targetCard?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                } else {
                                    const index = stateContext.selectedSensor.indexOf(data.id, 0);
                                    if (index > -1) {
                                        stateContext.selectedSensor.splice(index, 1);
                                    }
                                    dispatchContext!({ selectedSensor: stateContext.selectedSensor })

                                }

                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={100} sm={100} md={50} lg={30} xl={30}
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        alignContent="stretch"
                        bgcolor={'#fff'}
                    // maxHeight="500px"
                    >
                        {!isLoadingCurrentData ? <Grid item width={'100%'}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            alignContent="stretch"
                            wrap="nowrap"
                            padding="16px"
                            paddingBottom="0"

                        >
                            <Typography variant="body2" color="initial" fontWeight={600} >อุปกรณ์ออนไลน์ {countOnline}</Typography>
                            <SortBySelector label="เรียงลำดับ"
                                value={sortTypeCurData}
                                options={Object.keys(SortByOptions)}
                                onSelectedChange={(opt: string) => {
                                    // setSortTypeCurData("")
                                    setSortTypeCurData(opt)
                                }} />
                        </Grid> : <Grid item width={'100%'}
                            padding="16px"
                            paddingBottom="0"></Grid>}
                        <Grid item width={'100%'}   >
                            <CurrentDataList
                                sortby={sortTypeCurData}
                                onTapSelected={(id, preState, curState) => {
                                    console.log(id, curState);
                                    if (curState === true && stateContext.selectedSensor.includes(id) === false) {
                                        stateContext.selectedSensor.push(id)
                                        dispatchContext!({ selectedSensor: stateContext.selectedSensor })
                                    } else if (curState === false) {
                                        const index = stateContext.selectedSensor.indexOf(id, 0);
                                        if (index > -1) {
                                            stateContext.selectedSensor.splice(index, 1);
                                            dispatchContext!({ selectedSensor: stateContext.selectedSensor })
                                        }
                                        if (stateContext.selectedSensor.length === 0) {
                                            dispatchContext!({ compareDevice: !stateContext.compareDevice })
                                        }
                                    }
                                    console.log(stateContext.selectedSensor);


                                }} />
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item container spacing={2} direction={'row'} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} justifyContent={'flex-end'} >
                    <Grid item xs={100} sm={100} md={100} lg={100} xl={100}
                        container
                        spacing={2}
                        justifyContent="flex-end"
                        alignContent="flex-end"
                        columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                        padding={"0px 15px"}
                    >
                        <GroupManagement
                            type={DEVICES.LANDSLIDE}
                            deviceGroupList={deviceGroup}
                            selectedItemList={stateContext.selectedSensor}
                            mapDeviceNameId={stateContext.curSensorList.reduce((pre: any, cur: any) => {
                                let item: Record<string, string> = pre;
                                item[cur.device_id] = cur.device_name;
                                return item;
                            }, {})}
                            onAddGroup={handleAddDeviceGroup}
                            onEditGroup={handleEditGroup}
                            onDeleteGroup={handleDeleteGroup}
                            onGroupSelected={handleSelectedGroup}
                            onResetSelected={handleResetSelection}
                        />

                        <ExportLandSlide >
                            <Tooltip title="ดาวน์โหลดรายงาน">
                                <IconButton
                                    sx={{
                                        color:"#0739A2"
                                    }}
                                >
                                    <FileDownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </ExportLandSlide>
                    </Grid>
                </Grid>
                <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
                    <DeviceDataTab
                        tabName={["กราฟข้อมูลทั่วไป", "กราฟข้อมูลสัมพัทธ์", "กราฟข้อมูลสัมบูรณ์", "กราฟข้อมูลอุปกรณ์", "ตารางข้อมูลอุปกรณ์"]}
                        tabElement={[
                            <GraphListCard
                                urlPrefix={LANDSLIDE_GRAPH_BASE_URL}
                                panelIdList={LANDSLIDE_GRAPH_PANEL_ID_LIST}
                                deviceIdList={stateContext.selectedSensor}
                                dateTimeRange={stateContext.dateRange}
                                yAxisConfigurable
                            />,
                            <GraphListCard
                                urlPrefix={LANDSLIDE_GRAPH_BASE_URL}
                                panelIdList={LANDSLIDE_RELATIVE_GRAPH_PANEL_ID_LIST}
                                deviceIdList={stateContext.selectedSensor}
                                dateTimeRange={stateContext.dateRange}
                                yAxisConfigurable
                            />,
                            <GraphListCard
                                urlPrefix={LANDSLIDE_GRAPH_BASE_URL}
                                panelIdList={LANDSLIDE_ABSOLUTE_GRAPH_PANEL_ID_LIST}
                                deviceIdList={stateContext.selectedSensor}
                                dateTimeRange={stateContext.dateRange}
                            />,
                            <GraphListCard
                                urlPrefix={LANDSLIDE_GRAPH_BASE_URL}
                                panelIdList={LANDSLIDE_DEVICE_GRAPH_PANEL_ID_LIST}
                                deviceIdList={stateContext.selectedSensor}
                                dateTimeRange={stateContext.dateRange}
                            />,
                            <DeviceTable />,
                        ]}
                        tabWithDatePicker={[0, 1, 2]}
                        dateTimeRange={stateContext.dateRange}
                        onDateTimeChange={onDateTimeChange}
                        relativeProfile={RelProfileLandslide}
                    />
                </Grid>
            </Grid>
        </DashboardContainer >
    );
}

export default LandSlidePage

const DashboardContainer = styled.div`
  margin-left: 10px;
`

export const MapBox = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        height: "500px"
    },
    [theme.breakpoints.down('md')]: {
        height: "300px"
    },
}));



