import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useLayoutDispatch, changeHeaderText } from "context/LayoutContext";
import DetailsCard from 'components/Card/DetailsCard'
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField'


// component table
import BaseCurrentTable from 'components/BaseTable/BaseCurrentTable'
import {
  headCells
} from './WeatherInterfaces'

// component map
import MapWeather from 'components/Map/MapWeather.js';

//API
import {
  useGetDeviceAPI,
  WeatherItem,
  DEVICES,
  STATUS,
  useGetFile,
  FILES,
  mapresponseMapLayer,
  Layer
} from 'api/useRequest'
import useUserContext from 'context/UserContext';

import * as dateFns from 'date-fns';

import {
  Box,
  Grid,
  Typography,
  Paper,
} from "@mui/material"

import parseDateTime from 'helpers/parseDateTime'
import { CardTemplate } from 'components/Card/CardTemplate';
import { SimpleLoading } from 'components/Loading/SimpleLoading';
import { coordinateDecimalToMineGrid } from 'helpers/geo';
import SortBySelector from 'components/SelectBox/SortBySelector';

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { GraphListCard } from "../../../components/Card/GraphListCard";
import { WEATHER_STATION_GRAPH_BASE_URL, WEATHER_STATION_GRAPH_PANEL_ID_LIST } from "../../../constants/Graph";
import BaseMap from 'components/Map/BaseMap';
import SensorMarker from 'components/Map/Marker/SensorMarker';
import ThresholdSetting from 'components/ThresholdSetting/ThresholdSetting';
import { editDeviceNotification, getDeviceNotification, NotifyGroupItem, setDeviceNotification, SetNotiBody } from 'api/useDeviceNotifyAPI';
import { FieldItem } from 'components/ThresholdSetting/ThresholdSettingView';
import SnackbarUtils from 'helpers/utils';
import DefaultDateTimeRange from 'constants/DefaultDateTimeRage';

let TimeInterval: any
let IntervalTime: number = 60

const L = window.L;

const WeatherPage: React.FC<RouteComponentProps> = () => {
  //fetch data
  const isLogedin = useUserContext().auth?.access_token != undefined ? true : false;
  const isAdmin = useUserContext().permission?.permissions.includes('admin');
  const [thresholdSettingFields, setThresholdSettingFields] = useState<FieldItem[]>([]);
  const notifyGroup = useRef<NotifyGroupItem>();

  // fetch data Weather
  const WeatherAPI = useGetDeviceAPI();

  const FatchApi = () => {
    WeatherAPI.mutate(DEVICES.WEATHER, {
      onSuccess: (data: any) => {
        // call list file
        let newData = data?.data?.weather?.data?.items;
        if (newData !== undefined) {
          newData = newData.map((item: any) => {
            if (item.hasOwnProperty('latitude') || item.hasOwnProperty('longtitude')) {
              const mineGrid = coordinateDecimalToMineGrid(item.latitude, item.longitude);
              item.mine_n = mineGrid.mineN.toFixed(2);
              item.mine_e = mineGrid.mineE.toFixed(2);
            }
            return item;
          });
        }
        getWeatherNotify(newData);
        setWeatherListDevice(newData.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1)))
        const online = newData.filter((e: any) => (e.state === 'Online')).length;
        setCountOnline(`${online}/${newData.length}`)
        setIsLoadingCurrentData(false)

      },
    })
  }

  //end fetch data
  const dispatch = useLayoutDispatch()
  useEffect(() => {
    changeHeaderText(dispatch, "ระบบตรวจวัดคุณภาพอากาศ");
    (() => {

      FatchApi()

      TimeInterval = setInterval(() => {
        setLastFetchedTime(parseDateTime(new Date()))
        FatchApi()
      }, 1000 * 60);
    })()

    return () => {
      clearInterval(TimeInterval)
    }

  }, []);

  const [value, setValue] = React.useState(0);
  const [WeatherListDevice, setWeatherListDevice] = React.useState<WeatherItem[]>([])
  const [LastFetchedTime, setLastFetchedTime] = React.useState<string>(parseDateTime(new Date()));
  const [PickerDateTIme, setPickerDateTIme] = React.useState<Date[]>(DefaultDateTimeRange[DEVICES.WEATHER](new Date()));

  /* Set threshold to graph */
  /* [LOW,HIGH] */
  const [limit, setLimit] = React.useState([-1, -1])

  /* push to lv 2 */
  let history = useHistory();

  const SortByOptions: Record<string, any> = {
    'Device ID': 'device_id',
    'Device Name': 'device_name',
    "PM 2.5": 'pm_25',
    "PM 10": 'pm_10',
  }
  const [countOnline, setCountOnline] = useState<string>("");
  const [sortTypeCurData, setSortTypeCurData] = useState('Device Name');
  const [isLoadingCurrentData, setIsLoadingCurrentData] = useState(true);
  function sortDataList(opt: string) {
    if (SortByOptions[opt] === undefined) {
      return
    }
    if (SortByOptions[opt] === 'device_id' || SortByOptions[opt] === 'device_name') {
      setWeatherListDevice(WeatherListDevice.sort((a: any, b: any) => (a[SortByOptions[opt]] > b[SortByOptions[opt]] ? 1 : -1)))
    } else {
      setWeatherListDevice(WeatherListDevice.sort((a: any, b: any) => (a[SortByOptions[opt]] < b[SortByOptions[opt]] ? 1 : -1)))
    }
  }

  const onDateTimeChange = (value: Date[]) => {
    setPickerDateTIme(value)
  }


  function getWeatherNotify(curSensorList: WeatherItem[]) {
    getDeviceNotification(DEVICES.WEATHER)
      .then(
        (res: any) => {
          console.log(res);
          let { notifications } = res.data;
          let notiObject = notifications.find((e: any) => e.device_ids.length === curSensorList.length);
          let newThFileds = [
            {
              id: 'warning_threshold',
              lable: "แจ้งเตือนระดับเฝ้าระวังเมื่อค่า PM 2.5 มากกว่า",
              value: notiObject ? notiObject.warning_threshold : 0,
            },
            {
              id: 'critical_threshold',
              lable: "แจ้งเตือนระดับวิกฤตเมื่อค่า PM 2.5 มากกว่า",
              value: notiObject ? notiObject.critical_threshold : 0,
            },

          ]
          setThresholdSettingFields(newThFileds);
          if (notiObject !== undefined) {
            setLimit([notiObject.warning_threshold, notiObject.critical_threshold]);
          }
          notifyGroup.current = notiObject;
        }
      ).catch(
        (err: any) => {
          console.error(err);
        }
      );
  }

  const handleTresholdSubmit = (values: Record<string, string | number>) => {
    let body: SetNotiBody = {
      device_ids: WeatherListDevice.map(e => e.device_id),
      warning_threshold: Number(values.warning_threshold),
      critical_threshold: Number(values.critical_threshold),
    }

    if (notifyGroup.current === undefined) {
      setDeviceNotification(DEVICES.WEATHER, body)
        .then(
          (res: any) => {
            SnackbarUtils.success("Setting Devices Notify success !");
            setLimit([body.warning_threshold, body.critical_threshold]);
          }
        ).catch(
          (err: any) => {
            SnackbarUtils.error("Setting Devices Notify fail !");
          }
        );
    } else {
      editDeviceNotification(DEVICES.WEATHER, notifyGroup.current.id, body)
        .then(
          (res: any) => {
            SnackbarUtils.success("Setting Devices Notify success !");
            setLimit([body.warning_threshold, body.critical_threshold]);
          }
        ).catch(
          (err: any) => {
            SnackbarUtils.error("Setting Devices Notify fail !");
          }
        );
    }
  };

  return (
    <DashboardContainer>
      <Grid direction="row" container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>

        {/* ====================== First row  ====================== */}
        {/* column 1 */}
        <Grid item xs={100} sm={100} md={50} lg={30} xl={30} style={{ display: "flex", alignItems: "center", }} >
          <Box id={"Topic"}>
            <NormalText>ระบบตรวจวัดคุณภาพอากาศ</NormalText>
          </Box>
        </Grid>

        {/* column 2 */}
        <Grid item xs={100} sm={100} md={50} lg={70} xl={70}>

          <Grid direction="row" container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>

            <Grid item xs={100} sm={100} md={100} lg={100} xl={100} style={{ display: "flex", justifyContent: "flex-end" }}>
              <LastUpdateTextField datetime={LastFetchedTime} onClick={() => {
                FatchApi()
                setLastFetchedTime(parseDateTime(new Date()))
              }} />
              {isAdmin &&
                <>
                  <div style={{ marginLeft: "10px" }} />
                  <ThresholdSetting
                    dialogTitle="ตั้งค่า Threshold การแจ้งเตือนสภาพอากาศ: "
                    fields={thresholdSettingFields}
                    onSubmit={handleTresholdSubmit}
                  />
                </>
              }
            </Grid>

          </Grid>

        </Grid>


        {/* ====================== Second row  ====================== */}

        <Grid direction="column" container item xs={100} sm={100} md={100} lg={100} xl={100}>
          <Grid direction="row" container item xs={100} sm={100} md={100} lg={100} xl={100} style={{ backgroundColor: "white" }}>
            {/* column 1 */}
            <Grid item xs={100} sm={100} md={100} lg={50} xl={50} >
              <MapBox>
                {/* component map here. */}
                <BaseMap>
                  {
                    WeatherListDevice.map((e: WeatherItem, i: number) => (
                      <SensorMarker
                        key={`WeatherMarker_${i}`}
                        type={DEVICES.WEATHER}
                        state={e.state}
                        position={[Number(e.latitude), Number(e.longitude)]}
                        label={e.device_name}
                        data={e}
                      />
                    ))
                  }
                </BaseMap>
              </MapBox>
            </Grid>

            {/* column 2 */}
            <Grid
              item
              xs={100} sm={100} md={50} lg={50} xl={50}
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="stretch"
              bgcolor={'#fff'}
            // maxHeight="500px"
            >
              {!isLoadingCurrentData ? <Grid item width={'100%'}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="stretch"
                wrap="nowrap"
                padding="8px"

              >
                <Typography variant="body2" color="initial" fontWeight={600} >อุปกรณ์ออนไลน์ {countOnline}</Typography>
                <SortBySelector label="เรียงลำดับ"
                  value={sortTypeCurData}
                  options={Object.keys(SortByOptions)}
                  onSelectedChange={(opt: string) => {
                    // setSortTypeCurData("")
                    setSortTypeCurData(opt)
                    sortDataList(opt)
                  }} />
              </Grid> : <Grid item width={'100%'}
                padding="16px"
                paddingBottom="0"></Grid>}
              <Grid item width={'100%'} /* xs={100} sm={100} md={100} lg={100} xl={100} */ >
                {WeatherListDevice.length === 0 ? <MapBox><SimpleLoading /></MapBox> : <CustomScrollBox sx={{
                  overflow: 'auto',
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  bgcolor: "white",
                }}>
                  {WeatherListDevice.map((e: any, i: number) => (
                    <DetailsCard
                      key={`${i}_${e.device_id}`}
                      id={e.device_id}
                      name={e.device_name}
                      date={e.timestamp}
                      state={e.state}
                      data={[
                        { name: "PM 2.5", value: e.pm_25, unit: "ug/m³", enlarge: true, isError: e.pm_25 > 750, errMsg:"ค่าสูงผิดปกติ อุปกรณ์อาจมีปัญหา โปรดตรวจสอบ..." },
                        { name: "PM 10", value: e.pm_10, unit: "ug/m³", enlarge: true, isError: e.pm_10 > 750, errMsg:"ค่าสูงผิดปกติ อุปกรณ์อาจมีปัญหา โปรดตรวจสอบ..." },
                        { name: "Temperature", value: e.temperature, unit: "°C" },
                        { name: "Humidity", value: e.humidity, unit: "%" },
                        { name: "", value: "", unit: "" },
                        { name: "", value: "", unit: "" },

                      ]}
                      WeatherData={[e]}
                      location={`${0}, ${0}`}
                      path="/weather"
                      onTapSeeDashboard={() => {
                        history.push(`/weather/device/${e.device_id}?limitHigh=${limit[1]}&limitLow=${limit[0]}`)

                      }}
                    />

                  ))}
                </CustomScrollBox>}

              </Grid>

            </Grid>
          </Grid>
        </Grid>



        {/* ====================== Third row  ====================== */}
        {/* column 1 */}
        <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
          <DeviceDataTab
            tabName={["กราฟ", "อุปกรณ์"]}
            tabElement={[
              <GraphListCard
                urlPrefix={WEATHER_STATION_GRAPH_BASE_URL + `&var-limit_high=${limit[1]}&var-limit_low=${limit[0]}`}
                panelIdList={WEATHER_STATION_GRAPH_PANEL_ID_LIST}
                dateTimeRange={PickerDateTIme}
                yAxisConfigurable
              />,
              <CardTemplate>
                <BaseCurrentTable tableName={"รายละเอียดระบบตรวจวัดคุณภาพอากาศ"} device={DEVICES.WEATHER} order={"asc"} orderBy={"device_id"} tableCell={headCells} DataCurrentDevice={WeatherListDevice} />
              </CardTemplate>
            ]}
            tabWithDatePicker={[0]}
            dateTimeRange={PickerDateTIme}
            onDateTimeChange={onDateTimeChange}
          />
        </Grid>


      </Grid>
    </DashboardContainer>
  )
}

export default WeatherPage

const GraphElement = (props: { link: string[] }) => {
  return (
    <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0" container rowSpacing={2} columnSpacing={2}>
      {props.link !== undefined &&
        props.link.map((e: any, index: number) => {
          return (
            <Grid key={`${index}_Grid`} width="100%" item xs={100} sm={100} md={100} lg={100} xl={100}>
              <CardTemplate
                key={`${index}_CardTemplate`}
              >
                <div
                  key={`${index}_div`}>
                  <iframe
                    key={`${index}_iframe`}
                    src={e}
                    width="100%"
                    height="370"
                    frameBorder="0"
                  >
                  </iframe>
                </div>
              </CardTemplate>
            </Grid>
          )
        })
      }
    </Grid>
  )
}

const DashboardContainer = styled.div`
  margin-left: 10px;
`
const NormalText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontSize: '120%',
}));

const MapBox = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: "500px"
  },
  [theme.breakpoints.down('md')]: {
    height: "300px"
  },
}));

const CustomScrollBox = styled(Box)(({ theme }) => ({
  "> *": {
    margin: "12px 0"
  },
  '&::-webkit-scrollbar': {
    width: "6px"
  },
  '&::-webkit-scrollbar-track': {
    background: "#f1f1f1"
  },
  '&::-webkit-scrollbar-thumb': {
    background: "#888",
    borderRadius: "6px"
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: "#555"
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: '500px',
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: '300px',
    marginTop: "20px"
  },
}));
