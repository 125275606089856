import React, { FunctionComponent, useEffect, useState } from "react";
import DetailCardList, { IDataStation } from "components/List/DetailCardList";
import { usePageContext } from "./WaterLevelPage";
import * as WaterLvAPI from "api/useWaterLevelAPI";
import parseDateTime from "helpers/parseDateTime";
import { useHistory } from "react-router-dom";
import { SimpleLoading } from "components/Loading/SimpleLoading";

export const SortByOptions: Record<string, any> = {
    'Device ID': 'device_id',
    'Device Name': 'device_name',
    'Water Level': 'level',
}

interface CurrentDataListProps {
    sortby: string;
}

export const CurrentDataList: FunctionComponent<CurrentDataListProps> = (props) => {
    const { stateContext, dispatchContext } = usePageContext();
    const [dataStationList, setDataStationList] = useState<IDataStation[]>([])
    const history = useHistory()

    useEffect(() => {
        (async () => {
            await sortDataList();
            const datas: IDataStation[] = stateContext.curSensorList.map((e: any) => {
                const {
                    device_id, device_name, timestamp, level, raw, latitude, longitude, state,
                } = e;

                return {
                    id: device_id,
                    name: device_name,
                    date: timestamp,
                    state: state,
                    data: [
                        { name: "ค่าระดับน้ำ", value: level, unit: "mm" },
                        // { name: "4 - 20 mA", value: raw, unit: "mA" },
                    ],
                    location: `${latitude}, ${longitude}`,
                    path: "/waterlevel",
                    onTapSeeDashboard: () => {
                        history.push(`/waterlevel/device/${device_id}`)
                    }

                };
            });
            setDataStationList(datas);
        })();
    }, [stateContext.curSensorList, props.sortby]);

    return dataStationList.length === 0 ? <SimpleLoading /> : <DetailCardList dataList={dataStationList} path="/waterlevel" />;

    async function sortDataList() {
        if (SortByOptions[props.sortby] === undefined) {
            return
        }
        if (SortByOptions[props.sortby] === 'device_id' || SortByOptions[props.sortby] === 'device_name') {
            stateContext.curSensorList.sort((a: any, b: any) => (a[SortByOptions[props.sortby]] > b[SortByOptions[props.sortby]] ? 1 : -1));
        } else {
            stateContext.curSensorList.sort((a: any, b: any) => (a[SortByOptions[props.sortby]] < b[SortByOptions[props.sortby]] ? 1 : -1));
        }
    }
}
