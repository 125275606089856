import React, { useState, useEffect } from "react";
import SettingDeviceButton from "components/Button/SettingDeviceBtn";
import DeviceSettingDialog, { DeviceType, IWaterLevelSetting } from "components/Dialog/DeviceSettingDialog";
import { HEADER_TEXT, MAIN_PATH, usePageContext } from "./WaterLevelPageDevice";
import { ISetWaterLevelConfig, reqUploadConfig } from "api/useConfigSettingAPI";
import { DEVICES } from 'api/useRequest'
import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import { useParams } from "react-router-dom";
import * as WaterLvAPI from "api/useWaterLevelAPI";

export function DeviceSettingButton(props: { disabled?: boolean }) {
    /* Use to set header text */
    const dispatch = useLayoutDispatch();
    let { deviceID } = useParams<{ deviceID: string; }>();
    const { stateContext, dispatchContext } = usePageContext();
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const [settingData, setSettingData] = useState<IWaterLevelSetting>({
        device_name: "",
        sampling_rate: 0,
        latitude: 0,
        longitude: 0,
    });

    async function uploadConfig(values: Record<string, any>) {
        let body: ISetWaterLevelConfig = {
            device_id: deviceID,
            device_name: values.device_name,
            sampling_rate: values.sampling_rate,
            latitude: values.latitude,
            longitude: values.longitude,
        }
        console.log('body = ');
        console.log(body);


        const res = await reqUploadConfig(DEVICES.WATERLEVEL, body);
        // if (res.status !== 200) {
        //     return;
        // }
        (async () => {
            const res = await WaterLvAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            const items = res.data.water_level.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
            const curSensor = items.find((e: any) => e.device_id === deviceID);
            dispatchContext!({ curSensorList: items, curSensorItem: curSensor });
            /* Set page header */
            changeHeaderText(dispatch, {
                texts: [HEADER_TEXT, `${values.device_name}`],
                link: `${window.location.protocol}//${window.location.host}${MAIN_PATH}`
            });
        })();

    }

    function onDialogSave(values: Record<string, any>) {
        console.log("water level onDialogSave");

        uploadConfig(values);
        setDialogOpen(false);
        //TODO: upload saved data
    };

    function onDialogCancel() {
        setDialogOpen(false);
    };

    function onTapSettingButton() {
        //TODO: implement function ontap of DownloadButton
        console.log("Test onTap DownloadButton");
        setDialogOpen(true);
    }

    useEffect(() => {
        if (isDialogOpen === true) {
            return
        }
        setSettingData({
            device_name: stateContext.curSensorItem.device_name,
            sampling_rate: stateContext.curConfigItem.sampling_rate,
            latitude: parseFloat(stateContext.curConfigItem.latitude),
            longitude: parseFloat(stateContext.curConfigItem.longitude),
        });
    }, [stateContext.curConfigItem, stateContext.curSensorItem])

    return <>
        <SettingDeviceButton disabled={props.disabled} onTap={onTapSettingButton} />
        <DeviceSettingDialog
            isOpen={isDialogOpen}
            deviceType={DeviceType.WaterLevel}
            currentValue={settingData}
            valueDescription={{
                device_name: "Device Name",
                sampling_rate: "Sampling Rate",
                latitude: "Latitude",
                longitude: "Longitude",
            }}
            description="Threshold of ค่าระดับน้ำ (mm)"
            onCancel={onDialogCancel}
            onSave={onDialogSave} />
    </>;


}
