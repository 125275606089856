import requestInstance from "configure/requestConfig";
import SnackbarUtils from "helpers/utils";
import { DEVICES, getTypeId } from "./useRequest";

export interface deviceGroupItem {
    group_id: number;
    group_name: string;
    device_ids: string[];
}

export async function getDeviceGroups(deviceType: DEVICES) {
    try {
        const path = "/api/grouping/bytype";
        const params = { device_type: getTypeId(deviceType) }

        const result = await requestInstance.get(path, { params: params });
        console.log("getDeviceGroups result", result);
        const { data: { items } } = result;
        console.log({ items });
        return items as deviceGroupItem[];
    } catch (err) {
        console.error("getDeviceGroups err", err);
        return [] as deviceGroupItem[];
    }

}

export async function registerDeviceGroup(deviceType: DEVICES, groupName: string, deviceIds: string[]) {
    try {
        const path = "/api/grouping/create";
        const body = {
            device_type: getTypeId(deviceType),
            device_ids: deviceIds,
            group_name: groupName
        };
        let res = await requestInstance.post(path, body);
        if (res.status !== 201) {
            throw new Error("res.status !== 201");
        }
        SnackbarUtils.success("เพิ่มกลุ่มของอุปกรณ์เรียบร้อยแล้ว");
    } catch (error) {
        console.error("registerDeviceGroup error", error);
        SnackbarUtils.error("เพิ่มกลุ่มของอุปกรณ์ไม่สำเร็จ");
    }
}

export async function updateDeviceGroup(deviceType: DEVICES, groupName: string, deviceIds: string[], groupId:number) {
    try {
        const path = "/api/grouping/edit";
        const body = {
            group_id:       groupId,
            device_type:    getTypeId(deviceType),
            device_ids:     deviceIds,
            group_name:     groupName
        }
        let res = await requestInstance.patch(path, body);
        if (res.status !== 200) {
            throw new Error("res.status !== 200");
        }
        SnackbarUtils.success("แก้ไขกลุ่มของอุปกรณ์เรียบร้อยแล้ว");
    } catch (error) {
        console.error("updateDeviceGroup error", error);
        SnackbarUtils.error("แก้ไขกลุ่มของอุปกรณ์ไม่สำเร็จ");
    }
    
}

export async function deleteDeviceGroup(groupId:number) {
    try {
        const path = "/api/grouping/remove";
        const params = { group_id: groupId };
        let res = await requestInstance.delete(path, {params:params});
        if (res.status !== 200) {
            throw new Error("res.status !== 200");
        }
        SnackbarUtils.success("ลบกลุ่มของอุปกรณ์เรียบร้อยแล้ว");
    } catch (error) {
        console.error("updateDeviceGroup error", error);
        SnackbarUtils.error("ลบกลุ่มของอุปกรณ์ไม่สำเร็จ");
    }
}