import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import type { RouteComponentProps } from 'react-router-dom'
import { useParams, useHistory } from 'react-router-dom'

import { useLayoutDispatch, changeHeaderText, HeaderTextLink } from "context/LayoutContext";
import SensorCard from 'components/Card/SensorCard'
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField'

import BaseRawTable from 'components/BaseTable/BaseRawTable'
import {
  headCellsDevice
} from './PiezometerInterfaces'

import SelectDevice from 'components/SelectBox/SelectDevice'

// component map
import MapPiezometer from 'components/Map/MapPiezometer.js';

//API
import {
  useGetDeviceAPI,
  PiezometerItem,
  DEVICES,
  useGetFile,
  FILES,
  STATUS,
  mapresponseMapLayer,
  Layer
} from 'api/useRequest'

import useUserContext from 'context/UserContext';

import parseDateTime from 'helpers/parseDateTime'

import { SelectChangeEvent } from "@mui/material";

import {
  Box,
  Grid,
  Typography,
  Paper
} from "@mui/material"

import { styled as styledMui } from '@mui/material/styles';

import * as dateFns from 'date-fns';

import { CardTemplate } from 'components/Card/CardTemplate';

/* Config */
import { defaultPiezoConfigItem, IPiezoConfigItem, ISetPiezoConfig, reqGetConfig, reqUploadConfig } from 'api/useConfigSettingAPI';
import DeviceSettingDialog, { DeviceType, IPiezoSetting } from 'components/Dialog/DeviceSettingDialog';
import SettingDeviceButton from 'components/Button/SettingDeviceBtn';
import { SimpleLoading } from 'components/Loading/SimpleLoading';

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { GraphListCard } from "../../../components/Card/GraphListCard";
import { PIEZOMETER_DEVICE_GRAPH_PANEL_ID_LIST, PIEZOMETER_GRAPH_BASE_URL, PIEZOMETER_GRAPH_PANEL_ID_LIST } from "../../../constants/Graph";
import BaseMap from 'components/Map/BaseMap';
import SensorMarker from 'components/Map/Marker/SensorMarker';
import { editDeviceNotification, getDeviceNotification, NotifyGroupItem, setDeviceNotification, SetNotiBody } from 'api/useDeviceNotifyAPI';
import SnackbarUtils from 'helpers/utils';
import { FieldItem } from 'components/ThresholdSetting/ThresholdSettingView';
import ThresholdSetting from 'components/ThresholdSetting/ThresholdSetting';
import { RelProfilePiezometer } from 'components/Datetime/DatePickerRelativeProfile';
import { coordinateDecimalToMineGrid } from 'helpers/geo';
import DetailsCard from 'components/Card/DetailsCard';
import CommentDialog from './CommentDialog';
import { CustomScrollBox } from './PiezometerPage';
import { IDevAddrResItem, useDeviceAddressSelector } from 'components/DeviceAddressSelector/useDeviceAddressSelector';
import DeviceAddressSelector from 'components/DeviceAddressSelector/DeviceAddressSelector';
import DeviceAddressSetting from 'components/DeviceAddressSetting/DeviceAddressSetting';
import DefaultDateTimeRange from 'constants/DefaultDateTimeRage';
import StopMeasuring from 'components/StopMeasuring/StopMeasuring';

let TimeInterval: any

type deviceIDParams = {
  deviceID: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Options {
  name: string;
  value: string;
}

const HEADER_TEXT = "ระบบตรวจวัดแรงดันน้ำใต้ดิน"

function createHeaderTextLink(name: string): HeaderTextLink {
  return {
    texts: [HEADER_TEXT, `${name}`],
    link: `${window.location.protocol}//${window.location.host}/piezometer`
  };
}

const PiezometerPageDevice: React.FC<RouteComponentProps> = () => {
  //fetch data
  let { deviceID } = useParams<deviceIDParams>();
  let history = useHistory();
  const dispatch = useLayoutDispatch()
  const isLogedin = useUserContext().auth?.access_token != undefined ? true : false;;
  const isAdmin = useUserContext().permission?.permissions.includes('admin');

  const FatchApi = () => {
    PiezometerAPI.mutate(DEVICES.PIEZOMETER, {
      onSuccess: (data: any) => {
        let option_: Options[] = []
        setPiezometerListDeviceRaw(data?.data?.ground_water?.data?.items)
        data?.data?.ground_water?.data?.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1)).forEach((e: PiezometerItem, i: number) => {
          if (e.device_id === DeviceID) {
            // setPiezometerTitle(e.device_name)
            const headerTextLink: HeaderTextLink = createHeaderTextLink(e.device_name);
            changeHeaderText(dispatch, headerTextLink)
            setPiezometerListDevice([e])
          }
          option_.push({
            name: e.device_name,
            value: e.device_id,
          })
        })
        getConfig(DeviceID)
        setOption(option_)

      },
    })
  }



  // fetch data Peizometer
  const PiezometerAPI = useGetDeviceAPI();

  const [PiezometerListDevice, setPiezometerListDevice] = React.useState<PiezometerItem[]>([])
  const [PiezometerListDeviceRaw, setPiezometerListDeviceRaw] = React.useState<PiezometerItem[]>([])
  const [Option, setOption] = React.useState<Options[]>([])
  const [DeviceID, setDeviceID] = React.useState<string>(deviceID)
  const [LastFetchedTime, setLastFetchedTime] = React.useState<string>(parseDateTime(new Date()));
  const [PickerDateTIme, setPickerDateTIme] = React.useState<Date[]>(DefaultDateTimeRange[DEVICES.PIEZOMETER](new Date()));

  useEffect(() => {
    changeHeaderText(dispatch, HEADER_TEXT);
    clearInterval(TimeInterval);
    (() => {
      FatchApi()
      TimeInterval = setInterval(() => {
        setLastFetchedTime(parseDateTime(new Date()))
        FatchApi()
      }, 1000 * 60);
    })();
    getPiezoNotify();
    return () => {
      clearInterval(TimeInterval)
    }

  }, [DeviceID]);

  const FatchApiSelectDevice = (device_: string) => {
    var Device_id_ = device_
    setDeviceID(Device_id_)
    history.replace(`/piezometer/device/${Device_id_}`)
    PiezometerListDeviceRaw.forEach((e: PiezometerItem, i: number) => {
      if (Device_id_ === e.device_id) {
        const headerTextLink: HeaderTextLink = createHeaderTextLink(e.device_name);
        setPiezometerListDevice([e])
        changeHeaderText(dispatch, headerTextLink)
        // setPiezometerTitle(e.device_name)
      }
    })
    getConfig(device_).then((result) => {

    }).catch((err) => {

    });;

  }
  const [curConfigItem, setCurConfigItem] = React.useState<IPiezoConfigItem>(defaultPiezoConfigItem)
  async function getConfig(deviceID: string) {
    reqGetConfig(DEVICES.PIEZO).then((res) => {
      const configs: IPiezoConfigItem[] = res.data;
      const configItem: IPiezoConfigItem = configs.find(e => e.device_id == deviceID) ?? defaultPiezoConfigItem;
      setCurConfigItem(configItem)
    }).catch((err) => {

    });
  }

  /* Set threshold */
  /* Set threshold to graph */
  /* [LOW,HIGH] */
  const [limit, setLimit] = React.useState([-1, -1]);
  const [thresholdSettingFields, setThresholdSettingFields] = useState<FieldItem[]>([]);
  const notifyGroup = useRef<NotifyGroupItem>();

  const onDateTimeChange = (value: Date[]) => {
    setPickerDateTIme(value)
  }

  function getPiezoNotify() {
    getDeviceNotification(DEVICES.PIEZO)
      .then(
        (res: any) => {
          let { notifications } = res.data;
          let notiObject = notifications.find((e: any) => e.device_ids.includes(DeviceID.toUpperCase()));
          let newThFileds = [
            {
              id: 'warning_threshold',
              lable: "แจ้งเตือนระดับเฝ้าระวังเมื่อค่า Total head ( m.MSL ) มากกว่า",
              value: notiObject ? notiObject.warning_threshold : 0,
            },
            // {
            //   id: 'critical_threshold',
            //   lable: "แจ้งเตือนระดับวิกฤตเมื่อค่า PM 2.5 มากกว่า",
            //   value: notiObject ? notiObject.critical_threshold : 0,
            // },
          ];
          setThresholdSettingFields(newThFileds);
          if (notiObject !== undefined) {
            setLimit([notiObject.warning_threshold, notiObject.critical_threshold]);
          }
          notifyGroup.current = notiObject;

        }
      ).catch(
        (err: any) => {
          console.error(err);
        }
      );
  }

  const handleTresholdSubmit = (values: Record<string, string | number>) => {
    let body: SetNotiBody = {
      device_ids: [PiezometerListDevice[0].device_id.toUpperCase()],
      warning_threshold: Number(values.warning_threshold),
      critical_threshold: 0,
    }
    if (notifyGroup.current === undefined) {
      setDeviceNotification(DEVICES.PIEZO, body)
        .then(
          (res: any) => {
            setLimit([Number(values.warning_threshold), Number(values.critical_threshold)]);
            SnackbarUtils.success("Setting Devices Notify success !");
          }
        ).catch(
          (err: any) => {
            SnackbarUtils.error("Setting Devices Notify fail !");
          }
        );
    } else {
      editDeviceNotification(DEVICES.PIEZO, notifyGroup.current.id, body)
        .then(
          (res: any) => {
            setLimit([Number(values.warning_threshold), Number(values.critical_threshold)]);
            SnackbarUtils.success("Setting Devices Notify success !");
          }
        ).catch(
          (err: any) => {
            SnackbarUtils.error("Setting Devices Notify fail !");
          }
        );
    }

  };

  const setCommentConfig = (devId: string, comment: string) => {
    try {
      const config = curConfigItem;
      const dev = PiezometerListDevice[0];
      if (config === undefined) throw new Error("config not found");
      if (dev === undefined) throw new Error("device not found");

      const setPiezoConfig: ISetPiezoConfig = {
        device_id: devId,
        device_name: dev.device_name,
        sampling_rate: config.sampling_rate,
        ntc_res: config.ntc_res,
        ntc_beta: config.ntc_beta,
        piezo_g: config.piezo_g,
        piezo_k: config.piezo_k,
        piezo_zero_read: config.piezo_zero_read,
        piezo_temp: config.piezo_temp,
        tip_elevation: config.tip_elevation,
        latitude: parseFloat(config.latitude),
        longitude: parseFloat(config.longitude),
        area: config.area,
        observation: config.observation,
        elevation: config.elevation,
        remark: comment,
      }

      setDevConfig(setPiezoConfig);
    } catch (error) {
      console.error({ error });
    }

  }

  const setDevConfig = (config: ISetPiezoConfig) => {
    try {
      reqUploadConfig(DEVICES.PIEZO, config).then((result) => {
        getConfig(config.device_id);
      }).catch((err) => {
        console.error({ err });
      });
    } catch (error) {
      console.error({ error });
    }
  }

  const setLastDataByAddr = (addr_des: string) => {
    console.log("setLastDataByAddr", addr_des);


    const devAddrResItem = devAddrRes.find((e: IDevAddrResItem) => e.addr_des === addr_des);
    if (!devAddrResItem) return

    console.log("devAddrResItem", devAddrResItem);

    let { config, last_value } = devAddrResItem;

    console.log("config", config);
    console.log("last_value", last_value);

    if (config) {
      setCurConfigItem({ ...curConfigItem, ...config });
    }

    if (last_value) {
      if (!last_value.latitude && !last_value.longitude) {
        last_value.latitude = devAddrResItem.latitude;
        last_value.longitude = devAddrResItem.longitude;
      }
      setPiezometerListDevice([{ ...PiezometerListDevice[0], ...last_value }]);
    }
  }

  const cbAddrSelected = (addr_des: string, from: (Date | null), to: (Date | null)) => {
    setPickerDateTIme([from!, to ?? new Date()])
  }
  const { maxDate, minDate, devAddrRes, devAddr, isNotSelectedLastAddr, isMeasuringStop, handleDevAddrChange, fetchDeviceAddress } = useDeviceAddressSelector(DEVICES.PIEZOMETER, deviceID, PickerDateTIme, cbAddrSelected);
  useEffect(() => {
    setLastDataByAddr(devAddr);
  }, [devAddr])


  return (
    <DashboardContainer>

      <Grid
        container
        justifyContent="space-between"
        rowSpacing={2}
        columnSpacing={2}
        columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>

        {/* ====================== First row  ====================== */}
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="baseline"
          alignContent="center"
          wrap="nowrap"
          gap={1}
        >
          {/* column 1 */}
          <SelectDevice selected={DeviceID} Option={Option} onChange={(event: SelectChangeEvent) => {
            FatchApiSelectDevice(event.target?.value)
          }} />
          {/* column 2 */}
          <DeviceAddressSelector address={devAddrRes} value={devAddr} onChange={handleDevAddrChange} />
          {/* column 3 */}
          <Grid
            item
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <Grid item width={'fit-content'} >
              {!isNotSelectedLastAddr ?
                isMeasuringStop ?
                  <DeviceAddressSetting deviceId={DeviceID} fetchAddr={fetchDeviceAddress} /> :
                  <StopMeasuring deviceId={DeviceID} addrDes={devAddr} fetchAddr={fetchDeviceAddress} />
                : null
              }
            </Grid>
            {/* setting device */}
            <Grid item width={'fit-content'} >
              <DeviceSettingButton disabled={isNotSelectedLastAddr} fatchApi={FatchApi} curConfigItem={curConfigItem} curSensorItem={PiezometerListDevice[0]} />
            </Grid>
            <Grid item width={'fit-content'} >
              <ThresholdSetting
                dialogTitle={"ตั้งค่า Threshold การแจ้งเตือน: " + PiezometerListDevice[0]?.device_name}
                fields={thresholdSettingFields}
                onSubmit={handleTresholdSubmit}
                disabled={isNotSelectedLastAddr}
              />
            </Grid>

          </Grid>
        </Grid>

        {/* ====================== Second row  ====================== */}
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="strech"
          rowSpacing={2}
          columnSpacing={2}
          columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
        >

          {/* Column 1 map */}
          <Grid item xs={100} sm={100} md={50} lg xl
            minHeight="500px"
          >
            <MapBox>
              {PiezometerListDevice.length > 0 ?
                <BaseMap>
                  {
                    PiezometerListDevice.map((e: PiezometerItem, i: number) => (
                      <SensorMarker
                        key={`PiezoMeterMarker_${i}`}
                        type={DEVICES.PIEZO}
                        state={e.state}
                        position={[Number(e.latitude), Number(e.longitude)]}
                        label={e.device_name}
                        data={e}
                      />
                    ))
                  }
                </BaseMap>
                : <SimpleLoading />}
            </MapBox>
          </Grid>

          {/* Column 2 card */}

          {PiezometerListDevice.length === 0 ?
            <Grid item xs={100} sm={100} md={50} lg={35} xl={15}
              container
              direction="column"
              alignItems="stretch"
            >
              <SimpleLoading />
            </Grid> :
            <Grid item xs={100} sm={100} md={50} lg={35} xl={15}
              container
              direction="column"
              alignItems="stretch"
              wrap="nowrap"
            >
              <Grid item width="100%" marginLeft="-8px" >
                <LastUpdateTextField fullWidth datetime={LastFetchedTime} onClick={() => {
                  FatchApi()
                  setLastFetchedTime(parseDateTime(new Date()))
                }} />
                <br />
              </Grid>

              {
                PiezometerListDevice.map((e: any, i: number) => {
                  let conf = curConfigItem;
                  const { mineN, mineE } = coordinateDecimalToMineGrid(e.latitude, e.longitude);
                  return (

                    <DetailsCard key={`${i}_${e.device_id}`} id={e.device_id} name={e.device_name} state={e.state} date={e.timestamp}
                      // path="/piezometer"
                      data={[
                        { name: "Mine N", value: mineN, unit: "" },
                        { name: "Total head", value: e.msl, unit: "m.MSL" },
                        { name: "Mine E", value: mineE, unit: "" },
                        { name: "Pore Pressure", value: e.kpa || "0", unit: "kPa" },
                        { name: "Elevation", value: conf?.elevation || "", unit: "msl" },
                        { name: "Digit", value: e.digit, unit: "" },
                        { name: "Tip Elevation", value: conf?.tip_elevation || "", unit: "msl" },
                        { name: "Temperature", value: e.temperature, unit: "°C" },
                        { name: "Observation", value: conf?.observation || "-", unit: "" },
                      ]}
                      location={`${e.latitude}, ${e.longitude}`}
                      // isSelected={(selectedSensor.indexOf(e.device_id) !== -1)}
                      // onTapSelected={(id, preState, curState) => {
                      //   if (curState === true && selectedSensor.includes(id) === false) {
                      //     setSelectedSensor([...selectedSensor, id]);
                      //   } else if (curState === false) {
                      //     let newSelected = [...selectedSensor];
                      //     const index = newSelected.indexOf(id, 0);
                      //     if (index > -1) {
                      //       newSelected.splice(index, 1);
                      //       setSelectedSensor(newSelected);
                      //     }
                      //   }
                      // }}
                      utilButton={
                        <Grid item
                          container
                          direction="column"
                          alignItems={'flex-end'}
                          gap={1}
                        >
                          <CommentDialog
                            title={e.device_name}
                            comment={conf?.remark || ""}
                            onSave={(txt) => {
                              //TODO: Implement save comment to server.
                              setCommentConfig(e.device_id, txt);
                            }}
                          />
                          {/* <GetDashboardButton variant="outlined" type="submit" onClick={() => {
                                                history.push(`/piezometer/device/${e.device_id}`)
                                              }}>ดูแดชบอร์ด</GetDashboardButton> */}
                        </Grid>
                      }
                    />
                  )
                })
              }
            </Grid>
          }
        </Grid>


        {/* ====================== Third row  ====================== */}
        {/* column 1 */}
        <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
          <DeviceDataTab
            tabName={["กราฟข้อมูลทั่วไป", "กราฟข้อมูลอุปกรณ์", "ตารางข้อมูลอุปกรณ์"]}
            tabElement={[
              <GraphListCard
                urlPrefix={PIEZOMETER_GRAPH_BASE_URL + `&var-limit_high=${limit[1]}&var-limit_low=${limit[0]}`}
                panelIdList={PIEZOMETER_GRAPH_PANEL_ID_LIST}
                dateTimeRange={PickerDateTIme}
                deviceIdList={[deviceID]}
                yAxisConfigurable
              />,
              <GraphListCard
                urlPrefix={PIEZOMETER_GRAPH_BASE_URL + `&var-limit_high=${limit[1]}&var-limit_low=${limit[0]}`}
                panelIdList={PIEZOMETER_DEVICE_GRAPH_PANEL_ID_LIST}
                dateTimeRange={PickerDateTIme}
                deviceIdList={[deviceID]}
                yAxisConfigurable
              />,
              <CardTemplate>
                <BaseRawTable tableName={"รายละเอียดอุปกรณ์ตรวจวัดคุณภาพน้ำ (Piezometer)"} device={DEVICES.PIEZOMETER} DateTime={PickerDateTIme} order={"asc"} orderBy={"device_id"} tableCell={headCellsDevice} device_id={DeviceID} />
              </CardTemplate>
            ]}
            tabWithDatePicker={[0, 1, 2]}
            dateTimeRange={PickerDateTIme}
            onDateTimeChange={onDateTimeChange}
            relativeProfile={RelProfilePiezometer}
            maxDateTime={maxDate ?? undefined}
            minDateTime={minDate ?? undefined}
          />
        </Grid>


      </Grid>

    </DashboardContainer >
  )
}

export default PiezometerPageDevice

const DashboardContainer = styled.div`
  margin-left: 10px;
`

const NormalText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontSize: '120%',
  paddingBottom: theme.spacing(0.5),
}));

const MapBox = styled(Paper)`
  height: 496px;
`

const BoxTabPicker = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-btween;
`

const Mdivider = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 10px;
  margin-bottom: 10px;
`

function DeviceSettingButton({ disabled, fatchApi, curConfigItem, curSensorItem }: { disabled?: boolean, fatchApi: () => void, curConfigItem: IPiezoConfigItem, curSensorItem: PiezometerItem }) {
  /* Use to set header text */
  let { deviceID } = useParams<{ deviceID: string; }>();
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [settingData, setSettingData] = React.useState<IPiezoSetting>({
    device_name: "",
    sampling_rate: 0,
    ntc_res: 0,
    ntc_beta: 0,
    piezo_g: 0.0,
    piezo_k: 0.0,
    piezo_zero_read: 0,
    piezo_temp: 0,
    tip_elevation: 0.0,
    latitude: 0,
    longitude: 0,
    area: "",
    observation: "",
    remark: "",
    elevation: 0

  });

  async function uploadConfig(values: Record<string, any>) {
    let body: ISetPiezoConfig = {
      device_id: deviceID,
      device_name: values.device_name,
      sampling_rate: parseInt(values.sampling_rate),
      ntc_res: parseInt(values.ntc_res),
      ntc_beta: parseInt(values.ntc_beta),
      piezo_g: parseFloat(values.piezo_g),
      piezo_k: parseFloat(values.piezo_k),
      piezo_zero_read: parseInt(values.piezo_zero_read),
      piezo_temp: parseFloat(values.piezo_temp),
      tip_elevation: parseFloat(values.tip_elevation),
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
      area: values.area,
      observation: values.observation,
      remark: values.remark,
      elevation: parseFloat(values.elevation)
    }

    const res = await reqUploadConfig(DEVICES.PIEZO, body);

    // if (res.status !== 200) {
    //   return;
    // }
    fatchApi()

  }

  function onDialogSave(values: Record<string, any>) {
    uploadConfig(values);
    setDialogOpen(false);
    //TODO: upload saved data
  };

  function onDialogCancel() {
    setDialogOpen(false);
  };

  function onTapSettingButton() {
    //TODO: implement function ontap of DownloadButton
    console.log("Test onTap DownloadButton");
    setDialogOpen(true);
  }

  useEffect(() => {
    if (isDialogOpen === true) {
      return
    }
    if (curConfigItem === undefined || curSensorItem === undefined) {
      return;
    }
    setSettingData({
      device_name: curSensorItem.device_name,
      sampling_rate: curConfigItem?.sampling_rate || 0,
      ntc_res: curConfigItem?.ntc_res || 0,
      ntc_beta: curConfigItem?.ntc_beta || 0,
      piezo_g: curConfigItem?.piezo_g || 0.0,
      piezo_k: curConfigItem?.piezo_k || 0.0,
      piezo_zero_read: curConfigItem?.piezo_zero_read || 0,
      piezo_temp: curConfigItem?.piezo_temp || 0,
      tip_elevation: curConfigItem?.tip_elevation || 0.0,
      latitude: parseFloat(curConfigItem?.latitude || "0"),
      longitude: parseFloat(curConfigItem?.longitude || "0"),
      area: curConfigItem?.area || "",
      observation: curConfigItem?.observation || "",
      remark: curConfigItem?.remark || "",
      elevation: curConfigItem?.elevation || 0,
    });
  }, [curConfigItem, curSensorItem])

  return <>
    <SettingDeviceButton disabled={disabled} onTap={onTapSettingButton} />
    <DeviceSettingDialog
      isOpen={isDialogOpen}
      deviceType={DeviceType.Piezometer}
      currentValue={settingData}
      valueDescription={{
        device_name: "Device Name",
        area: "Area",
        latitude: "Latitude",
        longitude: "Longitude",
        elevation: "Elevation",
        observation: "Observation",
        tip_elevation: "Tip Elevation",
        piezo_g: "Gauge factor [G]",
        piezo_k: "Thermal factor [K]",
        piezo_zero_read: "Zero Reading",
        piezo_temp: "Installation Temperature [C]",
        sampling_rate: "Sampling Rate",
        ntc_res: "NTC Res",
        ntc_beta: "NTC Beta",
        remark: "Remark",
      }}
      description="Configuration of Piezo"
      onCancel={onDialogCancel}
      onSave={onDialogSave} />
  </>;


}
