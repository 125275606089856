import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import NormalText from 'constants/TextStyles';
import { Box, IconButton, Paper } from "@mui/material";
import DownloadIcon from 'assets/icons/icon-download-back.png';
import FullScreenIcon from 'assets/icons/icon-full-screen.png';
import { CardTemplate } from "components/Card/CardTemplate";
import MapLandslide from 'components/Map/MapLandslide.js';
import { usePageContext } from "./LandSlidePageDevice";
import * as LandSlideAPI from "api/useLandSlideAPI";
import styled from 'styled-components'
import { useParams } from "react-router-dom";
import { SimpleLoading } from "components/Loading/SimpleLoading";
import BaseMap from "components/Map/BaseMap";
import SensorMarker from "components/Map/Marker/SensorMarker";
import { DEVICES } from "api/useRequest";


export function MapCard() {
    const { stateContext, dispatchContext } = usePageContext();

    return (
        <MapBox >
            {stateContext.curSensorItem ?
                <BaseMap>
                    <SensorMarker
                        type={DEVICES.LANDSLIDE}
                        state={stateContext.curSensorItem.state}
                        position={[Number(stateContext.curSensorItem.latitude), Number(stateContext.curSensorItem.longitude)]}
                        label={stateContext.curSensorItem.device_name}
                        data={stateContext.curSensorItem}
                    />
                </BaseMap>
                : <SimpleLoading />}
        </MapBox>
    );
}

const MapBox = styled(Paper)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        height: "500px"
    },
    [theme.breakpoints.down('md')]: {
        height: "300px"
    },
}));