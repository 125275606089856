import React, { useContext, useState, useReducer } from 'react';



export interface StateContext {
    isOpen: boolean;
    fromDate: Date;
    toDate: Date;
    onChange: (value: Date[]) => void;
}
export interface Store {
    state: StateContext;
    dispatch?: React.Dispatch<any>;
}

const defaultState: StateContext = {
    isOpen: false,
    fromDate: new Date(),
    toDate: new Date(),
    onChange: (value) => { },
};

export const myContext = React.createContext<Store>({ state: defaultState });

interface IStateProvider {
    children: JSX.Element;
}
export const StateProvider = ({ children }: IStateProvider) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    return <myContext.Provider value={{ state, dispatch }} children={children} />;
};

export const reducer = (state: StateContext, action: any) => {
    return { ...state, ...action }
};