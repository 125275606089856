
export const LANDSLIDE_GRAPH_PANEL_ID_LIST: number[] = [2, 3, 4, 6, 35, 24, 18, 17,];
export const LANDSLIDE_DEVICE_GRAPH_PANEL_ID_LIST: number[] = [7, 10, 9];
export const LANDSLIDE_RELATIVE_GRAPH_PANEL_ID_LIST: number[] = [54, 55, 56, 34, 57];
export const LANDSLIDE_ABSOLUTE_GRAPH_PANEL_ID_LIST: number[] = [/* 70, 71, */ 72, 73, 74];
export const LANDSLIDE_GRAPH_BASE_URL: string = process.env.REACT_APP_GRAFANABASE_BASE_URL +
    '/d-solo/OT793Rhnk/landslide' +
    '?orgId=1' +
    '\&var-pdop_threshold=2.5' +
    '\&var-v_accuracy_threshold=4' +
    '\&var-h_accuracy_threshold=4' +
    '\&var-RTK=1';


export const WATER_LEVEL_GRAPH_PANEL_ID_LIST: number[] = [10, 12, 9];
export const WATER_LEVEL_GRAPH_BASE_URL: string = process.env.REACT_APP_GRAFANABASE_BASE_URL +
    '/d-solo/wtlv/water-level' +
    '?orgId=1';


export const GPS_TRACKING_GRAPH_PANEL_ID_LIST: number[] = [2, 3];
export const GPS_TRACKING_GRAPH_BASE_URL: string = process.env.REACT_APP_GRAFANABASE_BASE_URL +
    '/d-solo/419p2Ionk/gps-tracker' +
    '?orgId=1';


export const PIEZOMETER_GRAPH_PANEL_ID_LIST: number[] = [18, 20, 11, 16, 22 ];
export const PIEZOMETER_DEVICE_GRAPH_PANEL_ID_LIST: number[] = [14, 10, 15, 12, 13];
export const PIEZOMETER_GRAPH_BASE_URL: string = process.env.REACT_APP_GRAFANABASE_BASE_URL +
    '/d-solo/piezometer/piezometer' +
    '?orgId=1';


export const ARSENIC_GRAPH_PANEL_ID_LIST: number[] = [10, 12, 9];
export const ARSENIC_GRAPH_BASE_URL: string = process.env.REACT_APP_GRAFANABASE_BASE_URL +
    '/d-solo/arsn/arsenic' +
    '?orgId=1';


export const WEATHER_STATION_GRAPH_PANEL_ID_LIST: any[] = [5, 4, [{ expectId: "e6a7010705000001", panelId: 14 }, { expectId: "e6a7010705000002", panelId: 15 }], 2, 3, 7, 6, 12, 9];
export const WEATHER_STATION_GRAPH_BASE_URL: string = process.env.REACT_APP_GRAFANABASE_BASE_URL +
    '/d-solo/wths/weather-station' +
    '?orgId=1';

