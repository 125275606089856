import { RouteComponentProps } from 'react-router'
import DashboardPage from './dashboard/DashboardPage'
import SettingPage from './setting_new/SettingPage'
import UserManagementPage from './usermanagement/UserManagementPage'
import NotFoundPage from './error/NotFoundPage'
import DefaultLayout from '../components/Layout/DefaultLayout'
import RoutePath from 'constants/RoutePath'
import RoutePermission from 'constants/RoutePermission'
import WeatherPage from './sensors/Weather/WeatherPage'
import WeatherPageDevice from './sensors/Weather/WeatherPageDevice'
import ArsenicPage from './sensors/Arsenic/ArsenicPage'
import ArsenicPageDevice from './sensors/Arsenic/ArsenicPageDevice'

import PiezometerPage from './sensors/Piezometer/PiezometerPage'
import PiezometerPageDevice from './sensors/Piezometer/PiezometerPageDevice'

import WirelessPage from './sensors/wireless/WirelessPage'
import ConveyorPage from './sensors/conveyor/ConveyorPage'
import ConveyorPageDevice from './sensors/conveyordevice/ConveyorPageDevice'
import LandSlidePage from './sensors/landslide/LandSlidePage'
import LandSlidePageDevice from './sensors/landslidedevice/LandSlidePageDevice'
import WaterLevelPage from './sensors/waterlevel/WaterLevelPage'
import WaterLevelPageDevice from './sensors/waterleveldevice/WaterLevelPageDevice'
import GpsTrackingPage from './sensors/gpstracking/GpsTrackingPage'
import GpsTrackingPageDevice from './sensors/gpstrackingdevice/GpsTrackingPageDevice'

import MapLayerSettingPage from './setting_new/MapLayerSetting'
import TruckPage from './sensors/truck/TruckPage'

import LineNoti from 'pages/setting_new/LineNoti'

interface RouteConfig {
  path: string
  component: React.ComponentType<RouteComponentProps>
  meta: {
    layout: React.ComponentType<any> | undefined
    requiredAuth: string[] | undefined
  }
}

const routes: RouteConfig[] = [
  {
    path: RoutePath.dashboard,
    component: DashboardPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.none,
    },
  },
  {
    path: RoutePath.setting,
    component: SettingPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.setting,
    },
  },
  {
    path: RoutePath.usermanagement,
    component: UserManagementPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.usermanagement,
    }
  },
  {
    path: RoutePath.weather,
    component: WeatherPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.weather,
    },
  },
  {
    path: RoutePath.weatherDevice,
    component: WeatherPageDevice,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.weather,
    },
  },
  {
    path: RoutePath.arsenic,
    component: ArsenicPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.arsenic,
    },
  },
  {
    path: RoutePath.arsenicDevice,
    component: ArsenicPageDevice,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.arsenic,
    },
  },
  {
    path: RoutePath.piezometer,
    component: PiezometerPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.piezometer,
    },
  },
  {
    path: RoutePath.piezometerDevice,
    component: PiezometerPageDevice,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.piezometer,
    },
  },
  {
    path: RoutePath.wireless,
    component: WirelessPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.wireless,
    },
  },
  {
    path: RoutePath.conveyor,
    component: ConveyorPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.conveyor,
    },
  },
  {
    path: RoutePath.conveyorDevice,
    component: ConveyorPageDevice,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.conveyor,
    },
  },
  {
    path: RoutePath.landslide,
    component: LandSlidePage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.landslide,
    },
  },
  {
    path: RoutePath.landslideDevice,
    component: LandSlidePageDevice,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.landslide,
    },
  },
  {
    path: RoutePath.waterlevel,
    component: WaterLevelPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.waterlevel,
    },
  },
  {
    path: RoutePath.waterlevelDevice,
    component: WaterLevelPageDevice,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.waterlevel,
    },
  },
  {
    path: RoutePath.maplayersetting,
    component: MapLayerSettingPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.maplayersetting,
    },
  },
  {
    path: RoutePath.gpstracking,
    component: GpsTrackingPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.gpstracking,
    },
  },
  {
    path: RoutePath.gpstrackingDevice,
    component: GpsTrackingPageDevice,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.gpstracking,
    },
  },
  {
    path: RoutePath.truck,
    component: TruckPage,
    meta: {
      layout: DefaultLayout,
      requiredAuth: RoutePermission.truck,
    },
  },
  {
    path: RoutePath.noti_line,
    component: LineNoti,
    meta: {
      layout: undefined,
      requiredAuth: RoutePermission.noti_line,
    },
  },
]

/**
 * NOTES: This should add at last index to act as fallback route when don't matches any path
 */
routes.push({
  path: '*',
  component: NotFoundPage,
  meta: {
    layout: undefined,
    requiredAuth: RoutePermission.none,
  },
})

export default routes
