import * as React from 'react';
import BaseDialog from '../Dialog/BaseDialog'
import { PermissionDetail } from './Permissions';

interface IEditDialogProps {
  open: boolean;
  setOpen: () => void;
  employeeNumber: number;
  employeeID: number;
  role: string;
  permissions: PermissionDetail[]|undefined
}

const Options = [
  'ยกเลิก',
  'บันทึก'
]

const EditDialog = (props: IEditDialogProps) => {
  //console.log("Edit Dia props"+props)
  const {open, setOpen, employeeNumber, role, permissions, employeeID} = props;
  return (
    <BaseDialog 
    init={open} 
    setOpen={setOpen} 
    scrollType={true} 
    buttonMenus={Options} 
    HeaderText={"แก้ไขผู้ใช้"} 
    employeeNumber={employeeNumber}
    employeeID={employeeID}
    role={role}
    permissions={permissions}/>
  );
}

export default EditDialog;