import React from 'react';
import { Button } from "@mui/material";
import styled from "styled-components";
import color from "../../constants/Colors";
import LoginFrom from '../LoginFrom/LoginFrom';

const Login: React.FC = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <LoginButton variant="outlined" onClick={handleClickOpen}>Log In</LoginButton>
            <LoginFrom open={open} handleClose={handleClose} onLoggedInSuccess={(props) =>{}} />
        </>
    );
}
export default Login;

const LoginButton = styled(Button)`
    border: 1px solid ${color.iconBlue};
    color: ${color.iconBlue};
    box-sizing: border-box;
    border-radius: 8px;
    width: 86px;
`