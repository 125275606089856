import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import React, { useEffect, useContext, useState } from "react";
import { RouteComponentProps, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { MapCard } from "./LanSlideDeviceMapCard";
import * as dateFns from 'date-fns';

/* API */
import * as LandSlideAPI from "api/useLandSlideAPI";
import { ISensorItem } from "api/useLandSlideAPI";
import { DEVICES } from "api/useRequest";
import { defaultLandSlideConfigItem, ILandSlideConfigItem, reqGetConfig } from "api/useConfigSettingAPI";

import { UpdateCurrentDataButton } from "./UpdateCurrentDataButton";
import styled from 'styled-components'
import { SelectDeviceName } from "./SelectDeviceName";
import { DeviceSettingButton } from "./DeviceSettingButton";
import { CurDetailCard } from "./CurDetailCard";
import { DownloadCsvButton } from "./DownloadCsvButton";
import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { DeviceTable } from "./DeviceTable";
import { GraphListCard } from "../../../components/Card/GraphListCard";
import { LANDSLIDE_GRAPH_BASE_URL, LANDSLIDE_GRAPH_PANEL_ID_LIST } from "../../../constants/Graph";
import { RelProfileLandslide } from 'components/Datetime/DatePickerRelativeProfile';
import DeviceAddressSetting from "components/DeviceAddressSetting/DeviceAddressSetting";
import DeviceAddressSelector from "components/DeviceAddressSelector/DeviceAddressSelector";
import { getDeviceAddress } from "api/useDeviceAddressAPI";
import { IDevAddrResItem, useDeviceAddressSelector } from "components/DeviceAddressSelector/useDeviceAddressSelector";
import { DatePicker } from '@mui/lab';
import DefaultDateTimeRange from "constants/DefaultDateTimeRage";
import StopMeasuring from "components/StopMeasuring/StopMeasuring";


interface IPageContext {
    curSensorList: ISensorItem[];
    curSensorItem: ISensorItem;
    curConfigList: ILandSlideConfigItem[];
    curConfigItem: ILandSlideConfigItem;
    allSensorList: ISensorItem[];
    dateRange: Date[];
}

const defaultContext: IPageContext = {
    curSensorList: [],
    curSensorItem: LandSlideAPI.defaultSensorItem,
    curConfigList: [],
    curConfigItem: defaultLandSlideConfigItem,
    allSensorList: [],
    dateRange: DefaultDateTimeRange[DEVICES.LANDSLIDE](new Date()),
}


const pageContext = React.createContext<{ stateContext: IPageContext, dispatchContext?: React.Dispatch<any>; }>({ stateContext: defaultContext });

export const usePageContext = () => useContext(pageContext);


export const HEADER_TEXT = "ระบบตรวจวัดการเคลื่อนตัวชั้นหน้าดิน"
export const MAIN_PATH = "/landslide"
const LandSlidePageDevice: React.FC<RouteComponentProps> = () => {


    /* Create global state in page */
    const [stateContext, dispatchContext] = React.useReducer((state: IPageContext, action: any) => {
        return { ...state, ...action }
    }, defaultContext);


    return (
        <pageContext.Provider value={{ stateContext, dispatchContext }}>
            <LandSlidePageDeviceContent />
        </pageContext.Provider>
    );



}

export default LandSlidePageDevice

const DashboardContainer = styled.div`
  margin-left: 10px;
`

function LandSlidePageDeviceContent() {
    /* Use to set header text */
    const dispatch = useLayoutDispatch();
    /* Get params */
    let { deviceID } = useParams<{ deviceID: string; }>();

    const { stateContext, dispatchContext } = usePageContext()

    async function getConfig() {
        const res = await reqGetConfig(DEVICES.LANDSLIDE);
        console.log(res);
        if (res.status !== 200) {
            return
        }
        const configs: ILandSlideConfigItem[] = res.data;
        const configItem: ILandSlideConfigItem = configs.find(e => e.device_id == deviceID) ?? defaultLandSlideConfigItem;

        dispatchContext!({ curConfigList: configs })
        dispatchContext!({ curConfigItem: configItem })
    }

    useEffect(() => {
        onDateTimeChange(DefaultDateTimeRange[DEVICES.LANDSLIDE](new Date()));

        (async () => {
            const res = await LandSlideAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            let items = res.data.landslide.data.items.sort((a: any, b: any) => (a.diff2d < b.diff2d ? 1 : -1));
            items.forEach((element: LandSlideAPI.ISensorItem) => {
                const { timestamp } = element;
                let hrBetweenTwoDate = dateFns.differenceInHours(new Date(), new Date(timestamp));
                element.state = hrBetweenTwoDate > 1 ? "Offline" : "Online";
            });
            const curSensor = await items.find((e: any) => e.device_id === deviceID);

            /* Set Header */
            changeHeaderText(dispatch, {
                texts: [HEADER_TEXT, `${curSensor.device_name}`],
                link: `${window.location.protocol}//${window.location.host}${MAIN_PATH}`
            });

            dispatchContext!({ curSensorList: items, curSensorItem: curSensor });
        })();

        (async () => {
            console.log(deviceID);

            const res = await LandSlideAPI.reqDeviceData(stateContext.dateRange, deviceID);
            if (res.status != 200) {
                return [];
            }
            const { items } = res.data;
            console.log(items);

            dispatchContext!({ allSensorList: items });
        })();

        getConfig();
        /* Set page header */
    }, [deviceID])

    const onDateTimeChange = (value: Date[]) => {
        dispatchContext!({ dateRange: value });
        (async () => {
            const res = await LandSlideAPI.reqDeviceData(value, deviceID);
            if (res.status != 200) {
                return [];
            }
            const { items } = res.data;
            console.log(items);

            dispatchContext!({ allSensorList: items });
        })();
    };

    const setLastDataByAddr = (addr_des: string) => {

        const devAddrResItem = devAddrRes.find((e: IDevAddrResItem) => e.addr_des === addr_des);
        if (!devAddrResItem) return

        let { config, last_value } = devAddrResItem;

        if (config) {
            dispatchContext!({ curConfigItem: { ...stateContext.curConfigItem, ...config } });
        }

        if (last_value) {
            if (!last_value.latitude && !last_value.longitude) {
                last_value.latitude = devAddrResItem.latitude;
                last_value.longitude = devAddrResItem.longitude;
            }

            dispatchContext!({ curSensorItem: { ...stateContext.curSensorItem, ...last_value } });
        }
    }

    const cbAddrSelected = (addr_des: string, from: (Date | null), to: (Date | null)) => {
        dispatchContext!({ dateRange: [from!, to ?? new Date()] });
    }
    const { maxDate, minDate, devAddrRes, devAddr, isNotSelectedLastAddr, isMeasuringStop, handleDevAddrChange, fetchDeviceAddress } = useDeviceAddressSelector(DEVICES.LANDSLIDE, deviceID, stateContext.dateRange, cbAddrSelected);

    useEffect(() => {
        setLastDataByAddr(devAddr);
    }, [devAddr])
    


    return <DashboardContainer>
        <Grid
            width="100%"
            height="100%"
            container
            direction="column"
            rowSpacing={2}
            columnSpacing={2}
            columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
        >
            {/* <HeaderRow /> */}
            <Grid
                item
                container
                direction="row"
                columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
            >

                <Grid item xs={100} sm={100} md={100} lg={100} xl={100}>
                    <Grid
                        container
                        wrap="nowrap"
                        gap={1}
                    >
                        {/* select device */}
                        <SelectDeviceName />
                        <DeviceAddressSelector address={devAddrRes} value={devAddr} onChange={handleDevAddrChange} />
                        {/* upload data */}
                        <Grid
                            container
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                            gap={1}
                            wrap="nowrap"
                        >
                            {!isNotSelectedLastAddr ?
                                isMeasuringStop ?
                                <DeviceAddressSetting deviceId={deviceID} fetchAddr={fetchDeviceAddress} /> :
                                <StopMeasuring deviceId={deviceID} addrDes={devAddr} fetchAddr={fetchDeviceAddress} />
                                : null
                            }

                            <div>
                                <DeviceSettingButton disabled={isNotSelectedLastAddr} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <MapRow /> */}
            <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="strech"
                rowSpacing={2}
                columnSpacing={2}
                columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
            >

                {/* Column 1 map */}
                <Grid item xs={100} sm={100} md={50} lg xl
                    minHeight="500px"
                >
                    <MapCard />
                </Grid>

                {/* Column 2 card */}
                <Grid item xs={100} sm={100} md={50} lg={35} xl={15}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    gap={2}
                >
                    <UpdateCurrentDataButton />
                    <Grid item xs paddingLeft="16px">
                        <CurDetailCard />
                    </Grid>
                </Grid>

            </Grid>

            <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
                <DeviceDataTab
                    tabName={["กราฟ", "ข้อมูลดิบ"]}
                    tabElement={[
                        <GraphListCard
                            urlPrefix={LANDSLIDE_GRAPH_BASE_URL}
                            panelIdList={LANDSLIDE_GRAPH_PANEL_ID_LIST}
                            deviceIdList={[deviceID]}
                            dateTimeRange={stateContext.dateRange}
                            yAxisConfigurable
                        />,
                        <DeviceTable />
                    ]}
                    tabWithDatePicker={[0, 1]}
                    dateTimeRange={stateContext.dateRange}
                    onDateTimeChange={onDateTimeChange}
                    relativeProfile={RelProfileLandslide}
                    maxDateTime={maxDate ?? undefined}
                    minDateTime={minDate ?? undefined}
                />
            </Grid>
        </Grid>
    </DashboardContainer>;
}


