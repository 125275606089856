import React from 'react'
import { styled } from '@mui/material/styles';

import { 
  Button
} from '@mui/material';

import DeleteMapLayerDialog from '../../components/Dialog/DeleteMapLayer'

interface Props {
  onClick: any;
  // filename: string;
  // layername: string;
}

export default function DeleteLayer(props: Props) {

  return (
    <>
      <DeleteLayerButton variant="outlined" type="submit" size="large" onClick={props.onClick}>
        Delete
      </DeleteLayerButton>
    </>
  )
}

const DeleteLayerButton = styled(Button)`
width: 100%;
minWidth: 49px;
height: 24px;
color: #E24526;
border-color: #E24526;
&:hover {
    color: white;
    background-color: #E24526;
    border-color: #E24526;
    // opacity:0.8;
}
text-transform: none;
`