import React, { useState, useEffect } from "react";
import { RouteComponentProps } from 'react-router-dom'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { styled } from "@mui/material/styles";
import fontFamily from '../../constants/Fonts'
import Typography from '@mui/material/Typography'

import * as yup from 'yup';
import { useFormik } from 'formik';
import TextField from "@mui/material/TextField";

export enum DeviceType {
    LandSlide,
    GpsTracking,
    Piezometer,
    WaterLevel,
    Arsenic,
    Weather,
    Conveyor,
    Truck,
}
export interface IArsenicSetting {
    device_name: string;
    sampling_rate: number;
    threshold: number;
    consecutive_time: number;
    latitude: number;
    longitude: number;
}

const ArsenicCheckIsError = yup.object({
    device_name: yup.string().required('Enter device name'),
    sampling_rate: yup.number().required(),
    threshold: yup.number().required(),
    consecutive_time: yup.number().required(),
    latitude: yup.number().required(),
    longitude: yup.number().required(),
})
export interface ILandSlideSetting {
    device_name: string;
    sampling_rate: number;
}
const LandSlideCheckIsError = yup.object({
    device_name: yup.string().required('Enter device name'),
    sampling_rate: yup.number().required(),

})

export interface IPiezoSetting {
    device_name: string;
    sampling_rate: number;
    ntc_res: number;
    ntc_beta: number;
    piezo_g: number;
    piezo_k: number;
    piezo_zero_read: number;
    piezo_temp: number;
    tip_elevation: number;
    latitude: number;
    longitude: number;
    area: string;
    observation: string;
    remark: string;
    elevation: number;
}

const PiezometerCheckIsError = yup.object({
    device_name: yup.string().required('Enter device name'),
    sampling_rate: yup.number().required('Enter number'),
    ntc_res: yup.number().required('Enter number'),
    ntc_beta: yup.number().required('Enter number'),
    piezo_g: yup.number().required('Enter number'),
    piezo_k: yup.number().required('Enter number'),
    piezo_zero_read: yup.number().required('Enter number'),
    piezo_temp: yup.number().required('Enter number'),
    tip_elevation: yup.number().required('Enter number'),
    latitude: yup.number().required(),
    longitude: yup.number().required(),
    area: yup.string(),
    observation: yup.string(),
    remark: yup.string(),
    elevation: yup.number(),
})

export interface IWaterLevelSetting {
    device_name: string;
    sampling_rate: number;
    latitude: number;
    longitude: number;
}

const WaterLevelCheckIsError = yup.object({
    device_name: yup.string().required('Enter device name'),
    sampling_rate: yup.number().required(),
    latitude: yup.number().required(),
    longitude: yup.number().required(),
})

export interface IConveyorSetting {
    device_name: string;
    sampling_rate: number;
    threshold: number;
    // latitude: number;
    // longitude: number;
    line: string;
    name: string;
    reverse: boolean;

}

const ConveyorCheckIsError = yup.object({
    device_name: yup.string().required('Enter device name'),
    sampling_rate: yup.number().min(0).required(),
    threshold: yup.number().min(0).required(),
    // latitude: yup.string().required(),
    // longitude: yup.string().required(),
    line: yup.string().required(),
    name: yup.string().required(),
    reverse: yup.boolean().required(),

})

export interface IWeatherSetting {
    device_name: string;
    sampling_rate: number;
    latitude: number;
    longitude: number;
}

const WeatherCheckIsError = yup.object({
    device_name: yup.string().required('Enter device name'),
    sampling_rate: yup.number().min(0).required(),
    latitude: yup.string().required(),
    longitude: yup.string().required(),
})
export interface ITruckSetting {
    device_name: string;
    latitude: number;
    longitude: number;
}
const TruckCheckIsError = yup.object({
    device_name: yup.string().required('Enter device name'),
    latitude: yup.string().required(),
    longitude: yup.string().required(),
})
export interface IGpsTrackingSetting {
    device_name: string;
    height: number;
}
const GpsTrackingCheckIsError = yup.object({
    device_name: yup.string().required('Enter device name'),
    height: yup.number().min(0).required(),
})

interface IDeviceSettingDialog {
    isOpen: boolean;
    deviceType: DeviceType;
    currentValue: Record<string, any>;
    valueDescription?: Record<string, any>;
    currentSelector?: Record<string, Array<string>>;
    selectorDescription?: Record<string, any>;
    description: string;
    onCancel: () => void;
    onSave: (values: any) => void;

}

const DeviceSettingDialog: React.FC<IDeviceSettingDialog> = (props/* : IDeviceSettingDialog & any */) => {
    const { isOpen, deviceType, description, onCancel, onSave, currentValue, valueDescription = {}, currentSelector = {}, selectorDescription = {} } = props;

    const [formValue, setFormValue] = useState(currentValue);
    const [selector, setSelector] = useState<Record<string, string[]>>(currentSelector);

    useEffect(() => {
        setSelector(currentSelector)
        Object.entries(currentValue).map(e => {
            let key = e[0];
            formik.values[`${key}`] = e[1]//Boolean(e[1]) ? e[1] : setDefaultByType(e[1]);
        });
    }, [currentValue])

    const [selectValue, setSelectValue] = React.useState('');

    const handleChange = (event: any) => {
        setSelectValue(event.target.value);
    };

    const formik = useFormik({
        initialValues: formValue,
        validationSchema: getCheckErrorObject(deviceType),
        onSubmit: (values) => {
            console.log("dialog submit");

            onSave(values);
        },
    });

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={isOpen}
            onClose={onCancel}
        >
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>ตั้งค่าอุปกรณ์</DialogTitle>
                <DialogContent>

                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: '100%',
                            gap: "15px"
                        }}
                    >
                        <Typography variant="body2" color="initial">
                            {""}
                        </Typography>
                        <Box
                            width="100%"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                gap: "15px",
                            }}
                        >

                            {


                                Object.entries(valueDescription).map((ee: [string, any], index: number) => {
                                    let key = ee[0];
                                    let e = currentValue[key];
                                    if (key === "line" && selector !== {}) {
                                        return (
                                            <FormControl key={`${index}_${key}_${"FormControl"}`} fullWidth>
                                                <InputLabel key={`${index}_${key}_${"InputLabel"}`} id="key-selector">Line</InputLabel>
                                                <Select
                                                    labelId="key-selector"
                                                    key={`${index}_${key}_${"Select"}`}
                                                    id={key}
                                                    name={key}
                                                    value={formik.values[`${key}`]}
                                                    label="Line"
                                                    error={Boolean(formik.errors[`${key}`])}
                                                    onChange={(event) => {
                                                        formik.values['name'] = ""
                                                        formik.handleChange(event)
                                                    }}
                                                >
                                                    {Object.keys(selector).map((e: any, i: number) => {
                                                        return <MenuItem key={`${index}_${key}_${"MenuItem"}_${i}`} value={e}>{e}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        )
                                    } else if (key === "name" && selector !== {}) {
                                        return (
                                            <FormControl key={`${index}_${key}_${"FormControl"}`} fullWidth>
                                                <InputLabel key={`${index}_${key}_${"InputLabel"}`} id="value-selector">Line - Name</InputLabel>
                                                <Select
                                                    labelId="value-selector"
                                                    key={`${index}_${key}_${"Select"}`}
                                                    id={key}
                                                    name={key}
                                                    value={formik.values[`${key}`]}
                                                    label="Line - Name"
                                                    error={Boolean(formik.errors[`${key}`])}
                                                    onChange={formik.handleChange}
                                                >
                                                    {selector[formik.values['line']] !== undefined ? selector[formik.values['line']].map((e: any, i: number) => {
                                                        return <MenuItem key={`${index}_${key}_${"MenuItem"}_${i}`} value={e}>{e}</MenuItem>
                                                    }) : null}
                                                </Select>
                                            </FormControl>
                                        )
                                    }
                                    return (
                                        <TextField
                                            key={`${index}_${key}_${"TextField"}`}
                                            fullWidth
                                            id={key}
                                            name={key}
                                            label={(valueDescription[`${key}`] !== undefined) ? valueDescription[`${key}`] : key}
                                            variant="outlined"
                                            value={(typeof formik.values[`${key}`] !== 'boolean' && Boolean(formik.values[`${key}`]) === false) ? '' : formik.values[`${key}`] /* setDefaultByType(formik.values[`${key}`]) */}
                                            error={Boolean(formik.errors[`${key}`])}
                                            helperText={formik.errors[`${key}`]}
                                            onChange={formik.handleChange} />
                                    );
                                })
                            }

                        </Box>
                        <Box></Box>


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={onCancel}>ยกเลิก</Button>
                    <CustomButton type="submit" variant="text" >บันทึก</CustomButton>
                </DialogActions>
            </form>
        </Dialog>

    );
}

export default DeviceSettingDialog
export const objs = {
    "LINE  X": [
        "Y7B",
        "Y7A",
        "X6A",
        "X4",
        "X6B",
        "X1B",
        "X3",
        "X2",
        "Y11",
        "Y9",
        "Z17",
        "Y12",
        "Y10",
        "CR.B",
        "X5",
        "Y8",
        "Z15",
        "CR.A",
        "Z14",
        "CR.S",
        "CR.C",
        "Z12",
        "Z13",
        "BWE.1",
        "BWE.3",
        "BWE.2",
        "Z16",
        "CR.R",
        "CR.Q",
        "X1A",
        "SPD.X"
    ],
    "LINE COAL": [
        "F3",
        "F5",
        "LINE COAL",
        "LINE COAL",
        "LINE COAL",
        "LINE COAL",
        "L 3.1",
        "L 2.1",
        "L 4.1",
        "L 1.2",
        "L2.2",
        "L1.3A",
        "L1.3",
        "L3.3",
        "L3.4",
        "L1.4",
        "L1.5A",
        "L1.4A",
        "L3.2",
        "L 1.1",
        "L3.5",
        "L1.5",
        "L1.7",
        "L1.6",
        "F2",
        "F1",
        "F4",
        "D1",
        "LINE COAL",
        "LINE COAL",
        "LINE COAL",
        "LINE COAL",
        "LINE COAL",
        "S3",
        "R1",
        "R3",
        "S2",
        "S5.1",
        "S3.1",
        "S4",
        "R4",
        "R5",
        "R6",
        "R4.1",
        "R4.2",
        "R4.3",
        "R4.4",
        "S5",
        "S1",
        "D2",
        "R2",
        "L4.6",
        "L1.7",
        "L3.6",
        "L1.5",
        "L 2.4",
        "L2.3",
        "L4.2",
        "L 5.2",
        "L5.1",
        "CR.1",
        "CR.2",
        "L3.7",
        "LINE COAL",
        "FB",
        "CR.4",
        "CR.3"
    ],
    "LINE  WB": [
        "B1",
        "WB6",
        "WB7",
        "B8",
        "B9",
        "B10",
        "B11",
        "B12",
        "B13",
        "B14",
        "CR.B",
        "B15",
        "CR.M",
        "CR.I",
        "CR.I",
        "CR.M",
        "CR.C",
        "CR.A",
        "WB2",
        "WB3",
        "B1.1",
        "WB4",
        "WB5"
    ],
    "LINE  WA": [
        "WA6",
        "A1",
        "WA7",
        "A8",
        "A9",
        "CR.K",
        "CR.N",
        "A10",
        "B11",
        "A12",
        "BW.2",
        "BW.1",
        "CR.J",
        "CR.D",
        "A11",
        "WA2",
        "WA3",
        "WA4",
        "WA5"
    ]
}
function Selector({ id, selector, initValue, onSelectorChange }: { id: string, selector: Record<string, string[]>, initValue: { line: string, name: string }, onSelectorChange: (line: string, name: string) => void }) {
    const lineList = Object.keys(selector);
    const [key, setKey] = useState(initValue.line)
    const [lineName, setLineName] = useState(initValue.name)
    return <Box
        width="100%"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: "15px",
        }}
    >
        <FormControl fullWidth>
            <InputLabel id="key-selector">Line</InputLabel>
            <Select
                labelId="key-selector"
                id="key-select"
                value={key}
                label="Line"
                onChange={(event) => {
                    setKey(event.target.value)
                    setLineName("")
                    onSelectorChange(event.target.value, "")

                }}
            >
                {lineList.map(e => {
                    return <MenuItem value={e}>{e}</MenuItem>
                })}
            </Select>
        </FormControl>
        <FormControl fullWidth>
            <InputLabel id="value-selector">Line - Name</InputLabel>
            <Select
                labelId="value-selector"
                id="value-select"
                value={lineName}
                label="Line - Name"
                onChange={(event) => {
                    setLineName(event.target.value)
                    onSelectorChange(key, event.target.value)
                }}
            >
                {selector[key] !== undefined ? selector[key].map(e => {
                    return <MenuItem value={e}>{e}</MenuItem>
                }) : null}
            </Select>
        </FormControl>
    </Box>
}

function getCheckErrorObject(deviceType: DeviceType) {
    let checkObj = undefined;
    switch (deviceType) {
        case DeviceType.LandSlide:
            checkObj = LandSlideCheckIsError;
            break;
        case DeviceType.GpsTracking:
            checkObj = GpsTrackingCheckIsError;
            break;
        case DeviceType.Piezometer:
            checkObj = PiezometerCheckIsError;
            break;
        case DeviceType.WaterLevel:
            checkObj = WaterLevelCheckIsError;
            break;
        case DeviceType.Arsenic:
            checkObj = ArsenicCheckIsError;
            break;
        case DeviceType.Weather:
            checkObj = WeatherCheckIsError;
            break;
        case DeviceType.Conveyor:
            checkObj = ConveyorCheckIsError;
            break;
        case DeviceType.Truck:
            checkObj = TruckCheckIsError;
            break;
        default:
            break;

    }

    return checkObj
}
const CustomButton = styled(Button)(({ theme }) => ({
    width: "80px",
    borderRadius: "8px",
    fontFamily: fontFamily,
    fontSize: "16px",

    lineHeight: 1,

    color: theme.palette.getContrastText('#0739A2'),
    backgroundColor: '#0739A2',
    "&:hover": {
        backgroundColor: "#0739A2",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
            backgroundColor: '#0739A2'
        }
    },

}));

function setDefaultByType(param: any) {
    if (typeof param === 'number') {
        return 0
    } else if (typeof param === 'string') {
        return ""
    } else if (typeof param === 'boolean') {
        return false
    } else {

    }
}