import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useMap, GeoJSON, Marker, Polyline } from "react-leaflet";
import "react-leaflet-shapefile-v2";
import * as Leaflet from 'leaflet';
import shp from "shpjs";
import useMapContext from "context/MapContext";
import AntPathOverlay from "./AntPath";
interface ShapeOverlayProps {
    fileType: any;
    lineWidth?: number;
}
const ShapeOverlay: FunctionComponent<ShapeOverlayProps> = (props) => {
    const { fileType, lineWidth } = props;
    const [geoJsonData, setGeoJsonData] = useState<any>(null);
    const mapContext = useMapContext()
    useEffect(() => {
        (async () => {
            if (fileType === undefined) {
                return;
            }

            const fileName = fileType.file_name;
            let filePath: string = `${process.env.REACT_APP_API_BASE_URL}/api/files/${fileName}`;

            let newGeoData: any = mapContext.getOverlayData(fileName)
            if (newGeoData === undefined) {
                let data = await shp(filePath);
                mapContext.addOverlayData(fileName, data)
                newGeoData = data;
            }
            setGeoJsonData(newGeoData);
        })()
    }, [])

    return useMemo(() => (
        geoJsonData !== null ?
            <GeoJSON data={geoJsonData}
                pointToLayer={function (geoJsonPoint, latlng) {
                    return (Leaflet as any).circleMarker(latlng, { radius: 3 });
                }}
                style={{
                    color: props.fileType.color,
                }}
                onEachFeature={function popUp(f: any, l: any) {
                    var out = [];
                    if (f.properties) {
                        for (var key in f.properties) {
                            out.push(key + ": " + f.properties[key]);
                        }
                        // l.bindPopup(out.join("<br />"));
                        l.bindTooltip(out.join("<br />"));
                    }
                }} /> : null
    ), [geoJsonData]);
}

export default ShapeOverlay;