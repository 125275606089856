import React from "react";
import { Box, CircularProgress } from "@mui/material";

export function SimpleLoading() {
    return <Box
        width={'100%'}
        height={'100%'}
        sx={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
        }}
    >
        <CircularProgress />
    </Box>;
}
