import React from 'react';
import { Paper, Grid, Typography, Button, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import color from "../../constants/Colors";
import { fontSize } from '@mui/system';

import parseDateTime from 'helpers/parseDateTime'

import WeatherSensorCard from 'components/Card/WeatherSensorCard'

import icon_temperature from 'assets/icons/weatherIcon/icon-tempereture.svg'
import icon_rain from 'assets/icons/weatherIcon/icon-rain.svg'
import icon_air from 'assets/icons/weatherIcon/icon-air.svg'
import SelectBtn from 'components/Button/SelectBtn';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export interface Data {
    name: string;
    value: any;
    value2?: number;
    unit: string;
    unit2?: string;
    status?: string;
    batt?: string | number;
    solar?: string | number;
    tag?: string;
    enlarge?: boolean;
    isError?: boolean;
    errMsg?: string;

}

export interface IUtilityButton {
    label: string;
    onClick: () => void;
}

export interface IDetailsProps {
    id?: string;
    name: string;
    state?: string;
    date?: string;
    data?: Data[];
    WeatherData?: any;
    location?: string;
    path?: string;
    onTapSeeDashboard?: () => void;
    utilButton?: JSX.Element;
    isSelected?: boolean;
    onTapSelected?: (deviceId: string, preState: boolean, curState: boolean) => void;
}


const DetailsCard: React.FC<IDetailsProps> = ({ ...props }) => {
    let history = useHistory();
    const textColor = props.state === "Online" ? color.statusOn : color.statusOff;
    return (
        <Grid
            id={`detail-card-${props.id}`}
            width="100%"
            height="100%"
            padding="8px"
            container
            spacing={1}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
            border="1px solid #E9EBF0"
            borderRadius="8px"
            bgcolor="#fff"
        >
            {/* Row 1 name id */}
            <Grid container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                wrap="wrap"
                // gap="5px"
                alignItems="baseline"
            >
                {/* Name (ID: xxxxx) */}
                <Typography variant="h6" color="initial" fontWeight="500" sx={{ color: "#000", marginRight: "8px" }}>
                    {props.name}
                </Typography>
                <Typography variant="body2" color="initial" sx={{ color: "#868788", fontSize: "0.75rem" }}>
                    {(`( ${props.id} )`).toUpperCase()}
                </Typography>
                {props.path === '/landslide' || props.path === '/piezometer'
                    ? <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        <SelectBtn deviceId={props.id} isSelected={props.isSelected === undefined ? false : props.isSelected} onTapSelected={props.onTapSelected} />
                    </div>
                    : null
                }
            </Grid>

            {/* Row 2 status date */}
            <Grid container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                justifyContent="space-between"
            >
                {/* Status  */}
                {props.state !== undefined ?
                    <Grid item xs={50} sm={50} md={50} lg={50} xl={50}
                        container
                        gap="5px"
                        alignItems="center"
                    >
                        <Typography variant="body2" color="initial" sx={{ color: "#000", fontSize: "0.8rem" }}>
                            {props.state}
                        </Typography>
                        <Paper variant="outlined" sx={{
                            height: "8px",
                            width: "8px",
                            backgroundColor: getStatusColor(props.state),
                            borderRadius: "50%",
                            display: "inline-block",
                        }} />
                    </Grid>
                    : null}

                {/* DateTime */}
                <Typography variant="body2" color="initial" sx={{ color: "#868788", fontSize: "0.7rem" }}>
                    {parseDateTime(props.date)}
                </Typography>


            </Grid>

            {/* Row 3 values */}
            <Grid container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                rowSpacing="5"
                marginTop="5%"
            // columnSpacing="5px"
            >
                {/*
                        Title
                        value unit
                    */}
                {props.data?.map((e: Data, index: number) => {
                    let txtColor = e.status !== undefined ? (e.status === "Online" ? color.statusOn : color.statusOff) : textColor;
                    txtColor = e.isError ? color.statusWarning:txtColor;
                    return (
                        // <Grid item xs={33} sm={33} md={33} lg={33} xl={33}
                            <Grid item xs={50} sm={50} md={50} lg={50} xl={50}
                                key={`${index}_${e.name}_Grid`}
                                container
                                direction="column"
                                paddingLeft="8px"
                            >
                                <Grid item container gap={1}>
                                    <Typography
                                        key={`${index}_${e.name}_Typography`}
                                        variant={e.enlarge ? "h6" : "body2"}
                                        color="initial"
                                        fontWeight="700"
                                        sx={{ color: txtColor }}>
                                        {e.name}
                                    </Typography>
                                    { e.isError &&
                                        <Tooltip title={e.isError ? (e.errMsg || "") : ""} >
                                            <WarningRoundedIcon sx={{ color: "#FFCD1B" }} />
                                        </Tooltip>
                                    }
                                </Grid>
                                {typeof e.value === 'string' || typeof e.value === 'number'
                                    ? (
                                        <Grid item
                                            key={`${index}_${e.name}_Grid_2`}
                                            container
                                            alignItems="baseline"
                                            gap="5px"
                                        >

                                            <Typography variant={e.enlarge ? "h5" : "body1"} color="initial"
                                                key={`${index}_${e.name}_Typography_2`}
                                                fontWeight="600"
                                                sx={{ color: txtColor }}>
                                                {
                                                    typeof e.value === 'number'
                                                        ? e.value.toFixed(2)
                                                        : e.value === ""
                                                            ? e.value
                                                            : (isNaN(+e.value) === false
                                                                ? parseFloat(e.value).toFixed(2)
                                                                : e.value)
                                                }
                                            </Typography>
                                            <Typography variant={e.enlarge ? "h6" : "body2"} color="initial"
                                                key={`${index}_${e.name}_Typography_3`}
                                                sx={{ color: txtColor }}>
                                                {e.unit}
                                            </Typography>
                                        </Grid>
                                    )
                                    : e.value}
                            </Grid>

                    )
                })}

                {/* Row 5 Utility button */}
                {
                    (props.utilButton != undefined)
                        ? <Grid container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                            justifyContent="center"
                            marginTop="5%"
                        >
                            {props.utilButton}
                            {/* <GetDashboardButton variant="outlined" type="submit" onClick={props.utilButton.onClick}>{props.utilButton.label}</GetDashboardButton> */}
                        </Grid>
                        : null
                }


                {
                    props.WeatherData?.map((e: any, i: number) => <Grid
                        key={`${i}_${e.name}_Grid`}
                        container rowSpacing={2} columnSpacing={2} direction="row" columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
                        {/* <Grid item xs={100} sm={100} md={100} lg={100} xl={100} >
                            <WeatherSensorCard
                                tag={"air"}
                                title={"Air Quality"}
                                // value={0}
                                // unit={"ppt"}
                                // img={icon_air}
                                pm2_5={e.pm_25}
                                pm1_0={e.pm_10}
                                pm2_5_unit={"ug/m"}
                                pm1_0_unit={"ug/m"}
                            />
                        </Grid> */}
                        {/* <Grid item xs={100} sm={100} md={100} lg={50} xl={50} >
                            <WeatherSensorCard
                                tag={"temperature"}
                                title={"Temperature"}
                                value={e.temperature}
                                unit={"°C"}
                                // tmin={0}
                                // tmax={0}
                                img={icon_temperature}
                            />
                        </Grid> */}

                        <Grid
                            key={`${i}_${e.name}_Grid_2`}
                            item xs={100} sm={100} md={100} lg={60} xl={60} >
                            <WeatherSensorCard
                                key={`${i}_${e.name}_WeatherSensorCard`}
                                tag={"rain"}
                                title={"Rain"}
                                // value={e.rain_24hr}
                                // unit={"mm"}

                                rain={e.rain}
                                rain_24h={e.rain_24hr}

                                img={icon_rain}
                                humidity={e.humidity}
                                temperature={e.temperature}
                                t_unit={"°C"}
                                pressure={''}
                                h_unit={"%"}
                                p_unit={"kPa"}
                                state={e.state}
                            />
                        </Grid>
                        <Grid
                            key={`${i}_${e.name}_Grid_3`}
                            item xs={100} sm={100} md={100} lg={40} xl={40} >
                            <WeatherSensorCard
                                key={`${i}_${e.name}_WeatherSensorCard_2`}
                                tag={"wind"}
                                title={"Wind Speed"}
                                value={e.wind_speed}
                                unit={"m/s"}
                                from={e.wind_direction}
                                f_unit={"°"}
                                state={e.state}

                            />
                        </Grid>
                    </Grid>)
                }


            </Grid>

            {/* Row 4 coordinate */}
            {/* <Grid container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
            >

                <Grid item xs={100} sm={100} md={100} lg={100} xl={100}
                    container
                    direction="column"
                    alignItems="center"
                >
                    <Typography
                        variant="subtitle1"
                        color="initial"
                        fontWeight="600"
                        sx={{ color: "#000" }}>
                        {"Coordinate"}
                    </Typography>
                    <Typography variant="body1" color="initial"
                        sx={{ color: "#4C4C4C" }}>
                        {props.location}
                    </Typography>
                </Grid>

            </Grid> */}



            {/* Row 6 see dashboard //center */}
            {
                (props.path != undefined)
                    ? <Grid container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                        justifyContent="center"
                        marginTop="5%"
                    >
                        <GetDashboardButton variant="outlined" type="submit" onClick={props.onTapSeeDashboard === undefined ? () => {
                            history.push(`${props.path}/device/${props.id}`)
                        } : props.onTapSeeDashboard}>ดูแดชบอร์ด</GetDashboardButton>
                    </Grid>
                    : null
            }
        </Grid >
    )
}

export default DetailsCard

export const GetDashboardButton = styled(Button)`
    border: 1px solid ${color.iconBlue};
    color: ${color.iconBlue};
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
`

function getStatusColor(STATE: string | undefined) {
    // OFFLINE: 0,      #C4C4C4
    // ONLINE: 1,       #62BC3B
    // OFF: 2,          #C4C4C4
    // ON: 3,           #62BC3B
    // WARNING: 4,      #FFDD28
    // ERROR: 5,        #E24526
    if (STATE === "Online") {
        return "#62BC3B"
    } /* else if (STATE === L._STATUS.OFF){
      return "#C4C4C4"
    } else if (STATE === L._STATUS.ON){
      return "#62BC3B"
    } else if (STATE === L._STATUS.WARNING){
      return "#FFDD28"
    } else if (STATE === L._STATUS.ERROR){
      return "#E24526"
    } */ else {
        return "#C4C4C4"
    }
}
