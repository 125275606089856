import React, { FunctionComponent, useEffect, useState } from "react";
import DetailCardList, { IDataStation } from "components/List/DetailCardList";
import { usePageContext } from "./GpsTrackingPage";
import * as GpsTrackingAPI from "api/useGpsTrackingAPI";

import parseDateTime from 'helpers/parseDateTime'
import { useHistory } from "react-router-dom";
import { SimpleLoading } from "components/Loading/SimpleLoading";
import { coordinateDecimalToMineGrid } from "helpers/geo"

export const SortByOptions: Record<string, any> = {
  'Device ID': 'device_id',
  'Device Name': 'device_name',
  'Altitude': 'real_altitude',
  'Speed': 'speed',
}

interface CurrentDataListProps {
  sortby: string;
}

export const CurrentDataList: FunctionComponent<CurrentDataListProps> = (props) => {
  const { stateContext, dispatchContext } = usePageContext();
  const [dataStationList, setDataStationList] = useState<IDataStation[]>([])
  const history = useHistory()
  useEffect(() => {
    (async () => {
      sortDataList();
      const datas: IDataStation[] = stateContext.curSensorList.map((e: any) => {
        const {
          device_id,
          device_name,
          power_pri,
          power_sec,
          state,
          latitude,
          longitude,
          altitude,
          real_altitude,
          speed,
          timestamp,
        } = e;

        const mineGrid = coordinateDecimalToMineGrid(latitude, longitude);

        return {
          id: device_id,
          name: device_name,
          date: timestamp,
          state: state,
          data: [
            { name: "MineN", value: mineGrid.mineN, unit: "" },
            { name: "MineE", value: mineGrid.mineE, unit: "" },
            { name: "Altitude", value: real_altitude, unit: "m" },
            { name: "Speed", value: speed, unit: "km/hr" },
          ],
          location: `${latitude}, ${longitude}`,
          path: "/gpstracking",
          onTapSeeDashboard: () => {
            history.push(`/gpstracking/device/${device_id}`)
          }
        };
      });
      setDataStationList(datas);
    })();
  }, [stateContext.curSensorList, props.sortby]);

  return dataStationList.length === 0 ? <SimpleLoading /> : <DetailCardList dataList={dataStationList} path="/gpstracking" />;

  function sortDataList() {
    if (SortByOptions[props.sortby] === undefined) {
      return
    }
    if (SortByOptions[props.sortby] === 'device_id' || SortByOptions[props.sortby] === 'device_name') {
      stateContext.curSensorList.sort((a: any, b: any) => (a[SortByOptions[props.sortby]] > b[SortByOptions[props.sortby]] ? 1 : -1));
    } else {
      stateContext.curSensorList.sort((a: any, b: any) => (a[SortByOptions[props.sortby]] < b[SortByOptions[props.sortby]] ? 1 : -1));
    }
  }

}
