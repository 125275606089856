import React, { FunctionComponent, useEffect, useState } from "react";
import DetailCardList, { IDataStation } from "components/List/DetailCardList";
import { usePageContext } from "./TruckPage";
import * as TruckAPI from "api/useTruckAPI";
import { defaultTruckConfigItem } from "api/useConfigSettingAPI";
import parseDateTime from "helpers/parseDateTime";
import parseTime from "helpers/parseTime";
import { SimpleLoading } from "components/Loading/SimpleLoading";
import { format } from "date-fns";


export const SortByOptions: Record<string, any> = {
  'Device ID': 'device_id',
  'Device Name': 'device_name',
}

interface CurrentDataListProps {
  sortby: string;
}

export const CurrentDataList: FunctionComponent<CurrentDataListProps> = (props) => {
  const { stateContext, dispatchContext } = usePageContext();
  const [dataStationList, setDataStationList] = useState<IDataStation[]>([])
  useEffect(() => {
    (async () => {
      await sortDataList();
      const datas: IDataStation[] = stateContext.curSensorList.map((e: any) => {
        const {
          device_id,
          device_name,
          timestamp,
          latitude,
          longitude,
          state,
          qr_detected_at,
          qr_detected_image,
          dump_lift_image,
          qr_value
        } = e;
        // let device_state = TruckAPI.MapStateToDeviceCardState[state];
        // let date: Date = new Date(timestamp);
        // let dataName: string = state === 'Online' ? 'ทำงาน' : 'ไม่ทำงาน';
        return {
          id: device_id,
          name: device_name,
          date: new Date(timestamp).toLocaleString(),
          state: state,
          data: [
            { name: "หมายเลขรถ", value: qr_value === '' ? "-" : qr_value, unit: "" },
            { name: "", value: '', unit: "" },
            { name: "รถเข้า Crusher", value: format(new Date(qr_detected_at), 'HH:mm'), unit: "" },
            { name: "รถเทท้าย", value: format(new Date(timestamp), 'HH:mm'), unit: "" },
            { name: "", value: <img src={process.env.REACT_APP_API_BASE_URL + qr_detected_image} alt="detected-image" width="100%" />, unit: "" },
            { name: "", value: <img src={process.env.REACT_APP_API_BASE_URL + dump_lift_image} alt="dump-lift-image" width="100%" />, unit: "" },
            // { name: "Latitude", value: latitude, unit: "" },
            // { name: "Longitude", value: longitude, unit: "" },

          ],
          location: `${latitude}, ${longitude}`,
          // utilButton: <DeviceSettingButton curSensorItem={e} curConfigItem={stateContext.curConfigList.find(item => item.device_id === device_id) ?? defaultTruckConfigItem} />
        };
      });
      setDataStationList(datas);
    })();
  }, [stateContext.curSensorList, props.sortby]);

  return dataStationList.length === 0 ? <SimpleLoading /> : <DetailCardList dataList={dataStationList} path="/drump_truck" />;

  async function sortDataList() {
    if (SortByOptions[props.sortby] === undefined) {
      return
    }
    if (SortByOptions[props.sortby] === 'device_id' || SortByOptions[props.sortby] === 'device_name') {
      stateContext.curSensorList.sort((a: any, b: any) => (a[SortByOptions[props.sortby]] > b[SortByOptions[props.sortby]] ? 1 : -1));
    } else {
      stateContext.curSensorList.sort((a: any, b: any) => (a[SortByOptions[props.sortby]] < b[SortByOptions[props.sortby]] ? 1 : -1));
    }
  }
}
