import { useEffect, useRef, useState } from "react";
import { DialogData } from "./EditPermissionDialog";
import { UpdateUser } from "api/useUserManagementAPI";
import SnackbarUtils from '../../../../helpers/utils';

const useEditPermissionDialog = (data: DialogData, reloadfn: ()=> void) => {
    const { employeeNumber, role, allPermission } = data;
    const [permissions, setPermissions] = useState([] as string[]);
    const [selectedRole, setSelectedRole] = useState("User");
    const [selectedAllPermission, setSelectedAllPermission] = useState(false);
    useEffect(() => {
        setPermissions(data.permissions);
        calculateSelectedAll(data.permissions, data.role);
        setSelectedRole(data.role);

    }, [data])
    
    const calculateSelectedAll = (permissions: string[], selectedRole: string) => {
        let isSelectAll = selectedRole === "Admin" || allPermission.every((permission) => permissions.includes(permission.name));
        setSelectedAllPermission(isSelectAll);
    };

    const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setSelectedRole((event.target as HTMLInputElement).value);
        calculateSelectedAll(permissions, (event.target as HTMLInputElement).value);
    };

    const handleSelectPermission = (event: React.ChangeEvent<HTMLInputElement>, name:string) => {
        let newPermissions = [...permissions];
        if (permissions.includes(name)) {
            newPermissions = permissions.filter((permission) => permission !== name);
        } else {
            newPermissions.push(name);
        }
        setPermissions(newPermissions);
        calculateSelectedAll(newPermissions, selectedRole);
    }

    const handleAllPermission = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedAllPermission(event.target.checked);
        let newPermissions = [] as string[];
        if (event.target.checked) {
            allPermission.forEach((permission) => {
                newPermissions.push(permission.name);
            });
        }
        setPermissions(newPermissions);
    }

    const handleEditUserPermission = () => {
        let role = selectedRole.toLowerCase();
        let newPermissions = [] as string[];
        if (role === "user") {
            newPermissions = permissions;
        }
        newPermissions.push(role);
        SnackbarUtils.warning("Updating user permission...");
        UpdateUser(data.employeeID, newPermissions).then((result) => {
            console.log("Update user permission successfully", result);
            SnackbarUtils.success("Update user permission successfully");
            reloadfn();
        }).catch((err) => {
            console.error("Update user permission failed", err);
            SnackbarUtils.error("Update user permission failed");
        });

    }

    return {
        permissions,
        employeeNumber,
        selectedRole,
        selectedAllPermission,
        handleChangeRole,
        handleSelectPermission,
        handleAllPermission,
        handleEditUserPermission
    };
}

export default useEditPermissionDialog;