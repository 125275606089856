import { ISensorItem } from "api/useTruckAPI";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { addHours, format } from "date-fns";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid'
import { CSVLink } from "react-csv";
import { ToolButton, HeaderCell } from "./Styles";
import Typography from '@mui/material/Typography'
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";




interface CountingTableByTimeRangeProps {
    row: any[];
    column: any[];
}

const CountingTableByTimeRange: FunctionComponent<CountingTableByTimeRangeProps> = (props) => {
    const {
        row,
        column,
    } = props;

    const rows = row;
    const columns = column;


    return (
        <Paper elevation={1} style={{ height: 'fit-content', width: '100%', backgroundColor: '#fff' }}>
            <div
                className="container"
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                }}
            >
                {row[row.length - 1].find((e: any) => (typeof e === 'number' && e > 0)) === undefined &&
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            bottom: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    >
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            width={"100%"}
                            height={"100%"}
                        >
                            <Typography variant="h3" color="error">ไม่มีข้อมูล</Typography>
                        </Grid>
                    </div>
                }
                <TableContainer sx={{ maxHeight: 688 }}>
                    <Table stickyHeader size="small" aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((name: any, index: number) => (
                                    <HeaderCell key={`header-cell-${name}-${index}`} variant="head" align="center" colSpan={1} >{name}</HeaderCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .map((row: any, index: number) => {
                                    return (
                                        <TableRow key={`data-row-${index}`}>
                                            {row.map((e: any, idx: number) => {
                                                return (
                                                    <TableCell key={`data-row-${index}-cell-${idx}`} variant="body" align="center" colSpan={1} sx={{ border: "1px solid #c4c4c4" }}>
                                                        <Typography variant="body1" color="initial" fontWeight={(index === rows.length - 1) ? 700 : 400} >
                                                            {e !== 0 ? e : ""}
                                                        </Typography>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Paper>
    );
}

export default CountingTableByTimeRange;
