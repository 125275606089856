import { FunctionComponent, useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ISensorItem, reqDeviceData } from "api/useGpsTrackingAPI";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, TextField, Theme } from "@mui/material";
import { usePageContext } from "./GpsTrackingPage";
import { SxProps } from "@mui/system";
import { coordinateDecimalToMineGrid } from "helpers/geo"

enum ValueType {
    Lat = 'Latitude',
    Long = 'Longitude',
    Alt = 'Altitude'
}

interface IRowDataItem {
    id: number | string;
    name: string;
    val_1_00: string[];
    val_5_00: string[];
    val_9_00: string[];
    val_13_00: string[];
    val_17_00: string[];
    val_21_00: string[];
}

interface IColumnDataItem {
    field: string;
    headerName: string; /* Properties of IRowDataItem */
    width: number;
    sortable: boolean;
}

interface TrackingTableProps {

}

const TrackingTable: FunctionComponent<TrackingTableProps> = () => {
    const interestTimeList: string[] = ['01:00:00', '05:00:00', '09:00:00', '13:00:00', '17:00:00', '21:00:00'];
    const headColor = "#001E32"
    const textHeadColor = "#fff"
    const headStyle: SxProps<Theme> = { border: "1px solid #c4c4c4", bgcolor: headColor, color: textHeadColor, fontSize: 12, fontWeight: 700, padding: "8px" }
    const col2LineStyle: SxProps<Theme> = {
        border: '0',
        padding: '4px 8px',
        fontSize: 14,
        minWidth: 100,
        '& :last-child': {
            fontSize: 10,
            marginTop: '2px',
            color: 'gray'
        }
    }
    const head2LineStyle: SxProps<Theme> = {
        ...col2LineStyle,
        bgcolor: headColor,
        color: textHeadColor,
        fontWeight: 700,
        '& :last-child': {
            fontSize: 10,
            marginTop: '-6px'
        }
    }
    const { stateContext, dispatchContext } = usePageContext();
    const [rowDataList, setRowDataList] = useState<IRowDataItem[]>([]);
    const [dateValue, setDateValue] = useState<Date>(new Date());

    useEffect(() => {
        if (stateContext.curSensorList.length === 0) {
            return
        }
        (async () => {
            // let interestTimeList: string[] = ['01:00:00', '05:00:00', '09:00:00', '13:00:00', '17:00:00', '21:00:00'];
            let row: IRowDataItem[] = []
            makeRowTemplate(row);
            let deviceDataList: ISensorItem[] = []
            for (let index = 0; index < interestTimeList.length; index++) {
                const element = interestTimeList[index];
                let lstDeviceID: string[] = [];
                let deviceItem = await getDeviceData(new Date(dateValue.toDateString() + ` ${element}`), new Date(`${dateValue.toDateString()} ${element.replace("00:00", "59:59")}`))
                console.log('deviceItem', deviceItem);

                deviceItem.forEach((item: ISensorItem) => {
                    if (!lstDeviceID.includes(item.device_id)) {
                        lstDeviceID.push(item.device_id)
                        deviceDataList.push(item)
                        if (row.find((e: IRowDataItem) => (e.name === item.device_name)) === undefined) {
                            row.push(
                                {
                                    id: `${item.timestamp}_${item.device_id}`,
                                    name: item.device_name,
                                    val_1_00: ["", "", ""],
                                    val_5_00: ["", "", ""],
                                    val_9_00: ["", "", ""],
                                    val_13_00: ["", "", ""],
                                    val_17_00: ["", "", ""],
                                    val_21_00: ["", "", ""],
                                }
                            )
                        }

                        row.forEach((rowItem: Record<string, any>) => {
                            if (rowItem.name === item.device_name/*  && rowItem.value_type === ValueType.Lat */) {
                                const key: string = `val_${parseInt(element.slice(0, 2))}_00`;
                                rowItem[key] = [item.latitude, item.longitude, item.real_altitude]
                            } /* else if (rowItem.name === item.device_name && rowItem.value_type === ValueType.Long) {
                                const key: string = `val_${parseInt(element.slice(0, 2))}_00`;
                                rowItem[key] = item.longitude
                            } else if (rowItem.name === item.device_name && rowItem.value_type === ValueType.Alt) {
                                const key: string = `val_${parseInt(element.slice(0, 2))}_00`;
                                rowItem[key] = item.altitude
                            } */
                        });

                    }
                });
            }
            setRowDataList(row.sort((a: any, b: any) => (a.name > b.name ? 1 : -1)))




        })()
    }, [dateValue, stateContext.curSensorList]);

    function makeRowTemplate(row: IRowDataItem[]) {
        stateContext.curSensorList.forEach((element, index) => {
            if (row.find((e: IRowDataItem) => (e.name === element.device_name)) === undefined) {
                row.push(
                    {
                        id: `${index}_${element.device_id}`,
                        name: element.device_name,
                        val_1_00: ["", "", ""],
                        val_5_00: ["", "", ""],
                        val_9_00: ["", "", ""],
                        val_13_00: ["", "", ""],
                        val_17_00: ["", "", ""],
                        val_21_00: ["", "", ""],
                    }
                );
            }
        });
    }
    async function getDeviceData(fromDate: Date, toDate: Date) {
        const res = await reqDeviceData([fromDate, toDate]);
        if (res.status != 200) {
            return [];
        }
        const { items } = res.data;
        return items
    }

    return (
        <Grid
            container
            spacing={1}
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            margin={0}>
            <Paper elevation={0} style={{ height: 'fit-content', width: 'fit-content', margin: '4px', alignSelf: 'flex-end' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label=""
                        value={dateValue}
                        onChange={(newValue) => {
                            if (newValue != null) {
                                setDateValue(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Paper>
            <Paper elevation={1} style={{ height: 'fit-content', width: '100%', backgroundColor: '#fff' }}>
                <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell variant="head" align="center" colSpan={1} sx={headStyle} >Time</TableCell>
                                {interestTimeList.map((time: string) => (<TableCell key={time} variant="head" align="center" colSpan={3} sx={headStyle} >{time}</TableCell>))}
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head" align="center" colSpan={1} sx={headStyle} >Name</TableCell>
                                {interestTimeList.map((time: string, index: number) => (
                                    <TableCell key={`${index}_${time}`} variant="head" align="center" colSpan={3} sx={headStyle} >
                                        <TableCell variant="head" align="center" sx={head2LineStyle} >
                                            <div>MineN</div>
                                            <div>(Latitude)</div>
                                        </TableCell>
                                        <TableCell variant="head" align="center" sx={head2LineStyle} >
                                            <div>MineE</div>
                                            <div>(Longitude)</div>
                                        </TableCell>
                                        <TableCell variant="head" align="center" sx={{ ...head2LineStyle, minWidth: 70, verticalAlign: 'top' }} >
                                            Altitude
                                        </TableCell>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowDataList.map((e: Record<string, any>, index: number) => {
                                return (
                                    <TableRow key={`${index}_body_row`}>
                                        {Object.keys(e).map((key: string) => {
                                            const element = e[key];
                                            if (key === 'id') {
                                                return null
                                            } else if (key === 'name') {
                                                return (
                                                    <TableCell variant="body" align="center" colSpan={1} sx={{ border: "1px solid #c4c4c4" }} >{element}</TableCell>
                                                )
                                            } else {
                                                const mineGrid = coordinateDecimalToMineGrid(element[0], element[1]);
                                                return (
                                                    <TableCell variant="body" align="center" colSpan={3} sx={{ border: "1px solid #c4c4c4", padding: "8px" }} >
                                                        <TableCell variant="body" align="center" sx={col2LineStyle} >
                                                            <div>{(element[0] === "") || mineGrid.mineN.toFixed(2)}</div>
                                                            <div>{element[0]}</div>
                                                        </TableCell>
                                                        <TableCell variant="body" align="center" sx={col2LineStyle} >
                                                            <div>{(element[1] === "") || mineGrid.mineE.toFixed(2)}</div>
                                                            <div>{element[1]}</div>
                                                        </TableCell>
                                                        <TableCell variant="body" align="center" sx={{ ...col2LineStyle, minWidth: 70 }} >
                                                            {element[2]}
                                                        </TableCell>
                                                    </TableCell>
                                                )
                                            }
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>
    );
}

export default TrackingTable;
