import React, { useState } from "react";
import styled from "styled-components";

import {
  Paper,
  Grid,
  Typography,
  Box,
} from "@mui/material"
import color from "../../constants/Colors";

import icon_warning from "assets/icons/icon-warning.png"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useHistory } from "react-router-dom";

interface ISensorsProps {
  id?: number | string;
  name?: string;
  date?: string;
  value?: number | string;
  unit?: string;
  province?: string;
  status?: string;
  isonline?: string;
  img?: JSX.Element;
  normally?: boolean;
  normallyValue?: number;
  open?: string;
  online?: string;
  tag?: string;
}

const SensorCard: React.FC<ISensorsProps> = ({ ...props }) => {
  let history = useHistory();
  const mapTagToPath: Record<string, string> = {
    "gateway": "/wireless",
    "landslide": "/landslide",
    "gps_tracking": "/gpstracking",
    "ground_water": "/piezometer",
    "water_level": "/waterlevel",
    "arsenic": "/arsenic",
    "weather": "/weather",
    "conveyor": "/conveyor",
    "dump_truck": "/truck",
  }
  const handleCardClick = () => {
    if (props.tag && mapTagToPath[props.tag]) {
      history.push(mapTagToPath[props.tag]);
    }
  };
  return (
    <Item style={{ cursor: "pointer" }} onClick={handleCardClick} >
      <Grid container direction="column">

        <Grid item container direction="row" justifyContent="space-between">
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 1,
                mt: 1,
                bgcolor: 'white',
                flexWrap: 'wrap'
              }}
              style={{ marginLeft: (props.name ? "8px" : "0px") }}
            >
              <NormalText>{props.name}</NormalText>
            </Box>
          </Grid>
        </Grid>
        {/* normally */}
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              m: 1,
              bgcolor: 'white',
              flexWrap: 'wrap',
            }}
            style={{ margin: (props.normally !== undefined || props.value ? "8px" : "0px") }}
          >
            {
              props.normally === undefined ?
                <BoxValue >
                  <Value>{props.value}</Value> <Unit>{props.unit}</Unit>
                </BoxValue> :
                <BoxNormallyStatus>
                  {
                    props.normally ? <div style={{ color: "#62BC3B" }}>ค่าปกติ</div> :
                      <div style={{ color: "#E24526", height: "28px" }}>{`${props.normallyValue} ผิดปกติ`}
                        <img src={icon_warning} />
                      </div>
                  }
                </BoxNormallyStatus>
            }
            <BoxIcon>
              {
                props.img
              }
            </BoxIcon>
          </Box>
        </Grid>
        {/* province */}
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              m: 1,
              bgcolor: 'white',
              flexWrap: 'wrap'
            }}
            style={{ margin: (props.province ? "8px" : "0px") }}
          >
            <NormalText>{props.province}</NormalText>
          </Box>
        </Grid>
        {/* date */}
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              m: 1,
              bgcolor: 'white',
              flexWrap: 'wrap'
            }}
            style={{ margin: (props.date ? "8px" : "0px") }}
          >
            <NormalTextDate>{props.date}</NormalTextDate>
          </Box>
        </Grid>
        {/* status */}
        <Grid item style={{ display: (props.status === undefined && props.isonline === undefined ? "none" : "block") }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              m: 1,
              bgcolor: 'white',
              flexWrap: 'wrap'
            }}
            style={{ margin: (props.status !== undefined ? "8px" : "0px") }}
          >
            {
              <>
                {props.status === "Online" ? <CheckCircleIconOn /> : <CheckCircleIconOff />} <Stauts>{props.status === "Online" ? "Open" : "Close"}</Stauts>
              </>
            }
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              m: 1,
              bgcolor: 'white',
              flexWrap: 'wrap'
            }}
            style={{ margin: (props.isonline !== undefined ? "8px" : "0px") }}
          >
            <>
              {props.isonline === "Online" ? <CheckCircleIconOnline /> : <CheckCircleIconOffline />} <Stauts>{props.isonline === "Online" ? "Online" : "Offline"}</Stauts>
            </>
          </Box>
        </Grid>
        {/* open */}
        {props.open ?
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                m: 1,
                bgcolor: 'white',
                flexWrap: 'wrap'
              }}
              style={{ marginTop: (props.open ? "8px" : "0px") }}
            >
              {/* <NormalTextDate>เปิด:{"    "+props.open}</NormalTextDate> */}
            </Box>
          </Grid>
          : null}
        {/* online */}
        {props.online ?
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                m: 1,
                bgcolor: 'white',
                flexWrap: 'wrap'
              }}
              style={{ marginTop: (props.online ? "8px" : "0px") }}
            >
              <NormalTextDate>online:{"    " + props.online}</NormalTextDate>
            </Box>
          </Grid>
          : null}

      </Grid>
    </Item>
  )
}

export default SensorCard

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  elevation: 0,
  textAlign: 'left',
  // color: theme.palette.text.primary,
  maxWidth: '100%',
  minWidth: '100px',
  position: "relative",

  // maxHeight: '100%',
  // minHeight: '90%',
}));

const NormalText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontSize: '120%',
  paddingBottom: theme.spacing(0.5),
}));

const NormalTextDate = styled(Typography)(({ theme }) => ({
  fontSize: '100%',
  paddingBottom: theme.spacing(0.5),
  color: 'rgba(0, 0, 0, 0.6)'
}));

const BoxValue = styled.div`
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
`
const BoxNormallyStatus = styled.div`
  font-size: 20px;
  display: flex;
  align-items: flex-end;
`
const BoxIcon = styled.div`
  width: 45%;
  height: 75%;
  position: absolute;
  z-index: 10;
  left: 55%;
  top: 25%;
`

const Value = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: rgba(7, 57, 162, 1);
`
const Unit = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: rgba(7, 57, 162, 1);
  padding-bottom: 4px;
`
const Stauts = styled.span`
  margin-left: 10px;
  font-size: '110%',
`
const CheckCircleIconOn = styled(CheckCircleIcon)(({ theme }) => ({
  color: 'rgba(22, 155, 0, 1)',
}));
const CheckCircleIconOff = styled(CheckCircleIcon)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.2)',
}));

const CheckCircleIconOnline = styled(CheckCircleIcon)(({ theme }) => ({
  color: 'rgba(22, 155, 0, 1)',
}));
const CheckCircleIconOffline = styled(CheckCircleIcon)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.2)',
}));

