import { FunctionComponent } from "react";
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { SettingButton } from "components/ThresholdSetting/ThresholdSettingView";
import { ToolButton } from "../CountingTable/Styles";
import QrMappingTable from "./QrMappingTable";
import { QrMapItem } from "api/useTruckAPI";
import { useDialogController } from "./useDialogController";
import AddEditQrMapDialog, { QrMapItemSaved } from "./AddEditQrMapDialog";



interface QrMappingDialogButtonProps {
    qrMaps: QrMapItem[];
    onAddQrMapItem?: (values: QrMapItemSaved) => void;
    onEditQrMapItem?: (values: QrMapItemSaved) => void;
    onDeleteQrMapItem?: (id: number) => void;
}

const QrMappingDialogButton: FunctionComponent<QrMappingDialogButtonProps> = (props) => {
    const {
        qrMaps,
        onAddQrMapItem,
        onEditQrMapItem,
        onDeleteQrMapItem,
    } = props;
    const { isOpen, handleDialogClose, handleDialogOpen } = useDialogController();
    return (
        <>
            <SettingButton onClick={handleDialogOpen}>{"จับคู่ QR - หมายเลขรถ"}</SettingButton>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={isOpen}
                onClose={handleDialogClose}
                aria-labelledby={"qr-mapping-dialog"}
            >
                <DialogTitle id={"qr-mapping-dialog"}>
                    {"จับคู่ QR - หมายเลขรถ"}
                </DialogTitle>
                <DialogContent>
                    <QrMappingTable qrMaps={qrMaps} onEditQrMapItem={onEditQrMapItem} onDeleteQrMapItem={onDeleteQrMapItem} />
                </DialogContent>
                <DialogActions>
                    <AddEditQrMapDialog qrMaps={qrMaps} onSave={onAddQrMapItem} >เพิ่ม</AddEditQrMapDialog>
                    <Button onClick={handleDialogClose} color="primary">ปิด</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default QrMappingDialogButton;