import React, { useState, useRef, useLayoutEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  IconButton,
  Menu,
  MenuItem,
  Stack
} from "@mui/material"

import styled from "styled-components";
import color from "../../constants/Colors";
import EditDialog from '../Dialog/EditDialog'
import DeleteDialog from '../Dialog/DeleteDialog'
import useUserContext from 'context/UserContext';

export interface Data {
  id: number;
  employeenumber: number;
  role: string;
  dashboardpermission: string;
}

const createData = (
  id: number,
  employeenumber: number,
  role: string,
  dashboardpermission: string
): Data => {
  return {
    id,
    employeenumber,
    role,
    dashboardpermission,
  };
}

export { createData }

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
}

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ backgroundColor: color.headTableBaackground }}>
      <TableRow>
        <TableCell padding="checkbox" align="left">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {headCells.length === 2 ?
          <TableCell align="right"></TableCell>
          :
          null
        }

        {numSelected > 0 ?
          <TableCell align="right">
            <IconButton aria-label="delete" size="small" color="inherit">
              <DeleteIcon />
            </IconButton>
          </TableCell>
          :
          <TableCell align="right"></TableCell>
        }
      </TableRow>
    </TableHead>
  );
}

interface BaseTableProps {
  headCells: HeadCell[];
  rows: Data[];
  menuOptions: string[];
  selectPage: string;
}

function BaseTable(props: BaseTableProps) {
  const { headCells, rows, menuOptions, selectPage } = props;
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('employeenumber');
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dialogEditOpen, setDialogEditOpen] = useState(false);
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);

  const listPermissions = useUserContext().listPermissions;
  //console.log("Base Table: " + dialogEditOpen)

  const firstRender = useRef(true);
  useLayoutEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((e) => e.employeenumber);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, employeenumber: number) => {
    const selectedIndex = selected.indexOf(employeenumber);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, employeenumber);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    //console.log(newSelected)
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (employeenumber: number) => selected.indexOf(employeenumber) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  //menu things
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuHandleClick = (event: React.MouseEvent<HTMLElement>, employeenumber: number, employeeid: number, role: string) => {
    setAnchorEl(event.currentTarget);
    setDialogEmployeeNo(employeenumber);
    setDialogEmployeeID(employeeid);
    setDialogRole(role);
  };

  const menuHandleClose = () => {
    setAnchorEl(null);
  };

  const openDialogHandler = (menu: string) => {
    menuHandleClose()
    menu === "แก้ไข" ? setDialogEditOpen(true) : setDialogDeleteOpen(true);
    // setDialogUserName(name);
    // setDialogUserEmail(email);
  }

  const [DialogEmployeeNo, setDialogEmployeeNo] = useState(0)
  const [DialogEmployeeID, setDialogEmployeeID] = useState(0);
  const [DialogRole, setDialogRole] = useState("user")

  //end menu things

  const AcceptDeclineHandler = (menu: string, employeenumber: number, employeeid: number, role: string) => {
    setDialogEmployeeNo(employeenumber);
    setDialogEmployeeID(employeeid)
    setDialogRole(role);

    menu === "accept" ? setDialogEditOpen(true) : setDialogDeleteOpen(true);
  }

  return (
    <Box >
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 20 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.employeenumber);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.employeenumber}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.employeenumber)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        width="15%"
                      >
                        {row.employeenumber}
                      </TableCell>
                      <TableCell align="left">{row.role}</TableCell>
                      <TableCell align="left">{row.dashboardpermission}</TableCell>
                      <TableCell align="right">
                        {selectPage == "user" ?
                          <div>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={(event) => menuHandleClick(event, row.employeenumber, row.id, row.role)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={menuHandleClose}
                              PaperProps={{
                                style: {
                                  maxHeight: '100%',
                                  width: '20ch',
                                },
                              }}
                            >
                              {menuOptions.map((menuOption) => (
                                <MenuItem key={menuOption} onClick={() => openDialogHandler(menuOption)}>
                                  {menuOption}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                          :
                          <Stack spacing={2} direction="row" sx={{ justifyContent: "right" }}>
                            <AcceptButton key="Accept" variant="outlined" onClick={() => AcceptDeclineHandler("accept", row.employeenumber, row.id, row.role)}>Accept</AcceptButton>
                            <DeclineButton key="Decline" variant="outlined" onClick={() => AcceptDeclineHandler("delete", row.employeenumber, row.id, row.role)}>Decline</DeclineButton>
                          </Stack>

                        }
                      </TableCell>
                      {/* <TableCell align="right"> 
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(event) => menuHandleClick(event, row.employeenumber)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={menuHandleClose}
                          PaperProps={{
                            style: {
                              maxHeight: '100%',
                              width: '20ch',
                            },
                          }}
                        >
                          {menuOptions.map((menuOption) => (
                            <MenuItem key={menuOption} onClick={() => openDialogHandler(menuOption)}>
                              {menuOption}
                            </MenuItem>
                          ))}
                        </Menu>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <EditDialog
        open={dialogEditOpen}
        setOpen={() => setDialogEditOpen(false)}
        employeeNumber={DialogEmployeeNo}
        employeeID={DialogEmployeeID}
        role={DialogRole}
        permissions={listPermissions} />
      <DeleteDialog
        open={dialogDeleteOpen}
        setOpen={() => setDialogDeleteOpen(false)}
        employeeNumber={DialogEmployeeNo}
        employeeID={DialogEmployeeID}
        role={DialogRole}
        permissions={listPermissions} />
    </Box>
  );
}

export default BaseTable;

const AcceptButton = styled(Button)`
    border: 1px solid ${color.iconBlue};
    color: ${color.iconBlue};
    box-sizing: border-box;
    border-radius: 8px;
    width: 86px;
`

const DeclineButton = styled(Button)`
    border: 1px solid ${color.buttonRed};
    color: ${color.buttonRed};
    box-sizing: border-box;
    border-radius: 8px;
    width: 86px;
    & :hover {
      border: 1px solid ${color.buttonRed};
    }
`