import React, { useEffect, useState } from "react";
import MapConveyer from 'components/Map/MapConveyer';
import axios from 'axios';
import { MapBox, usePageContext } from "./ConveyorPage";
import * as ConveyorAPI from "api/useConveyorAPI";
import { getAngle } from "helpers/calLatLongDir";
import { defaultConveyorConfigItem, IConveyorConfigItem } from "api/useConfigSettingAPI";

import getshapefile from 'helpers/getshapefile'

//API
import {
    STATUS
} from 'api/useRequest'
import { SimpleLoading } from "components/Loading/SimpleLoading";
import BaseMap, { FileType } from "components/Map/BaseMap";
import useMapContext, { getMapFileTypeData } from "context/MapContext";
import ShapeOverlay from "components/Map/ShapeOverlay";
import shp from "shpjs";
import AntPathOverlay from "components/Map/AntPath";
import { Pane, Polyline, Tooltip } from "react-leaflet";
import Typography from '@mui/material/Typography'
import { ConveyorMarkers } from "./ConveyorMarkers";


export function ConveyorMapContent() {
    const { stateContext, dispatchContext } = usePageContext();
    const mapContext = useMapContext()
    const [coordByName, setCoordByName] = useState<Record<string, any[]>>({});
    const [coordByLine, setCoordByLine] = useState<Record<string, any[]>>({});
    const [propertiesByName, setPropertiesByName] = useState<Record<string, any>>({});
    const [lineStatus, setLineStatus] = useState<Record<string, boolean>>({});
    const [baseOverlayColor, setBaseOverlayColor] = useState("");
    const [mapTFPointByName, setMapTFPointByName] = useState<Record<string, any>>({});



    useEffect(() => {
        createAntPathData();
        checkLinesStatus();
        initTransferPointData();
    }, [stateContext.curSensorList])

    return (
        <MapBox>
            {stateContext.curSensorList.length > 0 ?
                <BaseMap>

                    {coordByLine &&
                        Object.keys(coordByLine).map((line: string, index: number) => {
                            return (
                                <Pane key={`${index}_${line}`} name={`AntPathOverlay-Pane-${line}`} style={{ zIndex: 500 }}>
                                    {lineStatus[line] === undefined ?
                                        <Polyline pathOptions={{ color: baseOverlayColor, weight: 4 }} positions={coordByLine[line]} /> :
                                        <AntPathOverlay positions={coordByLine[line]} paused={(lineStatus[line] === undefined || lineStatus[line] === false) ? true : false} />
                                    }
                                </Pane>
                            )
                        })
                    }
                    {coordByName && propertiesByName && coordByLine &&
                        Object.keys(coordByName).map((name: string, index: number) => (
                            <Pane key={`${index}_${name}`} name={`Polyline-Pane-${name}`} style={{ zIndex: 501 }}>
                                <Polyline pathOptions={{ opacity: 0, weight: 4 }} positions={coordByName[name]} >
                                    <Tooltip>
                                        {propertiesByName[name] &&
                                            Object.keys(propertiesByName[name]).map((header: string) => (
                                                <div key={`${name}_${header}`}>
                                                    <Typography variant="caption" color="initial">{`${header} : ${propertiesByName[name][header]}`}</Typography>
                                                    <br />
                                                </div>
                                            ))
                                        }
                                    </Tooltip>
                                </Polyline>
                            </Pane>
                        ))
                    }

                    <ConveyorMarkers
                        stateContext={stateContext}
                        mapTFPointByName={mapTFPointByName}
                        lineStatus={lineStatus}
                    />
                </BaseMap>
                : <SimpleLoading />
            }
        </MapBox >
    );

    async function createAntPathData() {
        let files: any[] = mapContext.getFileList();
        if (files.length === 0) {
            files = await getMapFileTypeData();
            mapContext.setFileList(files);
        }
        let conveyorFile = files.find((mapFile: any) => (mapFile.file_type === FileType.ShapeFile_Conveyer));
        if (conveyorFile === undefined) {
            return;
        }
        setBaseOverlayColor(conveyorFile.color)
        const fileName = conveyorFile.file_name;
        let filePath: string = `${process.env.REACT_APP_API_BASE_URL}/api/files/${fileName}`;

        let newGeoData: any = mapContext.getOverlayData(fileName);
        if (newGeoData === undefined) {
            let data = await shp(filePath);

            mapContext.addOverlayData(fileName, data);
            newGeoData = data;
        }

        /* Line */
        let tempConveyorLine: string[] = [];
        let mapCoordByLine: Record<string, any[]> = {};
        let mamLineStatus: Record<string, any> = {};
        /* Name */
        let tempConveyorName: string[] = [];
        let mapCoordByName: Record<string, any[]> = {};
        let mapPropertiesByName: Record<string, any> = {};

        newGeoData?.features?.forEach((e: any, i: number) => {
            /* Line */
            let tempLinekey: string = e.properties?.line.replace(/\s+/g, '').toUpperCase();
            if (tempConveyorLine.includes(tempLinekey) === false) {
                tempConveyorLine.push(tempLinekey);
                mapCoordByLine[tempLinekey] = [];
            }
            /* Name */
            let tempNamekey: string = e.properties?.name.replace(/\s+/g, '').toUpperCase();
            if (tempConveyorName.includes(tempNamekey) === false) {
                tempConveyorName.push(tempNamekey);
            }

            if (e.geometry?.coordinates) {
                let newArr = e.geometry?.coordinates.map((point: number[]) => [point[1], point[0]]); /* .sort((first: number[], second: number[]) => (first[0] - second[0])) */

                /* Line */
                mapCoordByLine[tempLinekey].push(newArr);
                /* Name */
                mapCoordByName[tempNamekey] = newArr;
                mapPropertiesByName[tempNamekey] = e.properties;
            }

        });
        /* Line */

        setCoordByLine(mapCoordByLine);
        /* Name */

        setCoordByName(mapCoordByName);

        setPropertiesByName(mapPropertiesByName);


    };

    function checkLinesStatus() {
        let newVal = { ...lineStatus };
        stateContext.curSensorList.forEach(e => {
            let key = e.line.replace(/\s+/g, '').toUpperCase();
            newVal[key] = (e.state === 'Online' && e.value > 0) ? true : Boolean(newVal[key]);
        });

        setLineStatus(newVal);
    }

    async function initTransferPointData() {
        let files = mapContext.getFileList();
        let fileName = files.find((mapFile: any) => {
            let lcFileName = mapFile.file_name.toLowerCase();
            return (lcFileName.includes("transferpoint"));
        })?.file_name;
        if (fileName === undefined) {
            return;
        }

        let filePath: string = `${process.env.REACT_APP_API_BASE_URL}/api/files/${fileName}`;

        let transferPointDataList: any = mapContext.getOverlayData(fileName)?.features;

        if (transferPointDataList === undefined) {
            let data: any = await shp(filePath);
            mapContext.addOverlayData(fileName, data);
            transferPointDataList = data.features;
        }

        let newTFPointByName: Record<string, any> = {};
        transferPointDataList.forEach((e: any) => {
            let name = e.properties?.name;
            let pos = e.geometry?.coordinates;
            if (name !== undefined && pos !== undefined) {
                newTFPointByName[name] = [pos[1], pos[0]];
            }
        })

        console.log("newTFPointByName", newTFPointByName);

        setMapTFPointByName(newTFPointByName);
    }
}


