import React, { useState, useEffect } from "react";
import SettingDeviceButton from "components/Button/SettingDeviceBtn";
import DeviceSettingDialog, { DeviceType, ILandSlideSetting } from "components/Dialog/DeviceSettingDialog";
import { HEADER_TEXT, MAIN_PATH, usePageContext } from "./LandSlidePageDevice";
import { Grid } from "@mui/material";
import axios from "axios";
import { ILandSlideConfigItem, reqGetConfig, reqUploadConfig } from "api/useConfigSettingAPI";
import { useParams } from "react-router-dom";
import * as LandSlideAPI from "api/useLandSlideAPI";
import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import { DEVICES } from "api/useRequest";
import * as dateFns from "date-fns";

export function DeviceSettingButton(props: { disabled?: boolean }) {
    /* Use to set header text */
    const dispatch = useLayoutDispatch();
    const { stateContext, dispatchContext } = usePageContext();
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    let { deviceID } = useParams<{ deviceID: string; }>();
    const [settingData, setSettingData] = useState<ILandSlideSetting>({
        device_name: "",
        sampling_rate: 0,
    });

    async function uploadConfig(values: Record<string, any>) {
        let body = {
            device_id: deviceID,
            device_name: values.device_name,
            sampling_rate: parseInt(values.sampling_rate),
        }

        const res = await reqUploadConfig(DEVICES.LANDSLIDE, body);
        // if (res.status !== 200) {
        //     return;
        // }
        (async () => {
            const res = await LandSlideAPI.reqCurDeviceData();
            if (res.status != 200) {
                return [];
            }
            const items = res.data.landslide.data.items.sort((a: any, b: any) => (a.diff2d < b.diff2d ? 1 : -1));
            items.forEach((element: LandSlideAPI.ISensorItem) => {
                const { timestamp } = element;
                let date: Date = new Date(new Date(timestamp).toLocaleString());
                let hrBetweenTwoDate = dateFns.differenceInHours(new Date(), new Date(timestamp));
                element.state = hrBetweenTwoDate > 1 ? "Offline" : "Online";
            });
            const curSensor = await items.find((e: any) => e.device_id === deviceID);

            /* Set Header */
            changeHeaderText(dispatch, {
                texts: [HEADER_TEXT, `${values.device_name}`],
                link: `${window.location.protocol}//${window.location.host}${MAIN_PATH}`
            });

            dispatchContext!({ curSensorList: items, curSensorItem: curSensor });

        })();

    }

    function onDialogSave(values: Record<string, any>) {
        uploadConfig(values);
        setDialogOpen(false);
        //TODO: upload saved data
    };

    function onDialogCancel() {
        setDialogOpen(false);
    };

    function onTapSettingButton() {
        setDialogOpen(true);
    }

    useEffect(() => {
        if (isDialogOpen === true) {
            return
        }
        /* effect */
        setSettingData({
            device_name: stateContext.curSensorItem.device_name,
            sampling_rate: stateContext.curConfigItem.sampling_rate,
        });

    }, [stateContext.curConfigItem, stateContext.curSensorItem])

    return <Grid container item height="100%" width="100%">
        <SettingDeviceButton disabled={props.disabled} onTap={onTapSettingButton} />
        <DeviceSettingDialog
            isOpen={isDialogOpen}
            deviceType={DeviceType.LandSlide}
            currentValue={settingData}
            valueDescription={{
                device_name: "Device Name",
                sampling_rate: "Sampling Rate",
            }}
            description="Threshold of Top soil movement (mm)"
            onCancel={onDialogCancel}
            onSave={onDialogSave} />
    </Grid>;


}
