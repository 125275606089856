import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useLayoutDispatch, changeHeaderText } from "context/LayoutContext";
import DetailsCard from 'components/Card/DetailsCard'
import LastUpdateTextField from 'components/Datetime/LastUpdateTextField'

// component table
import BaseCurrentTable from 'components/BaseTable/BaseCurrentTable'
import {
  headCells
} from './ArsenicInterfaces'

// component map
import MapArsenic from 'components/Map/MapArsenic.js';

//API
import {
  useGetDeviceAPI,
  ArsenicItem,
  DEVICES,
  STATUS,
  useGetFile,
  FILES,
  mapresponseMapLayer,
  Layer
} from 'api/useRequest'
import useUserContext from 'context/UserContext';

import * as dateFns from 'date-fns';

import {
  Box,
  Grid,
  Typography,
  Paper,
} from "@mui/material"

import parseDateTime from 'helpers/parseDateTime'

import { CardTemplate } from 'components/Card/CardTemplate';
import { SimpleLoading } from 'components/Loading/SimpleLoading';
import { coordinateDecimalToMineGrid } from 'helpers/geo';
import SortBySelector from 'components/SelectBox/SortBySelector';

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { GraphListCard } from "../../../components/Card/GraphListCard";
import { ARSENIC_GRAPH_BASE_URL, ARSENIC_GRAPH_PANEL_ID_LIST } from "../../../constants/Graph";
import BaseMap from 'components/Map/BaseMap';
import SensorMarker from 'components/Map/Marker/SensorMarker';
import DefaultDateTimeRange from 'constants/DefaultDateTimeRage';

let TimeInterval: any
let IntervalTime: number = 60
const L = window.L;

const ArsenicPage: React.FC<RouteComponentProps> = () => {
  //fetch data
  const isLogedin = useUserContext().auth?.access_token != undefined ? true : false;;
  const isAdmin = useUserContext().permission?.permissions.includes('admin');



  // fetch data arsenic
  const ArsenicAPI = useGetDeviceAPI();

  const FatchApi = () => {
    setIsLoadingCurrentData(true)
    ArsenicAPI.mutate(DEVICES.ARSENIC, {
      onSuccess: (data: any) => {
        let newData = data?.data?.arsenic?.data?.items;
        if (newData !== undefined) {
          newData = newData.map((item: any) => {
            if (item.hasOwnProperty('latitude') || item.hasOwnProperty('longtitude')) {
              const mineGrid = coordinateDecimalToMineGrid(item.latitude, item.longitude);
              item.mine_n = mineGrid.mineN.toFixed(2);
              item.mine_e = mineGrid.mineE.toFixed(2);
            }
            return item;
          });
        }
        setArsenicListDevice(newData)
        const online = newData.filter((e: any) => (e.state === 'Online')).length;
        setCountOnline(`${online}/${newData.length}`)
        setIsLoadingCurrentData(false)
      },
    })
  }

  //end fetch data
  const dispatch = useLayoutDispatch()
  useEffect(() => {
    changeHeaderText(dispatch, "ระบบตรวจวัดสารหนู");
    (() => {
      FatchApi()
      TimeInterval = setInterval(() => {
        setLastFetchedTime(parseDateTime(new Date()))
        FatchApi()
      }, 1000 * IntervalTime);
    })()

    return () => {
      clearInterval(TimeInterval)
    }

  }, []);

  const [value, setValue] = React.useState(0);
  const [ArsenicListDevice, setArsenicListDevice] = React.useState<ArsenicItem[]>([])
  const [LastFetchedTime, setLastFetchedTime] = React.useState<string>(parseDateTime(new Date()));
  const [PickerDateTIme, setPickerDateTIme] = React.useState<Date[]>(DefaultDateTimeRange[DEVICES.ARSENIC](new Date()));
  const [LayerFile, setLayerFile] = React.useState<Layer[]>([]);

  /* push to lv 2 */
  let history = useHistory();

  const SortByOptions: Record<string, any> = {
    'Device ID': 'device_id',
    'Device Name': 'device_name',
  }
  const [countOnline, setCountOnline] = useState<string>("");
  const [sortTypeCurData, setSortTypeCurData] = useState('Device Name');
  const [isLoadingCurrentData, setIsLoadingCurrentData] = useState(true);
  function sortDataList(opt: string) {
    if (SortByOptions[opt] === undefined) {
      return
    }
    if (SortByOptions[opt] === 'device_id' || SortByOptions[opt] === 'device_name') {
      setArsenicListDevice(ArsenicListDevice.sort((a: any, b: any) => (a[SortByOptions[opt]] > b[SortByOptions[opt]] ? 1 : -1)))
    } else {
      setArsenicListDevice(ArsenicListDevice.sort((a: any, b: any) => (a[SortByOptions[opt]] < b[SortByOptions[opt]] ? 1 : -1)))
    }
  }

  const onDateTimeChange = (value: Date[]) => {
    setPickerDateTIme(value)
  }

  return (
    <DashboardContainer>
      <Grid direction="row" container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>


        {/* ====================== First row  ====================== */}
        {/* column 1 */}
        <Grid item xs={100} sm={100} md={30} lg={30} xl={30} style={{ display: "flex", alignItems: "center", }} >
          <Box id={"Topic"}>
            <NormalText>ระบบตรวจวัดสารหนู</NormalText>
          </Box>
        </Grid>

        {/* column 2 */}
        <Grid item xs={100} sm={100} md={70} lg={70} xl={70}>

          <Grid direction="row" container rowSpacing={2} columnSpacing={2} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>

            <Grid item xs={100} sm={100} md={100} lg={100} xl={100} style={{ display: "flex", justifyContent: "flex-end" }}>
              <LastUpdateTextField datetime={LastFetchedTime} onClick={() => {
                FatchApi()
                setLastFetchedTime(parseDateTime(new Date()))
              }} />
            </Grid>

          </Grid>

        </Grid>


        {/* ====================== Second row  ====================== */}

        <Grid direction="column" container item xs={100} sm={100} md={100} lg={100} xl={100}>
          <Grid direction="row" container item xs={100} sm={100} md={100} lg={100} xl={100} style={{ backgroundColor: "white" }}>
            {/* column 1 */}
            <Grid item xs={100} sm={100} md={50} lg={65} xl={65} >
              <MapBox>
                {/* component map here. */}
                {/* {
                  ElementMap === null ? <SimpleLoading /> : ElementMap
                } */}
                {ArsenicListDevice.length > 0 ?
                  <BaseMap>
                    {
                      ArsenicListDevice.map((e: ArsenicItem, i: number) => (
                        <SensorMarker
                          key={`ArsenicMarker_${i}`}
                          type={DEVICES.ARSENIC}
                          state={e.state}
                          position={[Number(e.latitude), Number(e.longitude)]}
                          label={e.device_name}
                          data={e}
                        />
                      ))
                    }
                  </BaseMap>
                  : <SimpleLoading />}
              </MapBox>
            </Grid>

            {/* column 2 */}
            <Grid
              item
              xs={100} sm={100} md={50} lg={35} xl={35}
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="stretch"
              bgcolor={'#fff'}
            // maxHeight="500px"
            >
              {!isLoadingCurrentData ? <Grid item width={'100%'}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="stretch"
                wrap="nowrap"
                padding="8px"

              >
                <Typography variant="body2" color="initial" fontWeight={600} >อุปกรณ์ออนไลน์ {countOnline}</Typography>
                <SortBySelector label="เรียงลำดับ"
                  value={sortTypeCurData}
                  options={Object.keys(SortByOptions)}
                  onSelectedChange={(opt: string) => {
                    // setSortTypeCurData("")
                    setSortTypeCurData(opt)
                    sortDataList(opt)
                  }} />
              </Grid> : <Grid item width={'100%'}
                padding="16px"
                paddingBottom="0"></Grid>}
              <Grid item width={'100%'} /* xs={100} sm={100} md={100} lg={100} xl={100} */ >
                {ArsenicListDevice.length === 0 ? <MapBox><SimpleLoading /></MapBox> : <CustomScrollBox sx={{

                  maxHeight: '445px',
                  overflow: 'auto',
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  bgcolor: "white",
                }}>
                  {ArsenicListDevice.map((e, i) => (
                    <DetailsCard key={`${i}_${e.device_id}`} id={e.device_id} name={e.device_name} date={e.timestamp} state={e.state} data={[
                      {
                        name: e.device_name,
                        value: e.percent,
                        // status: (e.state === "Online" ? "ON" : "OFF"), /** status: ON, OFF, ERROR */
                        unit: "ppb",
                      },
                      {
                        name: "",
                        value: "",
                        unit: "",
                      },
                      {
                        name: "Battery",
                        value: e.power_sec,
                        unit: "V",
                      },
                      {
                        name: "Solar Cell",
                        value: e.power_pri,
                        unit: "V",
                      }
                    ]}
                      location={`${e.latitude}, ${e.longitude}`} path="/arsenic"
                      onTapSeeDashboard={() => {
                        history.push(`/arsenic/device/${e.device_id}`)
                      }} />
                  ))}
                </CustomScrollBox>
                }
              </Grid>
            </Grid>

          </Grid>
        </Grid>

        {/* ====================== Third row  ====================== */}
        {/* column 1 */}
        <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
          <DeviceDataTab
            tabName={["กราฟ", "อุปกรณ์"]}
            tabElement={[
              <GraphListCard
                urlPrefix={ARSENIC_GRAPH_BASE_URL}
                panelIdList={ARSENIC_GRAPH_PANEL_ID_LIST}
                dateTimeRange={PickerDateTIme}
                yAxisConfigurable
              />,
              <CardTemplate>
                <BaseCurrentTable tableName={"รายละเอียดอุปกรณ์ตรวจวัดสารหนู (Arsenic)"} device={DEVICES.ARSENIC} order={"asc"} orderBy={"device_id"} tableCell={headCells} DataCurrentDevice={ArsenicListDevice} />
              </CardTemplate>
            ]}
            tabWithDatePicker={[0]}
            dateTimeRange={PickerDateTIme}
            onDateTimeChange={onDateTimeChange}
          />
        </Grid>

      </Grid>
    </DashboardContainer >
  )
}

export default ArsenicPage

const GraphElement = (props: { link: string[] }) => {
  return (
    <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0" container rowSpacing={2} columnSpacing={2}>
      {props.link !== undefined &&
        props.link.map((e: any, index: number) => {
          return (
            <Grid key={`${index}_Grid`} width="100%" item xs={100} sm={100} md={100} lg={100} xl={100}>
              <CardTemplate
                key={`${index}_CardTemplate`}
              >
                <div
                  key={`${index}_div`}>
                  <iframe
                    key={`${index}_iframe`}
                    src={e}
                    width="100%"
                    height="370"
                    frameBorder="0"
                  >
                  </iframe>
                </div>
              </CardTemplate>
            </Grid>
          )
        })
      }
    </Grid>
  )
}

const DashboardContainer = styled.div`
  margin-left: 10px;
`
const NormalText = styled(Typography)(({ theme }) => ({
  color: 'black',
  fontSize: '120%',
}));

const MapBox = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: "500px"
  },
  [theme.breakpoints.down('md')]: {
    height: "300px"
  },
}));

const CustomScrollBox = styled(Box)(({ theme }) => ({
  "> *": {
    margin: "12px 0"
  },
  '&::-webkit-scrollbar': {
    width: "6px"
  },
  '&::-webkit-scrollbar-track': {
    background: "#f1f1f1"
  },
  '&::-webkit-scrollbar-thumb': {
    background: "#888",
    borderRadius: "6px"
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: "#555"
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: '500px',
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: '300px',
    marginTop: "20px"
  },
}));
