import { LatLngExpression } from "leaflet";
import { FunctionComponent, ReactNode, useEffect, useMemo, useState } from "react";
import { LayersControl, MapContainer, Marker, Popup, TileLayer, useMap, useMapEvent, useMapEvents, WMSTileLayer } from "react-leaflet";
import DownloadButton from "./DownloadButton";
import ReactLeafletKml from 'react-leaflet-kml';
import axios from "axios";
import requestInstance from "configure/requestConfig";
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import KmlOverlay from "./KmlOverlay";
import ShapeOverlay from "./ShapeOverlay";
import SensorMarker from "./Marker/SensorMarker";
import { DEVICES } from "api/useRequest";
import IconMarker from "./Marker/IconMarker";
import { Grid, Paper, Typography } from "@mui/material";
import { coordinateDecimalToMineGrid } from "helpers/geo";
import useMapContext, { getMapFileTypeData } from "context/MapContext";

interface BaseMapProps {
    center?: LatLngExpression;
    children?: ReactNode;
}

const BaseMap: FunctionComponent<BaseMapProps> = (props: BaseMapProps) => {
    const [coords, setCoords] = useState({ lat: 0, lng: 0 });
    const [mineGrids, setMineGrids] = useState({ mineN: 0, mineE: 0 })
    useEffect(() => {
        setMineGrids(coordinateDecimalToMineGrid(coords.lat, coords.lng))
    }, [coords])



    return (
        <div
            className="container"
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    width: 'fit-content',
                    height: 'fit-content',
                    bottom: 0,
                    left: 0,
                    zIndex: 450,
                }}
            >
                {coords &&
                    <Paper variant="outlined"  >
                        <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            gap="1px"
                            padding={"2px 6px"}
                        >
                            <Grid
                                item
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="baseline"
                                wrap="nowrap"
                                gap="10px"
                            >
                                <Typography variant="caption" color="initial">{"Latitude :"}</Typography>
                                <Typography variant="caption" color="initial">{coords.lat.toFixed(5)}</Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="baseline"
                                wrap="nowrap"
                                gap="10px"
                            >
                                <Typography variant="caption" color="initial">{"Longitude :"}</Typography>
                                <Typography variant="caption" color="initial">{coords.lng.toFixed(5)}</Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="baseline"
                                wrap="nowrap"
                                gap="10px"
                            >
                                <Typography variant="caption" color="initial">{"MineN :"}</Typography>
                                <Typography variant="caption" color="initial">{mineGrids.mineN.toFixed(2)}</Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="baseline"
                                wrap="nowrap"
                                gap="10px"
                            >
                                <Typography variant="caption" color="initial">{"MineE :"}</Typography>
                                <Typography variant="caption" color="initial">{mineGrids.mineE.toFixed(2)}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>}
            </div>
            <BaseMapContent {...props} setCoords={setCoords} />
        </div>
    )
}

export default BaseMap;


// interface BaseMapContentProps {
//     center?: LatLngExpression;
//     children?: ReactNode;
// }

export enum FileType {
    ShapeFile = "ShapeFile",
    ShapeFile_Conveyer = "ShapeFile_Conveyer",
    KML = "KML",
    GeoTiff = "GeoTiff",
}

const BaseMapContent: FunctionComponent<BaseMapProps & { setCoords: React.Dispatch<React.SetStateAction<{ lat: number; lng: number; }>> }> = (props) => {
    const [mapFileList, setMapFileList] = useState<any[]>([])
    const mapContext = useMapContext()
    useEffect(() => {
        new Promise(async (resolve, reject) => {
            let files: any[] = mapContext.getFileList()
            console.log("mapContext.getFileList()", files);
            if (files.length === 0) {
                files = await getMapFileTypeData()
                mapContext.setFileList(files)
            }
            let maemohFiles = files.filter((file: any) => file.file_name.startsWith("MaemohArea"));
            files = files.filter((file) => !file.file_name.startsWith("MaemohArea"));
            files = [...maemohFiles, ...files];

            resolve(files)
        }).then((result) => {
            console.log("end Promise result", result);
            
            setMapFileList(result as any[])
        }).catch((err) => {
            console.error(err)
        });

    }, []);






    return (
        <MapContainer
            fullscreenControl
            fullscreenControlOptions={{
                position: 'topright',
            }}
            center={props.center ? props.center : [18.33839487268557, 99.71946716308595]}
            zoom={12}
            maxZoom={22}
            minZoom={1}
            style={{ height: "100%" }}

        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                maxZoom={22}
                maxNativeZoom={18}
            />
            <LayersControl
                collapsed
                sortLayers={false}
                position="topright"
            >
                {(mapFileList.length > 0) &&
                    mapFileList.map((mapFile: any, index: number) => {

                        if (mapFile.file_type === FileType.KML) {
                            return (
                                // <LayersControl.Overlay key={`${index}_${mapFile.name}`} name={mapFile.name}>
                                <KmlOverlay key={`${mapFile.name}`} fileType={mapFile} lineWidth={mapFile.name.includes('EGAT_KML') ? 1 : undefined} />
                                // </LayersControl.Overlay>
                            )
                        } else if (mapFile.file_type === FileType.ShapeFile) {
                            return (
                                <LayersControl.Overlay key={`${mapFile.name}`} name={mapFile.name}>
                                    <ShapeOverlay fileType={mapFile} />
                                </LayersControl.Overlay>
                            )
                        } else if (mapFile.file_type === FileType.GeoTiff) {
                            let filename = mapFile.file_name.split(".")[0];
                            let leadingWord = "MaemohArea";
                            let checked = (filename as string).startsWith(leadingWord);
                            return (
                                <LayersControl.Overlay key={`${mapFile.name}`} name={mapFile.name} checked={checked}>
                                    <WMSTileLayer
                                        transparent
                                        url={`${process.env.REACT_APP_API_BASE_URL}/geoserver/EGAT-IoT/wms?tiled=true`}
                                        layers={`EGAT-IoT:${filename}`}
                                        format={"image/png"}
                                        maxZoom={22}
                                        maxNativeZoom={22}
                                    />
                                </LayersControl.Overlay>
                            )
                        }
                    })
                }
            </LayersControl>
            <DownloadButton />
            <MouseMoveController setCoords={props.setCoords} />
            {props.children}
        </MapContainer>
    )
}

const MouseMoveController: FunctionComponent<{ setCoords: React.Dispatch<React.SetStateAction<{ lat: number; lng: number; }>> }> = (props) => {
    const map = useMapEvents({
        mousemove: (e) => {
            props.setCoords({ lat: e.latlng.lat, lng: e.latlng.lng });
        },
    })
    return (null);

}
