import { FunctionComponent } from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";
import fontFamily from '../../constants/Fonts';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { SaveButton } from '../Button/DialogButtonStyles';
import { useDevcieAddressSetting } from "./useDevcieAddressSetting";



interface DeviceAddressSettingProps {
    deviceId: string;
    fetchAddr: () => Promise<any>;
}

const DeviceAddressSetting: FunctionComponent<DeviceAddressSettingProps> = (props) => {
    const { deviceId, fetchAddr } = props;
    const buttonText = "เปลี่ยนที่อยู่ติดตั้ง";

    const {
        isOpen,
        address,
        addressError,
        handleDialogClose,
        handleDialogOpen,
        handleAddressChange,
        handleSave
    } = useDevcieAddressSetting(deviceId, fetchAddr);


    return (
        <>
            <CustomButton
                variant="contained"
                color="primary"
                startIcon={<EditLocationAltIcon sx={{ color: "#4C4C4C" }} />}
                size="small"
                onClick={handleDialogOpen}
            >
                {buttonText}
            </CustomButton>
            <Dialog open={isOpen} onClose={handleDialogClose} aria-labelledby="device-address-setting">
                <DialogTitle id={"device-address-setting"}>
                    {buttonText}
                </DialogTitle>
                <DialogContent>
                    {/* address field */}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="baseline"
                        alignContent="center"
                        wrap="nowrap"
                        gap={1}
                    >
                        <Typography variant="body1" color="initial">ที่อยู่ติดตั้ง :</Typography>
                        <TextField
                            id="device-address"
                            label=""
                            value={address}
                            size="small"
                            onChange={handleAddressChange}
                            error={addressError}
                            helperText={addressError ? "กรุณากรอกที่อยู่ติดตั้ง" : ""}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        color="primary"
                    >
                        ยกเลิก
                    </Button>
                    <SaveButton
                        onClick={handleSave}
                        color="primary"
                    >
                        ยืนยัน
                    </SaveButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: "8px",
    fontFamily: fontFamily,
    fontSize: "auto",
    // lineHeight: 1,

    color: theme.palette.getContrastText('#FFFFFF'),
    backgroundColor: '#FFFFFF',
    "&:hover": {
        backgroundColor: "#FFFFFF",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
            backgroundColor: '#FFFFFF'
        }
    },

}));

export default DeviceAddressSetting;