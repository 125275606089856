import React, { FunctionComponent, useEffect, useState } from "react";
import DetailCardList, { IDataStation } from "components/List/DetailCardList";
import { usePageContext } from "./LandSlidePage";
import * as LandSlideAPI from "api/useLandSlideAPI";

import parseDateTime from 'helpers/parseDateTime'
import { SimpleLoading } from "components/Loading/SimpleLoading";

export const SortByOptions: Record<string, any> = {
  'Device ID': 'device_id',
  'Device Name': 'device_name',
  'Diff 2D': 'diff2d',
  'Diff 3D': 'diff3d',
}

interface CurrentDataListProps {
  sortby: string;
  onTapSelected: (deviceId: string, preState: boolean, curState: boolean) => void;
}

export const CurrentDataList: FunctionComponent<CurrentDataListProps> = (props) => {
  const { stateContext, dispatchContext } = usePageContext();
  const [dataStationList, setDataStationList] = useState<IDataStation[]>([])
  useEffect(() => {
    (async () => {
      await sortDataList();
      const datas: IDataStation[] = stateContext.curSensorList.map((e: LandSlideAPI.ISensorItem) => {
        const {
          device_id,
          device_name,
          timestamp,
          atitude,
          latitude,
          longitude,
          state,
          mine_n,
          mine_e,
          diff2d,
          diff3d
        } = e;

        return {
          id: device_id,
          name: device_name,
          date: timestamp,
          state: state,
          data: [
            { name: "Mine N", value: parseFloat(mine_n).toFixed(2), unit: "" },
            { name: "Mine E", value: parseFloat(mine_e).toFixed(2), unit: "" },
            { name: "Diff 2D", value: diff2d, unit: "m" },
            { name: "Diff 3D", value: diff3d, unit: "m" },
            { name: "MSL", value: parseFloat(atitude).toFixed(3), unit: "m" },
            // { name: "", value: null, unit: "", status: "" },

          ],
          location: `${latitude}, ${longitude}`,
          path: "/landslide",
          isSelected: (stateContext.selectedSensor.indexOf(`${device_id}`) !== -1),
          onTapSelected: props.onTapSelected,

        };
      });

      setDataStationList(datas);
    })();
  }, [stateContext.curSensorList, stateContext.selectedSensor.length, props.sortby]);

  return dataStationList.length === 0 ? <SimpleLoading /> : <DetailCardList dataList={dataStationList} path="/landslide" />;

  async function sortDataList() {
    if (SortByOptions[props.sortby] === undefined) {
      return
    }
    if (SortByOptions[props.sortby] === 'device_id' || SortByOptions[props.sortby] === 'device_name') {
      stateContext.curSensorList.sort((a: any, b: any) => (a[SortByOptions[props.sortby]] > b[SortByOptions[props.sortby]] ? 1 : -1));
    } else {
      stateContext.curSensorList.sort((a: any, b: any) => (a[SortByOptions[props.sortby]] < b[SortByOptions[props.sortby]] ? 1 : -1));
    }
  }
}