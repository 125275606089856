import requestInstance from "configure/requestConfig";


export const GetAllPermissions = async () => {
    return requestInstance.get('/api/permissions/');
}

export const GetAllUser = async (status:string,offset?:string,limit?:string) => {
    let param = {
        status: status,
        offset: offset,
        limit: limit
    }
    return requestInstance.get('/api/users/', { params: param });
}

export const UpdateUser = async (id:string, permissionList:string[]) => {
    let body = {
        permissions: permissionList
    }
    return requestInstance.patch(`/api/users/${id}`, body);
}