import { changeHeaderText, useLayoutDispatch } from "context/LayoutContext";
import React, { useEffect, useContext, useState } from "react";
import type { RouteComponentProps } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import styled from 'styled-components'
import NormalText from '../../../constants/TextStyles';
import { WaterLevelMapContent } from "./WaterLevelMapContent";
import * as WaterLvAPI from "api/useWaterLevelAPI";
import * as dateFns from 'date-fns';
import { UpdateCurrentDataButton } from "./UpdateCurrentDataButton";
import { CurrentDataList, SortByOptions } from "./CurrentDataList";
import { defaultSensorItem, ISensorItem } from "api/useWaterLevelAPI";
import useUserContext from 'context/UserContext';
import { Typography } from "@mui/material";
import SortBySelector from "components/SelectBox/SortBySelector";

import { DeviceDataTab } from "components/DeviceDataTab/DeviceDataTab";
import { DeviceTable } from "./DeviceTable";
import { WaterQuantityTable } from "./WaterQuantityTable";
import { GraphListCard } from "../../../components/Card/GraphListCard";
import { WATER_LEVEL_GRAPH_BASE_URL, WATER_LEVEL_GRAPH_PANEL_ID_LIST } from "../../../constants/Graph";
import DefaultDateTimeRange from "constants/DefaultDateTimeRage";
import { DEVICES } from "api/useRequest";

interface IPageContext {
    curSensorList: ISensorItem[];
    curSensorItem: ISensorItem;
    allSensorList: ISensorItem[];
    dateRange: Date[];

}

const defaultContext: IPageContext = {
    curSensorList: [],
    curSensorItem: defaultSensorItem,
    allSensorList: [],
    dateRange: DefaultDateTimeRange[DEVICES.WATERLEVEL](new Date()),
}
const pageContext = React.createContext<{ stateContext: IPageContext, dispatchContext?: React.Dispatch<any>; }>({ stateContext: defaultContext });

export const usePageContext = () => useContext(pageContext);

const HEADER_TEXT = "ระบบตรวจวัดระดับน้ำ"

const WaterLevelPage: React.FC<RouteComponentProps> = () => {
    const isAdmin = useUserContext().permission?.permissions.includes('admin');

    const dispatch = useLayoutDispatch()
    const [countOnline, setCountOnline] = useState<string>("");
    const [sortTypeCurData, setSortTypeCurData] = useState('Device Name');
    const [isLoadingCurrentData, setIsLoadingCurrentData] = useState(true);

    useEffect(() => {
        /* Set page header */
        changeHeaderText(dispatch, HEADER_TEXT);
        onDateTimeChange(DefaultDateTimeRange[DEVICES.WATERLEVEL](new Date()));
        (async () => {
            setIsLoadingCurrentData(true)
            const res = await WaterLvAPI.reqCurDeviceData();
            if (res.status != 200) {
                setIsLoadingCurrentData(false)
                return [];
            }
            const items = res.data.water_level.data.items.sort((a: any, b: any) => (a.device_name > b.device_name ? 1 : -1));
            const online = items.filter((e: any) => (e.state === 'Online')).length;
            setCountOnline(`${online}/${items.length}`)
            dispatchContext!({ curSensorList: items });
            setIsLoadingCurrentData(false)
        })();

        (async () => {
            const res = await WaterLvAPI.reqDeviceData(stateContext.dateRange);
            if (res.status != 200) {
                return [];
            }
            const { items } = res.data;
            dispatchContext!({ allSensorList: items });
        })();
    }, [])
    /* Create global state in page */
    const [stateContext, dispatchContext] = React.useReducer((state: IPageContext, action: any) => ({ ...state, ...action }), defaultContext);

    const onDateTimeChange = (value: Date[]) => {
        dispatchContext!({ dateRange: value });
    };

    return (
        <pageContext.Provider value={{ stateContext, dispatchContext }}>
            <DashboardContainer>
                <Grid
                    width="100%"
                    height="100%"
                    container
                    direction="column"
                    rowSpacing={2}
                    columnSpacing={2}
                    columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                >
                    {/* <HeaderRow /> */}
                    <Grid
                        item
                        container
                        direction="row"
                        columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}
                    >
                        <Grid item xs={100} sm={100} md={30} lg={30} xl={30} container alignItems="center" >
                            <NormalText>
                                {HEADER_TEXT}
                            </NormalText>
                        </Grid>
                        <Grid item xs={100} sm={100} md={70} lg={70} xl={70}>
                            <Grid container rowSpacing={5} columnSpacing={5} columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} justifyContent="flex-end">
                                <Grid item xs={100} sm={100} md={100} lg={100} xl={100} container justifyContent="flex-end" >
                                    <UpdateCurrentDataButton />
                                    {/* {isAdmin ? <>
                                        <div style={{ marginLeft: "10px" }} />
                                        <ThresholdSettingButton sensorName='water_level' callbackSetting={(lowHigh: any) => {
                                            dispatchContext({ limit: lowHigh })
                                        }} />
                                    </> : null
                                    } */}
                                </Grid>
                                {/* <Grid item xs={100} sm={100} md={35} lg={35} xl={35} container justifyContent="flex-end" >
                                    <DownloadCsvButton />
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>


                    {/* <MapRow /> */}
                    <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }} >
                        <Grid item xs={100} sm={100} md={50} lg={70} xl={70} maxHeight="500px">
                            <WaterLevelMapContent />
                        </Grid>
                        <Grid
                            item
                            xs={100} sm={100} md={50} lg={30} xl={30}
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            alignContent="stretch"
                            bgcolor={'#fff'}
                        // maxHeight="500px"
                        >
                            {!isLoadingCurrentData ? <Grid item width={'100%'}
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                alignContent="stretch"
                                wrap="nowrap"
                                padding="16px"
                                paddingBottom="0"

                            >
                                <Typography variant="body2" color="initial" fontWeight={600} >อุปกรณ์ออนไลน์ {countOnline}</Typography>
                                <SortBySelector label="เรียงลำดับ"
                                    value={sortTypeCurData}
                                    options={Object.keys(SortByOptions)}
                                    onSelectedChange={(opt: string) => {
                                        // setSortTypeCurData("")
                                        setSortTypeCurData(opt)
                                    }} />
                            </Grid> : <Grid item width={'100%'}
                                padding="16px"
                                paddingBottom="0"></Grid>}
                            <Grid item width={'100%'}   >
                                <CurrentDataList
                                    sortby={sortTypeCurData}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <TableZone /> */}
                    <Grid item direction="row" container columns={{ xs: 100, sm: 100, md: 100, lg: 100, xl: 100 }}>
                        <DeviceDataTab
                            tabName={["สรุปปริมาณน้ำ", "กราฟ", "อุปกรณ์"]}
                            tabElement={[
                                <WaterQuantityTable />,
                                <GraphListCard
                                    urlPrefix={WATER_LEVEL_GRAPH_BASE_URL}
                                    panelIdList={WATER_LEVEL_GRAPH_PANEL_ID_LIST}
                                    dateTimeRange={stateContext.dateRange}
                                    yAxisConfigurable
                                />,
                                <DeviceTable />,
                            ]}
                            tabWithDatePicker={[1]}
                            dateTimeRange={stateContext.dateRange}
                            onDateTimeChange={onDateTimeChange}
                        />
                    </Grid >

                </Grid>
            </DashboardContainer>
        </pageContext.Provider>
    );
}

export default WaterLevelPage

const DashboardContainer = styled.div`
  margin-left: 10px;
`
