import { useEffect, useRef, useState } from "react";
import { RowItem } from "../useUserTable";

export interface PaginationProps {
    rowsPerPage: number;
    rowsPerPageOptions: number[];
    page: number;
    count: number;
    onPageChange: (event: unknown, newPage: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const usePagination = (rowsLength: number) => {
    const rowsPerPageOptions = [10, 20, 50, 100];
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const emptyRows = useRef(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsLength) : 0);
    const calculateEmptyRows = (page: number = 0, rowsPerPage: number = 10) => {
        emptyRows.current = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsLength) : 0;
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        calculateEmptyRows(newPage, rowsPerPage);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        calculateEmptyRows(0, parseInt(event.target.value, 10));
    }
    

    return {
        rowsPerPageOptions,
        rowsPerPage,
        page,
        count: rowsLength,
        emptyRows: emptyRows.current,
        onPageChange: handleChangePage,
        onRowsPerPageChange: handleChangeRowsPerPage,
    }
}

export default usePagination;