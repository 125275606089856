import { FunctionComponent } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { fontSize, width } from '@mui/system';
import { styled } from "@mui/material/styles";
import { InputBase, Typography, Grid, Container, Tooltip } from "@mui/material";
import * as dateFns from 'date-fns';
import Skeleton from '@mui/material/Skeleton';

interface DeviceAddressSelectorProps {
    address: any[];
    value: string;
    onChange?: (value: string) => void;
}

const DeviceAddressSelector: FunctionComponent<DeviceAddressSelectorProps> = (props) => {
    const { address, value, onChange } = props;

    const handleChange = (event: SelectChangeEvent) => {
        if (onChange) {
            onChange(event.target.value as string);
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="baseline"
            alignContent="center"
            wrap="nowrap"
            width="fit-content"
        >
            <Typography noWrap variant="body1" color="initial">ที่อยู่ติดตั้ง</Typography>
            {address.length > 0 ?
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                {/* <InputLabel id="demo-select-small" >ประวัติที่อยู่ติดตั้ง</InputLabel> */}
                <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    input={<SelectDeviceInput />}
                    value={value}
                    label="ประวัติที่อยู่ติดตั้ง"
                    onChange={handleChange}
                >
                    {
                        address.map((e) => (
                            <MenuItem key={`dev-addr-menu-${e.addr_des}`} value={e.addr_des}>
                            <Tooltip  title={`${(e.start_date as string).split(" ")[0]} - ${e.end_date ? (e.end_date as string).split(" ")[0]: "ปัจจุบัน"}`}
                                placement="right-start"
                            >
                                <span>
                                    {e.addr_des}
                                </span>
                            </Tooltip>
                        </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>:
            <Skeleton animation="wave" width={120} height={40} />
            
        }
        </Grid>

    );
}

const SelectDeviceInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '5px 16px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

export default DeviceAddressSelector;