import Grid from '@mui/material/Grid';
import { GraphCard } from "./GraphCard";
import useUserContext from 'context/UserContext';

interface IGraphListCardProps {
    urlPrefix: string;
    panelIdList: number[] | any[];
    deviceIdList?: string[];
    dateTimeRange: Date[];
    yAxisConfigurable?: boolean;
};

export const GraphListCard = (props: IGraphListCardProps) => {
    const accessToken = useUserContext().auth?.access_token
    const isGraphObject = (item: any) => typeof item === 'object' &&
        item.hasOwnProperty('expectId') &&
        item.hasOwnProperty('panelId');

    const GraphCardEl = (id: number, idx: number) => {
        return (
            <GraphCard
                key={`${idx}-panelId-${id}`}
                linkPrefix={props.urlPrefix + `&auth_token=${accessToken}`}
                deviceIdList={props.deviceIdList}
                panelId={id}
                dateTimeRange={props.dateTimeRange}
                yAxisConfigurable={props.yAxisConfigurable}
            />
        );
    }

    const GraphCardObject = (item: any, idx: number) => {
        if (props.deviceIdList === undefined ||
            props.deviceIdList?.length === 0 ||
            props.deviceIdList?.includes(item.expectId)) {
            return GraphCardEl(item.panelId, idx);
        }
    }

    return (
        <Grid container rowSpacing={2} columnSpacing={2}>
            { props.panelIdList.map( (item, idx) => {
                if (Array.isArray(item)) {
                    return (
                        <Grid item container rowSpacing={2} columnSpacing={2}>
                            { item.map( (nestItem) => {
                                let el;
                                if (isGraphObject(nestItem))
                                    el = GraphCardObject(nestItem, idx)
                                else
                                    el = GraphCardEl(nestItem, idx)

                                return el &&
                                    <Grid item flexBasis={400} flexGrow={1}>
                                        {el}
                                    </Grid>
                            })}
                        </Grid>
                    );
                }
                else if (isGraphObject(item)) {
                    return GraphCardObject(item, idx);
                }

                return GraphCardEl(item, idx);
            })}
        </Grid>
    );
};
