import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { Data } from "components/WaterLevelDevicesDetailTable";
import { usePageContext } from "./WaterLevelPage";
import { CardTemplate } from "components/Card/CardTemplate";
import { DEVICES } from "api/useRequest";
import BaseCurrentTable from "components/BaseTable/BaseCurrentTable";
import parseDateTime from "helpers/parseDateTime";
import { coordinateDecimalToMineGrid } from 'helpers/geo';

export function DeviceTable() {
    const { stateContext, dispatchContext } = usePageContext();
    const [dataList, setDataList] = useState<any[]>([]);
    useEffect(() => {
        const datas: any[] = stateContext.curSensorList.map((e: any) => {
            const {
                device_id,
                device_name,
                power_pri,
                power_sec,
                level,
                raw,
                state,
                latitude,
                longitude,
                timestamp,
            } = e;

            const mineGrid = coordinateDecimalToMineGrid(latitude, longitude);

            let retVal: any = {
                device_id: device_id,
                device_name: device_name,
                power_pri: power_pri,
                power_sec: power_sec,
                level: level,
                // raw: raw,
                state: state,
                latitude: latitude,
                longitude: longitude,
                mine_n: mineGrid.mineN.toFixed(2),
                mine_e: mineGrid.mineE.toFixed(2),
                timestamp,
            }
            return retVal;
        });
        setDataList(datas);
    }, [stateContext.curSensorList])

    return <Grid item xs={100} sm={100} md={100} lg={100} xl={100} p="0">
        <CardTemplate>
            <BaseCurrentTable tableName={"รายละเอียดอุปกรณ์ตรวจวัดระดับน้ำ"} device={DEVICES.WATERLEVEL} order={"asc"} orderBy={"device_id"} tableCell={headCells} DataCurrentDevice={dataList} />
        </CardTemplate>
    </Grid>;
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    isStatus?: boolean;
    isUpper?: boolean;
    isUseFormatTime?: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'device_id',
        numeric: false,
        isUpper: true,
        disablePadding: false,
        label: 'ID',
    },
    {
        id: 'device_name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
    },
    {
        id: 'state',
        numeric: false,
        isStatus: true,
        disablePadding: false,
        label: 'State',
    },
    {
        id: 'timestamp',
        numeric: false,
        isUseFormatTime: true,
        disablePadding: false,
        label: 'Last Updated',
    },
    {
        id: 'mine_n',
        numeric: true,
        disablePadding: false,
        label: 'Mine N',
    },
    {
        id: 'mine_e',
        numeric: true,
        disablePadding: false,
        label: 'Mine E',
    },
    {
        id: 'level',
        numeric: true,
        disablePadding: false,
        label: 'ค่าระดับน้ำ (mm)',
    },
    // {
    //     id: 'raw',
    //     numeric: true,
    //     disablePadding: false,
    //     label: '4-20 mA',
    // },
    {
        id: 'power_pri',
        numeric: true,
        disablePadding: false,
        label: 'Power In',
    },
    {
        id: 'power_sec',
        numeric: true,
        disablePadding: false,
        label: 'Solar Cell',
    },

];
